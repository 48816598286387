import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Sidebar from '../components/navbar';
import {  FaPlay,FaPause,FaReply } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import searchicon from '../icons/std.svg';
import add from '../icons/add.svg';
import settings from '../icons/settings.svg';
import filter from '../icons/filter.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';

import { useAuth } from '../auth.js';
import API_BASE_URL from '../components/config';

function Addcontact() {
  const navigate = useNavigate();

  const [showsecondModal, setsecShowModal] = useState(false); 
  
  const [listname, setListname] = useState('');
  const [tagname, setTagname] = useState('');
 
  const secModalOpen = () => setsecShowModal(true);
  const secleModalClose = () => setsecShowModal(false)
  const [showModal, setShowModal] = useState(false);
  const [id, setUserid] = useState('');
  const [totalsent, setTotalsent] = useState('0');
  const [unread, setTotalunread] = useState('0');
  const [deliverby, setDeliverby] = useState('0');
  const [processing, setProcessing] = useState(false);
  const [description, setDescription] = useState('');
  const [tagsucc, setTagsucc] = useState('');
  
  const [totalcontacts, setTotalContacts] = useState('0');
  const [statuscontacts, setStatuscount] = useState('0');
  const [list, setLists] = useState([]);
  const [tags, setTags] = useState([]);
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false);
  const [selectedContact, setSelectedContact] = useState({
    firstname: '',
    lastname: '',
    phone: '',
    address: '',
    email: '',
    tags: '',
    list: '',
    status: 'New',
    city: '',
    zip: '',
    state: '',
    notes: '',
  });
  const addtag = async (tagname,description,event) => {
    setProcessing(true)
    setTagsucc(false)
    event.preventDefault();

    try {
      // Send POST request to add a list
      const response = await axios.post(`${API_BASE_URL}/api/add-tag`, { id, tagname,description });
      
      setProcessing(false)
      setShowModal(false);
      const newtag = {
        id: response.data,
        tagname: tagname, 
        description:description,
    
      };
      const taghs = tags ? [...tags, newtag] : [newtag];
      setTags(taghs);
      setSelectedContact(prevState => ({
        ...prevState,
        tags: tagname // Automatically select the newly added list
      }));
    } catch (error) {
      setProcessing(false)

      console.error('Error adding list:');
    }
  };
  const addlist = async (listname,event) => {
    setProcessing(true)
    event.preventDefault();

    try {
      // Send POST request to add a list
      const response = await axios.post(`${API_BASE_URL}/api/add-list`, { id, listname });
      
      setProcessing(false)
      setsecShowModal(false);
      const newlist = {
        id: response.data,
        listname: listname, 
    
      };
      const lists = list ? [...list, newlist] : [newlist];
      setLists(lists);
      setSelectedContact(prevState => ({
        ...prevState,
        list: listname // Automatically select the newly added list
      }));
      setListname('')
    } catch (error) {
      setProcessing(false)

      console.error('Error adding list:');
    }
  };
  const formatPhoneNumber = (number) => {
    const normalizedNumber = number.replace(/\D/g, ''); 

    if (normalizedNumber.length === 0) return ''; // Handle empty input
    if (normalizedNumber.length <= 3) return `(${normalizedNumber})`; // Ensure both brackets
    if (normalizedNumber.length <= 6) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3)}`;
    if (normalizedNumber.length <= 10) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6)}`;

    // Handle numbers longer than 10 digits
    return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6, 10)}`;
};
  const handleInputChange = (e) => {
    const { id, value } = e.target;
    // console.log(id,value)
    setSelectedContact(prevState => ({
      ...prevState,
      [id]: value
    }));
  };
 
 
  
  
  
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    // Set background color
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
    
    
    const fetchContacts = async () => {
      const id = sessionStorage.getItem("login");
      // alert(id)
      if (id) {
        setAuthenticated(true);
        setUserid(id);
  
        try {
          const response = await axios.post(`${API_BASE_URL}/api/analytics`, { id });
          // console.log(response)
  
          const listh= await axios.post(`${API_BASE_URL}/api/fetch-lists`, {
            id
          });
          setLists(listh.data);

          const tags= await axios.post(`${API_BASE_URL}/api/fetch-tag`, {
            id
          });
          setTags(tags.data);
        } catch (error) {
          alert('error')
          // Handle error if necessary
        
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchContacts();
  }, []);
 
  
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setProcessing(true);
    // alert(selectedContact.phone.length )
    if (selectedContact.phone.length < 14) {
        alert('Please Enter Correct Phone Number.');
        setProcessing(false);
        return;
      }
    try {
   
      await axios.post(`${API_BASE_URL}/api/add-crm-contact`, {selectedContact,id:id});

    
      
      alert("Contact Submit Succesfully")
      navigate('/crm')
    } catch (error) {
        setProcessing(false);

      alert('Error submitting form:');
    }
  };

  if (!authenticated) {
    
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
            <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => addtag(tagname, description,event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE TAG</h4>
        </div>
        <div className="modal-body ">
            
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">Name:</label>
                <input type="text" placeholder="Tag Name" required onChange={(e) => setTagname(e.target.value)}  className="form-control" id="recipient-name"  />
              </div>
               <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Description:</label>
                <textarea type="text" name="type" className="form-control" required onChange={(e) => setDescription(e.target.value)}  />

              </div>
             
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Saving....' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal>
          {/* <Modal show={showfilterModal} contentClassName="custom-modal-content" onHide={handlefilterModalClose} backdropClassName="custom-backdrop" >
   <form id="columnForm" >
        <Modal.Header closeButton>
        </Modal.Header>
        
        <Modal.Body>
       
      
        <div class="container " style={{fontSize: '15px'}}>
          <div class="row">
            <div  class="col-md-6 float-left" style={{textAlign:'left'}}>
              <span>Lists</span>
              <br></br>
              <br></br>
              <div ><span className="ball-icon">OK</span> <span >Included</span></div>
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div ><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" style={{ }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
            <div  class="col-md-6 float-right" style={{textAlign:'right'}}>
            <div style={{textAlign:'left'}}> <span >Tags</span></div>
          
              
              <br></br>
              <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Included</span></div>
              
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" >
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
          </div>
        </div>



       

             
         
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlefilterModalClose} data-dismiss="modal" >Close</button>
            <button type="submit"  className="btn btn-primary save_web_btn" >Apply</button>
        </Modal.Footer>
        </form>
      </Modal> */}
 
 <Modal show={showsecondModal} contentClassName="custom-modal-content" onHide={secleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => addlist(listname, event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE LIST</h4>
        </div>
        <div className="modal-body ">
            
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">List Name:</label>
                <input type="text" placeholder="List Name" className="form-control" required onChange={(e) => setListname(e.target.value)} value={listname} id="recipient-name"  />
              </div>
             
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={secleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn"  disabled={processing}>{processing ? 'Saving....' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal>
         
        <div className="row ">
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'-2px'}}>
          <h4 className="text-white ml-4" style={{marginLeft:'30px'}}>NEW CONTACT</h4>
          <form onSubmit={handleFormSubmit}>
          <div className='container mt-3' style={{display: 'inline-table'}}>
                  <div className='row'>
                    <div className='col-md-4'>
                      <label className="text-white mb-2">First Name:</label>
                      <input className="form-control" required type="text" placeholder='First Name' required id="firstname" onChange={handleInputChange} />
                    </div>
                    <div className='col-md-4'>
                      <label className="text-white mb-2">Last Name:</label>
                      <input className="form-control" required type="text" placeholder='Last Name'  id="lastname" onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <label className="text-white mb-2">Phone:</label>
                      <br />
                      <input className="form-control" required type="text" placeholder='Phone' required     value={formatPhoneNumber(selectedContact.phone)}
 id="phone" onChange={handleInputChange} />
                    </div>
                    <div className='col-md-4'>
                      <label className="text-white mb-2">Property Address:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='Address'  id="address" onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className='row'>
                  <div className='col-md-3'>
                      <label className="text-white mb-2">City:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='City'  id="city" onChange={handleInputChange}  />
                    </div>
                    <div className='col-md-3'>
                      <label className="text-white mb-2">State:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='State'  id="state" onChange={handleInputChange}  />
                    </div>
                    <div className='col-md-2'>
                      <label className="text-white mb-2">Zip:</label>
                      <br />
                      <input className="form-control" type="number" placeholder='Zip'  id="zip" onChange={handleInputChange}  />
                    </div>
                    </div>
                  <div className='row'>
                    <div className='col-md-4'>
                      <label className="text-white mb-2">Email:</label>
                      <br />
                      <input className="form-control" type="email" placeholder='Email'  id="email"  onChange={handleInputChange} />
                    </div>
                    <div className='col-md-4'>
                      
                      <label className="text-white mb-2">Status:</label>
                      <br />
                      <select id="status" name="status" className="form-select custom-dorpdown-toglle"  onChange={handleInputChange} >
                        <option >Select Status</option>
                        <option selected value="New">New</option>
                        <option value="Hot">Hot</option>
                        <option value="Warm">Warm</option>
                        <option value="Not Interested">Not Interested</option>
                        <option value="DNC">DNC</option>
                        <option value="Follow UP">Follow Up</option>
                      </select>
                      {/* <input type="text" className="form-control" placeholder='Tags' required id="tags" onChange={handleInputChange} /> */}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-3'>
                      <label className="text-white mb-2">List:</label>
                      <br />
                      <select id="list" name="list" className="form-select custom-dorpdown-toglle"   value={selectedContact.list}  onChange={handleInputChange}>
                        <option >Select List</option>
                        {list.map(item => (
                          <option value={item.listname}>{item.listname}</option>
                        ))}
                      </select>
                     
                    </div>
                    <div className='col-md-1'> <button type="button" onClick={secModalOpen} className="btn btn-primary" style={{marginTop:'31px',height: '35px',
    textwrap: 'nowrap',
    fontSize: '13px !important'}}>Create </button></div>
                    <div className='col-md-3'>
                    <label className="text-white mb-2">Tags:</label>
                      <br />
                      <select id="tags" name="tags" className="form-select custom-dorpdown-toglle" value={selectedContact.tags} onChange={handleInputChange}>
                        <option >Select Tags</option>
                        {tags.map(item => (
                          <option key={item.tagname} value={item.tagname}>{item.tagname}</option>
                        ))}
                      </select>
                    </div>
                    <div className='col-md-1'> <button type="button"  onClick={handleModalOpen} className="btn btn-primary " style={{marginTop:'31px',height: '35px',
    textwrap: 'nowrap',
    fontSize: '13px !important'}}>Create </button></div>

                  </div>
                 
                  <div className='row'>
                    <div className='col-md-8'>
                      <label className="text-white mb-2">Notes:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='Notes'  id="notes" onChange={handleInputChange}  />
                    </div>
                  </div>
                </div>
                <div className='row mt-4'>
                    <div className='col-md-2' style={{marginLeft:'30px'}}>
                        <button type="submit" className="btn btn-primary"> Save Contact</button>
                    </div>
                </div>
                </form>
    

     
        </div>
        </div>
  
          
        </Sidebar>
        );
      }
    
}

export default Addcontact;
