import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link,useNavigate,useLocation  } from 'react-router-dom';
import '../css/loginform.css';
import card  from '../icons/card.png';
import tickicon  from '../icons/green.png';

import logo from '../icons/logo.png';

function Thankyou() {
    
     
    const [countries, setCountries] = useState([]);
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [emailverified, setEmail] = useState('');
  const [numberverify, setNumber] = useState('');


  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const [disableResend, setDisableResend] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));


  return (
    <section className="bg-white ">
  <div className="container">
    <div className="row justify-content-center">
      <center><div className="col-12 mt-3 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
      <img src={card} style={{width: '13%'}}></img>
      
</div></center>
<div class="container center">
  <div class="row justify-content-center">
    
    <div class="col-md-6">
    
    </div>
      </div>
    </div>
  </div>
  </div>
  


  
  
</section>
  );
}

export default Thankyou;
