import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import { FaEdit } from 'react-icons/fa';

import Sidebar from '../components/navbar';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';


function Quickreply() {
    const [reply, setReply] = useState('');
    const [replyid, setReplyid] = useState('');
    
    useEffect(() => {
      
      document.body.style.backgroundColor = "rgb(42,45,54)";
    }, []);
  const maxLength = 160;

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setReply(inputValue);
    }
};
  const wordCount = reply.trim().split(/\s+/).filter(Boolean).length;

  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility
  const [repsucc, setRepsucc] = useState('');
     // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
    const handleModalOpen = () => {
      setRepsucc(false);
      setShowModal(true);
      setReply('')
      setReplyid('')
      setReplyname('')
    };
    const handleModalOpenBycreation = () => {
      setRepsucc(false);
      setShowModal(true);
    };
  const [authenticated, setAuthenticated] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [replies, setReplies] = useState([]);

  const [id, setUserid] = useState('');
  const [replyname, setReplyname] = useState('');
  
  useEffect(() => {
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)

        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-replies`, {
            id
          });
          setReplies(response.data);
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchreplies(); 
  }, []);
  
const editmodalwork = async (replyname, reply,id) => {
  setReplyname(replyname)
  setReply(reply)
  setReplyid(id)
  handleModalOpenBycreation()
};
const addreply = async (id, replyname,reply,event) => {
    setProcessing(true)
    setRepsucc(false)
    event.preventDefault();

    try {
      // Send POST request to add a list

      const response = await axios.post(`${API_BASE_URL}/api/add-reply`, { id, replyname,reply,replyid });
      
      setProcessing(false)
      setShowModal(false);
      const newReply = {
        id: response.data,
        replyname: replyname, 
        reply: reply, 
      };
      // const updaetReply = replies ? [...replies, newReply] : [newReply];
      // setReplies(updaetReply);
      if (replyid) {
        setReplies(prevReplies =>
          prevReplies.map(item => item.id === replyid ? newReply : item)
        );
      } else {
        // Append new reply
        setReplies(prevReplies => [...prevReplies, newReply]);
      }
    } catch (error) {
      setProcessing(false)

      console.error('Error adding Replies:');
    }
  };
  const handleDelete = async (reply) => {
    
    setRepsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to delete this Reply?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-replies/${reply}`);
      
    
      setReplies(prevWebhooks => prevWebhooks.filter(item => item.id !== reply));
      setRepsucc(true)
    } catch (error) {
      setRepsucc(false)

      console.error('Error deleting webhook:', error);
    }
  };
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
   <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => addreply(id, replyname, reply,event)}>
        <Modal.Body>
        <div className="text-center">
        <h4>{replyid ? 'UPDATE REPLY' : 'CREATE REPLY'}</h4>
        </div>
        <div className="modal-body ">
           
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">Name:</label>
                <input type="text" placeholder="Reply Name" required value={replyname} onChange={(e) => setReplyname(e.target.value)} className="form-control" id="recipient-name"  />
              </div>
              <div className="form-group">
                <div className="mt-3 mb-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
                    
                    <label >Reply:</label>
                        
                    <div style={{textAlign:'right'}}>
                    <label className={reply.length >= maxLength ? 'custom-countl' : 'def-countl' } >
                    {reply.length} / {maxLength}&nbsp;&nbsp; 
                    <span>Characters</span></label>
                    </div>
                </div>
                
        <textarea
          type="text"
          name="type"
          className="form-control"
          value={reply}
          onChange={handleChange}
          required />
              </div>
             
            
          </div>
        </Modal.Body>
        
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Saving....' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal>
       
        
        <div className="row ">
          <div className="col-3" style={{marginRight: '-60px'}}>
          <Menu/>
    
          </div>
          <div className="col-9" style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
        <div className="tab">
      <div className={`tab-item ${location.pathname === '/sms_settings' ? 'active' : ''}`}>
        <Link to="/sms_settings" className="link disabled">DNC Manager</Link>
        {location.pathname === '/sms_settings' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/auto_dnc' ? 'active' : ''}`}>
      <Link to="/auto_dnc"  className="link disabled">Auto DNC</Link>
        {location.pathname === '/auto_dnc' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/quick_reply' ? 'active' : ''}`}>
      <Link to="/quick_reply"  className="link disabled">Quick Reply</Link>
        {location.pathname === '/quick_reply' && <div className="strip"></div>}
      </div>
      
      {/* <div className={`tab-item ${location.pathname === '/sms_template' ? 'active' : ''}`}>
      <Link to="/sms_template"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_template' && <div className="strip"></div>}
      </div> */}
      
    </div>
    </div>
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;Quick Reply</h5>
        {repsucc && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Reply Deleted Successfuly !</div>}

            
            <div className=" mb-2" style={{textAlign:'right'}}>
          
          <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Reply
          </button>
          </div>
          {replies.length === 0 && <div className="col-md-12 text-center">
      <div className="mb-2 ml-2" style={{color:'white',fontSize:'25px'}}>No Reply Found</div>
            </div>}
          <div class="table-responsive text-nowrap mt-3">
    <table class="table" >
      <thead>
      {replies.length > 0 &&<tr>
          {/* <th>Id</th> */}
                  <th style={{padding:'0px'}}>Name</th>
                  <th style={{padding:'0px'}}>Reply</th>
                  <th></th>
                  
        </tr>
  }
      </thead>
      <tbody class="table-border-bottom-0">
                     
                  {replies.map((item) => (
          <tr key={item.id}>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.replyname}</td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.reply}</td>
            <td></td>
            <td></td>
            <td>
            <FaEdit
                className="fs-5 mr-2 mt-3"
                onClick={() => editmodalwork(item.replyname, item.reply, item.id)}
              />&nbsp;&nbsp;
              {/* <FaEdit class="fs-5 mr-2 mt-3" onClick={editmodalwork}></FaEdit> */}
              <img src={deletes} className="mt-3" alt="Delete" onClick={() => handleDelete(item.id)} />
            </td>
          </tr>
        ))}
                  
                                        </tbody>
    </table>
    <div class="pagnination">
          
      </div>
  </div>
        </div>
        
        </div>
          
      </div>
      
        </Sidebar>
        );
      }
    
}

export default Quickreply;
