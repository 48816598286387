import React, { useEffect, useState, useRef,useCallback } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import ReactDOM from 'react-dom';
import Sidebar from '../components/navbar';
import { FaSun,FaEdit,FaHistory,FaThumbsDown,FaHome,FaFire, FaTimesCircle, FaBan, FaReply } from 'react-icons/fa';
import loadinggif from '../icons/loading.gif';

import searchicon from '../icons/std.svg';
import add from '../icons/add.svg';
import settings from '../icons/settings.png';
import filter from '../icons/filter.svg';
import { Modal,Button } from 'react-bootstrap'
import DataTable from 'datatables.net';
import $ from 'jquery';
import { Dropdown, Form, FormControl } from 'react-bootstrap';
import API_BASE_URL from '../components/config';
import { saveAs } from 'file-saver';
import * as Papa from 'papaparse';
function CRM() {
 
  const navigate = useNavigate();
  const [fromValue, setFromValue] = useState('');
const [toValue, setToValue] = useState('');
const [customValue, setCustomAreaValue] = useState('');
const [totalselected, setTotalselected] = useState(0);
const [webhookresult, setWebhookResult] = useState('');
const [selectedIds, setSelectedIds] = useState(new Set());
const [phonenumbers, setPurchasednumbers] = useState([]); 
const [selectednumber, setSelectednumber] = useState('');
const [newcontact, setNewContact] = useState(false);
const [selectedtag, setSelectedTag] = useState(false);
const [loading, setLoading] = useState(false);


const [webhook, setWebhook] = useState([]);
  const [toRangeValue, setToRangeValue] = useState('');
  const [audienceName, setaudienceName] = useState('');
  const [isDropdownDisableds, setIsDropdownDisabled] = useState(true);
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false);
  // webhookmodal
  const [webhookModal, setWebhookModal] = useState(false);
  const handlewebhookModalClose = () => setWebhookModal(false);
  // filtermodal
  const [showfilterModal, setfilterShowModal] = useState(false);
  const handlefilterModalOpen = () => setfilterShowModal(true); 
  const handlefilterModalClose = () => setfilterShowModal(false);
// import options modal
const [, updateState] = useState(); 
const forceUpdate = useCallback(() => updateState({}), []);

const [showimporoptionsmmodal, setImportoptShowModal] = useState(false);
  const handleimportoptModalOpen = () => setImportoptShowModal(true); 
  const handleimportoptModalClose = () => setImportoptShowModal(false);

  const [showpushtophonemodal, setpushttophoneShowModal] = useState(false);
  const handlephoneModalOpen = () => setpushttophoneShowModal(true); 
  const handlephoneModalClose = () => setpushttophoneShowModal(false);

  const [showpushaudiencemodal, setpushttaudienceShowModal] = useState(false);
  const handleaudienceModalOpen = () => setpushttaudienceShowModal(true); 
  const handleaudienceModalClose = () => setpushttaudienceShowModal(false);
// edit modal
const [showeditmodal, setshoweditmodal] = useState(false);
const [selectedContact, setSelectedContact] = useState({
  firstname: '',
  lastname: '',
  phone: '',
  address: '',
  city: '',
    state: '',
    zip: '',
  email: '',
  
  tags: '',
  list: '',
  status: '',
  notes: '',
});
  const handleeditModalOpen = (contact) => {
    setNewContact(false);

    setSelectedContact(contact);
    setshoweditmodal(true);
  };
  const opennewcontactmodal = (contact) => {
    setNewContact(true);
    setshoweditmodal(true);
  };
  const handleeditModalClose = () => {
    setshoweditmodal(false);
    setSelectedContact({
      firstname: '',
      lastname: '',
      phone: '',
      address: '',
      city: '',
      state: '',
      zip: '',
      email: '',
      tags: '',
      list: '',
      status: '',
      notes: '',
    });

  };
  
  
    

  const handleInputChange = (e) => {
    const { id, value } = e.target;
    // console.log(id,value)
    setSelectedContact(prevState => ({
      ...prevState,
      [id]: value
    }));
  };
  const [id, setUserid] = useState('');
  const [contacts, setContacts] = useState([]);
  const [lists, setLists] = useState([]);
  const [tags, setTags] = useState([]);
  const [selectedList, setSelectedList] = useState('');
  const selectedRowIds = []
  const [includedLists, setIncludedLists] = useState('');
const [excludedLists, setExcludedLists] = useState('');
const [includedTags, setIncludedTags] = useState('');
const [excludedTags, setExcludedTags] = useState('');
const [sending, setSending] = useState(false);
const [selectedWebhooks, setSelectedWebhooks] = useState([]);
const [rowsIds, setRowsIds] = useState([]);
const handlewebhookModalOpen = () =>{
  setSelectedWebhooks([])
  setWebhookModal(true); 
} ;
const includedListsRef = useRef(includedLists);
const excludedListsRef = useRef(excludedLists);
const includedTagsRef = useRef(includedTags);
const excludedTagsRef = useRef(excludedTags);

useEffect(() => {
  includedListsRef.current = includedLists;
}, [includedLists]);

useEffect(() => {
  excludedListsRef.current = excludedLists;
}, [excludedLists]);
useEffect(() => {
  includedTagsRef.current = includedTags;
}, [includedTags]);
useEffect(() => {
  excludedTagsRef.current = excludedTags;
}, [excludedTags]);

const handleIncludeListChange = (e) => {
  setIncludedLists([])
  const options = Array.from(e.target.selectedOptions, option => option.value);
  if (!options.includes("Select")) {
    setIncludedLists(options);
  }
};

const handleExcludeListChange = (e) => {
  setExcludedLists([])
  const options = Array.from(e.target.selectedOptions, option => option.value);
  if (!options.includes("Select")) {
    setExcludedLists(options);
  }
};

const handleIncludeTagChange = (e) => {
  setIncludedTags([])
  const options = Array.from(e.target.selectedOptions, option => option.value);
  if (!options.includes("Select")) {
    setIncludedTags(options);
  }
};

const handleExcludeTagChange = (e) => {
  setExcludedTags([])
  const options = Array.from(e.target.selectedOptions, option => option.value);
  if (!options.includes("Select")) {
    setExcludedTags(options);
  }
};



  let table;
   
  const [authenticated, setAuthenticated] = useState(null);
  useEffect(() => {
    // Set background color
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
  
    const fetchContacts = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true);
        setUserid(id);
        try{
          const response= await axios.post(`${API_BASE_URL}/api/fetch-numbers`, {
            id
          });
          setPurchasednumbers(response.data);
        }catch(error){

        };
        try {
          const response = await axios.post(`${API_BASE_URL}/api/fetch-contacts`, { id });
  
  if (response.data) {
    const { crm, crm_list,tags } = response.data;
  
    // Setting contacts
    if (crm && crm.length > 0) {
      // const shouldExcludeDocument = (document) => {
      //   const keys = Object.keys(document);
      //   return keys.length === 5 && keys.includes('_id') && keys.includes('user_id') && keys.includes('phone') && keys.includes('status') && keys.includes('date');
      // };
    
      // // Filter the crm array to exclude unwanted documents
      // const filteredCrm = crm.filter(document => !shouldExcludeDocument(document));
      // console.log(filteredCrm)
      // // Setting contacts
      // if (filteredCrm && filteredCrm.length > 0) {
      //   setContacts([...filteredCrm]);
      // }
      setContacts([...crm]);
    } else {
      setContacts([]); // Set to empty array if crm is not available or empty
    }
  
    // Setting crm_list
    if (crm_list && crm_list.length > 0) {
      setLists([...crm_list]);
    } else {
      setLists([]); 
    }
    if (tags && tags.length > 0) {
      setTags([...tags]);
    } else {
      setTags([]); 
    }
    
  
  }
  
        } catch (error) {
          alert('error')
          // Handle error if necessary
        }
        try {
          const webhookfetch = await axios.post(`${API_BASE_URL}/api/fetch-webhook`, { id });

  if (webhookfetch.data) {
    setWebhook(webhookfetch.data);
    
   
   
  }
  
        } catch (error) {
          
          // Handle error if necessary
        }

      } else {
        navigate("/login");
      }
    };
  
    fetchContacts();
  }, []);
  
  const [tableInstance, setTableInstance] = useState(null); // State to hold DataTable instance
useEffect(() => {
        updateTotalSelected();
    }, [rowsIds]);
  const updateTotalSelected = () => {
    // alert(rowsIds.length)
    setTotalselected(rowsIds.length);
    setIsDropdownDisabled(rowsIds.length === 0 ? 1 : 0);
    // const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
    // let selectedCount = 0;
  
    // allRows.each(function(row) {
    //   const checkbox = $(row).find('input[type="checkbox"]')[0];
    //   if (checkbox.checked) {
    //     selectedCount++;
    //   }
    // });
  
    // setTotalselected(selectedCount);
    // setIsDropdownDisabled(selectedCount === 0 ? 1 : 0);
  };
  const crmajax = `${API_BASE_URL}'/api/fetch-crm-data`
  const handledelete = async (e) => {
    setLoading(true)
    const confirmed = window.confirm('Are you sure you want to delete this Contact?');
    if (!confirmed) {
      return;
    }
  
    const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
    const newRowsIds = [];
  
    // allRows.each(function(row) {
    //   const checkbox = $(row).find('input[type="checkbox"]')[0];
    //   if (checkbox && checkbox.checked) {
    //     newRowsIds.push(row.id);
    //   }
    // });
  
    try {
     
      const response = await axios.post(`${API_BASE_URL}/api/delete-contacts`, {userid:id, ids: rowsIds });
      alert("Contact Deleted Succesfully")
      // window.location.reload()
    } catch (error) {
      // Handle error
    }
  };
  // window.location.reload()
  const [searchCriteria, setSearchCriteria] = useState({
    id: 2, // Initial search criteria
    search: '', // Default search value
    searchColumn: '' // Column to search in
});
const assigntag = async (e) => {
  e.preventDefault()
    const confirmed = window.confirm(`Are you sure you want to Assign ${selectedtag} ?`);
    if (!confirmed) {
      return;
    }
  
    const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
    const newRowsIds = [];
  
    allRows.each(function(row) {
      const checkbox = $(row).find('input[type="checkbox"]')[0];
      if (checkbox && checkbox.checked) {
        newRowsIds.push(row.id);
      }
    });
  
    try {
     
      const response = await axios.post(`${API_BASE_URL}/api/assign-tag`, {userid:id, ids: rowsIds,tag:selectedtag });
      alert("Tags Assigned Succesfully")

      // window.location.reload()
      tableInstance.columns().every(function () {
        this.search('').draw();
      });
      handletagModalClose()

    } catch (error) {
      // Handle error
    }
  };
  const tableRef = useRef(null);
  const editicon = (id) => {
    return <FaEdit id={id} />;

   
};
  const renderStatusIcon = (status) => {
    switch (status) {
        case 'New':
            return <FaHome className="fa-home-icon" />;
        case 'Hot':
            return <FaFire className="fa-fire-icon" />;
        case 'Warm':
            return <FaSun className="fa-warm-icon" />;
        case 'Not Interested':
            return <FaThumbsDown className="fa-thumb-icon" />;
        case 'DNC':
            return <FaBan className="fa-dnc-icon" />;
        case 'Follow UP':
            return <FaHistory className="fa-history-icon" />;
        default:
            return null;
    }
};
const [alreadySent, setAlreadySent] = useState(false);
const [allIds, setAllIds] = useState([]);
const fetchAllIds = (searchCriteria) => {
//   if (!alreadySent ) {
//     // alert("run")
//     console.log("uid",id)
//     setAlreadySent(true) // Set alreadySent to true after sending once
//     searchCriteria = {
//       id: sessionStorage.getItem("login"), // Adjust as per your needs
//       includedLists: "", // Replace with your state variables
//       excludedLists: "", // Replace with your state variables
//       includedTags: "", // Replace with your state variables
//       excludedTags: "", // Replace with your state variables
//       searchColumn: "",
//       search: ""
//   };
// }
//   if (!searchCriteria) {
//     searchCriteria = {
//         id: id, // Adjust as per your needs
//         includedLists: "", // Replace with your state variables
//         excludedLists: "", // Replace with your state variables
//         includedTags: "", // Replace with your state variables
//         excludedTags: "", // Replace with your state variables
//         searchColumn: "",
//         search: ""
//     };
// }
  // Fetch all IDs based on the current filter criteria from your server
  $.ajax({
      url: `${API_BASE_URL}/api/fetch-all-ids`,
      type: 'POST',
      contentType: 'application/json',
      data: JSON.stringify(searchCriteria),
      success: (response) => {
          setAllIds(response.ids);
      },
  });
  // console.log(allIds)
};

  useEffect(() => {
   
    //   if (tableInstance) {
    //     tableInstance.destroy();
    //     // Clear any state related to DataTables
    //     setRowsIds([]);
    //     setTableInstance(null);
    // }
        const newTableInstance = new DataTable('.crm_tabble', {
            serverSide: true,
            // select: {
            //     style: 'multi',
            //     selector: 'td:first-child input[type="checkbox"]'
            // },
            processing: true,
      
            
            language: {
                processing: `<div class="loading-container"><img class="loading-gif" src="${loadinggif}" alt="Loading..." /></div>`,
            },
            ajax: {
                url: `${API_BASE_URL}/api/fetch-crm-data`,
                type: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                },
                data: function(d) {
                    d.id = id; // Adjust as per your needs
                    d.includedLists = includedListsRef.current;
                    d.excludedLists = excludedListsRef.current;
                    d.includedTags = includedTagsRef.current;
                    d.excludedTags = excludedTagsRef.current;
                    // console.log('Data sent to server:', JSON.stringify(d));
                    // console.log(includedLists,excludedLists)
                    return JSON.stringify(d);
                },
                dataSrc: 'crm'
            },
            columnDefs: [
              {
                  targets: 0,
                  createdCell: function(td, cellData, rowData, row, col) {
                    // Clear the cell
                    td.innerHTML = '';

                    // Apply class to the cell
                    td.className = 'contact-column';

                    // Render React component into the cell
                    ReactDOM.render(
                        <div className="contact-column-content">
                            <input 
                                type="checkbox" 
                                id={rowData.id} 
                                className="contact-checkbox" 
                                defaultChecked={rowsIds.includes(rowData.id)} 
                            />
                            <FaEdit className="edit-icon" id={`edit-${rowData.id}`} style={{marginLeft:'12px',cursor:'pointer'}} />
                        </div>,
                        td
                    );
                },
                  // createdCell: function(td, cellData, rowData, row, col) {
                  //     // Clear the cell
                  //     td.innerHTML = '';

                  //     // Render React component into the cell
                  //     ReactDOM.render(
                  //         <>
                  //             <input 
                  //                 type="checkbox" 
                  //                 id={rowData.id} 
                  //                 className="contact-checkbox" 
                  //                 defaultChecked={rowsIds.includes(rowData.id)} 
                  //             />
                  //             <FaEdit id={`edit-${rowData.id}`} />
                  //         </>,
                  //         td
                  //     );
                  // },
                  orderable: false,
              }
          ],
            // columnDefs: [
            //     {
            //         targets: 0,
            //         render: function(data, type, row, meta) {
            //           // const isChecked = false;
            //           const isChecked = rowsIds.includes(row.id);
                     
            //           return `<input type="checkbox" id="${row.id}" class="contact-checkbox" ><FaEdit id="${row.id}" />`;
            //           // return `<input type="checkbox" id="${row.id}" class="contact-checkbox" ${isChecked ? 'checked' : ''} 
            //           //             onclick="event.stopPropagation()" 
            //           //             onChange="handletableeckboxChange('e','${row.id}')">`;
            //       },
            //       orderable: false,
            //     }
            // ],
            
            columns: [
                {
                    title: '',
                    data: null,
                  //   render: function(data, type, row, meta) {
                  //     const isChecked = selectedIds.has(row.id); // Assuming 'id' is the unique identifier for each row
                  //    console.log(selectedIds)
                  //    console.log(isChecked)
                  //    return `<input id=${row.id} type="checkbox" class="contact-checkbox" checked  />`;
                  //   //  return `<input id=${row.id} type="checkbox" class="contact-checkbox" defaultChecked ${isChecked ? 'checked' : ''} />`;
                  //     // Render checkbox input with Gyrocode checkboxes plugin
                  //     // return `<input type="checkbox" class="contact-checkbox" />`;
                  // }
               
                },
                {
                  title: 'Lead Status',
                  data: 'status',
                   defaultContent: '',
                  createdCell: (td, cellData, rowData, row, col) => {
                      ReactDOM.render(
                          <>
                              <span className="d-none">{cellData}</span>
                              {renderStatusIcon(cellData)}
                          </>,
                          td
                      );
                      td.classList.add('status-cell');
                  }
              },
                
                { title: 'First Name', data: 'firstname', defaultContent: '' },
                { title: 'Last Name', data: 'lastname', defaultContent: '' },
                {
                    title: 'Phone', data: 'phone', render: function(data, type, row) {
                        return formatPhoneNumbercrm(data);
                    }
                },
                { title: 'Property Address', data: 'address', defaultContent: '' },
                { title: 'City', data: 'city', defaultContent: '' },
                { title: 'State', data: 'state', defaultContent: '' },
                { title: 'Zip', data: 'zip', defaultContent: '' },
                { title: 'Email', data: 'email', defaultContent: '' },
                { title: 'List', data: 'list', defaultContent: '' },
                { title: 'Tags', data: 'tags' , defaultContent: ''},
                { title: 'Notes', data: 'notes', defaultContent: '' },
                // Add more columns as needed
            ],
             
            stateSave: true,
            bDestroy: true,
            ordering: false,
            orderCellsTop: true,
            // pageLength: 11, 
            scrollCollapse: true,
            lengthMenu: [10, 15, 50, 100],
            initComplete: function () {
                const that = this;
                const api = this.api();
                // api.checkboxes();
                
                // // Handle checkbox change event
                // api.on('change', ':checkbox', function() {
                //     const checkbox = $(this);
                //     const isChecked = checkbox.prop('checked');
                //     const itemId = checkbox.closest('tr').attr('id');
                //     handletableeckboxChange(isChecked, itemId); // Call your checkbox change handler
                // });
                that.api().columns().every(function () {
                    const column = this;
                    const footerCell = column.footer();

                    const select = footerCell.querySelector('select');
                    if (select) {
                        select.addEventListener('change', function () {
                          
                            const selectedValue = this.value;
                            setSearchCriteria(prevState => ({
                              ...prevState,
                              search: selectedValue
                          }));
                            if (column.search() !== selectedValue) {
                                column.search(selectedValue).draw();
                                fetchAllIds({
                                  id: id, // Adjust as per your needs
                                  includedLists: includedLists, // Replace with your state variables
                                  excludedLists: excludedLists, // Replace with your state variables
                                  includedTags: includedTags, // Replace with your state variables
                                  excludedTags: excludedTags, // Replace with your state variables
                                  searchColumn: column.dataSrc(),
                                  search: selectedValue // Pass the current search value
                              });
                              
                            }
                        });
                    }

                    const input = footerCell.querySelector('input[type="text"]');
                    if (input) {
                        input.addEventListener('keyup', function () {
                            if (column.search() !== this.value) {
                                column.search(this.value).draw();
                                fetchAllIds({
                                  id: id, // Adjust as per your needs
                                  includedLists: includedLists, // Replace with your state variables
                                  excludedLists: excludedLists, // Replace with your state variables
                                  includedTags: includedTags, // Replace with your state variables
                                  excludedTags: excludedTags, // Replace with your state variables
                                  searchColumn: column.dataSrc(),
                                  search: this.value // Pass the current search value
                              });
                            }
                        });
                    }
                });
            }
        });
        newTableInstance.on('draw', function() {
          $('.edit-icon').on('click', function(event) {
            const rowData = newTableInstance.row($(this).closest('tr')).data(); // Get data for the clicked row
          // alert(rowData)
            handleeditModalOpen(rowData);
            // event.stopPropagation();
        });
          $('input[type="checkbox"].contact-checkbox').off('click').off('change');
      
          // Add new event handlers
          $('input[type="checkbox"].contact-checkbox').on('click', function(event) {
              event.stopPropagation();
          }).on('change', function(event) {
              const itemId = this.id;
              handletableeckboxChange(event, itemId);
          });
      
          newTableInstance.rows().every(function() {
              const row = this.node();
              const checkbox = $(row).find('td:first-child input[type="checkbox"]');
              const itemId = $(row).find('td:first-child input[type="checkbox"]').attr('id');
              // const itemId = row.getAttribute('id');
              // console.log('selectedids',selectedIds,itemId,rowsIds)
              // console.log('checked',rowsIds)
              // console.log('xxxxxxxxxxxxxxx',rowsIds,istemId)
              setRowsIds(prevRowsIds => {
                checkbox.prop('checked', prevRowsIds.includes(itemId));
                return prevRowsIds;
            });
          });
        //   newTableInstance.on('click', 'tbody tr', function() {
        //     const rowData = newTableInstance.row(this).data(); // Get data for the clicked row
        //     handleeditModalOpen(rowData); // Trigger your modal or action with the row data
        // });
      });
      
     
     

      newTableInstance.columns().every(function () {
        this.search('').draw();
      });
      const initialSearchCriteria = {
        id: sessionStorage.getItem("login"), // Adjust as per your needs
        includedLists: "", // Replace with your state variables
        excludedLists: "", // Replace with your state variables
        includedTags: "", // Replace with your state variables
        excludedTags: "", // Replace with your state variables
        searchColumn: "",
        search: ""
    };
    //     const initialSearchCriteria = { id: 2 }; // Adjust as per your needs
    fetchAllIds(initialSearchCriteria);
        // Set the new DataTable instance in state
        setIncludedLists([]);
    setExcludedLists([]);
    setIncludedTags([]);
    setExcludedTags([]);
        setTableInstance(newTableInstance);
        // setIncludedLists([])
        // setExcludedLists([])
        // setIncludedTags([])
        // setExcludedTags([])
    
}, [contacts]); // Depend on contacts and selectedIds
// useEffect(() => {
//   tableInstance.on('draw', function() {
//   tableInstance.rows().every(function() {
//       const row = this.node();
//       const checkbox = $(row).find('td:first-child input[type="checkbox"]');
//       const itemId = $(row).find('td:first-child input[type="checkbox"]').attr('id');
//       checkbox.prop('checked', rowsIds.includes(itemId)); // Update checkbox state based on rowsIds
//   });
// });
// }, [rowsIds]); // This useEffect runs whenever rowsIds state changes

$('.crm_table').on('change', 'input.contact-checkbox', function() {
  const checkbox = $(this);
  // alert(checkbox)
  const isChecked = checkbox.prop('checked');
  // const row = newTableInstance.row(checkbox.closest('tr'));
  // const rowData = row.data();
  const itemid = $(this).attr('id')
  // Update your local state or UI based on checkbox change
  // if (isChecked) {
  //     selectedIds.add(itemid);
  // } else {
  //     selectedIds.delete(itemid);
  // }
  // alert(itemid)
  // handletableeckboxChange('e',itemid)
//   if (isChecked) {
//     setSelectedIds(new Set([...selectedIds, itemId]));
// } else {
//     selectedIds.delete(itemId);
//     setSelectedIds(new Set(selectedIds));
// }
  // Update checkbox state without triggering DataTables AJAX
  checkbox.prop('checked', isChecked);
});


  const handleSubmit = (e, tableInstance) => {
    e.preventDefault();
    setShowModal(false);
  
    if (tableInstance) {
      document.querySelectorAll('.toggle-vis').forEach((checkbox) => {
        let columnIdx = parseInt(checkbox.dataset.column);
        let column = tableInstance.column(columnIdx);
        
        if (column) {
          column.visible(checkbox.checked);
        }
      });
    }
  };
  const actionDropdown = document.getElementById('myActionSelect');
  
  const [action, setAction] = useState('');
  const [Filterselection, setFilterSelection] = useState('');

  const handleActionChange = (e) => {
    const selectedAction = e.target.value;
    setAction(selectedAction);
    
    // Perform the action immediately upon selection
    performAction(selectedAction);
  };
  
  const [ show, setShow ] = useState(false);
  const dropdownRef = useRef(null);
  const handleDocumentClick = (event) => {
    
    if (dropdownRef.current && !dropdownRef.current.contains(event.target)) {
      setShow(false);
    }
  };
  useEffect(() => {
    if (show) {
      document.addEventListener('mousedown', handleDocumentClick);
    } else {
      document.removeEventListener('mousedown', handleDocumentClick);
    }

    return () => {
      document.removeEventListener('mousedown', handleDocumentClick);
    };
  }, [show]);
  const handleTickClick = () => {
    
    setRowsIds([]);  // Clear the rowsIds state
    clearRowsIds();  // Clear any other state or variable related to rowsIds if necessary

    const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
    allRows.each(function(row) {
        const checkbox = $(row).find('input[type="checkbox"]')[0];
        if (checkbox) {
            checkbox.checked = false;
        }
    });

    const numberToSelect = parseInt(customValue, 10);
    if (numberToSelect < 1){
      return;
    }
    if (!isNaN(numberToSelect)) {
        const newRowsIds = [];  // Create a new array to store the updated rowsIds
        const selectedIds = [];
        for (let index = 0; index < numberToSelect && index < allIds.length; index++) {
            const itemId = allIds[index]; // Get the ID from allIds based on index
            const rowNode = allRows[index];
            selectedIds.push(itemId)
            const checkbox = $(rowNode).find('input[type="checkbox"]')[0];
            if (!newRowsIds.includes(itemId)) {
                newRowsIds.push(itemId);  // Push the ID to the new array
            }
            if (!rowNode) {
              continue; // Skip this iteration if rowNode is undefined
            }
          //   if (allIds.includes(checkbox.id)) {
          //     checkbox.checked = true;  // Check the checkbox if checkbox.id is in itemIds
          // }
            // if (itemId === checkbox.id) {
            //     checkbox.checked = true;  // Check the checkbox
            // }
        }
        allRows.each(function(row) {
          const checkbox = $(row).find('input[type="checkbox"]')[0];
          const itemId = checkbox ? checkbox.id : null;
      
          if (itemId && selectedIds.includes(itemId)) {
            checkbox.checked = true;
            // newRowsIds.push(itemId); // Add checked item ID to newRowsIds
          }
        });
        setRowsIds(newRowsIds);  // Update the rowsIds state
        updateTotalSelected();   // Call the function to update the total selected count
    }
    
};


  // const handleTickClick = () => {
  //   const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
  //   const newRowsIds = [];
  //   const numberToSelect = parseInt(customValue, 10);
   
    
  //   if (!isNaN(numberToSelect)) {
  //     for (let index = 0; index < allRows.length; index++) {
  //       const rowNode = allRows[index];
  //       const checkbox = $(rowNode).find('input[type="checkbox"]')[0];
      
  //       if (checkbox) {
  //         if (index < numberToSelect) {
  //           checkbox.checked = true;
  //         } else {
  //           checkbox.checked = false; 
  //         }
  //       }
  //     }
  //     setRowsIds(newRowsIds);
  //     updateTotalSelected();
  //   }
  // };
  const formRef = useRef(null);


  const handleselectwebhookchange = (event, webhookUrl) => {
    setSelectedWebhooks((prevSelected) => {
      const newSelected = event.target.checked 
        ? [...prevSelected, webhookUrl]
        : prevSelected.filter((url) => url !== webhookUrl);

      return newSelected;
    });
  };


  const handleRangeTickClick = () => {
    setRowsIds([]);
    clearRowsIds();
  
    const from = parseInt(fromValue, 10);
    const to = parseInt(toValue, 10);
    const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
  
    // Uncheck all checkboxes first
    allRows.each(function(row) {
      const checkbox = $(row).find('input[type="checkbox"]')[0];
      if (checkbox) {
        checkbox.checked = false;
      }
    });
  
    if (isNaN(from) || isNaN(to) || from > to) {
      return; // Invalid range, return early
    }
  
    const newRowsIds = [];
    const selectedIds = []; // IDs to be checked
  
    for (let index = from - 1; index < to; index++) {
      const itemId = allIds[index]; // Assuming allIds is an array containing item IDs
      const rowNode = allRows[index];
      selectedIds.push(itemId); 
      if (!newRowsIds.includes(itemId)) {
        newRowsIds.push(itemId);  // Push the ID to the new array
    }
if (!rowNode) {
  continue; // Skip this iteration if rowNode is undefined
}

// if(itemId === checkbox.id){
//   checkbox.checked = true
//   }
    }
  
    allRows.each(function(row) {
      const checkbox = $(row).find('input[type="checkbox"]')[0];
      const itemId = checkbox ? checkbox.id : null;
  
      if (itemId && selectedIds.includes(itemId)) {
        checkbox.checked = true;
        // newRowsIds.push(itemId); // Add checked item ID to newRowsIds
      }
    });
  
    setRowsIds(newRowsIds);
    updateTotalSelected();
  };
  
  const handletableeckboxChange = (event, itemId) => {
    setRowsIds(prevRowsIds => {
      // Create a new array based on the previous state
      let newRowsIds = [...prevRowsIds];

      if (event.target.checked) {
          if (!newRowsIds.includes(itemId)) {
              newRowsIds.push(itemId);
          }
      } else {
          newRowsIds = newRowsIds.filter(id => id !== itemId);
      }

     
      return newRowsIds;
  });
    updateTotalSelected();
};
// const handletableeckboxChange = (event, itemId) => {
//     setRowsIds(prevRowsIds => {
//         if (event.target.checked) {
//             if (!prevRowsIds.includes(itemId)) {
//                 return [...prevRowsIds, itemId];
//             }
//         } else {
//             return prevRowsIds.filter(id => id !== itemId);
//         }
//         return prevRowsIds; // Return unchanged array if no modifications are made
//     });
//     updateTotalSelected();
// };

const clearRowsIds = () => {
  setRowsIds(prevRowsIds => []);
  setRowsIds([]);
  forceUpdate();
};
  // const  handletableeckboxChange =  (e,id) => {
  // e.preventDefault()
  //   console.log('clicked id',id)
  //   // alert("haha")
  //   const newRowsIds = [...rowsIds];

  //   if (newRowsIds.includes(id)) {
  //     const index = newRowsIds.indexOf(id);
  //     if (index > -1) {
  //       newRowsIds.splice(index, 1);
  //     }
  //   } else {
  //     newRowsIds.push(id);
  //   }
  //   console.log('newid',rowsIds)
  //   setRowsIds(newRowsIds);
  //   if (selectedIds.has(id)) {
  //     selectedIds.delete(id);
  //             setSelectedIds(new Set(selectedIds));
  //         } else {
  //             console.log('id added')
  //             setSelectedIds(new Set([...selectedIds, id]));

  //         }
  //   console.log('set',selectedIds)
    
  //   updateTotalSelected()
  // };
//   function handletableeckboxChange(event, itemId) {
//     const checkbox = event.target;
//     const index = selectedIds.indexOf(itemId);
//     if (index !== -1) {
//         // Row ID already exists, so remove it
//         selectedIds.splice(index, 1);
//     } else {
//         // Row ID doesn't exist, so add it
//         selectedIds.push(itemId);
//     }
//     console.log('Selected IDs:', selectedIds); // Debugging purpose
// }
  // useEffect(() => {
  //   const checkboxes = document.querySelectorAll('.crm_tbody input[type="checkbox"]');
  //   checkboxes.forEach(checkbox => {
  //     checkbox.addEventListener('change', handletableeckboxChange);
  //   });

 
  //   return () => {
  //     checkboxes.forEach(checkbox => {
  //       checkbox.removeEventListener('change', handletableeckboxChange);
  //     });
  //   };
  // }, [contacts]);
 
  const handleSelectionChange = (eventKey,) => {
      
    if (eventKey === 'selectVisible') {
      setRowsIds([])
      const newRowsIds = [];
      setShow(false)
      
        // const checkboxes = document.querySelectorAll('.crm_tbody input[type="checkbox"]:not(:checked)');
        
        // checkboxes.forEach((checkbox) => {
        //     checkbox.checked = true;
        //     const itemId = checkbox.id;
        //     if (!newRowsIds.includes(id)) {
          
        //       newRowsIds.push(id); // Add each id to the newRowsIds array
        //   }
        //     // newRowsIds.push(itemId);
        // });
        const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
        allRows.each(function(row) {
          const checkbox = $(row).find('input[type="checkbox"]')[0];
          if (checkbox) {
            checkbox.checked = true;
            
          }
          if (!newRowsIds.includes(checkbox.id)) {
          
            newRowsIds.push(checkbox.id); // Add each id to the newRowsIds array
        }
        
      });
  
        setRowsIds(newRowsIds)
        updateTotalSelected()
    } else if (eventKey === 'selectAll') {
      setRowsIds([])
      const newRowsIds = [];
      // setRowsIds(prevRowsIds => [...prevRowsIds, ...allIds.filter(id => !prevRowsIds.includes(id))]);
      allIds.forEach(id => {
        if (!newRowsIds.includes(id)) {
          
            newRowsIds.push(id); // Add each id to the newRowsIds array
        }
      });
      setRowsIds(newRowsIds)
      const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
      allRows.each(function(row) {
        const checkbox = $(row).find('input[type="checkbox"]')[0];
        if (checkbox) {
          checkbox.checked = true;
          
        }
      
    });

    updateTotalSelected()
        
    } else if (eventKey === 'unselectAll') {
    rowsIds.splice(0, rowsIds.length);
  setRowsIds([]);

      clearRowsIds()
  console.log(rowsIds)
    updateTotalSelected()
    setAction('');

      const newRowsIds = [...rowsIds];
      
      setShow(false)
      const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
      allRows.each(function(row) {
        const checkbox = $(row).find('input[type="checkbox"]')[0];
        if (checkbox) {
          checkbox.checked = false;
          
        }
      
    });
        
    }  
    
    
};
const [showtagmodal, settagShowModal] = useState(false); // State to control the modal's visibility

  const handletagModalOpen = () => settagShowModal(true); // Function to open the modal
  const handletagModalClose = () => settagShowModal(false)
  
  
  const performAction = async  (selectedAction) => {
    
    if (selectedAction === 'Push To Webhook') {
      handlewebhookModalOpen()
    } else if (selectedAction === 'Push_Audience') {
      handleaudienceModalOpen()
      
    } 
     else if (selectedAction === 'Push To Phone') {
      handlephoneModalOpen()
      
    } else if (selectedAction === 'Assign Tag') {
      handletagModalOpen()
    } else if (selectedAction === 'Export CSV') {
      setLoading(true)
      // const columnHeaders = [];
      // tableInstance.columns().every(function (index) {
      //   const column = this;
      //   if (column.visible() && column.data().length > 0) {
      //     const headerText = column.header().textContent.trim();
      //     columnHeaders.push(headerText);
      //   }
      // });
      // const selectedData = [];
      // const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
      // allRows.each(function(row) {
      //   const checkbox = $(row).find('input[type="checkbox"]')[0];
      //   if (checkbox && checkbox.checked) {
      //     const rowData = [];
      //     $(row).find('td').each(function() {
      //       rowData.push($(this).text());
      //     });
      //     selectedData.push(rowData.join(','));
      //   }
      // });
      
      // const csvContent = 'data:text/csv;charset=utf-8,';
      // const csv = [columnHeaders.join(',')].concat(selectedData).join('\n');

      // const encodedCsv = encodeURI(csvContent + csv);
    
      // // Initiate download
      // const link = document.createElement('a');
      // link.setAttribute('href', encodedCsv);
      // link.setAttribute('download', 'CRM_EXPORT.csv');
      // document.body.appendChild(link);
      // link.click();
      // document.body.removeChild(link);

    //   const response= await axios.post(`${API_BASE_URL}/api/crm-export`, {
    //  ids:rowsIds
    // });
    // const data = response.data; 
    // let csvContent = "System NUMBERS\n"; 
    // const data = response.data;
    
    // Convert data to CSV format
    // let csvContent = "First Name,Last Name,Email,Status,List,Tags,Address,City,State,Zip,Phone\n"; // Add the header line
    
    // data.forEach(item => {
    //     csvContent += `${item['First Name']},${item['Last Name']},${item['Email']},${item['Status']},${item['List']},${item['Tags']},${item['Address']},${item['City']},${item['State']},${item['Zip']},${item['Phone']}\n`;
    // });
    
    // // Create a Blob from the CSV content
    // const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    // const url = window.URL.createObjectURL(blob);
    
    // // Create a link element to trigger the download
    // const a = document.createElement('a');
    // a.href = url;
    // a.download = 'CRM_EXPORT.csv';
    // document.body.appendChild(a);
    // a.click();
    // window.URL.revokeObjectURL(url);
    // document.body.removeChild(a);
    const response = await axios.post(`${API_BASE_URL}/api/crm-export`, { ids:rowsIds }, {
      responseType: 'blob',  // important to receive the response as a file
    });

    // Create a URL for the file and trigger download
    const blob = new Blob([response.data], { type: 'text/csv' });
    const link = document.createElement('a');
    link.href = window.URL.createObjectURL(blob);
    link.download = 'CRM_EXPORT.csv';
    document.body.appendChild(link);
    link.click();
    document.body.removeChild(link);

    setLoading(false)
    } else if (selectedAction === 'Delete') {
      // handledelete()
      
      const confirmed = window.confirm('Are you sure you want to delete this Contact?');
    if (!confirmed) {
      return;
    }
    setLoading(true)
    const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
    const newRowsIds = [];
  
    allRows.each(function(row) {
      const checkbox = $(row).find('input[type="checkbox"]')[0];
      if (checkbox && checkbox.checked) {
        newRowsIds.push(row.id);
      }
    });
  
    try {
     
      const response = await axios.post(`${API_BASE_URL}/api/delete-contacts`, {userid:id, ids: rowsIds });
      setLoading(false)
      alert("Contact Deleted Succesfully")
      setRowsIds([]);
      // tableInstance.columns().every(function () {
      //   this.search('').draw();
      // });
      window.location.reload()
      
    } catch (error) {
      // Handle error
    }
    }
  };;
  

  const selectAll = (e) => {
    const newSelectedIds = new Set(selectedIds);
    allIds.forEach(id => {
        if (!rowsIds.includes(id)) {
          
            rowsIds.push(id); // Add each id to the newRowsIds array
        }
      });
      // setRowsIds(allIds);
    //   const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
    //   allRows.each(function(row) {
    //     const checkbox = $(row).find('input[type="checkbox"]')[0];
    //     if (checkbox) {
    //       checkbox.checked = true;
          
    //     }
      
    // });

    updateTotalSelected()
    const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });

    const newRowsIds = [...rowsIds];

    if (e.target.checked) {
      allRows.each(function(row) {
        const checkbox = $(row).find('input[type="checkbox"]')[0];
        if (checkbox) {
          checkbox.checked = true;
          // const itemId = row.getAttribute('id');
          // if (!newRowsIds.includes(itemId)) {
          //   newRowsIds.push(itemId);
          // }
        }
      });
    } else {
      allRows.each(function(row) {
        const checkbox = $(row).find('input[type="checkbox"]')[0];
        if (checkbox) {
          checkbox.checked = false;
          // const itemId = row.getAttribute('id');
          // const index = newRowsIds.indexOf(itemId);
          // if (index > -1) {
          //   newRowsIds.splice(index, 1);
          // }
        }
      
    });
    setAction('')
    clearRowsIds()
    updateTotalSelected()
  }

  // setRowsIds(newRowsIds);
  // console.log(rowsIds)
  updateTotalSelected()
  }
  
  
  
 
  const handleSelectChange = (tableInstance) => {
    const selectElement = document.getElementById('list-select');
    // selectElement.addEventListener('change', handleChange);

    // tableInstance.columns().every(function () {
    //   this.search('').draw();
    // });
    // console.log("")
    if (selectElement && tableInstance) {
      // Remove any existing event listener to avoid duplication
      // selectElement.removeEventListener('change', handleChange);
  
      // function handleChange() {
        const selectedValue = selectElement.value;
        const columnIndex = 10;
  
        tableInstance.column(columnIndex).search(selectedValue).draw();
        fetchAllIds({
          id: id, // Adjust as per your needs
          includedLists: includedLists, // Replace with your state variables
          excludedLists: excludedLists, // Replace with your state variables
          includedTags: includedTags, // Replace with your state variables
          excludedTags: excludedTags, // Replace with your state variables
          searchColumn: "list",
          search:selectedValue // Pass the current search value
      });
    // alert("run")

      // }
      // selectElement.removeEventListener('change', handleChange);
      // Attach the event listener
      // selectElement.addEventListener('change', handleChange);
    }
  };
  
    
    const handlestatusdropdownChange = (tableInstance) => {
      // tableInstance.columns().every(function () {
      //   this.search('').draw();
      // });
      const selectElement = document.getElementById('statusSelect');
      const selectedStatus = selectElement.value;
      if (selectedStatus === "All") {
        tableInstance.columns().every(function () {
          this.search('').draw();
        });
      //   fetchAllIds({
      //     id: id, // Adjust as per your needs
      //     includedLists: includedLists, // Replace with your state variables
      //     excludedLists: excludedLists, // Replace with your state variables
      //     includedTags: includedTags, // Replace with your state variables
      //     excludedTags: excludedTags, // Replace with your state variables
      //     searchColumn: "status",
      //     search:selectedStatus // Pass the current search value
      // });
      } else {
        tableInstance.column(1).search(selectedStatus).draw();
        fetchAllIds({
          id: id, // Adjust as per your needs
          includedLists: includedLists, // Replace with your state variables
          excludedLists: excludedLists, // Replace with your state variables
          includedTags: includedTags, // Replace with your state variables
          excludedTags: excludedTags, // Replace with your state variables
          searchColumn: "status",
          search:selectedStatus // Pass the current search value
      });
      }
      
    };
  
   
  
    // useEffect(() => {
    //   const filteredData = selectedList ? contacts.filter(item => item.list === selectedList) : contacts;
      
    // }, [selectedList, contacts]);
   
  // const checkboxes = [
  //   "Lead Status",
  //   "First Name",
  //   "Last Name",
  //   "Phone",
  //   "Property Address",
  //   "Email",
  //   "List",
  //   "Tags"
  // ];
  const [isSubscribed, setIsSubscribed] = useState(true);

  const [checkedItems, setCheckedItems] = useState({
    checkbox1: true,
    checkbox2: true,
    checkbox3: true,
    checkbox4: true,
    checkbox5: true,
    checkbox6: true,
    checkbox7: true,
    checkbox8: true,
    checkbox9: true,
    checkbox10: true,
    checkbox11: true,
    checkbox12: true,
    
  });

  // Function to handle checkbox change
  const handleCheckboxChange = (e) => {
    const { name, checked } = e.target;
    setCheckedItems({ ...checkedItems, [name]: checked });
  };
  const handleClose = () => {
   
    handleModalClose();
  };
  const handleApplyFilters = (e) => {
    e.preventDefault();
    
    // Handle form submission logic
    if (tableInstance && (includedLists.length || excludedLists.length || includedTags.length || excludedTags.length)) {
      // console.log(includedLists,excludedLists)
        //       console.log('Payload:', {
        //     id: id,
        //     excludedLists: excludedLists.join('|'),
        //     excludedTags: excludedTags.join('|'),
        //     // Include other necessary fields
        // });
        
        // Perform any other actions like updating DataTable filters
        // Example:
        // tableInstance.column(10).search(excludedLists.join('|'), true, false);
        // tableInstance.column(11).search(excludedTags.join('|'), true, false);
        tableInstance.draw();
        fetchAllIds({
          id: id, // Adjust as per your needs
          includedLists: includedLists, // Replace with your state variables
          excludedLists: excludedLists, // Replace with your state variables
          includedTags: includedTags, // Replace with your state variables
          excludedTags: excludedTags, // Replace with your state variables
          searchColumn:"",
          search: "" // Pass the current search value
      });
    } else {
        alert('Please select at least one filter option.');
    }

    // Clear selections after form submission
    // setIncludedLists('');
    // setExcludedLists('');
    // setIncludedTags('');
    // setExcludedTags('');
};

//   const handleApplyFilters = (e) => {
    
//     e.preventDefault();
    
//     handlefilterModalClose();
//     if (tableInstance && (includedLists.length || excludedLists.length || includedTags.length || excludedTags.length)) {
//       let listFilter = includedLists.length ? includedLists.join('|') : '';
//       if (excludedLists.length) {
//           listFilter += (listFilter ? '|' : '') + `^(?!(${excludedLists.join('|')})).*$`;
//           // listFilter += (listFilter ? '|' : '') + `^(?!(${excludedLists.join('|')})).*$`;
//       }
//       // tableInstance.column(10).search(listFilter, true, false);
  
//       let tagFilter = includedTags.length ? includedTags.join('|') : '';
//       if (excludedTags.length) {
//           tagFilter += (tagFilter ? '|' : '') + `^(?!(${excludedTags.join('|')})).*$`;
//           // tagFilter += (tagFilter ? '|' : '') + `^(?!(${excludedTags.join('|')})).*$`;
//       }
//       // tableInstance.column(11).search(tagFilter, true, false);
  
//       tableInstance.draw();
//     //   setIncludedLists([])
//     // setExcludedLists([])
//     // setIncludedTags([])
//     // setExcludedTags([])
//   } else {
//       alert('Please select at least one filter option.');
//   }
  
// //   if (tableInstance && includedLists && excludedLists && includedTags && excludedTags) {
// //     let listFilter = includedLists.length ? includedLists.join('|') : '';
// //     if (excludedLists.length) {
// //         listFilter += (listFilter ? '|' : '') + `^(?!(${excludedLists.join('|')})).*$`;
// //     }
// //     tableInstance.column(7).search(listFilter, true, false);

// //     let tagFilter = includedTags.length ? includedTags.join('|') : '';
// //     if (excludedTags.length) {
// //         tagFilter += (tagFilter ? '|' : '') + `^(?!(${excludedTags.join('|')})).*$`;
// //     }
// //     tableInstance.column(8).search(tagFilter, true, false);

// //     tableInstance.draw();
// // }

//     // Apply the filters to the DataTable
//     // if (tableInstance && includedLists && excludedLists && includedTags && excludedTags ) {
//     //   tableInstance.column(7).search(includedLists.join('|'), true, false).draw();
//     //   tableInstance.column(7).search(`^(?!(${excludedLists.join('|')})).*$`, true, false).draw();
//     //   tableInstance.column(8).search(includedTags.join('|'), true, false).draw();
//     //   tableInstance.column(8).search(`^(?!(${excludedTags.join('|')})).*$`, true, false).draw();
//     // }
    

   

//   };
const handleReset = () => {
  setIncludedLists(prevState => []);
  setExcludedLists(prevState => []);
  setIncludedTags(prevState => []);
  setExcludedTags(prevState => []);
  
  tableInstance.columns().every(function () {
    this.search('').draw();
  });

  fetchAllIds({
    id: id, // Adjust as per your needs
    includedLists: [], // Clearing with an empty array
    excludedLists: [], // Clearing with an empty array
    includedTags: [], // Clearing with an empty array
    excludedTags: [], // Clearing with an empty array
    searchColumn: "",
    search: "" // Clearing the search value
  });

  handleModalClose();
  
};

  function formatPhoneNumbercrm(phone) {

    if (phone.startsWith("+1")) {
        return phone.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    } else {
        return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
    }
}
  const textareaRef = useRef(null);
  const pushaudience = async (e) => {
    e.preventDefault();

    if (audienceName.length === 0) {
      
      alert("Please select at least one Number.");
      return;
    }else{
    
      try {
        setSending(true)
        // setWebhookResult('')
        // const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
        // const newRowsIds = [];
      
        // allRows.each(function(row) {
        //   const checkbox = $(row).find('input[type="checkbox"]')[0];
        //   if (checkbox && checkbox.checked) {
        //     newRowsIds.push(row.id);
        //   }
        // });
        const response = await axios.post(`${API_BASE_URL}/api/push-audience`, {
                    name: audienceName,
                    id: rowsIds,
                });
                // setWebhookResult(response.data)
               setSelectednumber('')
                
                if(response.data === 1){
                  alert("Audience Name Already Taken")
                  setSending(false);
                  return;
                }

                setSending(false);
                alert("Audience Pushed Successfuly ")
                // window.location.reload()
                tableInstance.columns().every(function () {
                    this.search('').draw();
                  });
                  handleaudienceModalClose()
      
      } catch (error) {
        setSending(false);
        // setSelectedWebhooks([])
      }
    }
  };
  const pushphone = async (e) => {
    e.preventDefault();

    if (selectednumber.length === 0) {
      
      alert("Please select at least one Number.");
      return;
    }else{
    
      try {
        setSending(true)
        // setWebhookResult('')
        // const allRows = tableInstance.rows({ search: 'applied' }).nodes({ page: 'all' });
        // const newRowsIds = [];
      
        // allRows.each(function(row) {
        //   const checkbox = $(row).find('input[type="checkbox"]')[0];
        //   if (checkbox && checkbox.checked) {
        //     newRowsIds.push(row.id);
        //   }
        // });
        const response = await axios.post(`${API_BASE_URL}/api/push-phone`, {
                    number: selectednumber,
                    id: rowsIds,
                }, {
                    responseType: 'stream',
                });
                // setWebhookResult(response.data)
               setSelectednumber('')
                
                

                setSending(false);
                alert("Number Pushed Successfuly ")
                // window.location.reload()
                tableInstance.columns().every(function () {
                    this.search('').draw();
                  });
                  handlephoneModalClose()
      
      } catch (error) {
        setSending(false);
        // setSelectedWebhooks([])
      }
    }
  };
  const sendWebhook = async (e) => {
    e.preventDefault();
    const ids = []
    if (selectedWebhooks.length === 0) {
      
      alert("Please select at least one webhook.");
      return;
    }else{
    
      try {
        setSending(true)
        setWebhookResult('')
        // console.log(rowsIds)
      
        const response = await axios.post(`${API_BASE_URL}/api/send-webhook`, {
                    webhooks: selectedWebhooks,
                    id: rowsIds,
                }, {
                    responseType: 'stream',
                });
                setWebhookResult(response.data)
                setSelectedWebhooks([])
                // const reader = response.data.getReader();
                // const decoder = new TextDecoder();
                // let received = '';
                // while (true) {
                
                //     const { done, value } = await reader.read();
                //     if (done) break;
                //     received += decoder.decode(value);
                //     if (received.endsWith('\n')) {
                //       alert("dne")
                //         const message = received.trim();
                //         const isSuccess = message.includes('Successfuly');
                //         appendMessage(message, isSuccess);
                //         received = '';
                //     }
                // }
                

                setSending(false);
                alert("Sent Succesfully")
                handlewebhookModalClose()
      } catch (error) {
        setSending(false);
        setSelectedWebhooks([])
      }
    }
  };
  const handleFormSubmit = async (event) => {
    event.preventDefault();
    setSending(true);

    // const formData = {
    //   id: selectedContact.id,
    //   firstname: event.target.elements['firstname'].value,
    //   lastname: event.target.elements['lastname'].value,
    //   phone: event.target.elements['phone'].value,
    //   address: event.target.elements['address'].value,
    //   email: event.target.elements['email'].value,
    //   tags: event.target.elements['tags'].value,
    //   list: event.target.elements['list-select'].value,
    //   status: event.target.elements['status-select'].value,
    //   notes: event.target.elements['notes'].value,
    // };
    // console.log(selectedContact)
    try {
    if (newcontact){
      if(selectedContact.status.length  < 1 || selectedContact.list.length  < 1 ){
        alert("Please Select All Fields")
        setSending(false);
        return;
      }
      await axios.post(`${API_BASE_URL}/api/add-crm-contact`, {selectedContact,id:id});

    }else{
      await axios.post(`${API_BASE_URL}/api/update-crm-contact`, selectedContact);

    }
      
      alert("Contact Updated Succesfully")
      handleeditModalClose()

      // window.location.reload()
      tableInstance.columns().every(function () {
        this.search('').draw();
      });
      // handlephoneModalClose()
      setSending(false);

    } catch (error) {
      setSending(false);

      alert('Error submitting form:');
      // Handle error (e.g., show an error message)
    } finally {
      // setSending(false);
    }
  };
//   const formatPhoneNumber = (phoneNumber) => {
//     if (phoneNumber == null) {
//         return null; // Return null for null or undefined values
//     }

//     const digits = String(phoneNumber).replace(/\D/g, ''); // Ensure phoneNumber is a string and remove non-digit characters
//     const countryCode = '+1';

//     if (String(phoneNumber).startsWith('+1')) {
//       // alert(phoneNumber)
//         // If the number is already in international format with country code +1, keep it as is
//         return phoneNumber;
//     } else if (digits.length === 10) {
//         // Add +1 to 10 digit numbers
//         return countryCode + digits;
//     } else if (digits.length > 10) {
//         // Add +1 to numbers with more than 10 digits
//         return countryCode + digits.slice(-10);
//         // return countryCode + digits.slice(0);
//     } else {
//         return null; // Invalid phone number format
//     }
// };
  const formatPhoneNumber = (number) => {
    const normalizedNumber = number.replace(/\D/g, ''); 

    if (normalizedNumber.length === 0) return ''; // Handle empty input
    if (normalizedNumber.length <= 3) return `(${normalizedNumber})`; // Ensure both brackets
    if (normalizedNumber.length <= 6) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3)}`;
    if (normalizedNumber.length <= 10) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6)}`;

    // Handle numbers longer than 10 digits
    return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6, 10)}`;
};

  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
          {loading && (
          <>
        <div class="loading-container">
    <img src={loadinggif} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
       <Modal show={showtagmodal} contentClassName="custom-modal-content" onHide={handletagModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={assigntag}>

        <Modal.Body>
        <div className="text-center">
            <h4>Assing  Tags</h4>
        </div>
        <div className="modal-body ">
            {tags  ?(
            
              <div className="form-group" style={{textAlign:"center"}}>
              <select id="list-select" name="tags" className="form-select custom-dorpdown-toglle" value={selectedtag} onChange={(e) => setSelectedTag(e.target.value)} >
        <option selected>Select Tag</option>
        {tags.map(item => (
          <option  value={item.tagname}>{item.tagname}</option>
        ))}
      </select>
              
              </div>
              
          
            ):(
<div className="text-center text-white fs-6">
    No Tags Found <Link to="/data-management">Create New?</Link>
  </div>
            )}
          </div>
        </Modal.Body>
        {tags  &&(<Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handletagModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" >Assign</button>
        </Modal.Footer>
        )}
          </form>
      </Modal>
        <Modal show={showeditmodal} contentClassName="custom-modal-content" onHide={handleeditModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={handleFormSubmit}  >

        <Modal.Body>
        <div className="text-center">
             {newcontact ? (
        
             <h4>ADD CONTACT</h4>
             ):(
             <h4>EDIT CONTACT</h4>

             )}
        </div>
        <div className="modal-body ">
       {!newcontact ? (
     
       <div className='container mt-3'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className="text-white">First Name:</label>
                      <input className="form-control" type="text" placeholder='First Name' value={selectedContact.firstname || ''} id="firstname" onChange={handleInputChange} />
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">Last Name:</label>
                      <input className="form-control" type="text" placeholder='Last Name' value={selectedContact?.lastname || ''} id="lastname" onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className="text-white">Phone:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='Phone'     value={selectedContact.phone ? formatPhoneNumber(selectedContact.phone) : ''}
 id="phone" onChange={handleInputChange} />
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">Property Address:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='Address' value={selectedContact?.address || ''} id="address" onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className="text-white">City:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='City'     value={selectedContact?.city || ''}
 id="city" onChange={handleInputChange} />
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">State:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='State' value={selectedContact?.state || ''} id="state" onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className='row'>
                  <div className='col-md-6'>
                      <label className="text-white">Zip:</label>
                      <br />
                      <input className="form-control" placeholder='Zip' value={selectedContact?.zip || ''} id="zip" onChange={handleInputChange} />
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">Email:</label>
                      <br />
                      <input className="form-control" type="email" placeholder='Email' value={selectedContact?.email || ''} id="email" onChange={handleInputChange} />
                    </div>
                   
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className="text-white">List:</label>
                      <br />
                      <select id="list" name="list" className="form-select custom-dorpdown-toglle" value={selectedContact?.list || ''} onChange={handleInputChange}>
                        <option value="" disabled>Select List</option>
                        {lists.map(item => (
                          <option key={item.listname} value={item.listname}>{item.listname}</option>
                        ))}
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">Tags:</label>
                      <br />
                      <select id="tags" name="tags" className="form-select custom-dorpdown-toglle" value={selectedContact?.tags || ''} onChange={handleInputChange}>
                        <option value="" disabled>Select Tags</option>
                        {tags.map(item => (
                          <option key={item.tagname} value={item.tagname}>{item.tagname}</option>
                        ))}
                      </select>
                      {/* <input type="text" className="form-control" placeholder='Tags' value={selectedContact?.tags || ''} id="tags" onChange={handleInputChange} /> */}
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">Status:</label>
                      <br />
                      <select id="status" name="status" className="form-select custom-dorpdown-toglle" value={selectedContact?.status || ''} onChange={handleInputChange}>
                        <option value="" disabled>Select Status</option>
                        <option value="New">New</option>
                        <option value="Hot">Hot</option>
                        <option value="Warm">Warm</option>
                        <option value="Not Interested">Not Interested</option>
                        <option value="DNC">DNC</option>
                        <option value="Follow UP">Follow Up</option>
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">Notes:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='Notes' value={selectedContact?.notes || ''} id="notes" onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className='row'>
                    {/* <div className='col-md-6'>
                      <label className="text-white">Notes:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='Notes' value={selectedContact?.notes || ''} id="notes" onChange={handleInputChange} />
                    </div> */}
                  </div>
                </div>
                ):(
                <div className='container mt-3'>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className="text-white">First Name:</label>
                      <input className="form-control" type="text" placeholder='First Name' required id="firstname" onChange={handleInputChange} />
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">Last Name:</label>
                      <input className="form-control" type="text" placeholder='Last Name' required id="lastname" onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className="text-white">Phone:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='Phone' required     value={formatPhoneNumber(selectedContact.phone)}
 id="phone" onChange={handleInputChange} />
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">Property Address:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='Address'  id="address" onChange={handleInputChange} />
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className="text-white">Email:</label>
                      <br />
                      <input className="form-control" type="email" placeholder='Email'  id="email"  onChange={handleInputChange} />
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">Tags: <Link to="/data-management">Create</Link></label>
                      <br />
                      <select id="tags" name="tags" className="form-select custom-dorpdown-toglle"  onChange={handleInputChange}>
                        <option value="" disabled>Select Tags</option>
                        {tags.map(item => (
                          <option key={item.tagname} value={item.tagname}>{item.tagname}</option>
                        ))}
                      </select>
                      {/* <input type="text" className="form-control" placeholder='Tags' required id="tags" onChange={handleInputChange} /> */}
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-6'>
                      <label className="text-white">List: <Link to="/data-management">Create</Link></label>
                      <br />
                      <select id="list" name="list" className="form-select custom-dorpdown-toglle" required onChange={handleInputChange}>
                        <option >Select List</option>
                        {lists.map(item => (
                          <option key={item.listname} value={item.listname}>{item.listname}</option>
                        ))}
                      </select>
                    </div>
                    <div className='col-md-6'>
                      <label className="text-white">Status:</label>
                      <br />
                      <select id="status" name="status" className="form-select custom-dorpdown-toglle"  onChange={handleInputChange} >
                        <option >Select Status</option>
                        <option selected value="New">New</option>
                        <option value="Hot">Hot</option>
                        <option value="Warm">Warm</option>
                        <option value="Not Interested">Not Interested</option>
                        <option value="DNC">DNC</option>
                        <option value="Follow UP">Follow Up</option>
                      </select>
                    </div>
                  </div>
                  <div className='row'>
                    <div className='col-md-12'>
                      <label className="text-white">Notes:</label>
                      <br />
                      <input className="form-control" type="text" placeholder='Notes'  id="notes" onChange={handleInputChange}  />
                    </div>
                  </div>
                </div>
              
              )}
              </div>
                    
       
          
            
       
        </Modal.Body>
      <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleeditModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn"  disabled={sending}>{sending ? 'Saving....' : 'Save'}</button>
        </Modal.Footer>
       
          </form>
      </Modal>
      <Modal show={showimporoptionsmmodal} contentClassName="custom-modal-content" onHide={handleimportoptModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form  >

        <Modal.Body>
        <div className="text-center">
            <h4>Import Contacts</h4>
        </div>
        <div className="modal-body ">
           
            
              <div className="form-group" style={{textAlign:"center"}}>
             
               <Link to="/file_upload" type="button" className="btn btn_primary save_btn" style={{ marginRight: '5px' }}>
        <img alt="" className="mr-3" />&nbsp;&nbsp;Bulk Import
    </Link>&nbsp;&nbsp;
    <Link to="/add_contact" type="button" className="btn btn_primary save_btn" style={{ marginRight: '5px' }}>
        <img alt="" className="mr-3" />&nbsp;&nbsp;Single Contact
    </Link>
              </div>
              
          
          
          </div>
        </Modal.Body>
       
          </form>
      </Modal>
      
      <Modal show={showpushaudiencemodal} contentClassName="custom-modal-content" onHide={handleaudienceModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(e) => pushaudience(e)} >

        <Modal.Body>
        <div className="text-center">
            <h4>PUSH TO AUDIENCE</h4>
        </div>
        <div className="modal-body ">
        <input type="text" required name="campaignname" placeholder="E.G PROMOTION CAMPAIGN" className="form-control mt-1" value={audienceName} onChange={(e) => setaudienceName(e.target.value)} />
            
          </div>
        </Modal.Body>
       <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleaudienceModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn"  disabled={sending}>{sending ? 'Pushing....' : 'PUSH'}</button>
        </Modal.Footer>
        
          </form>
      </Modal>
           <Modal show={showpushtophonemodal} contentClassName="custom-modal-content" onHide={handlephoneModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(e) => pushphone(e)} >

        <Modal.Body>
        <div className="text-center">
            <h4>PUSH TO PHONE</h4>
        </div>
        <div className="modal-body ">
            {phonenumbers  ?(
            
              <div className="form-group" style={{textAlign:"center"}}>
              <select id="list-select" name="list" className="form-select custom-dorpdown-toglle" value={selectednumber} onChange={(e) => setSelectednumber(e.target.value)} >
        <option value="" selected>Select Phone Number</option>
        {phonenumbers.map(item => (
          <option value={item.number}>{item.name} {item.number}</option>
        ))}
      </select>
              
              </div>
              
          
            ):(
<div className="text-center text-white fs-6">
    No Numbers Found <Link to="/numbers">Purchase One?</Link>
  </div>
            )}
          </div>
        </Modal.Body>
        {phonenumbers  &&(<Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlephoneModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn"  disabled={sending}>{sending ? 'Pushing....' : 'PUSH'}</button>
        </Modal.Footer>
        )}
          </form>
      </Modal>
          {/* Push to webhook */}
       <Modal show={webhookModal}contentClassName="custom-modal-content modalwebhook" onHide={handlewebhookModalClose} backdropClassName="custom-backdrop">
  <form ref={formRef} onSubmit={sendWebhook}>
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body   >
   
    {sending ? (
            <div className="text-center">
              <p>Processing.....</p>
              {/* <textarea value={webhookresult} style={{ whiteSpace: 'pre-wrap',maxHeight:'500px', width:'100%', overflowY: 'auto' }}></textarea> */}

            </div>
         
          ) : (
            <>
              {webhook && webhook.length > 0 && (
                <div className="text-center">
                  <h4 className="text-center">SELECT WEBHOOK</h4>
                </div>
              )}
              {webhook && webhook.length > 0 ? (
                <table style={{ width: '100%' }} className="mt-3">
                  <thead>
                    <tr>
                      <th style={{ width: '50%' }}>Name</th>
                      <th style={{ width: '45%' }}>URL</th>
                      <th style={{ width: '5%', textAlign: 'right' }}>Action</th>
                    </tr>
                  </thead>
                  <tbody >
                    {webhook.map((item) => (
                      <tr key={item.webhookurl} >
                        <td>{item.webhookname}</td>
                        <td>{item.webhookurl}</td>
                        <td style={{ textAlign: 'right' }}>
                          <input type="checkbox" onChange={(e) => handleselectwebhookchange(e, item.webhookurl)} />
                        </td>
                      </tr>
                    ))}
                  </tbody>
                </table>
              ) : (
                <div className="text-center text-white fs-6">
    No Webhook Found <Link to="/integerations">Create New?</Link>
  </div>
              )}
            </>
          )}
        </Modal.Body>
        {!sending &&    (
     <Modal.Footer className="justify-content-center mb-2">
           {webhook && webhook.length > 0 && (
            <div style={{ display: 'flex', margin: '20px' }}>
              <button type="button" className="btn btn-secondary cancel_btn" onClick={handlewebhookModalClose} data-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary save_web_btn">Send</button>
            </div>
           )}
          </Modal.Footer>
           )}
  </form>
</Modal>


         <Modal show={showfilterModal} contentClassName="custom-modal-content" onHide={handlefilterModalClose} backdropClassName="custom-backdrop">
  <form id="columnForm" onSubmit={handleApplyFilters}>
    <Modal.Header closeButton></Modal.Header>
    <Modal.Body>
      <div className="container" style={{fontSize: '15px'}}>
        <div className="row">
          <div className="col-md-6 float-left" style={{textAlign: 'left'}}>
            <span>Lists</span>
            <br/><br/>
            <div><span className="ball-icon">OK</span> <span>Included</span></div>
            <br/>
            <select  id="mySelect" className="form-select custom-dorpdown-toglle mb-3"  onChange={handleIncludeListChange}>
            <option >Select</option>
             
              {lists.map(item => (
                <option key={item.listname} value={item.listname}>{item.listname}</option>
              ))}
            </select>

            <br/>
            <div><span className="ball-icon">OK</span> <span>Excluded</span></div>
            <br/>
            <select id="mySelect" className="form-select custom-dorpdown-toglle"  onChange={handleExcludeListChange}>
            <option  >Select</option>
              
              {lists.map(item => (
                <option key={item.listname} value={item.listname}>{item.listname}</option>
              ))}
            </select>
          </div>
          <div className="col-md-6 float-right" style={{textAlign: 'right'}}>
            <div style={{textAlign: 'left'}}><span>Tags</span></div>
            <br/>
            <div style={{textAlign: 'left'}}><span className="ball-icon">OK</span> <span>Included</span></div>
            <br/>
            <select value={includedTags} id="mySelect" className="form-select custom-dorpdown-toglle mb-3"   onChange={handleIncludeTagChange}>
            <option >Select</option>
             
              {tags.map(item => (
                <option key={item.tagname} value={item.tagname}>{item.tagname}</option>
              ))}
            </select>
            <br/>
            <div style={{textAlign: 'left'}}><span className="ball-icon">OK</span> <span>Excluded</span></div>
            <br/>
            <select  value={excludedTags} id="mySelect" className="form-select custom-dorpdown-toglle"  onChange={handleExcludeTagChange}>
            <option >Select</option>
              
              {tags.map(item => (
                <option key={item.tagname} value={item.tagname}>{item.tagname}</option>
              ))}
            </select>
          </div>  
        </div>
      </div>
    </Modal.Body>
    <Modal.Footer className="justify-content-center mb-2">
      {/* <button type="button" className="btn btn-secondary cancel_btn" onClick={handlefilterModalClose} data-dismiss="modal">Close</button> */}
      <div style={{display:'flex',margin:'20px'}}>
      <button type="button" className="btn btn-secondary cancel_btn" onClick={handlefilterModalClose} data-dismiss="modal">Close</button>

    <button type="button" className="btn btn-secondary cancel_btn" onClick={handleReset}>Reset</button>
    <button type="submit" className="btn btn-primary save_web_btn">Apply</button>
  </div>
  
    </Modal.Footer>
  </form>
</Modal>

  
      <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleClose} backdropClassName="custom-backdrop">
  {showModal && (
    <form id="columnForm" onSubmit={(e) => handleSubmit(e, tableInstance)}>
      <Modal.Header closeButton>
      </Modal.Header>
      
      <Modal.Body>
        <div className="text-center">
            <h4>TABLE CONFIGURATION</h4>
        </div>
        <div >
        <div class="container mt-3" style={{fontSize: '17px',padding: '10px 38px 1px 69px'}}>
  <div class="row">
    <div class="col-md-6">
      
      <div>
        <label className="mb-2">
          1. <input checked={checkedItems.checkbox1}
              onChange={handleCheckboxChange} id="subscribe" name="checkbox1" type="checkbox" class="toggle-vis" data-column="1" /> Lead Status
        
        </label>

      </div>
      <div>
        <label className="mb-2">
          2. <input type="checkbox" class="toggle-vis" data-column="2" checked={checkedItems.checkbox2}
              onChange={handleCheckboxChange} name="checkbox2"  /> First Name
        </label>
      </div>
      <div>
        <label className="mb-2">
          3. <input type="checkbox" class="toggle-vis" data-column="3"   checked={checkedItems.checkbox3}
              onChange={handleCheckboxChange} name="checkbox3" /> Last Name
        </label>
      </div>
      <div>
        <label className="mb-2">
          4. <input type="checkbox" class="toggle-vis" data-column="4"   checked={checkedItems.checkbox4}
              onChange={handleCheckboxChange} name="checkbox4" /> Phone
        </label>
      </div>
      <div>
        <label className="mb-2">
          5. <input type="checkbox" class="toggle-vis" data-column="5"   checked={checkedItems.checkbox5}
              onChange={handleCheckboxChange} name="checkbox5" /> Property Address
        </label>
      </div>
      <div>
        <label className="mb-2">
          11. <input type="checkbox" class="toggle-vis" data-column="11"   checked={checkedItems.checkbox11}
              onChange={handleCheckboxChange} name="checkbox11" /> Tags
        </label>
      </div>
      
    </div>
    <div class="col-md-6">
      
      <div>
        <label className="mb-2">
          6. <input type="checkbox" class="toggle-vis" data-column="6"   checked={checkedItems.checkbox6}
              onChange={handleCheckboxChange} name="checkbox6" /> City
        </label>
      </div>
      <div>
        <label className="mb-2">
          7. <input type="checkbox" class="toggle-vis" data-column="7"   checked={checkedItems.checkbox7}
              onChange={handleCheckboxChange} name="checkbox7" /> State
        </label>
      </div>
      <div>
        <label className="mb-2">
          8. <input type="checkbox" class="toggle-vis" data-column="8"   checked={checkedItems.checkbox8}
              onChange={handleCheckboxChange} name="checkbox8" /> Zip
        </label>
      </div>
      <div>
        <label className="mb-2">
          9. <input type="checkbox" class="toggle-vis" data-column="9"   checked={checkedItems.checkbox9}
              onChange={handleCheckboxChange} name="checkbox9" />Email
        </label>
      </div>
      <div>
        <label className="mb-2">
          10. <input type="checkbox" class="toggle-vis" data-column="10"   checked={checkedItems.checkbox10}
              onChange={handleCheckboxChange} name="checkbox10" /> List
        </label>
      </div>
      <div>
        <label className="mb-2">
          12. <input type="checkbox" class="toggle-vis" data-column="12"   checked={checkedItems.checkbox12}
              onChange={handleCheckboxChange} name="checkbox12" /> Notes
        </label>
      </div>
      
    </div>
  </div>
</div>

</div>

    

             
         
        </Modal.Body>
      <Modal.Footer className="justify-content-center mb-2">
        <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal">Close</button>
        <button type="submit" className="btn btn-primary save_web_btn">Save</button>
      </Modal.Footer>
    </form>
  )}
</Modal>

        
       
        <div className="row ">
          
          <div className="col-12" style={{marginLeft:'-2px'}}>
         
        <div className="crm_bd userEditForm mt-4 ">
        <div className="mb-2" style={{ textAlign: 'left' }}>
            <h5>&nbsp;Contacts</h5>
            </div>
            
            <div className="mb-2 justify-content-end" style={{ textAlign: 'right',display:'flex' }}>
            <div className="mt-1 fs-6 count-selection">{totalselected} Selected</div>
            <div ref={dropdownRef}>
              <Dropdown show = {show} onSelect={(eventKey) => handleSelectionChange(eventKey)} >
  <Dropdown.Toggle variant="secondary" id="dropdown-basic" onClick = {() => setShow(!show)}>
   Select Contacts
  </Dropdown.Toggle>

  <Dropdown.Menu style={{ padding: '10px', fontSize: '14px' }}> {/* Adjust padding and font size */}
  
    <Dropdown.Item eventKey="selectAll">Select All</Dropdown.Item>
    <Dropdown.Item eventKey="selectVisible">Select Visible</Dropdown.Item>
    <Dropdown.Item eventKey="unselectAll">Unselect All</Dropdown.Item>
    
    {/* Custom Area */}
    <Dropdown.Item>
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl
         type="Number"
          placeholder="Custom Number"
          value={customValue}
          onChange={(e) => {
            const value = e.target.value < 0 ? 0 : e.target.value;
    setCustomAreaValue(value);

            setFromValue('');
            setToValue('');
          }}
          

          style={{ marginRight: '10px', width: '100px' }} 
          onMouseDown={(e) => e.stopPropagation()}
        />
       <span 
                role="img" 
                aria-label="tick" 
                // ref={tickIconRef} 
                onClick={handleTickClick} 
                style={{ cursor: 'pointer' }}
              >
                ✔️
              </span>
      </div>
    </Dropdown.Item>

    {/* Custom Number */}
    <Dropdown.Item >
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <FormControl
         type="Number"
          placeholder="From"
          value={fromValue}
          // onChange={(e) => setFromValue(e.target.value)}
          onChange={(e) => {
            const value = e.target.value < 0 ? 0 : e.target.value;
            setFromValue(value);
            setCustomAreaValue('');
            
          }}
          style={{ marginRight: '10px', width: '100px' }}
          onMouseDown={(e) => e.stopPropagation()}
        />
        <FormControl
        type="Number"
          placeholder="To"
          value={toValue}
          // onChange={(e) => setToValue(e.target.value)}
          onChange={(e) => {
            
            const value = e.target.value < 0 ? 0 : e.target.value;
            setToValue(value);
            setCustomAreaValue('');
            
          }}
          style={{ marginRight: '10px', width: '100px' }}
          onMouseDown={(e) => e.stopPropagation()}
        />
         <span
                role="img"
                aria-label="tick"
                onClick={handleRangeTickClick}
                style={{ cursor: 'pointer' }}
              >
                ✔️
              </span>
      </div>
    </Dropdown.Item>
  </Dropdown.Menu>
</Dropdown></div>

          

      <select
        id="myActionSelect"
        className="form-select custom-dorpdown-toglle" 
        style={{ 
    width: '15%', 
    marginRight: '11px', 
    backgroundColor: isDropdownDisableds ? '' : '#f9b840'
    
}}

        value={action}
        onChange={handleActionChange}
        // disabled={isDropdownDisabled}
        disabled={isDropdownDisableds}
      >
        <option value=""> Action</option>
        <option value="Push To Webhook"> Push To Webhook</option>
        <option value="Push To Phone"> Push To Phone</option>
        <option value="Push_Audience"> Push To Audience</option>
        <option value="Assign Tag"> Assign Tag</option>
        <option value="Export CSV"> Export CSV</option>
        
        <option value="Delete"> Delete</option>
      </select>

        <button onClick={handlefilterModalOpen} className="btn lst_tag" style={{ marginRight: '9px',width: '13%' }}><img src={filter} style={{ marginRight: '22px'}}></img>Filter</button>
        <select id="list-select" className="form-select custom-dorpdown-toglle" style={{ width: '18%', marginRight: '11px' }} onChange={() => handleSelectChange(tableInstance)}>
        <option value="" selected> Show All List</option>
        {lists.map(item => (
          <option key={item.listname} value={item.listname}>{item.listname}</option>
        ))}
      </select>
       
      <Link onClick={handleimportoptModalOpen} type="button" className="btn btn_primary save_btn" style={{ marginRight: '5px' }}>
        Import Contacts
    </Link>
    {/* <Link to="/file_upload" type="button" className="btn btn_primary save_btn" style={{ marginRight: '5px' }}>
        <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Import Contacts
    </Link> */}
    <div className="tble-config d-inline">
        {/* <button onClick={opennewcontactmodal} type="button" className="btn p-0 add-icon-crm">
            <img src={add} alt="" />
        </button> */}
        <button onClick={handleModalOpen} type="button" className="btn p-0">
            <img src={settings} style={{width: '37px',
    borderRadius: '50px'}} />
        </button>
    </div>
</div>


          <div class="table-responsive text-nowrap mt-3 table-container ">
          <table className="display crm_tabble row-border order-column" ref={tableRef}>
  <thead class="crm_thead">
    <tr>
    <th className="text-center"></th>
   {/* <th className="text-center"> 
   <input type="checkbox" id="headerCheckbox" onChange={selectAll}
                   />
   {/* {allIds.length > 0 && (
   <input type="checkbox" id="headerCheckbox" onChange={selectAll}
                   />)} */}
                   
      {/* <th className="text-center"><input type="checkbox" id="headerCheckbox" onChange={handleSelectAllChange}
                  checked={selectedIds.size === contacts.length}  /></th> */}
      <th>Lead Status
       
      </th>
      <th>First Name</th>
      <th>Last Name</th>
      <th>Phone</th>
      <th>Property Address</th>
      <th>City</th>
      <th>State</th>
      <th>Zip</th>
      <th>Email</th>
      <th>List</th>
      <th>Tags</th>
      <th>Notes</th>
      
    </tr>
    {/* <tr class="tfoot">
    <th style={{borderBottom: 'none !important'}}><img src={searchicon}></img></th>

    <th>
     
      <select id="statusSelect" onChange={() => handlestatusdropdownChange(tableInstance)}>
     
  <option selected value="All">All</option>
  <option value="New"><FaSun className="fa-warm-icon"  /> New</option>
  <option value="Warm"><FaSun className="fa-warm-icon"  /> Warm</option>
  <option value="Hot"><FaSun className="fa-warm-icon"  /> Hot</option>
  <option value="Not Interested"><FaThumbsDown className="fa-thumb-icon"/>Not Interested</option>
  <option value="DNC"><FaBan className="fa-dnc-icon" /> DNC</option>
  <option value="Follow UP"><FaHistory className="fa-history-icon" />  Follow UP</option>
 
</select>
      </th>
      <th> <input type="text" placeholder="First Name"></input></th>
      <th><input type="text" placeholder="Last Name"></input></th>
      <th><input type="text" placeholder="Phone"></input></th>
      <th> <input type="text" placeholder="Property Address"></input></th>
      <th><input type="text" placeholder="City"></input></th>
      <th><input type="text" placeholder="State"></input></th>
      <th><input type="text" placeholder="Zip"></input></th>
      <th><input type="text" placeholder="Email"></input></th>
      <th><input type="text" placeholder="List"></input></th>
      <th><input type="text" placeholder="Tags"></input></th>
      <th><input type="text" placeholder="Notes"></input></th>
      
  
    </tr> */}
  </thead>
  <tfoot>
    <tr>
      <th style={{borderBottom: 'none !important'}}><img src={searchicon}></img></th>
      {/* <th><input type="text" placeholder="Lead Status"></input> </th> */}
      <th>
      {/* <Dropdown className="d-inline-block">
      <Dropdown.Toggle variant=""  className="custom-toggle" id="mySelect dropdown-basic">
        Filter
      </Dropdown.Toggle>

      <Dropdown.Menu>
      
 
      <li className="mb-2"><FaFire  className="fa-fire-icon"  /> Hot</li>
      <li className="mb-2"><FaSun className="fa-warm-icon"  /> Warm</li>
      <li className="mb-2"><FaHistory className="fa-history-icon" />  Follow UP</li>
      </Dropdown.Menu>
    </Dropdown> */}
      <select id="statusSelect" onChange={() => handlestatusdropdownChange(tableInstance)}>
     
  <option selected value="All">All</option>
  <option value="New"><FaSun className="fa-warm-icon"  /> New</option>
  <option value="Warm"><FaSun className="fa-warm-icon"  /> Warm</option>
  <option value="Hot"><FaSun className="fa-warm-icon"  /> Hot</option>
  <option value="Not Interested"><FaThumbsDown className="fa-thumb-icon"/>Not Interested</option>
  <option value="DNC"><FaBan className="fa-dnc-icon" /> DNC</option>
  <option value="Follow UP"><FaHistory className="fa-history-icon" />  Follow UP</option>
 
</select>
      </th>
      <th> <input type="text" placeholder="First Name"></input></th>
      <th><input type="text" placeholder="Last Name"></input></th>
      <th><input type="text" placeholder="Phone"></input></th>
      <th> <input type="text" placeholder="Property Address"></input></th>
      <th><input type="text" placeholder="City"></input></th>
      <th><input type="text" placeholder="State"></input></th>
      <th><input type="text" placeholder="Zip"></input></th>
      <th><input type="text" placeholder="Email"></input></th>
      <th><input type="text" placeholder="List"></input></th>
      <th><input type="text" placeholder="Tags"></input></th>
      <th><input type="text" placeholder="Notes"></input></th>
      
    
    </tr>
  </tfoot>
  <tbody className="crm_tbody">
  
  {contacts.length > 0 ? (
      contacts.map(item => (
        <tr id={item.id} onClick={() => handleeditModalOpen(item)} >
            <td><input type="checkbox"  onClick={(e) => e.stopPropagation()}  onChange={(e) => handletableeckboxChange(e, item.id)} ></input></td>
            {/* <td><input type="checkbox" checked={selectedIds.has(item.id)}  onChange={(e) => handletablecheckbox(e, item.id)}></input></td> */}
            <td className="text-center">
            <span className="d-none">{item.status}</span>
  {item.status === 'New' && <FaHome className="fa-home-icon" />}
  {item.status === 'Hot' && <FaFire className="fa-fire-icon" />}
  {item.status === 'Warm' && <FaSun className="fa-warm-icon" />}
  {item.status === 'Not Interested' && <FaThumbsDown className="fa-thumb-icon" />}
  {item.status === 'DNC' && <FaBan className="fa-dnc-icon" />}
  {item.status === 'Follow UP' && <FaHistory className="fa-history-icon" />}
</td>

          <td >{item.firstname}</td>

          <td style={{  textWrap: 'pretty' }} >{item.lastname}</td>
          {/* <td >{item.phone}</td> */}
          <td>{item.phone ? formatPhoneNumbercrm(item.phone) : ''}</td>
          <td >{item.address}</td>
          <td >{item.city}</td>
          <td >{item.state}</td>
          <td >{item.zip}</td>
          <td >{item.email}</td>
          <td >{item.list}</td>
          <td >{item.tags}</td>
          <td >{item.notes}</td>
         
        </tr>
      ))
    ):(
      <tr>
      <td colSpan="3" style={{ textAlign: 'center' }}>No Contacts Found</td>
    </tr>
      
    )
} 

  </tbody>
</table>

   
  </div>

        </div>
        
        </div>
          
      </div>
        
        </Sidebar>
        );
      }
    
}

export default CRM;
