
import React, { useState } from 'react';
import { useStripe, useElements, CardElement,PaymentElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import API_BASE_URL from '../components/config';

const Updtpayform =({ customerid })  => {
  const [error, setError] = useState(null);
  const stripe = useStripe();
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [processing, setProcessing] = useState(false);

  const hanldeupdatesubmit = async (event) => {
    setProcessing(true)
    event.preventDefault();
    const cardElement = elements.getElement(CardElement);
     const { paymentMethod, error } = await stripe.createPaymentMethod({
      type: 'card',
      card: cardElement,
    });

    if (error) {
        alert((error.message))
      // Handle error
    //   setError(error.message);
    } else {
        try{
            const response = await fetch(`${API_BASE_URL}/api/update-payment-method`, {
                method: 'POST',
                headers: {
                  'Content-Type': 'application/json',
                },
                body: JSON.stringify({
                  customer_id: customerid, // Replace with the subscription ID
                  new_payment_method_id: paymentMethod.id,
                }),
              });
      
              if (!response.ok) {
                setProcessing(false)
                alert('Failed to update payment method');
              }
              setProcessing(false)
              alert('Payment method updated successfully');
        }catch (error) {
          setProcessing(false)
          alert('Error updating payment method');
          }
          setProcessing(false)

    }
   
        
 


  };

  return (
    <div>
        {paymentError && <p className="alert alert-danger mt-2" style={{color:'#fff'}}>Please check your card</p> }
     
      <form onSubmit={hanldeupdatesubmit}>
      <label  className='ml-3' htmlFor="card-details" style={{marginLeft: '9px !important',color:'#fff'}}>Card Details:</label>

    
        <CardElement className="custom-card-element mt-2"/>
     
    

     
   
        
    
<div class='row mt-3  '>
  <br></br>
        
        
        
        
      <button className='btn btn-primary ing-reg-btn payment-btn mt-3 mb-3' disabled={!stripe || processing}>
                    {processing ? 'Processing...' : 'Update Card'}
                </button>
    </div>

      </form>
      
      {error && <p style={{ color: 'red' }}>{error}</p>}
    </div>
  );
};

export default Updtpayform;
