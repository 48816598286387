import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import Sidebar from '../components/navbar';
import {  FaTrash,FaPlay,FaPause,FaAngleRight,FaClock,FaCheck } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import searchicon from '../icons/std.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';

import loading from '../icons/loading.gif';

import axios from 'axios';
function Campaign() {
  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
  const navigate = useNavigate();
  const location = useLocation();


  const [showModal, setShowModal] = useState(false);
  const [loadingchk, setLoading] = useState(false);
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false);


  const [showtelnyxModal, setShowtelnyxModal] = useState(false);

 const handletelnyxModalOpen = () => setShowtelnyxModal(true); 
  const handletelnyxModalClose = () => {
    setShowtelnyxModal(false);
    navigate('/integerations')
    }



  const [showupgradeModal, setupgradeShowModal] = useState(false); 
  const handleupgradeModalOpen = () => setupgradeShowModal(true);
  const handleupgradeModalclose = () => {
    navigate('/')
    setupgradeShowModal(false);
}
  
  const [campaign, setCampaign] = useState([]);
  
  const [id, setUserid] = useState('');
  
  
  const [authenticated, setAuthenticated] = useState(null);
  useEffect(() => {
    setLoading(true)
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true);
        setUserid(id);
        const response = await axios.post(`${API_BASE_URL}/api/userinfo`, {
          id,
          dd: sessionStorage.getItem("dd"),
          changed: sessionStorage.getItem("changed"),
        });
        
        const {  apikey,plan } = response.data;
        
        var currentPage = window.location.pathname.split("/").pop(); // Assumes the page name is the last part of the URL
        // alert(response.data.id)
        if (currentPage === 'campaign') {
          // Check if the API key is missing or empty
          if (!apikey || apikey.length < 1) {
            handletelnyxModalOpen();
          } else if (!plan || plan === 'business-starter') {
              // If the API key exists, but the plan is missing or is 'business-starter', open the upgrade modal
              handleupgradeModalOpen();
          }
      }
        try {
          const response = await axios.post(`${API_BASE_URL}/api/fetch-campaign`, {
            id
          });
          setCampaign(response.data);
    setLoading(false)

        } catch (error) {
          setLoading(false)

        }
      } else {
        navigate("/login");
      }
    };
  
    // Call fetchreplies only when the component mounts
    fetchreplies();
  
 
  }, []);
  const handleDelete = async (reply) => {
    
  

   
    const confirmed = window.confirm('Are you sure you want to delete this Campaign?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-campaign/${reply}`);
      
      
      // setTemplates(prevWebhooks => prevWebhooks.filter(item => item._id !== reply));
      // setLoading(true)
      alert("Campaign Deleted Succesfuly")
      window.location.reload()
    } catch (error) {
      // setLoading(false)

      
    }
  };
  useEffect(() => {
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
    
      const intervalId = setInterval(async () => {
        try {
          const id = sessionStorage.getItem("login");
      
          if (id) {
            try {
              const response = await axios.post(`${API_BASE_URL}/api/fetch-campaign`, {
                id
              });
              
              const data  = response.data;
              setCampaign(data)
              
              
              
            } catch (error) {
              
            }
          } else {
            navigate("/login");
          }
        } catch (error) {
          alert('error')
        }
      }, 6000);
      
      return () => clearInterval(intervalId);
    
  }, []); 
  const updateItemStatus = async (id, newStatus) => {

    try {
      const response = await axios.post(`${API_BASE_URL}/api/update-campaign-status`, { id, status: newStatus });
      
        setCampaign(prevItems =>
          prevItems.map(item =>
            item.id === id ? { ...item, status: newStatus } : item
          )
        );
        setLoading(false)
      
    } catch (error) {
      console.error('Error updating status', error);
    }
  };

  const handleStatusToggle = (id, currentStatus) => {
    setLoading(true)
    const newStatus = currentStatus === 1 ? 2 : 1;
    updateItemStatus(id, newStatus);
  };
  const formatPhoneNumber = (phone) => {
    if (phone.startsWith("+1")) {
      return phone.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
}
  if (!authenticated) {
    return null;
  } else {
    return (
        <Sidebar>
               {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
<Modal show={showtelnyxModal} contentClassName="custom-modal-content" onHide={handletelnyxModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
       
        <Modal.Body>
        
        <div className="text-center">
            <h4>PLEASE CONNECT YOUR TELNYX ACCOUNT TO ACCESS THIS FEATURE</h4>
        </div>
        <div className="modal-body ">
            
        <div className="col-12" >
    <iframe width="440" height="299" src="https://www.youtube.com/embed/iSYeb4e-e18?si=jnZWJquIOKcHUS7L" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen=""></iframe>
  </div>
           
          </div>
        </Modal.Body>
      
       
      </Modal>

  <Modal show={showupgradeModal} contentClassName="custom-modal-content" onHide={handleupgradeModalclose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
       
        <Modal.Body>
        
        <div className="text-center">
            <h4>PLEASE UPGRADE YOUR  ACCOUNT TO ACCESS THIS FEATURE</h4>
        </div>
        {/* <div className="modal-body ">
            
        <div className="col-12" >
    <iframe width="440" height="299" src="https://www.youtube.com/embed/iSYeb4e-e18?si=jnZWJquIOKcHUS7L" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen=""></iframe>
  </div>
           
          </div> */}
        </Modal.Body>
      
       
      </Modal>
          {/* <Modal show={showfilterModal} contentClassName="custom-modal-content" onHide={handlefilterModalClose} backdropClassName="custom-backdrop" >
   <form id="columnForm" >
        <Modal.Header closeButton>
        </Modal.Header>
        
        <Modal.Body>
       
      
        <div class="container " style={{fontSize: '15px'}}>
          <div class="row">
            <div  class="col-md-6 float-left" style={{textAlign:'left'}}>
              <span>Lists</span>
              <br></br>
              <br></br>
              <div ><span className="ball-icon">OK</span> <span >Included</span></div>
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div ><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" style={{ }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
            <div  class="col-md-6 float-right" style={{textAlign:'right'}}>
            <div style={{textAlign:'left'}}> <span >Tags</span></div>
          
              
              <br></br>
              <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Included</span></div>
              
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" >
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
          </div>
        </div>



       

             
         
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlefilterModalClose} data-dismiss="modal" >Close</button>
            <button type="submit"  className="btn btn-primary save_web_btn" >Apply</button>
        </Modal.Footer>
        </form>
      </Modal> */}
 
       
        
        <div className="row ">
          
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
        <div className="tab">
      <div className={`tab-item ${location.pathname === '/campaign' ? 'active' : ''}`}>
        <Link to="/campaign" className="link disabled">Campaign</Link>
        {location.pathname === '/campaign' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/audience' ? 'active' : ''}`}>
      <Link to="/audience"  className="link disabled">Audience</Link>
        {location.pathname === '/audience' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/sms_cam_templates' ? 'active' : ''}`}>
      <Link to="/sms_cam_templates"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_cam_templates' && <div className="strip"></div>}
      </div>
      
    </div>
    </div>
       

            

<div className=" mb-2" style={{textAlign:'right'}}>
          
          <Link to="/create_campaign" type="button"  className="btn btn_primary save_btn"   >
        <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Campaign
        </Link>
        </div>
        
        {campaign.length > 0 ? ( 
          <div class="table-responsive text-nowrap mt-3">
    <table class="table" >
      <thead>
        <tr>
                  <th>Campaign</th>
                  <th>Status</th>
                  <th>Action</th>
                  <th>Delivered</th>
                  <th>Complete</th>
                  <th>Total Prospects</th>
                  <th>Total Sent</th>
                  
                  <th>Failed</th>
                  <th>Responses</th>
                  <th>Date Created</th>
                  <th>Numbers</th>

                  <th>Action</th>
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
      {campaign.map((item) => (
                                                <tr>
                      <td><Link style={{color:'snow'}} to={`/view_campaign/${item.id}`}>{item.name} <FaAngleRight /></Link></td>
                      <td>
                     
                      {item.status === 1 ? (
        'Running'
      ) : item.status === 2 ? (
        'Paused'
      ): item.status === 3 ? (
        'Completed'
      ) : (
        'Processing'
      )}
                        </td>
                      <td>
                      {item.status === 1 ? (
                <button
                  className="btn btn-danger"
                  style={{ width: '50px' }}
                  onClick={() => handleStatusToggle(item.id, item.status)}
                >
                  <FaPause />
                </button>
              ) : item.status === 2 ? (
                <button
                  className="btn btn-primary"
                  onClick={() => handleStatusToggle(item.id, item.status)}
                >
                  <FaPlay />
                </button>
              ): item.status === 3 ? (
                <FaCheck/>
              ) : (
                <FaClock />
              )}

                      </td>
                      <td>{item.status !== 0 ? item.totaldelivered || '0' : '0'}</td>
                      <td>
  {item.status !== 0 
    ? (item.totalsent && item.nondnc 
      ? `${Math.round((item.totalsent / item.nondnc) * 100)}%` 
      : '0%') 
    : '0%'}
</td>

<td>{item.status !== 0 ? item.nondnc || '0' : '0'}</td>
<td>{item.status !== 0 ? item.totalsent || '0' : '0'}</td>
<td>{item.status !== 0 ? item.totalfailed || '0' : '0'}</td>
<td>{item.status !== 0 ? item.totalresponse || '0' : '0'}</td>

                      <td>{item.date}</td>
                      
                      <td >
              {item.assignednumbers && item.assignednumbers.length > 0 ? (
                item.assignednumbers.map((phone, index) => (
                  <div key={index}>{formatPhoneNumber(phone)}</div>
                ))
              ) : (
                <div>No phone numbers available</div>
              )}
            </td>
                      <td><button className="btn btn-danger" onClick={() => handleDelete(item.id)}>Delete</button></td>
                      
                  </tr>
      ))}
                  
                  
                                        </tbody>
    </table>
    
  </div>
        ):(
          <div class="text-center">
            <p>No Campaign Found </p>
          </div>
        )}


       
       
        </div>
        </div>
          
     
          
        </Sidebar>
        );
      }
    
}

export default Campaign;
