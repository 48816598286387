import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import App from './App';
import {GoogleOAuthProvider} from '@react-oauth/google';
import reportWebVitals from './reportWebVitals';
import { AuthProvider } from './auth.js';
const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <AuthProvider><GoogleOAuthProvider clientId="684880730703-2gb45lkn9fndjq46n7iq2h4cvd6gqu2g.apps.googleusercontent.com">
    <App/></GoogleOAuthProvider></AuthProvider>;
  </React.StrictMode>
);


// function LoginForm() {
//   return (
//     <div className="container">
//       <div className="login-container">
//         <div className="logo">
//           <img src="your-logo.png" alt="Logo" />
//         </div>
//         <form>
//           <div className="mb-3">
//             <label htmlFor="username" className="form-label">Username</label>
//             <input type="text" className="form-control" id="username" placeholder="Enter your username" />
//           </div>
//           <div className="mb-3">
//             <label htmlFor="password" className="form-label">Password</label>
//             <input type="password" className="form-control" id="password" placeholder="Enter your password" />
//           </div>
//           <button type="submit" className="btn btn-primary">Login</button>
//         </form>
//       </div>
//     </div>
//   );
// }

// export default LoginForm;
// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
