import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import { Modal,Button } from 'react-bootstrap'
import NavDropdown from 'react-bootstrap/NavDropdown';
import Sidebar from '../components/navbar';
import { FaSun,FaFireAlt,FaHistory,FaThumbsDown,FaHome,FaFire, FaThermometerHalf, FaTimesCircle, FaBan, FaReply } from 'react-icons/fa';
import upload from '../icons/upload.svg';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';

function Leadstatus() {
  const navigate = useNavigate();
  const location = useLocation();
  const [authenticated, setAuthenticated] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility

  const handleModalOpen = () => setShowModal(true); // Function to open the modal
  const handleModalClose = () => setShowModal(false); // Function to close the modal

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("login");
    if (loggedInUser) {
      setAuthenticated(loggedInUser);
    } else {
      // Redirect to login if not authenticated
      navigate("/login");
    }
  }, []);

  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
      <Sidebar>
        <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE LIST</h4>
        </div>
        <div className="modal-body ">
            <form>
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">List Name:</label>
                <input type="text" className="form-control" id="recipient-name"  />
              </div>
            
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="button" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
      </Modal>
   
    <div className="row ">
      <div className="col-3" style={{marginRight: '-60px'}}>
      <Menu/>

      </div>
      <div className="col-9" style={{marginLeft:'-2px'}}>
        <div class="serch_bar full_width">
        <div className="tab">
      <div className={`tab-item ${location.pathname === '/data-management' ? 'active' : ''}`}>
        <Link to="/data-management" className="link disabled">Lists</Link>
        {location.pathname === '/data-management' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/lead-status' ? 'active' : ''}`}>
      <Link to="/lead-status"  className="link disabled">Lead Status</Link>
        {location.pathname === '/lead-status' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/tags' ? 'active' : ''}`}>
      <Link to="/tags"  className="link disabled">Tags</Link>
        {location.pathname === '/tags' && <div className="strip"></div>}
      </div>
    </div>
    </div>
    <div className="userEditForm mt-1">
            <h5>&nbsp;Status</h5>
            <div className=" mb-2" style={{textAlign:'right'}}>
          
          {/* <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Status
          </button> */}
      </div>
      <ul className="list-unstyled st_list">
      {/* <li><FaFire /> Hot</li>
      <li><FaThermometerHalf /> Warm</li>
      <li><FaTimesCircle /> Not Interested</li>
      <li><FaBan /> DNC</li>
      <li><FaReply /> Follow UP</li> */}
      <li className="mb-2"><FaHome className="fa-home-icon"  /> New</li>
      <li className="mb-2"><FaFire  className="fa-fire-icon"  /> Hot</li>
      <li className="mb-2"><FaSun className="fa-warm-icon"  /> Warm</li>
      <li className="mb-2"><FaThumbsDown className="fa-thumb-icon"/>Not Interested</li>
      <li className="mb-2"><FaBan className="fa-dnc-icon" /> DNC</li>
      <li className="mb-2"><FaHistory className="fa-history-icon" />  Follow UP</li>
    </ul>
      
        </div>
    </div>
      
  </div>
 
    </Sidebar>
    );
  }
}

export default Leadstatus;
