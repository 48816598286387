import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link,useNavigate  } from 'react-router-dom';
// import jwt from 'jsonwebtoken';
import { jwtDecode } from 'jwt-decode'
// import jwt_decode from "jwt-decode";
import eye  from '../icons/eye.png';
import tickicon  from '../icons/green.png';

import logo from '../icons/logo.png';
import google from '../icons/google.png';
import { GoogleLogin,useGoogleLogin } from '@react-oauth/google';
import {GoogleOAuthProvider} from '@react-oauth/google';
import PhoneInput from "react-phone-input-2";
import passwordpng from '../icons/pass.png';
import API_BASE_URL from '../components/config';

import { loadStripe } from '@stripe/stripe-js';
import 'react-phone-input-2/lib/plain.css';
import Paymentpage from './paymentpage.js';
const stripePromise = loadStripe('pk_live_51NcEOuHYDnVieE8qBDQDi9KA2qOYbL12Tc5FsfBmhdFaBVv6Iw74JD41SbnN9K7QqvOGCVnBHDnm0Z5RPlBmAjXz00uGQnoN3U');

function RegisterForm() {
 
  useEffect(() => {
    // change background color with a random color
    const id = sessionStorage.getItem("login");
      // alert(id)
      if (id) {
        navigate('/')
      }
    const color = Math.floor(Math.random()*16777215).toString(16);
    document.body.style.background = color;
    document.body.style.backgroundColor = "white";
  }, []);

  const navigate = useNavigate();
  const [processing, setProcessing] = useState(false);
  const [emailverified, setGogleEmail] = useState('');
  const [numberverify, setNumber] = useState('');
  const [paymentpage, setPaymentpage] = useState(false);
  const [planpage, setPlanpage] = useState(false);
  const [selectedplan, setSelectedPlan] = useState('');

  const [disableResend, setDisableResend] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [numcode, setnumCode] = useState('');
  const [error, setError] = useState('');
  const [email, setEmail] = useState('');
  const [coderesent, setCoderesent] = useState(false);
  
  const handleSubmit = async (e) => {
    setError('')

    setProcessing(true)
    e.preventDefault();
    
     
        try {
          const verificationCode = Math.floor(100000 + Math.random() * 900000);

      // Send the email and verification code to the backend
      const response = await axios.post(`${API_BASE_URL}/api/send-email-code`, { email, verificationCode });

          if (response.data === 0) {
            // setCodeSent(true);
            setError('')

            navigate(`/signup/code?email=${encodeURIComponent(email)}`);

          } else {
            setError('Email is Already Registered !')
            setProcessing(false)

          }
        } catch (error) {
          setError('Connection Error')
          setProcessing(false)


        }
      

    
    
  };
 
  
 
 
    const handlenumSubmit = async (e) => {
      setProcessing(true)
  
      e.preventDefault();
      setNumber(true)
      setDisableResend(true);
      if (disableResend) {
          setCountdown(5); // Reset countdown to 30 before starting the interval
          let interval = setInterval(() => {
            setCountdown((prevCountdown) => {
              if (prevCountdown === 1) {
                clearInterval(interval);
                setDisableResend(false);
                return 5;
              }
              return prevCountdown - 1;
            });
          }, 1000);
        }
        try {
         
          const response = await axios.post(`${API_BASE_URL}/api/number-verfication`, { phone });
    
          // alert(response.data)
              if (response.data === 1) {
          
                setEmail(true);
                // setPaymentpage(true)
                setProcessing(false)
        
              } else  if (response.data === 0){
                setError("Code Didn't Matched  ")
                setProcessing(false)
        
              }else{
                setError("Please Enter Correct Code  ")
                setProcessing(false)
  
  
              }
            } catch (error) {
              setError("Please Enter Correct Code  ")
              setProcessing(false)
        
            }
    }
    const handleResendClick = async (e) => {
      setCoderesent(false);
  
      setDisableResend(true);
     
      setCountdown(30); 
      let interval = setInterval(() => {
          setCountdown((prevCountdown) => {
          if (prevCountdown === 1) {
              clearInterval(interval);
              setDisableResend(false);
              return 5;
          }
          return prevCountdown - 1;
          });
      }, 1000);
      try {
  
        // Send the email and verification code to the backend
        const response = await axios.post(`${API_BASE_URL}/api/number-verfication`, { phone, numcode });
  
        // alert(response.data)
            if (response.data === 1) {
        
              setCoderesent(true);
              
              setProcessing(false)
      
            } 
          } catch (error) {
            setError("Please Enter Correct Code  ")
            setProcessing(false)
      
          }
    };
    
    const verifynumbercode = async (e) => {
      setCoderesent(false)
      setProcessing(true)
      e.preventDefault();
      try {
  
    const response = await axios.post(`${API_BASE_URL}/api/number-code`, {numcode});
    // alert(response.data)
        if (response.data === 1) {
    
          // setPaymentpage(true)
          setPlanpage(true)
          setProcessing(false)
  
        }else  if (response.data === 0){
          setError("Code Didn't Matched  ")
          setProcessing(false)
  
        }else{
          setError("Please Enter Correct Code  ")
          setProcessing(false)
  
  
        
      }
  
      } catch (error) {
        setError("Please Enter Correct Code  ")
  
        setProcessing(false)
  
      }
     
    };
    const handleplanchange= async (plan) => {
      setSelectedPlan(plan)
      setPaymentpage(true)
      setPlanpage(false)
      
    };
    const googlelogin = async (credentialResponse) => {
      
    const decodedCredential = jwtDecode(credentialResponse.credential);
    const userEmail = decodedCredential.email; // Assuming the email is stored in the 'email' field of the decoded token
    
     
    try {

  const response = await axios.post(`${API_BASE_URL}/api/send-email-code`, {google:'1', email:userEmail });
    

      if (response.data === 0) {
        // setCodeSent(true);
        setError('')

        setGogleEmail(userEmail)

      } else {
        setError('Email is Already Registered !')
        setProcessing(false)

      }
    } catch (error) {
      setError('Connection Error')
      setProcessing(false)


    }
};
  return (
    <section className="bg-white ">
  <div className="container">
    <div className="row justify-content-center">
    {!paymentpage && !planpage && ( <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div className='authentication-wrapper authentication-basic container-p-y'>
        <div className='authentication-inner py-4'>
        
        <div className="card  login-card" id="login-card">
        {!emailverified &&(<div className="card-body p-3 p-md-4 p-xl-5">
            <div className="text-center mb-3">
            <img src={logo} style={{width: '60%'}}></img>
            </div>
            <h4 class="mb-2 pt-2 text-center" id="color-loginform">Your business phone reimagined </h4>
            <p class="mb-4 " id="color-loginform">Create an Account and start your Free Trial</p>
            {/* <h2 className="fs-6 fw-normal text-center text-secondary mb-4">Sign in to your account</h2> */}
            <form onSubmit={handleSubmit}>
              <div className="row gy-2 overflow-hidden">
                <div className="col-12">
                {/* <button type='button' className='google-btn ing-reg-btn mt-2 mb-2' onClick={login}>
      <img src={google} className='google-icon mb-1'></img>&nbsp;&nbsp;Continue with Google
      
    </button> */}
    
    {/* <GoogleOAuthProvider clientId="422743159001-f7k41jr4p6jvht09kan5ki97ehksqghb.apps.googleusercontent.com"> */}
    <GoogleOAuthProvider clientId="25341634720-3g0t7jtj759ku4kp8h1igd990r26nb71.apps.googleusercontent.com">
                
                                <GoogleLogin
                                className="custom-google-login"
                                onSuccess={googlelogin}
               
                onError={() => {
                  console.log('Login Failed');
                }}
               />  <span className="custom-google-login-text">Sign in with Google</span></GoogleOAuthProvider>
                <p className='text-center' id="color-loginform">Or Continue with email</p>

                  <div className="form-floating mb-3">

                    <input value={email} onChange={(e) => setEmail(e.target.value)} type="email" className=" " name="email" id="email-pass-input" placeholder="name@example.com" required/>

                  </div>
                </div>
        
              {error && (<p className=" text-danger mt-2" >{error}</p>)}
                
                <div className="col-12">
                  <div className="d-grid my-1">
                    <button className="btn btn-primary ing-reg-btn" type="submit" disabled={processing}>{processing ? 'Submitting...' : 'Continue'} </button>
                  </div>
                </div>
                <div className="col-12">
    <p className="m-0 text-secondary text-center">
        Already have an account?<br></br> <Link to="/login" className="link-primary text-decoration-none">Sign in</Link>
    </p>
      <p class="mb-4 fs-6 text-center" id="color-loginform">By continuing, you acknowledge and accept our <Link to="https://prophone.io/terms_and_conditions.html">Terms and Conditions</Link> and <Link to="https://prophone.io/privacy_policy.html">Privacy Policy</Link></p>

</div>
          
              </div>
            </form>
          </div>)}
          {emailverified && !numberverify  && (
  <div className="card-body p-3 p-md-4 p-xl-5">
    <div className="text-center mb-3">
      <div className="text-center" style={{ width: '60%', fontSize: '45px', marginLeft: '50px' }}>📱</div>
    </div>
    <h4 className="mb-1 pt-2" id="color-loginform">Verify you're real</h4>
    <p className="mb-4" id="color-loginform">Enter your mobile number to receive an SMS verification code. Carrier charges may apply</p>
    <form onSubmit={handlenumSubmit}>
      <div className="row gy-2 overflow-hidden">
        <div className="col-12">
          <label htmlFor="password" className="form-label" id="color-loginform">Mobile Phone</label>
          <div className="form-floating mb-3">
           
            <PhoneInput
            required
            onlyCountries={['us']}
            areaCodes={{us: ['+1']}}
        enableAreaCodes={false}
        value={phone}
        onChange={setPhone}
        autoFormat={true}
        countryCodeEditable={false}
        placeholder="Phone Number"
        dropdownClass="phone-input-dropdown"
        preserveOrder={['onlyCountries', 'preferredCountries']}
        inputProps={{
          id: "number-input",
          required: true,
          autoFocus: true,
        }}
      >
        
      </PhoneInput>
          </div>
        </div>
        <div className="col-12">
          <div className="d-grid my-3">
            <button className="btn btn-primary ing-reg-btn" type="submit"disabled={processing}>{processing ? 'Sending Code...' : 'Get Code'}</button>
          </div>
        </div>
        <div className="col-12">
          <p className="m-0 text-secondary text-center">
            {/* <Link to="/signup" className="link-primary text-decoration-none">Go Back</Link> */}
          </p>
        </div>
      </div>
    </form>
  </div>
)}
        {numberverify &&  !paymentpage && !planpage &&(
  <div className="card-body p-3 p-md-4 p-xl-5">
    <div className="text-center mb-3">
      <div className="text-center ml-2" style={{ width: '60%', fontSize: '45px', marginLeft: '55px' }}>🔢</div>
    </div>
    <h4 className="mb-1 pt-2" id="color-loginform">Enter the code</h4>
    <p className="mb-1" id="color-loginform">we just sent verification code to  </p>
    <p className="mb-2 text-center" id="color-loginform">+{phone} </p>
    <form onSubmit={verifynumbercode}>
      <div className="row gy-2 overflow-hidden">
        <div className="col-12">
          {/* <label htmlFor="password" className="form-label" id="color-loginform">Mobile Phone</label> */}
          <div className="form-floating mb-3">
          <input value={numcode} onChange={(e) => setnumCode(e.target.value)} type='text' className=" " name="password" id="email-pass-input" placeholder="6-digit code" required/>

           
          </div>
          {error && (<p className="alert alert-danger mt-2" style={{color:'#fff'}}>{error}</p>)}

        </div>
        <div className="col-12">
          <div className="d-grid my-3">
            <button className="btn btn-primary ing-reg-btn" type="submit">Verify</button>
          </div>
        </div>
        <div className="col-12">
          <p className="m-0 text-secondary text-center">
          {coderesent &&(<p id="color-loginform">Code resent succesfully !</p>)}
          <button type="button" onClick={handleResendClick} className="btn btn-default text-decoration-none" disabled={disableResend}>
  {disableResend ? `Resend code in ${countdown}s` : 'Resend code'}
</button>

          </p>
        </div>
      </div>
    </form>
  </div>
)}
  
        </div>
        </div>
        </div>
      </div>)}
      {planpage && !paymentpage &&  (
      <section className="bg-white ">
      <div className="container">
        <div className="row justify-content-center">
          <center><div className="col-12 mt-3 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
          <img src={eye} style={{width: '13%'}}></img>
          <h4 class="mb-1  " id="color-loginform">Choose your plan</h4>
                <p class="mb-3  " id="color-loginform">Choose a plan to start your <strong>7-day free trial</strong><br></br> you can change or cancel at any time.</p>
    </div></center>
    <div class="container center">
      <div class="row justify-content-center">
        
      <div class="col-md-4">
        <div class="card mb-4" id='plan-card'>
                    
            <div class="card-body text-black" style={{fontSize: '15px', color: 'black !important'}}>
                
                <h3 class="float-left ml-2">Business Starter</h3>
                
                <span id="color-loginform" style={{fontSize:'14px' ,textAlignlast: 'left',color:'black !Important'}} class="float-left ml-2">For businesses looking to prospect<br></br></span>
                
                <div id="color-loginform" style={{display:'flex'}}><h2    class="float-left ml-2 fs-3 mt-2 mb-2">
    $&nbsp;<span id="color-loginform" style={{fontsize: '40px', fontweight: '600'}}>30&nbsp;&nbsp;</span></h2>
    
    <small className='mt-2' style={{fontsize: '14px'}}>&nbsp;Per Month</small>
    </div>
    <button onClick={() => handleplanchange('business-starter')} className='btn btn-primary sel-bsn'>Select business</button>
                {/* <h2 class="float-left ml-2 fs-3 mt-3 mb-3">$&nbsp;<span style={{fontSize:'40px',fontWeight: '600'}}>30 <small style={{fontsize: '14px'}}>Per Month</small></span></h2> */}
                
                {/* <a href="https://billing.prophone.io/b/bIY7tH0JIf8ugMwbII" class="btn btn-lg  text-white mb-3 btn-grad">Start Your 7 Day Free Trial   <i style={{fontsize: '15px'}} class="fa fa-arrow-right ml-3 fs-2"></i></a> */}
                
                
                <ul class="list-unstyled  p-2 pb-0 " style={{fontsize:'12px',color:'black'}}>
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Unlimited Text (pay as you go )</span></div></li>
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Unlimited Voice (pay as you go )</span></div></li>
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Full CRM </span></div></li>
                    <li><div class="d-flex align-items-center "><img style={{width: '25px'}} src={tickicon} className=""></img><span>Webhook  Integerations</span></div></li>
                    <li><div class="d-flex align-items-center "><img style={{width: '25px'}} src={tickicon} className=""></img><span>Zapier  Integeration (Coming Soon)</span></div></li>

                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>ProDialer (Coming Soon)</span></div></li> */}
                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Follow Up Automation (Coming Soon)</span></div></li> */}
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>DNC Manager </span></div></li>
                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Full Control Sms Automation (Coming Soon)</span></div></li> */}
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Auto DNC</span></div></li>
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Quick Replies</span></div></li>
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Status Tracking</span></div></li>
                </ul>
                
                
                
            </div>
        </div>
          </div>
          <div class="col-md-4">
        <div class="card mb-4" id='plan-card'>
                    
            <div class="card-body text-black" style={{fontSize: '15px', color: 'black !important'}}>
                
                <h3 class="float-left ml-2">Business Pro</h3>
                
                <span id="color-loginform" style={{fontSize:'14px' ,textAlignlast: 'left',color:'black !Important'}} class="float-left ml-2">For businesses looking to scale<br></br> without limits</span>
                
                <div id="color-loginform" style={{display:'flex'}}><h2    class="float-left ml-2 fs-3 mt-2 mb-2">
    $&nbsp;<span id="color-loginform" style={{fontsize: '40px', fontweight: '600'}}>99&nbsp;&nbsp;</span></h2>
    
    <small className='mt-2' style={{fontsize: '14px'}}>&nbsp;Per Month</small>
    </div>
    <button onClick={() => handleplanchange('business-pro')} className='btn btn-primary sel-bsn'>Select business</button>
                {/* <h2 class="float-left ml-2 fs-3 mt-3 mb-3">$&nbsp;<span style={{fontSize:'40px',fontWeight: '600'}}>30 <small style={{fontsize: '14px'}}>Per Month</small></span></h2> */}
                
                {/* <a href="https://billing.prophone.io/b/bIY7tH0JIf8ugMwbII" class="btn btn-lg  text-white mb-3 btn-grad">Start Your 7 Day Free Trial   <i style={{fontsize: '15px'}} class="fa fa-arrow-right ml-3 fs-2"></i></a> */}
                
                
                <ul class="list-unstyled  p-2 pb-0 " style={{fontsize:'12px',color:'black'}}>
  
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Everything From Business Starter </span></div></li>
                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>ProDialer (Coming Soon)</span></div></li> */}
                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Follow Up Automation (Coming Soon)</span></div></li> */}
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Automated SMS Campaigns </span></div></li>
                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Full Control Sms Automation (Coming Soon)</span></div></li> */}
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>ProDialer  (Coming Soon)</span></div></li>
                    <li><div class="d-flex align-items-center "><img style={{width: '25px'}} src={tickicon} className=""></img><span>Follow Up System (Coming Soon)</span></div></li>
                    <li><div class="d-flex align-items-center "><img style={{width: '25px'}} src={tickicon} className=""></img><span>Pro Mode (Coming Soon)</span></div></li>
                </ul>
                
                
                
            </div>
        </div>
          </div>
        </div>
      </div>
    </div>
    
         
        </div>
      
    </section>
    )}
  {paymentpage && !planpage && ( 
  <Paymentpage  email={emailverified} phone={phone} google={true} plan={selectedplan}/>

)}
    </div>
  </div>
</section>
  );
}

export default RegisterForm;
// import React, { useState } from 'react';
// import axios from 'axios';
// import { Link, useNavigate } from 'react-router-dom';

// import logo from '../icons/logo.png';
// import google from '../icons/google.png';
// import { useGoogleLogin } from '@react-oauth/google';

// function RegisterForm() {
//   const [email, setEmail] = useState('');
//   const [verificationCode, setVerificationCode] = useState('');
//   const [codeSent, setCodeSent] = useState(false);
//   const navigate = useNavigate();

//   const handleSubmit = async (e) => {
//     e.preventDefault();
//     if (!codeSent) {
//       try {
//         // Send verification code to the email
//         const response = await axios.post('${API_BASE_URL}/api/send-email-code', { email });
//         if (response.status === 200) {
//           setCodeSent(true);
//         } else {
//           // Handle error appropriately
//           console.error('Failed to send verification code');
//         }
//       } catch (error) {
//         // Handle error appropriately
//         console.error('Error sending verification code:', error);
//       }
//     } else {
//       // Verify the verification code entered by the user here
//       // You can make an API call to verify the code
//       // For demonstration, let's assume it's successful and then navigate to the next page
//       navigate(`/signup/code?email=${encodeURIComponent(email)}`);
//     }
//   };

//   const handleChange = (e) => {
//     const { name, value } = e.target;
//     if (name === 'verification-code') {
//       setVerificationCode(value);
//     } else {
//       setEmail(value);
//     }
//   };

//   const login = useGoogleLogin({
//     onSuccess: async (response) => {
//       console.log(response);
//     },
//   });

//   return (
//     <section className="bg-white">
//       <div className="container">
//         <div className="row justify-content-center">
//           <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
//             <div className="authentication-wrapper authentication-basic container-p-y">
//               <div className="authentication-inner py-4">
//                 <div className="card  login-card" id="login-card">
//                   <div className="card-body p-3 p-md-4 p-xl-5">
//                     <div className="text-center mb-3">
//                       <img src={logo} style={{ width: '60%' }} alt="Logo"></img>
//                     </div>
//                     <h4 className="mb-2 pt-2 text-center" id="color-loginform">
//                       Your business phone reimagined{' '}
//                     </h4>
//                     <form onSubmit={handleSubmit}>
//                       <div className="row gy-2 overflow-hidden">
//                       {!codeSent && (<div className="col-12">
//                           <button
//                             type="button"
//                             className="google-btn ing-reg-btn mt-2 mb-2"
//                             onClick={login}
//                           >
//                             <img src={google} className="google-icon mb-1" alt="Google Logo"></img>
//                             &nbsp;&nbsp;Continue with Google
//                           </button>
//                           <p className="text-center" id="color-loginform">
//                             Or Continue with email
//                           </p>
//                           <div className="form-floating mb-3">
//                             <input
//                               value={email}
//                               onChange={handleChange}
//                               type="email"
//                               className=""
//                               name="email"
//                               id="email-pass-input"
//                               placeholder="name@example.com"
//                               required
//                             />
//                           </div>
//                         </div>
//                       )}
//                         {codeSent && (
//                           <div className="col-12">
//                             <label htmlFor="verification-code" className="form-label" id="color-loginform">
//                               Verification Code
//                             </label>
//                             <div className="form-floating mb-3">
//                               <input
//                                 type="text"
//                                 value={verificationCode}
//                                 onChange={handleChange}
//                                 name="verification-code"
//                                 id="email-pass-input"
//                                 placeholder="Verification Code"
//                                 required
//                               />
//                             </div>
//                           </div>
//                         )}
//                         <div className="col-12">
//                           <div className="d-grid my-1">
//                             <button className="btn btn-primary ing-reg-btn" type="submit">
//                               {codeSent ? 'Verify' : 'Continue'}
//                             </button>
//                           </div>
//                         </div>
//                         <div className="col-12">
//                           <p className="m-0 text-secondary text-center">
//                             Already have an account?<br></br>{' '}
//                             <Link to="/login" className="link-primary text-decoration-none">
//                               Sign in
//                             </Link>
//                           </p>
//                         </div>
//                       </div>
//                     </form>
//                   </div>
//                 </div>
//               </div>
//             </div>
//           </div>
//         </div>
//       </div>
//     </section>
//   );
// }

// export default RegisterForm;
