import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link,useNavigate,useLocation  } from 'react-router-dom';
import '../css/loginform.css';
import eye  from '../icons/eye.png';
import tickicon  from '../icons/green.png';
// import PhoneNumberInput  from '../components/phonenumber';

// import { AuthProvider } from '../components/AuthContext';

// import { useAuth } from '../components/AuthContext';
function Plans() {
    const [countries, setCountries] = useState([]);
  const [phone, setPhone] = useState('');
  const [error, setError] = useState('');
  const [emailverified, setEmail] = useState('');
  const [numberverify, setNumber] = useState('');


  const navigate = useNavigate();
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const [disableResend, setDisableResend] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));

  const [successMessage, setSuccessMessage] = useState('');
  


  return (
    <section className="bg-white ">
  <div className="container">
    <div className="row justify-content-center">
      <center><div className="col-12 mt-3 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
      <img src={eye} style={{width: '13%'}}></img>
      <h4 class="mb-1  " id="color-loginform">Choose your plan</h4>
            <p class="mb-3  " id="color-loginform">Choose a plan to start your <strong>7-day free trail</strong><br></br> you can change or cancel at any time.</p>
</div></center>
<div class="container center">
  <div class="row justify-content-center">
    
    <div class="col-md-4">
    <div class="card mb-4" id='plan-card'>
                
        <div class="card-body text-black" style={{fontSize: '15px', color: 'black !important'}}>
            
            <h3 class="float-left ml-2">Buisness Pro</h3>
            
            <span id="color-loginform" style={{fontSize:'14px' ,textAlignlast: 'left',color:'black !Important'}} class="float-left ml-2">For Businesses looking for more cail handling<br></br> and automation features</span>
            
            <div id="color-loginform" style={{display:'flex'}}><h2    class="float-left ml-2 fs-3 mt-2 mb-2">
$&nbsp;<span id="color-loginform" style={{fontsize: '40px', fontweight: '600'}}>30&nbsp;&nbsp;</span></h2>

<small className='mt-2' style={{fontsize: '14px'}}>&nbsp;Per Month</small>
</div>
<Link to="/payment/testing@gmail.com" className='btn btn-primary sel-bsn'>Select buisness</Link>
            {/* <h2 class="float-left ml-2 fs-3 mt-3 mb-3">$&nbsp;<span style={{fontSize:'40px',fontWeight: '600'}}>30 <small style={{fontsize: '14px'}}>Per Month</small></span></h2> */}
            
            {/* <a href="https://billing.prophone.io/b/bIY7tH0JIf8ugMwbII" class="btn btn-lg  text-white mb-3 btn-grad">Start Your 7 Day Free Trial   <i style={{fontsize: '15px'}} class="fa fa-arrow-right ml-3 fs-2"></i></a> */}
            
            
            <ul class="list-unstyled  p-2 pb-0 " style={{fontsize:'12px',color:'black'}}>
                <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>SMS (pay as you go )</span></div></li>
                <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Voice (pay as you go )</span></div></li>
                <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Full CRM </span></div></li>
                <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>ProDialer </span></div></li>
                <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Follow Up Automation </span></div></li>
                <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Full Control Sms Automation </span></div></li>
                <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Phone App </span></div></li>
                <li><div class="d-flex align-items-center "><img style={{width: '25px'}} src={tickicon} className=""></img><span>Zapier </span></div></li>
            </ul>
            
            
            
        </div>
    </div>
      </div>
    </div>
  </div>
</div>

     
    </div>
  
</section>
  );
}

export default Plans;
