import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import Sidebar from '../components/navbar';
import {  FaPlay,FaPause,FaReply, FaTrash,FaDownload } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import searchicon from '../icons/std.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';

import loading from '../icons/loading.gif';

import axios from 'axios';
function Audience() {
  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [id, setUserid] = useState('');
  const [audience, setAudiences] = useState([]);
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false);
  const [loadingchk, setLoading] = useState(false); // State to control the modal's visibility

  
  
  
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true);
        setUserid(id);
  
        try {
          const response = await axios.post(`${API_BASE_URL}/api/fetch-audience`, {
            id
          });
          setAudiences(response.data);
        } catch (error) {
          // Handle error if necessary
        }
      } else {
        navigate("/login");
      }
    };
  
    // Call fetchreplies only when the component mounts
    fetchreplies();
  
 
  }, []);
  const handleDownload = async (reply) => {
    

   
    const confirmed = window.confirm('Are you sure you want to Download');
    if (!confirmed) {
      return; 
    }
  
    try {
      setLoading(true)
      // const response= await axios.post(`${API_BASE_URL}/api/download-audience/`,{id:reply});
      const response = await axios.post(`${API_BASE_URL}/api/download-audience`, {
        id: reply
    });
    
    const data = response.data;
    
    // Convert data to CSV format
    let csvContent = "First Name,Last Name,Address,City,State,Zip,Phone 1,Phone 2,Phone 3,Phone 4,Phone 5\n"; // Add the header line
    
    data.forEach(item => {
        csvContent += `${item['First Name']},${item['Last Name']},${item['Address']},${item['City']},${item['State']},${item['Zip']},${item['Phone 1']},${item['Phone 2']},${item['Phone 3']},${item['Phone 4']},${item['Phone 5']}\n`;
    });
    
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    
    // Create a link element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'AUDIENCE_EXPORT.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    
      
    setLoading(false)
      // setDNCnumbers(prevnumber => prevnumber.filter(item => item.number !== number));
    } catch (error) {
      setLoading(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  // const handleDownload = async (reply) => {
    
  

   
  //   const confirmed = window.confirm('Are you sure you want to download this Audience?');
  //   if (!confirmed) {
  //     return; 
  //   }
  
  //   try {
  //     await axios.post(`${API_BASE_URL}/api/download-audience/${reply}`);
      
      
  //     // setTemplates(prevWebhooks => prevWebhooks.filter(item => item._id !== reply));
  //     // setLoading(true)
  //     alert("Audience Deleted Succesfuly")
  //     window.location.reload()
  //   } catch (error) {
  //     // setLoading(false)

      
  //   }
  // };
  const handleDelete = async (reply) => {
    
  

   
    const confirmed = window.confirm('Are you sure you want to delete this Audience?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-audience/${reply}`);
      
      
      // setTemplates(prevWebhooks => prevWebhooks.filter(item => item._id !== reply));
      // setLoading(true)
      alert("Audience Deleted Succesfuly")
      window.location.reload()
    } catch (error) {
      // setLoading(false)

      
    }
  };
  if (!authenticated) {
    return null;
  } else {
    return (
        <Sidebar>
           {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
          {/* <Modal show={showfilterModal} contentClassName="custom-modal-content" onHide={handlefilterModalClose} backdropClassName="custom-backdrop" >
   <form id="columnForm" >
        <Modal.Header closeButton>
        </Modal.Header>
        
        <Modal.Body>
       
      
        <div class="container " style={{fontSize: '15px'}}>
          <div class="row">
            <div  class="col-md-6 float-left" style={{textAlign:'left'}}>
              <span>Lists</span>
              <br></br>
              <br></br>
              <div ><span className="ball-icon">OK</span> <span >Included</span></div>
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div ><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" style={{ }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
            <div  class="col-md-6 float-right" style={{textAlign:'right'}}>
            <div style={{textAlign:'left'}}> <span >Tags</span></div>
          
              
              <br></br>
              <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Included</span></div>
              
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" >
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
          </div>
        </div>



       

             
         
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlefilterModalClose} data-dismiss="modal" >Close</button>
            <button type="submit"  className="btn btn-primary save_web_btn" >Apply</button>
        </Modal.Footer>
        </form>
      </Modal> */}
 
       
         
        <div className="row ">
          
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
          <div className="tab">
      <div className={`tab-item ${location.pathname === '/campaign' ? 'active' : ''}`}>
        <Link to="/campaign" className="link disabled">Campaign</Link>
        {location.pathname === '/campaign' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/audience' ? 'active' : ''}`}>
      <Link to="/audience"  className="link disabled">Audience</Link>
        {location.pathname === '/audience' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/sms_cam_templates' ? 'active' : ''}`}>
      <Link to="/sms_cam_templates"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_cam_templates' && <div className="strip"></div>}
      </div>
      {/* <div className={`tab-item ${location.pathname === '/create_template' ? 'active' : ''}`}>
      <Link to="/create_template"  className="link disabled">Create Template</Link>
        {location.pathname === '/create_template' && <div className="strip"></div>}
      </div> */}
    </div>
    </div>
       
    <div className=" mb-2" style={{textAlign:'right'}}>
          
          <Link to="/create_audience" type="button"  className="btn btn_primary save_btn"   >
        <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Audience
        </Link>
        </div>
    {audience.length > 0 ? ( 
          <div class="table-responsive text-nowrap mt-3">
    <table class="table" >
      <thead>
        <tr>
                  <th>Audience</th>
                  <th>Total Prospects</th>
                  <th>View</th>
                  <th>Download</th>
                  <th>Action</th>
                  
                
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
      {audience.map((item) => (
                                                <tr>
                      <td>{item.audiencename}</td>
<td>{item.total}</td>



<td>
<Link to={`/view_audience/${item._id}`} className="btn btn-primary mt-3" style={{width:'90px'}}>View</Link>&nbsp;&nbsp;
 
</td>


<td>
                     <button className="btn btn-success mt-3" style={{width:'50px'}} onClick={() => handleDownload(item.audiencename)} ><FaDownload></FaDownload></button>

                       </td>
                      <td>
                     
                      <button className="btn btn-danger mt-3" style={{width:'50px'}} onClick={() => handleDelete(item.audiencename)} ><FaTrash></FaTrash></button>

                        </td>
                      
                  </tr>
      ))}
                  
                  
                                        </tbody>
    </table>
    
  </div>
        ):(
          <div class="text-center">
            <p>No Audience Found </p>
          </div>
        )}
        

        </div>
        </div>
          
     
          
        </Sidebar>
        );
      }
    
}

export default Audience;
