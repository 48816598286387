import React, { useEffect, useState,useRef } from "react";
import { Link, useNavigate } from 'react-router-dom';
import * as XLSX from 'xlsx';
import ticked from '../icons/OIP.png';
import DataTable from 'datatables.net';
import axios from 'axios';
import Sidebar from '../components/navbar';
import { FaTrash,FaFileExport, FaCog, FaSignOutAlt, FaUber, FaUsers } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import loading from '../icons/loading.gif';
import upload from '../icons/upload.svg';
import $ from 'jquery';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';
function Smssettings() {

  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility
  const [showuploadModal, setuploadShowModal] = useState(false); // State to control the modal's visibility
  const [loadingchk, setLoading] = useState(false); // State to control the modal's visibility
  const [fileData, setFileData] = useState(null);
  const [columnMappings, setColumnMappings] = useState([]);
  const [columnNames, setColumnNames] = useState(null);
  const [processing, setProcessing] = useState(false);
    const handleModalOpen = () => setShowModal(true); // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
    const [tableInstance, setTableInstance] = useState(null); // State to hold DataTable instance
    const [phoneNumber, setPhoneNumber] = useState('');
  
    const [csvData, setCsvData] = useState([]);
    const handleuploadModalOpen = () => setuploadShowModal(true); // Function to open the modal
    const handleuploadModalClose = () => setuploadShowModal(false); // 
    const [showdncModal, setShowhandleModal] = useState(false); // State to control the modal's visibility


    const handledncModalOpen = () => setShowhandleModal(true); // Function to open the modal
    const handledncModalClose = () => setShowhandleModal(false); // Functio


  const [authenticated, setAuthenticated] = useState(null);
  const [id, setUserid] = useState('');
  const [dncnumbers, setDNCnumbers] = useState([]);
  const [dncids, setIds] = useState([]);
  const [selectumbers, setSelectednumbers] = useState([]);
  const handlePhoneInputChange = (e) => {
    const formattedNumber = formatPhoneNumberS(e.target.value);
    setPhoneNumber(formattedNumber);
  };
  const formatPhoneNumberS = (number) => {
    const normalizedNumber = number.replace(/\D/g, ''); 
    if (normalizedNumber.length <= 3) return `(${normalizedNumber}`;
    if (normalizedNumber.length <= 6) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3)}`;
    if (normalizedNumber.length <= 10) return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6)}`;
    return `(${normalizedNumber.slice(0, 3)}) ${normalizedNumber.slice(3, 6)} ${normalizedNumber.slice(6, 10)}`;
  };
  
  useEffect(() => {
    const feftchdnc = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)
        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-dnc`, {
            id
          });
          setDNCnumbers(response.data.formatted_phone_numbers);
          setIds(response.data.ids);
          
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    feftchdnc(); 
  }, []);
  const selectAll = (e) => {
    const isChecked = e.target.checked;
  
    // Select all checkboxes in the table body
    const checkboxes = document.querySelectorAll('.dc-tbody input[type="checkbox"]');
    
    // Update all checkboxes to match the header checkbox state
    checkboxes.forEach((checkbox) => {
      checkbox.checked = isChecked;
    });
  
    // Update the selected numbers state based on the checkbox state
    if (isChecked) {
      // alert("checked")
      const allIds = Array.from(checkboxes).map((checkbox) => checkbox.value);
      setSelectednumbers(dncids);
    } else {
      setSelectednumbers([]);
    }
  };

  useEffect(() => {
    if (dncnumbers.length > 0) {
      const newTableInstance = new DataTable('.crm_table', {
        stateSave: true,
        searching: true,
        bDestroy: true,
        initComplete: function () {
          const that = this;
          that.api().columns().every(function () {
            const column = this;
            // const footerCell = column.footer();
            // const select = footerCell.querySelector('select');

            
          });
        },
        columnDefs: [
        //   {
        //     targets: 0,
        //     createdCell: function(td, cellData, rowData, row, col) {
        //       // Clear the cell
        //       td.innerHTML = '';

        //       // Apply class to the cell
        //       td.className = 'checkbox-column';

        //       // Render React component into the cell
        //       ReactDOM.render(
        //         <input 
        //             type="checkbox" 
        //             id={`checkbox-${row}` } // Unique ID for each row
        //             className="contact-checkbox" 
        //             defaultChecked={selectumbers.includes(rowData)} 
        //             // onChange={() => handleCheckboxChange(rowData)}
        //         />,
        //         td
        //     );
        //   },
        // },
          { orderable: false, targets: '_all' } // This line disables ordering for all columns
          
        ]

      });
      
      // $.fn.dataTable.ext.search.push((settings, data, dataIndex) => {
      //   const searchTerm = settings.oPreviousSearch.sSearch || '';
      //   const formattedSearchTerm = formatSearchTerm(searchTerm); // Format the search term
      //   const rowData = data[0].replace(/\D/g, ''); // Remove non-numeric characters from row data
      //   // console.log("serach",formattedSearchTerm.replace(/\D/g, ''))
      //   return rowData.includes(formattedSearchTerm.replace(/\D/g, ''));
      // });

      
      setTableInstance(newTableInstance);
    }
  }, [dncnumbers]);

  const formatSearchTerm = (term) => {
    const cleanTerm = term.replace(/\D/g, ''); // Remove non-numeric characters
    if (cleanTerm.length <= 3) {
      return cleanTerm;
    } else if (cleanTerm.length <= 6) {
      return `${cleanTerm.slice(0, 3)} ${cleanTerm.slice(3)}`;
    } else {
      return `${cleanTerm.slice(0, 3)} ${cleanTerm.slice(3, 6)} ${cleanTerm.slice(6)}`;
    }
  };

  useEffect(() => {
    
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);



  const getDisabledOptions = () => {
    const selectedOptions = columnMappings.map(mapping => mapping.importOption);
    return selectedOptions.filter(option => option !== 'do_not_import');
  };
  const handleFileChange = (event) => {
    setLoading(true)
    const file = event.target.files[0];
  
   
    if (!file || !file.name.endsWith('.xlsx') && !file.name.endsWith('.csv')) {
    setLoading(false)

      alert('Please upload an Excel file.');

      return;
    }
  
    const reader = new FileReader();
  
    reader.onload = (e) => {
      try {
        const data = new Uint8Array(e.target.result);
        const workbook = XLSX.read(data, { type: 'array' });
        const sheet = workbook.Sheets[workbook.SheetNames[0]]; // Assuming only one sheet
  
        // Get column and row counts
        const range = XLSX.utils.decode_range(sheet['!ref']);
        const numCols = range.e.c + 1;
        const numRows = range.e.r + 1;
        const jsonData = XLSX.utils.sheet_to_json(sheet);
        setCsvData(jsonData);
  
        // Get column names
        const columnNames = [];
        for (let i = 0; i < numCols; i++) {
          const cell = sheet[XLSX.utils.encode_cell({ r: 0, c: i })];
          const columnName = cell ? cell.v : `Column ${i + 1}`;
          if (!columnName.match(/^Column\s+\d+$/)) {
            // Exclude columns with numeric names
            columnNames.push(columnName);
          }
        }
  
        const fileName = file.name;
        // Store file details in state
        setFileData({ fileName, numRows, numCols });
        setColumnNames(columnNames);
        // Set column mappings with initial values
        const initialColumnMappings = columnNames.map(columnName => ({
          columnName,
          importOption: '',
        }));
        setColumnMappings(initialColumnMappings);
    setLoading(false)

      } catch (error) {
    setLoading(false)

        alert('Error reading column names. Please upload another file.');
      }
    };
  
    reader.readAsArrayBuffer(file);
  };
  const handleChange = (event, index) => {
    const selectedOption = event.target.value;
    const newColumnMappings = [...columnMappings];
    
    // Check if the selected mapping is already used
    if (selectedOption !== 'do_not_import' && newColumnMappings.some((mapping, i) => mapping.importOption === selectedOption && i !== index)) {
      alert(`${selectedOption} is already selected. Please choose another.`);
      return;
    }

    newColumnMappings[index].importOption = selectedOption;
    setColumnMappings(newColumnMappings);

    const targetRow = event.target.closest('.row');
    const targetInput = targetRow.querySelector('.mappedInput');
    const targetCol1 = targetRow.querySelector('.col-1 img');

    if (selectedOption === "do_not_import") {
      targetInput.style.backgroundColor = '';
      targetCol1.style.display = 'none';
      targetInput.classList.remove('select_col');
    } else {
      targetInput.classList.add('select_col');
      targetCol1.style.display = 'block';
    }
  };


  const adddnc = async (e) => {
    e.preventDefault()
    // alert(phoneNumber.length)
    if (phoneNumber.length < 14 ){
      alert("Enter Correct Phone Number")
      return
    }
    
    try {
      setLoading(true)
      const response= await axios.post(`${API_BASE_URL}/api/add-dnc-number`, {
        id,
        phoneNumber
      });
     if(response.data === 2){
    setLoading(false)

      alert("Number Already Exist")
      return
     }
     alert("Number Added Succesfuly")
     window.location.reload()
    setLoading(false)
      // setDNCnumbers(prevnumber => prevnumber.filter(item => item.number !== number));
    } catch (error) {
      setLoading(false)
      
      // console.error('Error deleting webhook:', error);
    }
  };
  const exportdnc = async () => {
    

   
    const confirmed = window.confirm('Are you sure you want to Export');
    if (!confirmed) {
      return; 
    }
  
    try {
      setLoading(true)
      const response= await axios.post(`${API_BASE_URL}/api/export-dnc`, {
        id
      });
      const data = response.data; // Assume response.data is an array of objects like [{ phone: "+16152601628" }, ...]

      // Convert data to CSV format
      let csvContent = "DNC NUMBERS\n"; // Add the header line
      data.forEach(item => {
        csvContent += `${item.phone}\n`;
      });
      
      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      
      // Create a link element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'DNC_EXPORT.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
    setLoading(false)
      // setDNCnumbers(prevnumber => prevnumber.filter(item => item.number !== number));
    } catch (error) {
      setLoading(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const handletableeckboxChange = (event, itemId) => {
    setSelectednumbers(prevRowsIds => {
      // Create a new array based on the previous state
      let newRowsIds = [...prevRowsIds];

      if (event.target.checked) {
          if (!newRowsIds.includes(itemId)) {
              newRowsIds.push(itemId);
          }
      } else {
          newRowsIds = newRowsIds.filter(id => id !== itemId);
      }

     
      return newRowsIds;
  });
};
  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber == null) {
        return null; // Return null for null or undefined values
    }

    const digits = String(phoneNumber).replace(/\D/g, ''); // Ensure phoneNumber is a string and remove non-digit characters
    const countryCode = '+1';

    if (String(phoneNumber).startsWith('+1')) {
      // alert(phoneNumber)
        // If the number is already in international format with country code +1, keep it as is
        return phoneNumber;
    } else if (digits.length === 10) {
        // Add +1 to 10 digit numbers
        return countryCode + digits;
    } else if (digits.length > 10) {
        // Add +1 to numbers with more than 10 digits
        return countryCode + digits.slice(-10);
        // return countryCode + digits.slice(0);
    } else {
        return null; // Invalid phone number format
    }
};
  const uploaddnc = (event) => {
    setLoading(true)
event.preventDefault()
  const requiredFields = ['DNC'];
  const mappedFields = columnMappings.map((mapping) => mapping.importOption);

  if (requiredFields.every((field) => mappedFields.includes(field))) {
    const phoneColumnMapping = columnMappings.find(mapping => mapping.importOption === 'DNC');

    const phoneColumnName = phoneColumnMapping.columnName;
    const uniquePhoneNumbers = new Set();

    const formattedCsvData = csvData
        .map(row => {
            const phoneNumber = row[phoneColumnName];
            
            
            // Check for duplicate phone numbers
            if (uniquePhoneNumbers.has(phoneNumber)) {
                return null;
            }
            
            uniquePhoneNumbers.add(phoneNumber);
            
            // Format the phone number
            row[phoneColumnName] = formatPhoneNumber(phoneNumber);
            return row;
        })
        .filter(Boolean); // Filter out rows that are null
    
    const postData = {
      userid:id,
      csvData:formattedCsvData,
      columnMappings,
 
    };

    axios.post(`${API_BASE_URL}/api/import-dnc`, postData)
      .then(response => {
        setLoading(false)

        alert('File uploaded successfully')
        window.location.reload()

      })
      .catch(error => {
            setLoading(false)

      });
  } else {
    alert("Please Mapped DNC Numbers")
  }
}
  const handleCsvDelete = () => {
    // Reset fileData state and column mappings
    setFileData(null);
    setColumnMappings([]);
    
  };
  const handlecheckDelete = async () => {
    
    
   
    const confirmed = window.confirm('Are you sure you want to delete this Number?');
    if (!confirmed) {
      return; 
    }
  
    try {
      setLoading(true)
      // await axios.delete(`${API_BASE_URL}/api/delete-dnc/${id}/${selectumbers}`);
      const response= await axios.post(`${API_BASE_URL}/api/delete-dnc`, {
        id,
        selectumbers
      });
      setLoading(false)
      alert("Number Deleted Successfuly")
      tableInstance.draw();
      tableInstance.columns().every(function () {
        this.search('').draw();
      });
      window.location.reload()
      // setDNCnumbers(prevnumber => prevnumber.filter(item => item.number !== number));
    } catch (error) {

      // console.error('Error deleting webhook:', error);
    }
  };
  const searchInputRef = useRef(null);
  // const formatSearchTerm = (term) => {
  //   const cleanTerm = term.replace(/\D/g, ''); // Remove non-numeric characters
  //   if (cleanTerm.length === 10) {
  //     return `${cleanTerm.slice(0, 3)} ${cleanTerm.slice(3, 6)} ${cleanTerm.slice(6)}`;
  //   }
  //   return term;
  // };

  // Handle custom input search
  const handleSearch = (e) => {
    const searchTerm = formatSearchTerm(e.target.value); // Format the search term
    // console.log('searchTerm',searchTerm)
    if (tableInstance) {
      tableInstance.search(searchTerm).draw(); // Use the formatted search term
    }
  };
  const handleDelete = async (id,number) => {
    
    
   
    const confirmed = window.confirm('Are you sure you want to delete this Number?');
    if (!confirmed) {
      return; 
    }
  
    try {
      setLoading(true)
      await axios.delete(`${API_BASE_URL}/api/delete-dnc/${id}/${number}`);
      setLoading(false)
      alert("Number Deleted Successfuly")
      window.location.reload()
      // setDNCnumbers(prevnumber => prevnumber.filter(item => item.number !== number));
    } catch (error) {

      // console.error('Error deleting webhook:', error);
    }
  };
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
             {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
  <Modal show={showuploadModal} contentClassName="custom-modal-content" onHide={handleuploadModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit=
         {(event) => uploaddnc(event)}>
          {/* <form> */}
        <Modal.Body>
        <div className="text-center">
            <h4>UPLOAD DNC</h4>
        </div>
        <div className="modal-body ">
        
              
              
             
            {!fileData &&<div className=" form-group mt-4">
              <div className="upload-container">
                <input type="file" id="upload" accept=".xlsx, .xls, .csv"  style={{ display: 'none' }} onChange={handleFileChange} />
                <label htmlFor="upload" className="upload-button">
                <img src={upload} alt="Upload Icon" class="upload-icon"></img>
                  UPLOAD EXCEL
                </label>
              </div>
            </div>
            } 
                {fileData && (
                        <div className="form-group mt-4">
                          <p>
                            {fileData.fileName}:
                            <span class="csv_detail"> {fileData.numRows} lines with {columnNames.length} columns</span>
                            <img src={deletes} alt="Delete Icon" className="delete-icon" onClick={handleCsvDelete} />
                          </p>
                          </div>
                
                )}
            {columnMappings.map((mapping, index) => (
                              <div key={index} class="row mt-3">
                                <div class="col-6">
                                  <div class="source_fields">
                                    <input type="text" disabled class="form-control mappedInput" id={`columnName${index + 1}`} title="Client Name" placeholder={mapping.columnName} />
                                  </div>
                                </div>
                                <div class="col-1"><img src={ticked} className="mt-2" style={{height: '18px'}}></img></div>
                                <div class="col-5">
                                  <div class="form-group">
                                  <select className="form-control importOption" 
                                          onChange={(event) => handleChange(event, index)}  // Pass index as an argument to handleChange
                                          id={`importOption${index + 1}`}
                                        >
                                          <option value="do_not_import">Do not import</option>
                        <option value="DNC" disabled={getDisabledOptions().includes('DNC')}>DNC</option>
                        
                                         
                                        </select>

                                  </div>
                                </div>
                                
                              </div>
                              
                            ))}
          </div>
        </Modal.Body>
        {fileData && (
          <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleuploadModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn"  disabled={processing}>{processing ? 'Submitting....' : 'Submit'}</button>
        </Modal.Footer>
        )}
        </form>
      </Modal>
   <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE REPLY</h4>
        </div>
        <div className="modal-body ">
            <form>
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">Name:</label>
                <input type="text" className="form-control" id="recipient-name"  />
              </div>
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Reply:</label>
                <textarea type="text" name="type" className="form-control"  />

              </div>
              {/* <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Type:</label>
                <input type="text" name="type" className="form-control" value="Local" id="recipient-name"  readOnly />

              </div>
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Area Code:</label>
                <input type="text" className="form-control" id="recipient-name"  />

              </div> */}
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="button" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
      </Modal>
       
      <Modal show={showdncModal} contentClassName="custom-modal-content" onHide={handledncModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(e) => adddnc(e)}>
        <Modal.Body>
        <div className="text-center">
            <h4>ADD MANUALLY</h4>
        </div>
        <div className="modal-body ">
           
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">Phone Number:</label>
                <input
            type="text"
            required
            className="new-num-input"
            placeholder="(xxx) xxx xxxx"
            value={phoneNumber}
            onChange={handlePhoneInputChange}
          />
              </div>
             
             
             
          
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handledncModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
        </form>
      </Modal> 
        <div className="row ">
          <div className="col-3" style={{marginRight: '-60px'}}>
          <Menu/>
    
          </div>
          <div className="col-9" style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
        <div className="tab">
      <div className={`tab-item ${location.pathname === '/sms_settings' ? 'active' : ''}`}>
        <Link to="/sms_settings" className="link disabled">DNC Manager</Link>
        {location.pathname === '/sms_settings' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/auto_dnc' ? 'active' : ''}`}>
      <Link to="/auto_dnc"  className="link disabled">Auto DNC</Link>
        {location.pathname === '/auto_dnc' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/quick_reply' ? 'active' : ''}`}>
      <Link to="/quick_reply"  className="link disabled">Quick Reply</Link>
        {location.pathname === '/quick_reply' && <div className="strip"></div>}
      </div>
      
      {/* <div className={`tab-item ${location.pathname === '/sms_template' ? 'active' : ''}`}>
      <Link to="/sms_template"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_template' && <div className="strip"></div>}
      </div> */}
      
    </div>
    </div>
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;DNC Settings</h5>
            {dncnumbers.length > 0 &&
            <div className="mt-2 mb-2" style={{textAlign:'left'}}>

              <span>&nbsp;Search: &nbsp;</span><input
        type="search"
        ref={searchInputRef}
        onChange={handleSearch}
        placeholder="Filter Numbers.."
        style={{padding:'5px',outline:'none'}}
      /><p className="mt-2">&nbsp;{selectumbers.length} Selected </p>
      </div>
  }
            <div className=" mb-2" style={{textAlign:'right'}}>
            <button type="button" onClick={handledncModalOpen}   className="btn btn-success " data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;ADD DNC
          </button>&nbsp;&nbsp;
             
            <button type="button" onClick={handleuploadModalOpen} className="btn btn_primary save_btn" data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Import DNC
          </button>&nbsp;&nbsp;
          <button type="button" className="btn btn-success " onClick={exportdnc}  >
          <FaFileExport  alt="" className="mr-3" />&nbsp;&nbsp;Export DNC
          </button>&nbsp;&nbsp;
          {selectumbers.length > 0 &&<button onClick={handlecheckDelete} type="button" className="btn btn-danger "  >

         Delete
          </button>
  }

      </div>
            {/* <div className=" mb-2" style={{textAlign:'right'}}>
          
          <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Reply
          </button>
          </div> */}
          
          {dncnumbers.length > 0  ? (
             
  <div className="table-responsive text-nowrap mt-3 ">
  <table className="table crm_table dnc-table">
        <thead>
            <tr>
              
                <th className="checkbox-column"><input type="checkbox" id="headerCheckbox" onChange={(e) => selectAll(e)}  /></th>
                <th className="number-column">Number</th>
                <th className="action-column">Action</th>
            </tr>
        </thead>
        <tbody class="table-border-bottom-0 dc-tbody">
            {dncnumbers.map((item) => (
                <tr key={item}>
            <td class="checkbox-column"><input type="checkbox"  onClick={(e) => e.stopPropagation()}  onChange={(e) => handletableeckboxChange(e, item)} checked={selectumbers.includes(item)} ></input></td>

                    {/* <td class="checkbox-column"><input type="checkbox" /></td> */}
                    <td class="number-column">{item}</td>
                    <td class="action-column">
                        <img src={deletes} className="mt-3" alt="Delete" onClick={() => handleDelete(id, item)} />
                    </td>
                </tr>
            ))}
        </tbody>
    </table>
  
</div>
          ):(
            <p className="mb-2 ml-2 text-center mt-3" style={{color:'white',fontSize:'17px'}}>No DNC Number Found</p>
          
          )}
        
        </div>
        
        </div>
          
      </div>
     
          {/* Additional content for Page 1 */}
        </Sidebar>
        );
      }
    
}

export default Smssettings;
