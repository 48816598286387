import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import API_BASE_URL from '../components/config';

import loading from '../icons/loading.gif';

import axios from 'axios';
import Sidebar from '../components/navbar';
import { FaSun, FaFireAlt, FaHistory, FaThumbsDown, FaHome, FaFire, FaThermometerHalf, FaTimesCircle, FaBan, FaReply } from 'react-icons/fa';
import arrowright from '../icons/arrow_right.svg';
import ticked from '../icons/OIP.png';

import deletes from '../icons/delete.svg';
import searchicon from '../icons/std.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import upload from '../icons/upload.svg';
import * as XLSX from 'xlsx';
function CreateAudience() {
  const [loadingchk, setLoading] = useState(false);
  const [id, setUserid] = useState('');
  const [audiencename, setAudiencename] = useState('');

  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
  const location = useLocation();
  const [fileData, setFileData] = useState(null);
  const [laststeps, setSteps] = useState(null);
  const [csvData, setCsvData] = useState([]);
  
  const [columnMappings, setColumnMappings] = useState([]);
  const [columnNames, setColumnNames] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility
  const [showsecondModal, setsecShowModal] = useState(false); // State to control the modal's visibility

  const handleModalOpen = () => setShowModal(true); // Function to open the modal
  const handleModalClose = () => setShowModal(false)
  const secModalOpen = () => setsecShowModal(true); // Function to open the modal
  const secleModalClose = () => setsecShowModal(false)
  // const handleFileChange = (event) => {
  //   setLoading(true)
  //   const file = event.target.files[0];
  
   
  //   if (!file || !file.name.endsWith('.xlsx') && !file.name.endsWith('.csv')) {
  //   setLoading(false)

  //     alert('Please upload an Excel file.');

  //     return;
  //   }
  
  //   const reader = new FileReader();
  
  //   reader.onload = (e) => {
  //     try {
  //       const data = new Uint8Array(e.target.result);
  //       const workbook = XLSX.read(data, { type: 'array' });
  //       const sheet = workbook.Sheets[workbook.SheetNames[0]]; // Assuming only one sheet
  
  //       // Get column and row counts
  //       const range = XLSX.utils.decode_range(sheet['!ref']);
  //       const numCols = range.e.c + 1;
  //       const numRows = range.e.r + 1;
  //       const jsonData = XLSX.utils.sheet_to_json(sheet);
  //       setCsvData(jsonData);
  
  //       // Get column names
  //       const columnNames = [];
  //       for (let i = 0; i < numCols; i++) {
  //         const cell = sheet[XLSX.utils.encode_cell({ r: 0, c: i })];
  //         const columnName = cell ? cell.v : `Column ${i + 1}`;
  //         if (!columnName.match(/^Column\s+\d+$/)) {
  //           // Exclude columns with numeric names
  //           columnNames.push(columnName);
  //         }
  //       }
  
  //       const fileName = file.name;
  //       // Store file details in state
  //       setFileData({ fileName, numRows, numCols });
  //       setColumnNames(columnNames);
  //       // Set column mappings with initial values
  //       const initialColumnMappings = columnNames.map(columnName => ({
  //         columnName,
  //         importOption: '',
  //       }));
  //       setColumnMappings(initialColumnMappings);
  //   setLoading(false)

  //     } catch (error) {
  //   setLoading(false)

  //       alert('Error reading column names. Please upload another file.');
  //     }
  //   };
  
  //   reader.readAsArrayBuffer(file);
  // };
 
  
 
const handleFileChange = (event) => {
    setLoading(true);
    const file = event.target.files[0];

    // Validate the file type
    if (!file || (!file.name.endsWith('.xlsx') && !file.name.endsWith('.csv'))) {
        setLoading(false);
        alert('Please upload an Excel or CSV file.');
        return;
    }

    const reader = new FileReader();

    reader.onload = (e) => {
        try {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: 'array' });
            const sheet = workbook.Sheets[workbook.SheetNames[0]]; // Assuming only one sheet

            // Convert sheet to JSON with raw data
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 });

            // Extract headers and rows
            const headers = jsonData[0];
            const rows = jsonData.slice(1);

            // Filter out rows that are empty
            const validRows = rows.filter(row => row.some(cell => cell !== undefined && cell !== null && cell.toString().trim() !== ""));

            // Convert valid rows to object format using headers
            const csvData = validRows.map(row => {
                const rowObject = {};
                headers.forEach((header, index) => {
                    rowObject[header] = row[index] !== undefined ? row[index] : "";
                });
                return rowObject;
            });

            // Set state
            setCsvData(csvData);

            // Determine column names from headers, excluding empty headers
            const columnNames = headers.filter(header => header && header.trim() !== "");

            setColumnNames(columnNames);

            // Set file details
            const fileName = file.name;
            setFileData({ fileName, numRows: csvData.length, numCols: columnNames.length });

            // Initialize column mappings
            const initialColumnMappings = columnNames.map(columnName => ({
                columnName,
                importOption: '',
            }));
            setColumnMappings(initialColumnMappings);

            setLoading(false);
        } catch (error) {
            setLoading(false);
            console.error('Error processing file:', error);
            alert('Error reading file. Please upload a different file.');
        }
    };

    // Read file as ArrayBuffer
    reader.readAsArrayBuffer(file);
};

  

  const formatPhoneNumber = (phoneNumber) => {
    if (phoneNumber == null) {
        return null; // Return null for null or undefined values
    }

    const digits = String(phoneNumber).replace(/\D/g, ''); // Ensure phoneNumber is a string and remove non-digit characters
    const countryCode = '+1';

    if (String(phoneNumber).startsWith('+1')) {
      // alert(phoneNumber)
        // If the number is already in international format with country code +1, keep it as is
        return phoneNumber;
    } else if (digits.length === 10) {
        // Add +1 to 10 digit numbers
        return countryCode + digits;
    } else if (digits.length > 10) {
        // Add +1 to numbers with more than 10 digits
        return countryCode + digits.slice(-10);
        // return countryCode + digits.slice(0);
    } else {
        return null; // Invalid phone number format
    }
};
const handleUpload = () => {
  setLoading(true);

  if (audiencename.length < 1) {
      setLoading(false);
      alert("Please Enter Audience Name");
      return;
  }
  const phoneColumnMappings = columnMappings.filter(mapping =>
    ["phone_1", "phone_2", "phone_3", "phone_4", "phone_5"].includes(mapping.importOption)
);
// const firstNameColumnMapping = columnMappings.find(mapping => mapping.importOption.toLowerCase() === 'first_name');

if (!phoneColumnMappings.length ) {
    setLoading(false);
    alert("Please Select Required Columns");
    return;
}

// const firstNameColumnName = firstNameColumnMapping.columnName;
const uniquePhoneNumbers = new Set();
const rawPhoneNumbers = []; // Array to store all phone numbers including duplicates
let totalValidRows = 0; // To count rows with at least one valid phone number

const formattedCsvData = csvData
    .map(row => {
        // const firstName = row[firstNameColumnName];

        // if (!firstName) {
        //     return null;
        // }

        let hasValidPhone = false;

        phoneColumnMappings.forEach(mapping => {
            const phoneColumnName = mapping.columnName;
            const phoneNumber = row[phoneColumnName];

            if (phoneNumber) {
                const formattedPhoneNumber = formatPhoneNumber(phoneNumber);
                rawPhoneNumbers.push(formattedPhoneNumber); // Add every phone number to rawPhoneNumbers array
                
                if (!uniquePhoneNumbers.has(formattedPhoneNumber)) {
                    uniquePhoneNumbers.add(formattedPhoneNumber);
                    hasValidPhone = true;
                }
                row[phoneColumnName] = formattedPhoneNumber;
            }
        });

        if (hasValidPhone) totalValidRows++; // Increment the count of valid rows

        return hasValidPhone ? row : null;
    })
    .filter(Boolean);


    // console.log("Total Raw Phone Numbers (including duplicates):", totalRawPhoneNumbers);
    // console.log("Total Unique Phone Numbers Before Formatting:", rawPhoneNumbers.length);
    // console.log("Total Prospects After Formatting:", totalValidRows);
    // alert(`Total Prospects Counted: ${uniquePhoneNumbers.size}`);

  const BATCH_SIZE = 1000;
  const totalBatches = Math.ceil(formattedCsvData.length / BATCH_SIZE);

  // Function to send the first batch with additional data
  const sendFirstBatch = async () => {
      const start = 0;
      const end = BATCH_SIZE;
      const firstBatchData = formattedCsvData.slice(start, end);

      const postData = {
          userid: id,
          name: audiencename,
          columnMappings,
          csvData: firstBatchData,
      };

      try {
          const res = await axios.post(`${API_BASE_URL}/api/upload-audience`, postData);
          if (res.data === 1) {
              setLoading(false);
              alert("Audience Name Already Exist Choose Another Name");
              return;
          }

          if (totalBatches > 1) {
              sendBatch(1); // Start sending the next batch
          } else {
              setLoading(false);
              alert('File uploaded successfully');
              navigate("/audience");
          }
      } catch (error) {
          setLoading(false);
          console.error('Error uploading first batch:', error);
          alert('Error uploading file. Please try again.');
      }
  };

  // Function to send subsequent batches
  const sendBatch = async (batchNumber) => {
      const start = batchNumber * BATCH_SIZE;
      const end = start + BATCH_SIZE;
      const batchData = formattedCsvData.slice(start, end);

      if (batchData.length > 0) {
          // const postData = {
          //     csvData: batchData,
          //     userid: id,
          // };
          const postData = {
            userid: id,
            checked:1,
            columnMappings,
            name: audiencename,
            csvData: batchData,
        };
          try {
              await axios.post(`${API_BASE_URL}/api/upload-audience`, postData);

              if (batchNumber < totalBatches - 1) {
                  sendBatch(batchNumber + 1);
              } else {
                  setLoading(false);
                  alert('File uploaded successfully');
                  navigate("/audience");
              }
          } catch (error) {
              setLoading(false);
              console.error('Error uploading batch:', error);
              alert('Error uploading file. Please try again.');
          }
      } else {
          setLoading(false);
          alert('File uploaded successfully');
          navigate("/crm");
      }
  };

  sendFirstBatch();
};

  const handleDelete = () => {
    // Reset fileData state and column mappings
    setFileData(null);
    setColumnMappings([]);
  };
  const changestep = () => {
    setSteps(true);
    // const gg =document.querySelector('.LST-TAG');
    // setTimeout(() => {
    //   gg.style.transition = 'transform 0.3s ease-in-out'; // Set transition properties
    //   gg.style.transform = 'translateX(0)'; // Apply transform after delay
    // }, 1000);
  };
  
  const revstep = () => {
    setSteps(null);
  };
  const navigate = useNavigate();

  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("login");
    if (loggedInUser) {
      setUserid(loggedInUser)
      setAuthenticated(loggedInUser);
    } else {
      // Redirect to login if not authenticated
      navigate("/login");
    }
  }, []);
  const getDisabledOptions = () => {
    const selectedOptions = columnMappings.map(mapping => mapping.importOption);
    return selectedOptions.filter(option => option !== 'do_not_import');
  };
  const handleChange = (event, index) => {
    const selectedOption = event.target.value;
    const newColumnMappings = [...columnMappings];
    
    // Check if the selected mapping is already used
    if (selectedOption !== 'do_not_import' && newColumnMappings.some((mapping, i) => mapping.importOption === selectedOption && i !== index)) {
      alert(`${selectedOption} is already selected. Please choose another.`);
      return;
    }

    newColumnMappings[index].importOption = selectedOption;
    setColumnMappings(newColumnMappings);

    const targetRow = event.target.closest('.row');
    const targetInput = targetRow.querySelector('.mappedInput');
    const targetCol1 = targetRow.querySelector('.col-1 img');

    if (selectedOption === "do_not_import") {
      targetInput.style.backgroundColor = '';
      targetCol1.style.display = 'none';
      targetInput.classList.remove('select_col');
    } else {
      targetInput.classList.add('select_col');
      targetCol1.style.display = 'block';
    }
  };

  
  if (!authenticated) {
    return null;
  } else {
    return (
        <Sidebar>
           {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
          {/* <Modal show={showfilterModal} contentClassName="custom-modal-content" onHide={handlefilterModalClose} backdropClassName="custom-backdrop" >
   <form id="columnForm" >
        <Modal.Header closeButton>
        </Modal.Header>
        
        <Modal.Body>
       
      
        <div class="container " style={{fontSize: '15px'}}>
          <div class="row">
            <div  class="col-md-6 float-left" style={{textAlign:'left'}}>
              <span>Lists</span>
              <br></br>
              <br></br>
              <div ><span className="ball-icon">OK</span> <span >Included</span></div>
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div ><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" style={{ }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
            <div  class="col-md-6 float-right" style={{textAlign:'right'}}>
            <div style={{textAlign:'left'}}> <span >Tags</span></div>
          
              
              <br></br>
              <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Included</span></div>
              
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" >
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
          </div>
        </div>



       

             
         
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlefilterModalClose} data-dismiss="modal" >Close</button>
            <button type="submit"  className="btn btn-primary save_web_btn" >Apply</button>
        </Modal.Footer>
        </form>
      </Modal> */}
 
       
         
        <div className="row ">
          
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
          <div className="tab">
      <div className={`tab-item ${location.pathname === '/campaign' ? 'active' : ''}`}>
        <Link to="/campaign" className="link disabled">Campaign</Link>
        {location.pathname === '/campaign' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/audience' ? 'active' : ''}`}>
      <Link to="/audience"  className="link disabled">Audience</Link>
        {location.pathname === '/audience' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/sms_cam_templates' ? 'active' : ''}`}>
      <Link to="/sms_cam_templates"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_cam_templates' && <div className="strip"></div>}
      </div>
      {/* <div className={`tab-item ${location.pathname === '/create_template' ? 'active' : ''}`}>
      <Link to="/create_template"  className="link disabled">Create Template</Link>
        {location.pathname === '/create_template' && <div className="strip"></div>}
      </div> */}
    </div>
    </div>
       

            
<div className="container ">
<div className="row">

  
<div className="crm_bd userEditForm  ">
                  <h5>&nbsp;Upload Excel </h5>
                  {/* <div class="frame">
                      <div class="center">
                        <div class="titleh">
                          <h1>Drop file to upload</h1>
                        </div>

                        <div class="dropzone">
                          <img src="http://100dayscss.com/codepen/upload.svg" class="upload-icon" />
                          <input type="file" class="upload-input" />
                        </div>

                        <button type="button" class="btnh" name="uploadbutton">Upload file</button>

                      </div>
                    </div> */}

                  <div class="
            ">
                    <div>
                      {!fileData && (
                        <div id="upload-container" style={{    width: '19%'}}>
                          <input type="file" id="upload" accept=".xlsx, .xls, .csv" onChange={handleFileChange} style={{ display: 'none' }} />
                          <label htmlFor="upload" className="upload-button">
                            <img src={upload} alt="Upload Icon" className="upload-icon" />
                            Upload Excel
                          </label>
                        </div>
                      )}
                      {fileData && (
                        <div>
                            <label htmlFor="aud-name" className="p-1">Audience Name :</label>
                            <br></br>
                            <input style={{width:'22%'}} className="ml-1 form-control mt-2 mb-2" id="aud-name" placeholder="Audience Name" required value={audiencename} onChange={(e) => setAudiencename(e.target.value)}  ></input>
                          <p className="p-1">
                            {fileData.fileName}:
                            <span class="csv_detail"> {fileData.numRows} lines with {columnNames.length} columns</span>
                            <img src={deletes} alt="Delete Icon" className="delete-icon" onClick={handleDelete} />
                          </p>
                          {!laststeps && 
                          (<div class="container mt-2" style={{ paddingRight: '37%' }}>
                            <h3 style={{ color: "#fff" }}>Data Mapping</h3>
                            <div class="alert alert-info mb-4"> It is required to have the mapping for Phone</div>
                            {columnMappings.map((mapping, index) => (
                              <div key={index} class="row mt-3">
                                <div class="col-6">
                                  <div class="source_fields">
                                    <input type="text" disabled class="form-control mappedInput" id={`columnName${index + 1}`} title="Client Name" placeholder={mapping.columnName} />
                                  </div>
                                </div>
                                <div class="col-1"><img src={ticked} style={{height: '22px'}}></img></div>
                                <div class="col-5">
                                  <div class="form-group">
                                  <select                                           className="form-control importOption" 
                                          onChange={(event) => handleChange(event, index)}  // Pass index as an argument to handleChange
                                          id={`importOption${index + 1}`}
                                        >
                                          <option value="do_not_import" >Do not import</option>
                                          
                                          <option value="first_name" disabled={getDisabledOptions().includes('first_name')}>First name</option>
                                          <option value="last_name" disabled={getDisabledOptions().includes('last_name')}>Last name</option>
                                          <option value="property_address" disabled={getDisabledOptions().includes('property_address')}>Property address</option>
                                          <option value="city" disabled={getDisabledOptions().includes('city')}>City</option>
                                          <option value="state" disabled={getDisabledOptions().includes('state')}>State</option>
                                          <option value="zip" disabled={getDisabledOptions().includes('zip')}>Zip</option>
                                          <option value="phone_1" disabled={getDisabledOptions().includes('phone_1')}>Phone 1</option>
                                          <option value="phone_2" disabled={getDisabledOptions().includes('phone_2')}>Phone 2</option>
                                          <option value="phone_3" disabled={getDisabledOptions().includes('phone_3')}>Phone 3</option>
                                          <option value="phone_4" disabled={getDisabledOptions().includes('phone_4')}>Phone 4</option>
                                          <option value="phone_5" disabled={getDisabledOptions().includes('phone_5')}>Phone 5</option>
                                        </select>

                                  </div>
                                </div>
                                
                              </div>
                              
                            ))}
                          </div>
                           )}
                          
                          <div class="container mt-5" style={{ paddingRight: '37%' }}>
                              <div  class="row mt-3">
                                <div class="col-4">
                                  <div class="">
                                  </div>
                                </div>
                                <div class="col-4">
                                 <div  class="text-center next_pre">

                                 
  
                                    <div className="text-center next_pre">
                                    <button type="submit" onClick={handleUpload}  className="btn btn-primary save_btn" style={{marginLeft:'20px',width:'100px'}}>Upload
                                        </button>
                                      
                                      
                                    </div>
                                 
                                  
                                   

                                  </div>
                                  
                              </div>
                                <div class="col-3"></div>
                                </div>
                                </div>
                              
                          
                        </div>
                      )}
                    </div>

        

                    
                  </div>
                </div>

       
        </div>
      
        </div>
        </div>
        </div>
          
     
    
          
        </Sidebar>
        );
      }
    
}

export default CreateAudience;
