import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import { Modal,Button } from 'react-bootstrap'
import API_BASE_URL from '../components/config';

import Sidebar from '../components/navbar';
// import { FaChartLine,FaInfoCircle,FaChevronDown,FaUser,FaPlug,FaPhone,FaUserCircle, FaCog, FaSignOutAlt, FaUber, FaUsers } from 'react-icons/fa';
import upload from '../icons/upload.svg';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';

function Tags() {
  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const [authenticated, setAuthenticated] = useState(null);
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility

  const handleModalOpen = () => setShowModal(true); // Function to open the modal
  const handleModalClose = () => setShowModal(false); // Function to close the modal
  const [processing, setProcessing] = useState(false);
  const [tags, setTags] = useState([]);

  const [id, setUserid] = useState('');
  const [tagname, setTagname] = useState('');
  const [description, setDescription] = useState('');
  const [tagsucc, setTagsucc] = useState('');

  useEffect(() => {
    const fetchusertags = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)

        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-tag`, {
            id
          });
          setTags(response.data);
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchusertags(); 
  }, []);
  const addtag = async (id, tagname,description,event) => {
    setProcessing(true)
    setTagsucc(false)
    event.preventDefault();

    try {
      // Send POST request to add a list
      const response = await axios.post(`${API_BASE_URL}/api/add-tag`, { id, tagname,description });
      
      setProcessing(false)
      setShowModal(false);
      const newtag = {
        id: response.data,
        tagname: tagname, 
        description:description,
    
      };
      const taghs = tags ? [...tags, newtag] : [newtag];
      setTags(taghs);
    } catch (error) {
      setProcessing(false)

      console.error('Error adding Tag:');
    }
  };

  const handleDelete = async (tagid) => {
    
    setTagsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to delete this tag?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-tag/${tagid}`);
      
    
      setTags(prevWebhooks => prevWebhooks.filter(item => item.id !== tagid));
      setTagsucc(true)
    } catch (error) {
      setTagsucc(false)

      console.error('Error deleting webhook:', error);
    }
  };


  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
      <Sidebar>
        <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => addtag(id, tagname, description,event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE TAG</h4>
        </div>
        <div className="modal-body ">
            
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">Name:</label>
                <input type="text" placeholder="Tag Name" required onChange={(e) => setTagname(e.target.value)}  className="form-control" id="recipient-name"  />
              </div>
               <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Description:</label>
                <textarea type="text" name="type" className="form-control"  onChange={(e) => setDescription(e.target.value)}  />

              </div>
             
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Saving....' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal>
     
    <div className="row ">
      <div className="col-3" style={{marginRight: '-60px'}}>
      <Menu/>

      </div>
      <div className="col-9" style={{marginLeft:'-2px'}}>
        <div class="serch_bar full_width">
        <div className="tab">
      <div className={`tab-item ${location.pathname === '/data-management' ? 'active' : ''}`}>
        <Link to="/data-management" className="link disabled">Lists</Link>
        {location.pathname === '/data-management' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/lead-status' ? 'active' : ''}`}>
      <Link to="/lead-status"  className="link disabled">Lead Status</Link>
        {location.pathname === '/lead-status' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/tags' ? 'active' : ''}`}>
      <Link to="/tags"  className="link disabled">Tags</Link>
        {location.pathname === '/tags' && <div className="strip"></div>}
      </div>
    </div>
    </div>
    <div className="userEditForm mt-1">
            <h5>&nbsp;Tags</h5>
        {tagsucc && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Tag Deleted Successfuly !</div>}

            <div className=" mb-2" style={{textAlign:'right'}}>
          
          <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Tags
          </button>
      </div>
      {tags.length === 0 && <div className="col-md-12 text-center">
      <div className="mb-2 ml-2" style={{color:'white',fontSize:'25px'}}>No Tags Found</div>
            </div>}
      <div className="table-responsive text-nowrap">
  <table className="table">
    <thead>
      {tags.length > 0 && <tr>
        <th style={{padding:'0px'}}>Name</th>
        <th style={{padding:'0px'}}>Description</th>
        <th></th>
        <th></th>
        <th></th>
       
      </tr>
  }
    </thead>
    <tbody className="table-border-bottom-0">
      
      {tags.map((item) => (
          <tr key={item.id}>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.tagname}</td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.description}</td>
            <td></td>
            <td></td>
            <td>
              <img src={deletes} className="mt-3" alt="Delete" onClick={() => handleDelete(item.id)} />
            </td>
          </tr>
        ))}
    </tbody>
  </table>
</div>
        </div>
    </div>
      
  </div>
  
     
    </Sidebar>
    );
  }
}

export default Tags;
