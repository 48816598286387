import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../components/config';

import Sidebar from '../components/navbar';
import { FaChartLine,FaInfoCircle,FaChevronDown,FaUser,FaPlug,FaPhone,FaUserCircle, FaCog, FaSignOutAlt, FaUber, FaUsers } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
function SupportCenter() {
  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility
  const [processing, setProcessing] = useState(false);

  const handleModalOpen = () => setShowModal(true); // Function to open the modal
  const handleModalClose = () => setShowModal(false); // Function to close the modal

  
  const [authenticated, setAuthenticated] = useState(null);
  const [repsucc, setRepsucc] = useState(null);
  const [tickets, setTickets] = useState([]);
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [id, setUserid] = useState('');
  
    useEffect(() => {
      const fetchreplies = async () => {
        const id = sessionStorage.getItem("login");
        
        if (id == 1) {
          setAuthenticated(true);
          setUserid(id);
    
          try {
            const response = await axios.post(`${API_BASE_URL}/api/all-tickets`, {
              id
            });
            setTickets(response.data);
          } catch (error) {
            // Handle error if necessary
          }
        } else {
          navigate("/login");
        }
      };
    
      // Call fetchreplies only when the component mounts
      fetchreplies();
    
      // Clear tickets state when the component unmounts to avoid memory leaks
      return () => {
        setTickets([]);
      };
    }, []);
  
  const formatDate = (date) => {
    const options = { year: 'numeric', month: 'long', day: 'numeric' };
    return new Date(date).toLocaleDateString(undefined, options);
  };
  
  const ticketSubmit = async (id,subject,message,event) => {
    setProcessing(true)
    event.preventDefault()
    

   
  
   
    try {
      const response= await axios.post(`${API_BASE_URL}/api/insert-ticket`, {
        id,
        subject,
        message,
      });
    setProcessing(false)

      if (response.data ){
        
        
        setShowModal(false);
        const newTicket = {
          id: response.data,
          subject: subject, 
          message: message, 
          closed: 0, 
          date: formatDate(new Date()), 
        };
      
      
        const updatedTickets = tickets ? [...tickets, newTicket] : [newTicket];
setTickets(updatedTickets);
      }
      // setRepsucc(false)
    } catch (error) {
      setProcessing(false)

   

      console.error('Error deleting webhook:', error);
    }
  };
  
  const handleOpen = async (id) => {
    
    setRepsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Open this Ticket?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/open-ticket/${id}`);
      
    window.location.reload()
    //   setTickets(prevWebhooks => prevWebhooks.filter(item => item.id !== id));
      setRepsucc(true)
    } catch (error) {
      setRepsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const handleClose = async (id) => {
    
    setRepsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Close this Ticket?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/close-ticket/${id}`);
      
    window.location.reload()
    //   setTickets(prevWebhooks => prevWebhooks.filter(item => item.id !== id));
      setRepsucc(true)
    } catch (error) {
      setRepsucc(false)

      console.error('Error deleting webhook:', error);
    }
  };
  const handleDelete = async (id) => {
    
    setRepsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to delete this Ticket?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-ticket/${id}`);
      
    
      setTickets(prevWebhooks => prevWebhooks.filter(item => item.id !== id));
      setRepsucc(true)
    } catch (error) {
      setRepsucc(false)

      console.error('Error deleting webhook:', error);
    }
  };
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
   <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit=
         {(event) => ticketSubmit(id, subject, message, event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>Submit Ticket</h4>
        </div>
        <div className="modal-body ">
        
              
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Subject:</label>
                <input type="text" name="type" placeholder="Subject" required value={subject} onChange={(e) => setSubject(e.target.value)} className="form-control" id="recipient-name"   />

              </div>
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Message:</label>
                <textarea type="text" className="form-control" id="recipient-name"  required value={message} onChange={(e) => setMessage(e.target.value)}  />

              </div>
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Submitting....' : 'Submit'}</button>
        </Modal.Footer>
        </form>
      </Modal>
       
        
     
        <div className="row ">
          
          <div className="col-12" style={{marginLeft:'-2px'}}>
            {/* <div class="serch_bar full_width">
        <div class="tab">
            <div routerlinkactive="active" placement="bottom" show-delay="500" class="tab-item active">
                <a class="link disabled" href="/app/setting/profile">User Profile</a>
                <div class="strip"></div>
            </div>
        </div>
        </div> */}
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;Tickets</h5>
        {repsucc && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Ticket Modified Successfuly !</div>}

{/*            
            <div className=" mb-2" style={{textAlign:'right'}}>
          
             
            <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Submit Ticket
          </button>
      </div> */}
      {tickets.length === 0 && <div className="col-md-12 text-center">
      <div className="mb-2 ml-2" style={{color:'white',fontSize:'25px'}}>No Tickets Found</div>
            </div>}
          {tickets.length > 0 && <div class="table-responsive text-nowrap mt-3">
    <table class="table">
      <thead>
        <tr>
        
                  <th style={{padding:'0px'}}>Subject</th>
                  <th style={{padding:'0px'}}>Message</th>
                  <th style={{padding:'0px'}}>User Email</th>
                  <th style={{padding:'0px'}}>Date</th>
                  
                  {/* <th></th> */}
                  <th>Action</th>
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
                      
                  {tickets.map((item) => (
          <tr key={item.id}>
            <td style={{padding:'0px'}}>{item.subject}</td>
            <td style={{padding:'0px',textWrap: 'pretty',linebreak: 'anywhere'}}>{item.message}</td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.email}</td>
            <td style={{padding:'0px'}}>{item.date}</td>
            
  {/* <td style={{ padding: '0px' }}>
  {item.attachment && (
    <>
   
    <a href={item.attachment} target="_blank" rel="noopener noreferrer">
     
    
    <img src={item.attachment} alt="Attachment" style={{ maxWidth: '100px', maxHeight: '100px' }} />
    </a>
    </>
    )}
  </td> */}


            
            
            <td>
              <Link to={`/all_tickets/${item.id}`} className="btn btn-danger" style={{backgroundColor:'#3683bc', borderColor:'#3683bc', marginRight:'12px'}}>View</Link>
              
              <button className="btn btn-danger" style={{ marginRight:'12px'}} onClick={() => handleDelete(item.id)}>Delete</button>
              {item.closed ==0 ?(
              <button className="btn btn-success ml-3"  onClick={() => handleClose(item.id)}>Close</button>
                
              ):(   
            // <span className="badge badge-default">Closed</span>  
            <button className="btn btn-primary" onClick={() => handleOpen(item.id)}>Open</button>  
              )}
            </td>

          </tr>
        ))}
                                        </tbody>
    </table>
   
  </div>
  }
        </div>
        
        </div>
          
      </div>
      
 
       
        </Sidebar>
        );
      }
    
}

export default SupportCenter;
