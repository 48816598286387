import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom';
import Sidebar from '../components/navbar';
import {  FaClock,FaCheck,FaPlay,FaPause,FaReply,FaDownload } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import searchicon from '../icons/std.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';

import loading from '../icons/loading.gif';

import axios from 'axios';
function ViewCampaign() {
  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [loadingchk, setLoading] = useState(false);
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false);
  const { campid } = useParams();
  const [campaign, setCampaign] = useState([]);
  const [campaignName, setCampaignName] = useState('');
  const [status, setStatus] = useState(0);
  const [id, setUserid] = useState('');
  const [startTime, setStartTime] = useState('');
  const [endTime, setEndTime] = useState('');
  const [rateminute, setRateMinute] = useState('');
  const [audience, setAudience] = useState('');
  const [weekdays, setWeekdays] = useState([]);
  const [templatename, setTemplate] = useState('');
  const [timeZone, setTimeZone] = useState('');
  
  const [authenticated, setAuthenticated] = useState(null);

  const [totaldelivered, setTotaldelivered] = useState(0);
  const [totalfailed, setTotalFailed] = useState(0);
  const [totalresponse, setTotalResponse] = useState(0);
  const [totalsent, setTotalsent] = useState(0);
  const [totalnumbers, setTotalNumbers] = useState(0);
  const [systemnumbers, setSystemnumbers] = useState(0);
  const [campaignId, setCampaignId] = useState(0);

  useEffect(() => {
    setLoading(true)
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true);
        setUserid(id);
  
        try {
          const response = await axios.post(`${API_BASE_URL}/api/fetch-campaign`, {
            campid
          });
          setCampaign(response.data);
          
          setCampaignName(response.data[0].name);
              setStatus(response.data[0].status);
              setEndTime(response.data[0].endtime);
              setStartTime(response.data[0].starttime);

              setTotalsent(response.data[0].totalsent);
              setTotaldelivered(response.data[0].totaldelivered);
              setTotalFailed(response.data[0].totalfailed);
              setTotalResponse(response.data[0].totalresponse);
              setTotalNumbers(response.data[0].nondnc);
              setSystemnumbers(response.data[0].numbers);
              
              // alert(totalnumbers)
              // alert(totalsent)
              setCampaignId(response.data[0]._id);
              setRateMinute(response.data[0].rateminute);
              setAudience(response.data[0].audiencename);
              setTemplate(response.data[0].templatename);
              setWeekdays(response.data[0].days);
              setTimeZone(response.data[0].timezone);

            // alert(response.data[0].totaldelivered,response.data[0].nondnc)
    setLoading(false)

        } catch (error) {
          setLoading(false)

        }
      } else {
        navigate("/login");
      }
    };
  
    // Call fetchreplies only when the component mounts
    fetchreplies();
  
 
  }, []);
  const formatPhoneNumber = (phone) => {
    if (phone.startsWith("+1")) {
      return phone.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
}
  
  const exportnondnc = async (numbers) => {
    const confirmed = window.confirm('Are you sure you want to Export?');
    if (!confirmed) {
      return; 
    }
  
    try {
      setLoading(true);
  
      // Ensure 'numbers' is an array and handle empty array case
      const data = Array.isArray(numbers) ? numbers : [];
      
      // Convert data to CSV format
      let csvContent = "Non DNC NUMBERS\n"; // Add the header line
      if (data.length > 0) {
        // Join all formatted phone numbers into a CSV string
        csvContent += data.map(formatPhoneNumber).join('\n');
      }
      
      // Create a Blob from the CSV content
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      
      // Create a link element to trigger the download
      const a = document.createElement('a');
      a.href = url;
      a.download = 'NON_DNC_EXPORT.csv';
      document.body.appendChild(a);
      a.click();
      
      // Cleanup
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
      setLoading(false);
    } catch (error) {
      setLoading(false);
      console.error('Error exporting DNC numbers:', error);
    }
  };
  
  useEffect(() => {
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
    
      const intervalId = setInterval(async () => {
        try {
          const id = sessionStorage.getItem("login");
      
          if (id) {
            try {
              const response = await axios.post(`${API_BASE_URL}/api/fetch-campaign`, {
                campid
              });
              
              const data  = response.data;
              setCampaign(data)
              setCampaignName(response.data[0].name);
              setStatus(response.data[0].status);
              setEndTime(response.data[0].endtime);
              setStartTime(response.data[0].starttime);
            //   alert(data)
              setTotalsent(response.data[0].totalsent);
              setTotaldelivered(response.data[0].totaldelivered);
              setTotalFailed(response.data[0].totalfailed);
              setTotalResponse(response.data[0].totalresponse);
              setTotalNumbers(response.data[0].nondnc);
              setCampaignId(response.data[0]._id);
              setSystemnumbers(response.data[0].numbers);



              setRateMinute(response.data[0].rateminute);
              setAudience(response.data[0].audiencename);
              setTemplate(response.data[0].templatename);
              setWeekdays(response.data[0].days);
              setTimeZone(response.data[0].timezone);

            } catch (error) {
              
            }
          } else {
            navigate("/login");
          }
        } catch (error) {
          alert('error')
        }
      }, 12000);
      
      return () => clearInterval(intervalId);
    
  }, []); 
  
const handleUpdate = async () => {
//  const confirmed = window.confirm('Are you sure you want to Edit');
//     if (!confirmed) {
//       return; 
//     }
     const response= await axios.post(`${API_BASE_URL}/api/update-campaign`, {
        campid:campaignId,
        campaigname:campaignName,
        starttime:startTime,
        endtime:endTime,
      });
      alert("Campaign Edited Successfuly")

}
const exportsystemnumbers = async (audname) => {
    

   
  const confirmed = window.confirm('Are you sure you want to Export');
  if (!confirmed) {
    return; 
  }

  try {
    setLoading(true)
    const response= await axios.post(`${API_BASE_URL}/api/export-system-numbers`, {
      id:audname
    });
    const data = response.data; 
    // let csvContent = "System NUMBERS\n"; 
    // const data = response.data;
    
    // Convert data to CSV format
    let csvContent = "First Name,Last Name,Address,City,State,Zip,Phone 1,Phone 2,Phone 3,Phone 4,Phone 5\n"; // Add the header line
    
    data.forEach(item => {
        csvContent += `${item['First Name']},${item['Last Name']},${item['Address']},${item['City']},${item['State']},${item['Zip']},${item['Phone 1']},${item['Phone 2']},${item['Phone 3']},${item['Phone 4']},${item['Phone 5']}\n`;
    });
    
    // Create a Blob from the CSV content
    const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
    const url = window.URL.createObjectURL(blob);
    
    // Create a link element to trigger the download
    const a = document.createElement('a');
    a.href = url;
    a.download = 'NUMBERS_EXPORT.csv';
    document.body.appendChild(a);
    a.click();
    window.URL.revokeObjectURL(url);
    document.body.removeChild(a);
    
  setLoading(false)
    // setDNCnumbers(prevnumber => prevnumber.filter(item => item.number !== number));
  } catch (error) {
    setLoading(false)

    // console.error('Error deleting webhook:', error);
  }
};
  const exportdnc = async (audname) => {
    

   
    const confirmed = window.confirm('Are you sure you want to Export');
    if (!confirmed) {
      return; 
    }
  
    try {
      setLoading(true)
      const response= await axios.post(`${API_BASE_URL}/api/export-audience-dnc`, {
        audname
      });
      const data = response.data; 
      let csvContent = "DNC NUMBERS\n"; 
      if (data.length > 0) {
       
        csvContent += data.join('\n');
      }
      
     
      const blob = new Blob([csvContent], { type: 'text/csv;charset=utf-8;' });
      const url = window.URL.createObjectURL(blob);
      
      
      const a = document.createElement('a');
      a.href = url;
      a.download = 'DNC_EXPORT.csv';
      document.body.appendChild(a);
      a.click();
      window.URL.revokeObjectURL(url);
      document.body.removeChild(a);
      
    setLoading(false)
      // setDNCnumbers(prevnumber => prevnumber.filter(item => item.number !== number));
    } catch (error) {
      setLoading(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const times = [
    '12:00 AM','1:00 AM', '2:00 AM', '3:00 AM', '4:00 AM', '5:00 AM', '6:00 AM', '7:00 AM', '8:00 AM', '9:00 AM', '10:00 AM', '11:00 AM', '12:00 PM',
    '1:00 PM', '2:00 PM', '3:00 PM', '4:00 PM', '5:00 PM', '6:00 PM', '7:00 PM', '8:00 PM', '9:00 PM', '10:00 PM', '11:00 PM'
  ];
  const updateItemStatus = async (id, newStatus) => {

    try {
      const response = await axios.post(`${API_BASE_URL}/api/update-campaign-status`, { id, status: newStatus });
      
        // setCampaign(prevItems =>
        //   prevItems.map(item =>
        //     item.id === id ? { ...item, status: newStatus } : item
        //   )
        // );
        setLoading(false)
      
    } catch (error) {
      console.error('Error updating status', error);
    }
  };

  const handleStatusToggle = (id, currentStatus) => {
    setLoading(true)
    const newStatus = currentStatus === 1 ? 2 : 1;
    setStatus(newStatus);
    // alert(newStatus)
    updateItemStatus(campaignId, newStatus);
  };
  const handleStartTimeChange = (e) => {
    setStartTime(e.target.value);
    if (endTime && times.indexOf(endTime) <= times.indexOf(e.target.value)) {
      setEndTime('');
    }
  };

  const handleEndTimeChange = (e) => {
    setEndTime(e.target.value);
  };

  const filterEndTimes = () => {
    if (!startTime) {
      return times;
    }
    const startIndex = times.indexOf(startTime);
    return times.slice(startIndex + 1);
  };
  if (!authenticated) {
    return null;
  } else {
    return (
        <Sidebar>
               {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
          {/* <Modal show={showfilterModal} contentClassName="custom-modal-content" onHide={handlefilterModalClose} backdropClassName="custom-backdrop" >
   <form id="columnForm" >
        <Modal.Header closeButton>
        </Modal.Header>
        
        <Modal.Body>
       
      
        <div class="container " style={{fontSize: '15px'}}>
          <div class="row">
            <div  class="col-md-6 float-left" style={{textAlign:'left'}}>
              <span>Lists</span>
              <br></br>
              <br></br>
              <div ><span className="ball-icon">OK</span> <span >Included</span></div>
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div ><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" style={{ }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
            <div  class="col-md-6 float-right" style={{textAlign:'right'}}>
            <div style={{textAlign:'left'}}> <span >Tags</span></div>
          
              
              <br></br>
              <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Included</span></div>
              
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" >
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
          </div>
        </div>



       

             
         
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlefilterModalClose} data-dismiss="modal" >Close</button>
            <button type="submit"  className="btn btn-primary save_web_btn" >Apply</button>
        </Modal.Footer>
        </form>
      </Modal> */}
 
       
        
        <div className="row ">
          
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
        <div className="tab">
      <div className={`tab-item ${location.pathname === '/campaign' ? 'active' : ''}`}>
        <Link to="/campaign" className="link disabled">Campaign</Link>
        <div className="strip"></div>
      </div>
      <div className={`tab-item ${location.pathname === '/audience' ? 'active' : ''}`}>
      <Link to="/audience"  className="link disabled">Audience</Link>
        {location.pathname === '/audience' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/sms_cam_templates' ? 'active' : ''}`}>
      <Link to="/sms_cam_templates"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_cam_templates' && <div className="strip"></div>}
      </div>
      
    </div>
    </div>
       

            

<div className=" mb-2" style={{textAlign:'right'}}>
   
        <div className="row">
         <div className="col-md-4">
         <div className="card text-white camp-card">
  <div className="card-header text-white">
    <div className="header-content">
        <div className="d-flex">
      <h4 className="float-left mt-3 mb-3">{campaignName}</h4>
    <div className="mt-2" style={{marginLeft:'auto'}}>
                      {status === 1 ? (
                <button
                  className="btn btn-danger"
                  style={{ width: '50px' }}
                  onClick={() => handleStatusToggle(campaignId, status)}
                >
                  <FaPause />
                </button>
              ) : status === 2 ? (
                <button
                  className="btn btn-primary"
                  onClick={() => handleStatusToggle(campaignId, status)}
                >
                  <FaPlay />
                </button>
              ): status === 3 ? (
                <FaCheck/>
              ) : (
                <FaClock />
              )}
</div>
                    </div>  
      <div className="progress-container">
      <span className="progress-label  ">
    {Math.round((totalsent / totalnumbers) * 100) === 100 ? '100% Complete' : `${Math.round((totalsent / totalnumbers) * 100)}%`}
  </span>
        <div className="progress"  >
          <div 
          className="progress-bar" 
          style={{ 
            width: `${(totalsent / totalnumbers) * 100}%`
          }}></div>
        </div>
      </div>
    </div>
  </div>
 
  <div className="card-body text-white">
  <div className="form-group mt-1 mb-1">
            <label>Campaign Name:</label>
            <input type="text" disabled={status === 1 || status === 3}
  required name="campaignname" placeholder="E.G PROMOTION CAMPAIGN" className="form-control mt-1" value={campaignName} onChange={(e) => setCampaignName(e.target.value)}  />
          </div>
          <div class="row mt-2 mb-2">
          <div class="col-md-6">
          <div className="form-group">
            <label>Start Time:</label>
            <select
            required
            disabled={status === 1 || status === 3}
            className="custom-dorpdown-toglle form-select mt-1"
            value={startTime}
            onChange={handleStartTimeChange}
            name="startime"
          >
            <option value="">Select Time</option>
            {times.map((time, index) => (
              <option key={index} value={time}>{time}</option>
            ))}
          </select>
          </div>
          </div>
          <div class="col-md-6">
          <div className="form-group">
            <label>End Time:</label>
           <select
            required
            name="endtime"
            disabled={status === 1 || status === 3 || !startTime }
            className="form-select custom-dorpdown-toglle mt-1"
            value={endTime}
            onChange={handleEndTimeChange}
          >
            <option value="">Select Time</option>
            {filterEndTimes().map((time, index) => (
              <option key={index} value={time}>{time}</option>
            ))}
          </select>  
          </div>
          </div>
          </div>
          <center>
          <button onClick={handleUpdate} disabled={status === 1 || status === 3  } class="btn btn-primary text-center mt-2 mb-2">Save</button>
          </center>
  </div>
  </div>
</div>
<div className="col-4">
            <div className="card text-white camp-card">
            <div className="card-header text-white">
    <div className="header-content">
        <p className="float-left mt-3 fs-5 mb-2">
                {weekdays.map(day => day).join(", ")}
            </p>
      {/* <h4 className="float-left mt-3 mb-3">{weekdays}</h4> */}
      
    </div>
  </div>
 
  <div className="card-body text-white">
  {/* <div className="form-group mt-1 mb-1">
            <label>Audience:</label>
            <input type="text" disabled={status === 1 || status === 3}
  required name="campaignname" placeholder="E.G PROMOTION CAMPAIGN" className="form-control mt-1" value={campaignName} onChange={(e) => setCampaignName(e.target.value)}  />
          </div> */}
          <div class="row mt-2 mb-2">
          <div class="col-md-6">
          <div className="form-group">
            <label>Audience:</label>
            <p>{audience}</p>
          </div>
          </div>
          <div class="col-md-6">
          <div className="form-group">
            <label>SMS Template:</label>
            <p>{templatename}</p>
  
          </div>
          </div>
          </div>
          
          <div class="row mt-2 mb-2">
          <div class="col-md-6">
          <div className="form-group">
            <label>Start Time:</label>
            <p>{startTime}</p>
          </div>
          </div>
          <div class="col-md-6">
          <div className="form-group">
            <label>End Time:</label>
            <p>{endTime}</p>
  
          </div>
          </div>
          </div>
          <div class="row mt-2 mb-2">
          <div class="col-md-6">
          <div className="form-group">
            <label>Time Zone:</label>
            <p>{timeZone}</p>
          </div>
          </div>
          <div class="col-md-6">
          <div className="form-group">
            <label>Rate Minute:</label>
            <p>{rateminute}</p>
  
          </div>
          </div>
          </div>
         
  </div>
            </div>
         </div>
         <div className="col-md-4">
            <div className="card camp-card">
                <div className="card-header text-white">
                    <div className="header-content">
                    <div className="form-group mt-2 mb-2">
                        <label class="mb-2">Total Prospect:</label>
                            <div className="progress-container mb-2">
                            <span className="progress-label">{totalnumbers}
       
    {/* {Math.round((totalsent / totalnumbers) * 100) === 100 ? '100% Complete' : `${Math.round((totalsent / totalnumbers) * 100)}%`} */}

      </span>
                            <div className="progress">
                            <div 
          className="progress-bar" 
          style={{ 
            width: `${totalnumbers * 100}%`
          }}></div>
                            </div>
                            </div>
                        </div>
                    <div className="form-group mt-2 mb-2">
                        <label class="mb-2">Total Sent:</label>
                            <div className="progress-container mb-2">
                            <span className="progress-label">{totalsent}
       
    {/* {Math.round((totalsent / totalnumbers) * 100) === 100 ? '100% Complete' : `${Math.round((totalsent / totalnumbers) * 100)}%`} */}

      </span>
                            <div className="progress">
                            <div 
          className="progress-bar" 
          style={{ 
            width: `${(totalsent / totalnumbers) * 100}%`
          }}></div>
                            </div>
                            </div>
                        </div>
                        <div className="form-group mt-2 mb-2">
                        <label class="mb-2">Total Delivered:</label>
                            <div className="progress-container mb-2">
                            <span className="progress-label">
        {totaldelivered}
    {/* {Math.round((totaldelivered / totalnumbers) * 100) === 100 ? '100% Complete' : `${Math.round((totaldelivered / totalnumbers) * 100)}%`} */}

      </span>
                            <div className="progress">
                            <div 
          className="progress-bar" 
          style={{ 
            width: `${(totaldelivered / totalnumbers) * 100}%`
          }}></div>
                            </div>
                            </div>
                        </div>
                    
                        <div className="form-group mt-2 mb-2">
                        <label class="mb-2">Total Failed:</label>
                            <div className="progress-container mb-2">
                            <span className="progress-label">
                                
                            {totalfailed}
        {/* {Math.round((totalfailed / totalsent) * 100) === 100 ? '100% Complete' : `${Math.round((totalfailed / totalsent) * 100)}%`} */}

      </span>
                            <div className="progress">
                            <div 
          className="progress-bar" 
          style={{ 
            width: `${(totalfailed / totalsent) * 100}%`
          }}></div>
                            </div>
                            </div>
                        </div>
                        <div className="form-group mt-2 mb-2">
                        <label class="mb-2">Total Response:</label>
                            <div className="progress-container mb-2">
                            <span className="progress-label">
       {totalresponse}
        {/* {Math.round((totalresponse / totaldelivered) * 100) === 100 ? '100% Complete' : `${Math.round((totalresponse / totaldelivered) * 100)}%`} */}

      </span>
                            <div className="progress">
                            <div 
          className="progress-bar" 
          style={{ 
            width: `${(totalresponse / totaldelivered) * 100}%`
          }}></div>
                            </div>
                            </div>
                        </div>
                        <div className="form-group mt-2 mb-2">
                        <label class="mb-2">Deliverablity Rate:</label>
                            <div className="progress-container mb-2">
                            <span className="progress-label">
       
    {Math.round((totaldelivered / totalsent) * 100) === 100 ? '100%' : `${Math.round((totaldelivered / totalsent) * 100)}%`}

      </span>
                            <div className="progress">
                            <div 
          className="progress-bar" 
          style={{ 
            width: `${(totaldelivered / totalsent) * 100}%`
          }}></div>
                            </div>
                            </div>
                        </div>
                        </div>
         </div>
         </div>
         </div>
         
        
    </div>
          
        </div>
       
        {campaign.length > 0 ? ( 
          <div class="table-responsive text-nowrap mt-3">
    <table class="table" >
      <thead>
        <tr>
                  <th>Audience</th>
                  <th>Total Prospects</th>
                  <th>Non DNC</th>
                  <th>DNC</th>
                  <th>System</th>
                  <th>Status</th>
                  
                
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
      {campaign.map((item) => (
                                                <tr>
                      <td>{item.audiencename}</td>
<td>{(item.nondnc || 0) + (item.dnc || 0)}</td>

<td>
  {item.status !== 0 && item.nondnc > 0 ? item.nondnc : '0'}&nbsp;&nbsp;
  {item.status !== 0 && item.nondnc > 0 && (
    <button onClick={() => exportnondnc(item.numbers)} style={{width:'50px'}} className="btn btn-success"><FaDownload/></button>
  )}
</td>

<td>
  {item.status !== 0 && item.dnc > 0 ? item.dnc : '0'}&nbsp;&nbsp;
  {item.status !== 0 && item.dnc > 0 && (
    <button onClick={() => exportdnc(item.audiencename)}   style={{width:'50px'}} className="btn btn-danger"><FaDownload/></button>
  )}
</td>
<td>
  {item.status !== 0 && item.numbers.length > 0 ? item.numbers.length : '0'}&nbsp;&nbsp;
  {item.status !== 0 && item.numbers.length > 0 && (
    <button onClick={() => exportsystemnumbers(item._id)}   style={{width:'50px'}} className="btn btn-primary"><FaDownload/></button>
  )}
</td>
                      
                      <td>
                     
                      {item.status === 1 ? (
        'Completed'
      ) : item.status === 2 ? (
        'Completed'
      )
      : item.status === 3 ? (
        'Completed'
      ) : (
        'Processing'
      )}
                        </td>
                      
                  </tr>
      ))}
                  
                  
                                        </tbody>
    </table>
    
  </div>
        ):(
          <div class="text-center">
            <p>No Campaign Found </p>
          </div>
        )}


       
      
        </div>
        </div>
          
     
          
        </Sidebar>
        );
      }
    
}

export default ViewCampaign;
