import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import FingerprintJS from '@fingerprintjs/fingerprintjs';

import Sidebar from '../components/navbar';
import {  FaInfoCircle,FaAngleRight,FaPause,FaReply,FaPlay,FaClock,FaCheck } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import searchicon from '../icons/std.svg';
import add from '../icons/add.svg';
import settings from '../icons/settings.svg';
import filter from '../icons/filter.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import BarGraph from '../components/bargraph';
import Piegraph from '../components/piechart';
import { useAuth } from '../auth.js';
import API_BASE_URL from '../components/config';
import loading from '../icons/loading.gif';

function Dashboard() {
  const navigate = useNavigate();
  const [loadingchk, setLoading] = useState(false);

  const [campaign, setCampaign] = useState([]);
 
  const [showModal, setShowModal] = useState(false);
  const [id, setUserid] = useState('');
  const [totalsent, setTotalsent] = useState('0');
  const [totalmesagessent, setTotalMsgsent] = useState('0');
  
  const [unread, setTotalunread] = useState('0');
  const [deliverby, setDeliverby] = useState('0');
  const [responserate, setResponse] = useState('0');
  const [totalcontacts, setTotalContacts] = useState('0');
  const [statuscontacts, setStatuscount] = useState('0');
  
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false);
  useEffect(() => {
    const generateFingerprint = async () => {
    const fp = await FingerprintJS.load();

    // Get the visitor identifier when you need it.
    const result = await fp.get();

    // This is the visitor identifier:
    const visitorId = result.visitorId;
    console.log(visitorId);
    document.body.style.backgroundColor = "rgb(42,45,54)";
    }
    generateFingerprint();
  }, []);
 
 
  
  
  
  const [authenticated, setAuthenticated] = useState(null);
  // const loggedInUser = sessionStorage.getItem("login");
  const [data, setData] = useState([
   
  ]);
  const updateItemStatus = async (id, newStatus) => {

    try {
      const response = await axios.post(`${API_BASE_URL}/api/update-campaign-status`, { id, status: newStatus });
      
        setCampaign(prevItems =>
          prevItems.map(item =>
            item.id === id ? { ...item, status: newStatus } : item
          )
        );
        setLoading(false)
      
    } catch (error) {
      console.error('Error updating status', error);
    }
  };
  const handleDelete = async (reply) => {
    
  

   
    const confirmed = window.confirm('Are you sure you want to delete this Campaign?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-campaign/${reply}`);
      
      
      // setTemplates(prevWebhooks => prevWebhooks.filter(item => item._id !== reply));
      // setLoading(true)
      alert("Campaign Deleted Succesfuly")
      window.location.reload()
    } catch (error) {
      // setLoading(false)

      
    }
  };
  const formatPhoneNumber = (phone) => {
    if (phone.startsWith("+1")) {
      return phone.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
}
  const handleStatusToggle = (id, currentStatus) => {
    setLoading(true)
    const newStatus = currentStatus === 1 ? 2 : 1;
    updateItemStatus(id, newStatus);
  };
  useEffect(() => {
    // Set background color
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
    
    
    const fetchContacts = async () => {
      const id = sessionStorage.getItem("login");
      // alert(id)
      if (id) {
        setAuthenticated(true);
        setUserid(id);
  
        try {
          const response = await axios.post(`${API_BASE_URL}/api/analytics`, { id });
          // console.log(response)
          
          setTotalsent(response.data.sent)
          setTotalMsgsent(response.data.total_sent)
          setTotalunread(response.data.unread)
          setDeliverby(response.data.deliverability_rate)
          setResponse(response.data.response_rate)
          setTotalContacts(response.data.total_contacts)
          setStatuscount(response.data.statuscount)
          const formattedData = response.data.statuscount.map(item => [item[0], item[1]]);
          setData(formattedData);
          setCampaign(response.data.campaings);
        } catch (error) {
          // alert('error')
          // Handle error if necessary
        
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchContacts();
  }, []);
  useEffect(() => {

    if (id) {
      const intervalId = setInterval(async () => {
        try {
          const response = await axios.post(`${API_BASE_URL}/api/analytics`, { id });
          // console.log(response)
  
          setTotalsent(response.data.sent)
          setTotalMsgsent(response.data.total_sent)
          setTotalunread(response.data.unread)
          setDeliverby(response.data.deliverability_rate)
          setResponse(response.data.response_rate)
          setTotalContacts(response.data.total_contacts)
          setStatuscount(response.data.statuscount)
           setCampaign(response.data.campaings);
          const formattedData = response.data.statuscount.map(item => [item[0], item[1]]);
          setData(formattedData);
        } catch (error) {
          // alert('error')
        }
      }, 6000);
      
      // Clear the interval when the component unmounts or when phoneNumberId changes
      return () => clearInterval(intervalId);
    }
  }, [id]); // Add phoneNumberId as a dependency
  

  // const data = [
  //   ['Hot : 22', 22],
  //   ['Warm : 34', 34],
  //   ['DNC : 23', 23],
  //   ['New :45', 45],
  //   ['Follow Up :15', 15],
  // ];
  const list = [
    [`Total Contacts: ${totalcontacts}`, totalcontacts],
];

  if (!authenticated) {
    
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
              {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
          {/* <Modal show={showfilterModal} contentClassName="custom-modal-content" onHide={handlefilterModalClose} backdropClassName="custom-backdrop" >
   <form id="columnForm" >
        <Modal.Header closeButton>
        </Modal.Header>
        
        <Modal.Body>
       
      
        <div class="container " style={{fontSize: '15px'}}>
          <div class="row">
            <div  class="col-md-6 float-left" style={{textAlign:'left'}}>
              <span>Lists</span>
              <br></br>
              <br></br>
              <div ><span className="ball-icon">OK</span> <span >Included</span></div>
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div ><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" style={{ }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
            <div  class="col-md-6 float-right" style={{textAlign:'right'}}>
            <div style={{textAlign:'left'}}> <span >Tags</span></div>
          
              
              <br></br>
              <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Included</span></div>
              
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" >
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
          </div>
        </div>



       

             
         
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlefilterModalClose} data-dismiss="modal" >Close</button>
            <button type="submit"  className="btn btn-primary save_web_btn" >Apply</button>
        </Modal.Footer>
        </form>
      </Modal> */}
 
       
         
        <div className="row ">
          
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'-2px'}}>
            {/* <div class="serch_bar full_width">
        <div class="tab">
            <div routerlinkactive="active" placement="bottom" show-delay="500" class="tab-item active">
                <a class="link disabled" href="/app/setting/profile">User Profile</a>
                <div class="strip"></div>
            </div>
        </div>
        </div> */}
        
        <div className="" style={{ display: 'flex', flexDirection: 'row' }}>
        <div className="col-3">
  <div className="card text-center" style={{ marginRight: '10px' }}>
    <div className="card-header mt-2">
      <h4 id="dash-num-color">Total Messages Sent <FaInfoCircle title="Metric is calculated based on the All messages you send out"/></h4>
    </div>
    <div className="card-body mb-3">
    <h2 id="dash-num-color">{totalmesagessent}</h2>
    </div>
  </div>
  </div>
        <div className="col-3">
  <div className="card text-center" style={{ marginRight: '10px' }}>
    <div className="card-header mt-2">
      <h4 id="dash-num-color">Initial Messages Sent <FaInfoCircle title="Metric is calculated based on the first message you send out"/></h4>
    </div>
    <div className="card-body mb-3">
    <h2 id="dash-num-color">{totalsent}</h2>
    </div>
  </div>
  </div>
    <div className="col-2">
  <div className="card text-center" style={{ marginRight: '10px' }}>
    <div className="card-header mt-2 ">
      <h4 id="dash-num-color">Deliverablity rate <FaInfoCircle title="Metric is calculated by taking the percentage of initial sends that delivered"/></h4>
    </div>
    <div className="card-body mb-3 mt-2">
    <h2 id="dash-num-color">{deliverby} %</h2></div>
  </div>
  </div>
  <div className="col-2">
  <div className="card text-center" style={{ marginRight: '10px' }}>
    <div className="card-header mt-2">
      <h4 id="dash-num-color">Response Rate  <FaInfoCircle title="Metric is calculated by taking the percentage of initial sends that received a response"/></h4>
    </div>
    <div className="card-body mb-3 mt-2">
    <h2 id="dash-num-color">{responserate} %</h2></div>
  </div>
  </div>
  <div className="col-2">
  <div className="card text-center">
    <div className="card-header mt-2">
      <h4 id="dash-num-color">Unread Messages <FaInfoCircle title="Metric is calculated based on all unread messages"/></h4>
    </div>
    <div className="card-body mb-3 mt-2">
      <h2 id="dash-num-color">{unread}</h2>
    </div>
  </div>
  </div>
  <br></br>
  
</div>

            
<div className="container mt-2">
<div className="row">
<h4 id="dash-num-color"></h4>

<div className="col-6 mt-2">
      <div className="card">
        <div className="card-header" id="dash-num-color">Contacts Status</div>
        <div className="card-body text-center mb-3" style={{    width: '70%',
    alignSelf: 'center'}}>
          <Piegraph data={data} />
        </div>
      </div>
</div>
<div className="col-6 mt-2">
    <div className="card ">
    <div className="card-header" id="dash-num-color">Total Contacts</div>
    <div className="card-body 
    ">
        <BarGraph data={list} />
    </div>
    </div>
</div>
<br></br>
<br></br>
        </div>
        <div className="" style={{marginLeft:'-2px',    backgroundColor: 'rgb(68 68 68 / 65%)',
    padding: '3px',
    borderRadius: '15px',
    marginTop: '19px'}}>

<div class="table-responsive text-nowrap mt-3">
  <table class="table">
    <thead>
      <tr>
        <th>Campaign</th>
        <th>Status</th>
        <th>Action</th>
        <th>Delivered</th>
        <th>Complete</th>
        <th>Total Prospects</th>
        <th>Total Sent</th>
        <th>Failed</th>
        <th>Responses</th>
        <th>Date Created</th>
        <th>Numbers</th>
        <th>Action</th>
      </tr>
    </thead>
    <tbody class="table-border-bottom-0">
      {campaign.length > 0 ? (
        campaign.map((item) => (
          <tr key={item.id}>
            <td>
              <Link style={{ color: 'snow' }} to={`/view_campaign/${item.id}`}>
                {item.name} <FaAngleRight />
              </Link>
            </td>
            <td>
              {item.status === 1 ? (
                'Running'
              ) : item.status === 2 ? (
                'Paused'
              ) : item.status === 3 ? (
                'Completed'
              ) : (
                'Processing'
              )}
            </td>
            <td>
              {item.status === 1 ? (
                <button
                  className="btn btn-danger"
                  style={{ width: '50px' }}
                  onClick={() => handleStatusToggle(item.id, item.status)}
                >
                  <FaPause />
                </button>
              ) : item.status === 2 ? (
                <button
                  className="btn btn-primary"
                  onClick={() => handleStatusToggle(item.id, item.status)}
                >
                  <FaPlay />
                </button>
              ) : item.status === 3 ? (
                <FaCheck />
              ) : (
                <FaClock />
              )}
            </td>
            <td>{item.status !== 0 ? item.totaldelivered || '0' : '0'}</td>
            <td>
              {item.status !== 0 
                ? (item.totalsent && item.nondnc 
                  ? `${Math.round((item.totalsent / item.nondnc) * 100)}%` 
                  : '0%') 
                : '0%'}
            </td>
            <td>{item.status !== 0 ? item.nondnc || '0' : '0'}</td>
            <td>{item.status !== 0 ? item.totalsent || '0' : '0'}</td>
            <td>{item.status !== 0 ? item.totalfailed || '0' : '0'}</td>
            <td>{item.status !== 0 ? item.totalresponse || '0' : '0'}</td>
            <td>{item.date}</td>
            <td>
              {item.assignednumbers && item.assignednumbers.length > 0 ? (
                item.assignednumbers.map((phone, index) => (
                  <div key={index}>{formatPhoneNumber(phone)}</div>
                ))
              ) : (
                <div>No phone numbers available</div>
              )}
            </td>
            <td>
              <button className="btn btn-danger" onClick={() => handleDelete(item.id)}>Delete</button>
            </td>
          </tr>
        ))
      ) : (
        <tr>
          <td colSpan="12" className="text-center">No Active Campaign</td>
        </tr>
      )}
    </tbody>
  </table>
</div>

       

{/* <div class="table-responsive text-nowrap mt-5">
    <table class="table" >
      <thead>
        <tr>
                  <th>Campaign</th>
                  <th>Status</th>
                  <th>Total Sent</th>
                  <th>Delivered</th>
                  <th>Failed</th>
                  <th>Response</th>
                  <th>Response Rate</th>
                  <th>DNC</th>
                  <th>Unread</th>
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
                                                <tr>
                      <td>Promotion</td>
                      <td>
                     
                      <button className="btn btn-danger" style={{width:'50px'}} > <FaPause></FaPause></button>

                        </td>
                      <td>35</td>
                      <td>50</td>
                      <td>25</td>
                      <td>2%</td>
                      <td>52</td>
                      <td>35</td>
                      <td>23</td>
                      
                  </tr>
                  <tr>
                      <td>Second Promotion</td>
                      <td>
                     
                      <button className="btn btn-primary"> <FaPlay></FaPlay></button>


                        </td>
                      <td>78</td>
                      <td>65</td>
                      <td>256</td>
                      <td>98%</td>
                      <td>98%</td>
                      <td>98%</td>
                      <td>25</td>
                      
                  </tr>
                  
                                        </tbody>
    </table>
    <div class="pagnination">
          
      </div>
  </div> */}

       
        </div>
      
        </div>
        </div>
        </div>
  
          
        </Sidebar>
        );
      }
    
}

export default Dashboard;
