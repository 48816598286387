import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import {  FaEdit} from 'react-icons/fa';

import Sidebar from '../components/navbar';

import deletes from '../icons/delete.svg';

import google from '../icons/google.png';
import { Modal,Button } from 'react-bootstrap'
import API_BASE_URL from '../components/config';

import { useLocation } from 'react-router-dom';

function Users() {
  const [id, setUserid] = useState('');
  const [totalusers, setTotalUser] = useState('0');
  const [plan, setSelectedPlan] = useState('');
  const [users, setUsers] = useState([]);

 
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false);


  const [updateshowModal, setupdateShowModal] = useState(false);
  const handleupdateModalOpen = () => setupdateShowModal(true); 
  const handleupdateModalClose = () => setupdateShowModal(false);


  const [reason, setReason] = useState('');
  const suspendAccount = async (event) => {
    event.preventDefault();
    // setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Suspend This Account?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response = await axios.delete(`${API_BASE_URL}/api/suspend-account/${id}/${reason}`);
      
      alert("User Account has been Suspended")
      window.location.reload()
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const openAccount = async (event,id) => {
    event.preventDefault();
    // setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Open This Account?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/suspend-account/${id}/''`);
      
      alert("User Account has been Opened")
      window.location.reload()
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const deleteaccount = async (event,userid) => {
    event.preventDefault();
    // setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Delete This Account?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/delete-account/${userid}`);
      
      alert("User Account has been Deleted")
      window.location.reload()
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    // Set background color
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
  
    const fetchContacts = async () => {
      const id = sessionStorage.getItem("login");
      // alert(id)
      if (id == 1) {
        setAuthenticated(true);
        // setUserid(id);
  
        try {
          const response = await axios.post(`${API_BASE_URL}/api/fetch-users`);
          // console.log(response)
  
          setTotalUser(response.data.total)
          setUsers(response.data.users)
          
          
        } catch (error) {
          alert('error')
          // Handle error if necessary
        
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchContacts();
  }, []);
  
  const handleLogin = async (userid) => {
   
    sessionStorage.setItem("changed",true)
    sessionStorage.setItem("login", userid);
    // const confirmed = window.confirm('Are you sure you want to Suspend This Account?');
    // if (!confirmed) {
    //   return; 
    // }
    window.location.href = "/"
    try {
     
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const handleSuspend = async (id) => {
    setShowModal(true)
    setUserid(id);
  };
  const updateplan = async (id) => {
    handleupdateModalOpen()
    setUserid(id);
  };

  
  

  const changeUserplan = async (event) => {
    event.preventDefault();
    // setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Change the Plan?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response = await axios.post(`${API_BASE_URL}/api/update-user-plan/${id}`);
      
      alert("Plan Has Been Updated")
      window.location.reload()
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  if (!authenticated) {
    return null;
  } else {
    return (
        <Sidebar>
         <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => suspendAccount(event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>Suspension Reason</h4>
        </div>
        <div className="modal-body ">
        <textarea  value={reason} onChange={(e) => setReason(e.target.value)} className="p-3"  required style={{width: '100%',
    height: '100px'}}></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <div style={{ display: 'flex' }}>
              <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary save_web_btn">Submit</button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      <Modal show={updateshowModal} contentClassName="custom-modal-content" onHide={handleupdateModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => changeUserplan(event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>UPDATE PLAN</h4>
        </div>
        <div className="modal-body ">
        <div className="d-flex">
          <p>PLAN : </p>
          
        <select value={plan} onChange={(e) => setSelectedPlan(e.target.value)} className="form-select custom-dorpdown-toglle mt-1" id="mySelect" >
                <option value="business-pro-legacy"> Business Pro (Legacy)</option>
                <option value="free"> Free</option>
                
            </select>
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <div style={{ display: 'flex' }}>
              <button type="button" className="btn btn-secondary cancel_btn" onClick={handleupdateModalClose} data-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary save_web_btn">Submit</button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
      
       
       
          
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'-2px'}}>
      
       

            

<div className=" mb-2" style={{textAlign:'left'}}>
          <h4 className="float-left text-white">Total Users: {totalusers} </h4>
          {/* <Link to="/create_campaign" type="button"  className="btn btn_primary save_btn"   >
        <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Campaign
        </Link> */}
        </div>
<div class="table-responsive text-nowrap mt-3">
    <table class="table" >
      <thead>
        <tr>
                  
                  <th>First Name</th>
                  <th>Last Name</th>
                  <th>Email</th>
                  <th>Auth</th>
                  <th>Phone</th>
                  <th>Password</th>
                  <th>Plan </th>
                  <th>Auto Suspend </th>
                  <th>Terminate</th>
                  <th>Billing </th>
                  <th>Action </th>
                  
                  
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
                                          
      {users.map((item) => (
          <tr key={item.id}>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.firstname}</td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.lastname}</td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.email}</td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>
              {item.googleauth == 0 ? (
                <span></span>
                ):(
                  <img src={google} style={
                    {
                      width: '30px',
                      borderRadius: '21px'
                    }
                  }></img>

                )
              }
              </td>
            <td style={{padding:'0px',textWrap: 'pretty'}}>{item.phone}</td>

            <td style={{padding:'0px',textWrap: 'pretty'}}>
            {item.googleauth === 0 ? (
                item.password
                ):(
                  <span></span>
                )}
                </td>
           <td>
  {item.plan === 'business-pro'
    ? 'Business Pro'
    : item.plan === 'business-pro-legacy'
    ? 'Business Pro (Legacy)'
    : item.plan === 'Free'
    ? 'Free'
    : item.plan === 'business-starter'
    ? 'Business Starter'
    : 'Business Starter'} 
    </td>
    <td>
      {item.suspendtime}
    </td>
                <td>
                <button type="button" className="btn  btn-danger  " onClick={(event) => deleteaccount(event,item._id)}  
        
        >Delete</button>
                </td>
            <td>
              
  <Link to={`/billing/${item.customer_id}`} type="button" className="btn btn_primary save_btn">
    &nbsp;&nbsp;View
  </Link>
</td>
              <td>
{item.suspened === 1 ? (
  <button type="button" className="btn  btn-primary  " onClick={(event) => openAccount(event,item._id)}  
        
        >Re Open</button>
):(
  <>
<button type="button" className="btn  btn_primary save_btn " onClick={() => handleSuspend(item._id)}
style={{backgroundColor:'red',borderColor:'red'}}  
        
        >Suspend</button>
        <button type="button" className="btn  btn_primary save_btn " onClick={() => handleLogin(item.id)} >Login</button>
        </>
)}
              
              </td>

        </tr>
        
        ))}
      
                      
                      
                  
                  
                                        </tbody>
    </table>
    
  </div>


       
       
        </div>
       
          
        </Sidebar>
        );
      }
    
}

export default Users;
