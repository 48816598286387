import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link,useNavigate  } from 'react-router-dom';
import '../css/loginform.css';
import API_BASE_URL from '../components/config';
import logo from '../icons/logo.png';
import alerticon from '../icons/alert-icon.png';
import { GoogleLogin,useGoogleLogin } from '@react-oauth/google';
import {GoogleOAuthProvider} from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Modal,Button } from 'react-bootstrap'


function Privacy() {
  const [processing, setProcessing] = useState(false);
  const [reason, setReason] = useState('');
   
  const [showModal, setShowModal] = useState(false);
  
  
 
  return (
   
    <section className="bg-white ">
 
  <div className="container">
    <div className="row justify-content-center text-center">
        
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-4 text-black" style={{textAlign:'-webkit-left',color:'black'}}>
      <>
  {/* Hello world */}
  <p
    style={{
      marginTop: "0pt",
      marginBottom: "0pt",
      textAlign: "center",
      lineHeight: "27pt"
    }}
  >
    <strong>
      <span className='text-black' style={{ fontFamily: "Poppins", fontSize: "27pt" }}>
        Privacy Policy
      </span>
    </strong>
  </p>
  {/* <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <em>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Last Updated: March 19, 2024
      </span>
    </em>
  </p> */}
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      ProPhone Inc and its affiliated companies (“we,” “us,” or “our”) works
      hard to build and maintain a relationship of trust with you. This Privacy
      Policy (the “Privacy Policy”) governs our collection, use, and disclosure
      of your Personal Information (as defined below) in connection with your
      use of our website(s) on which this Privacy Policy is linked
      (collectively, “Sites”), such as prophone.io, and any transactions,
      interactions, or other engagements you might have with us (collectively,
      “Services”).
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      This Privacy Policy explains what information we collect about you, how we
      use or disclose such information, how to exercise any rights you may have
      with respect to your Personal Information (including any rights to opt-out
      of certain uses of your information), and the safeguards we have in place
      to reduce the risk of unauthorized access and use. This Privacy Policy
      does not apply to our collection, use, or disclosure of Personal
      Information (as defined below) when acting as a data processor performing
      services on behalf of our clients. For information about the privacy
      practices of our clients, please consult their privacy policies.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      Please carefully read this Privacy Policy, which is part of our Terms and
      Conditions, before using our Sites and/or Services. If you do not agree
      with our Terms and Conditions or this Privacy Policy, you must refrain
      from using our Sites/Services. By accessing or using our Sites or
      Services, you agree to our use of your information consistent with our
      Terms and Conditions and this Privacy Policy.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        ABOUT THIS PRIVACY POLICY
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Privacy Policy Changes
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      This Privacy Policy is subject to change. We encourage you to review this
      Privacy Policy frequently for any revisions or amendments. If we make any
      material changes to this Privacy Policy, the revised policy will be posted
      on the Sites with the date of revision. Changes will be effective
      immediately upon posting. You will be deemed to have been made aware of
      and have accepted the changes by your continued use of our Sites or
      Services. If we determine that a change to this Privacy Policy will result
      in using your Personal Information acquired before the change being used
      in a materially new way, we will provide notice, such as by a posting on
      the Sites or an email to your last known email address, before using your
      Personal Information in the new way.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Conflict Between this Policy and Laws and Regulations
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      When federal, state, or local laws and regulations require a higher level
      of protection for your Personal Information, they take precedence over
      this Privacy Policy. The specific requirements of this Privacy Policy
      apply unless disallowed by applicable laws or regulations. Please be aware
      that the Sites and Services are subject to the laws of the United States
      of America and that your Personal Information will be processed in the
      United States and other jurisdictions. The Sites and Services are intended
      for use by individuals in the United States.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        INFORMATION WE COLLECT
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Personal Information
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We may collect information that personally identifies, relates to,
      describes, or is capable of being associated with you (“Personal
      Information”), including:
    </span>
  </p>
  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Unique Identifiers and Contact Information such as name, social media
        identifiers, mailing address, billing address, email address, phone
        number;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Financial Information such as credit card or bank account information;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Characteristics of Protected Classifications such as age, gender, race,
        ethnicity, national origin, military or veteran status;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Commercial Information such as the type of real estate you own, whether
        you rent or own real estate,dwelling age, size and type, mapping
        information, photos and satellite imagery, details about the home or
        building’s structure, information related to the financing of properties
        that your own;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Internet and Network Information such as IP address, device ID,
        browsing/search history, browser type, device type and settings;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Audio/Visual Information such as images, call recordings;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Inferences drawn from the above categories of Personal Information that
        relate to your preferences, characteristics, psychological trends,
        predispositions, behavior, attitudes, intelligence, abilities, and
        aptitudes (e.g., consumer response models, propensity to purchase
        models).
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        The specific Personal Information we collect about you may vary based on
        how you use the Sites and/or Services and your relationship with us.
      </span>
    </li>
  </ul>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Usage Data and Sites Activity
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We automatically collect information in connection with the actions you
      take on the Sites (“Usage Data”). For example, each time you visit our
      website, we may automatically collect the type of web browser you use, the
      type of device you use, the type of your operating system, the name of
      your Internet Service Provider, the pages you view on our Sites, referring
      and exit pages, the date and time of your visit, and the number of clicks
      to, from, and within the Sites, and the duration of your visits to the
      Sites. If this information is reasonably capable of being associated with
      you, we treat it as Personal Information under the category Internet and
      Network Information. If this information is not reasonably capable of
      being individually associated with you, we treat it as Usage Data.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Communication Recordings
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We may record calls and retain the content of text messages or other
      written/electronic communications between you and us. By communicating
      with us, you explicitly consent to our recording and retention of
      communications.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Business Information
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We also collect information about businesses and their representatives
      that engage with us such as EIN, TIN, address, phone number, email
      address, and credit card or bank account information (collectively,
      “Business Information”). This information is generally not considered
      Personal Information because it relates to a business or a business
      contact.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        HOW WE COLLECT INFORMATION
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black'style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Voluntary Disclosure
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We may ask you to provide us with Personal Information when you
      communicate with us (online or offline), use the Sites or Services, enter
      into a relationship with us, and at other times. You are not required to
      provide us your Personal Information; however, if you choose not to
      provide the requested information, you may not be able to use some or all
      of the features of the Sites or Services or we may not be able to fulfill
      your requested interaction.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Third-Party Data Sources
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We may collect Personal Information from third-party data sources such as
      data brokers, marketing/advertising agencies, financial institutions, and
      government agencies.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Social Media
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      If you engage with us via social media, use your social media credentials
      to access the Site or Services, or interact with social media buttons or
      widgets embedded in our Sites or Services, we may collect Personal
      Information available on your social media profile and Usage Data. For
      information about privacy controls available through your social media
      provider, please visit their website.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Cookies and Other Automated Tools
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We use cookies (a small text file placed on your computer) and other
      automated tools such as tracking pixels to improve the experience of the
      Sites and Services, such as saving your preferences from visit to visit to
      present you with a customized version of the Website. Many web browsers
      are initially set up to accept cookies. You can change your web browser to
      refuse all cookies or to indicate when a cookie is being sent. Please
      consult your web browser’s help section or similar resource for
      Instructions on how to manage cookies in your browser. However, certain
      features of the Sites and Services may not work properly if you delete or
      disable cookies.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        We use the following types of cookies:
      </span>
    </strong>
  </p>
  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <strong>
        <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
          Session Cookies:
        </span>
      </strong>
      <span style={{ fontFamily: "Inter", fontSize: "12pt" }}>&nbsp;</span>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Session cookies keep track of you or your information as you move from
        page to page within the Sites and Services and are typically deleted
        once you close your browser.
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <strong>
        <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
          Persistent Cookies:
        </span>
      </strong>
      <span style={{ fontFamily: "Inter", fontSize: "12pt" }}>&nbsp;</span>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Persistent cookies reside on your system and allow us to customize your
        experience if you leave and later return to the Sites and Services. For
        example, persistent cookies may allow us to remember your preferences
        and provide you with personalized content.
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <strong>
        <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
          Advertising Cookies:
        </span>
      </strong>
      <strong>
        <span style={{ fontFamily: "Inter", fontSize: "12pt" }}>&nbsp;</span>
      </strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Advertising cookies are used to learn more about you and advertise
        products/services that might interest you.
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <strong>
        <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
          Analytics Cookies:
        </span>
      </strong>
      <strong>
        <span style={{ fontFamily: "Inter", fontSize: "12pt" }}>&nbsp;</span>
      </strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Analytics cookies help us understand how our Sites and Services work and
        who is visiting our Sites or using our Services. Google Analytics is one
        tool we use, and you can learn more by reviewing Google’s Privacy
        Policy.
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <strong>
        <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
          Functional Cookies
        </span>
      </strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        : Functional cookies allow our Sites and Services to work as intended
        such as by allowing us to recognize and honor your login and take other
        actions for authentication and security.
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        We also employ web beacons, local shared objects (sometimes referred to
        as “flash cookies”), and other technologies that enable us to track
        certain aspects of a user’s visit to the Sites and use of the Services.
        For example, web beacons generally consist of an object embedded on a
        webpage or in an email that is invisible to the user but allows us to
        understand whether a user has viewed a particular webpage or email and
        to deliver certain Services. Web bacons typically help us collect Usage
        Data such as the time and date of a page or email view and a description
        of the page or email viewed.
      </span>
    </li>
  </ul>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      If you arrive at the Sites or Services by “clicking through” from another
      website, then certain information about you that you provided to that
      other website, such as the terms you were searching that led you to our
      Sites, may be transmitted to us and we may use it. You should review the
      privacy policy of any website from which you reached the Sites or Services
      to determine what information was collected by that website and for what
      purposes you agreed that website could use that information. We may retain
      information about you provided to us by other websites and will only use
      it in accordance with this Privacy Policy. Such information may be
      associated with other Usage Data or Personal Information.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Interest-Based Advertising
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We work with third-party advertisers, search providers, and ad networks
      (“Advertisers”) to learn more about you and show you our ads or other of
      our content that we believe would be relevant to you. Advertisers may also
      show you third-party ads or content when you engage with our Sites.
      Advertisers may collect and use information about your use of the Sites or
      Services as well as other websites and services. These companies may use
      cookies and other online tracking technologies to collect and use your
      information, including across time, devices, and websites. We and/or
      Advertisers may also append other data to the data collected by
      Advertisers to create an interest profile of individual users. Our Privacy
      Policy does not cover any use of information that an Advertiser may
      collect from you. Advertisements may be shown via the Sites, on
      third-party websites, or on social media properties. Using a tool created
      by the Network Advertising Initiated, you can opt out of several
      Advertisers’ activities. For more information, please visit the Network
      Advertising Initiative or the Digital Advertising Alliance (“DAA”)
      Consumer Choice Page. Opt-outs are device and browser specific; therefore,
      you will need to set your opt-out preferences for each device and browser.
      Please note that opt-outs only apply to interest-based advertising and not
      all advertising networks participate. You may still receive other types of
      online advertising.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Information from Advertisements
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      If you arrive at the Sites via an advertisement (e.g., banner ad), we may
      collect information regarding the advertisement with which you interacted
      and your interactions (e.g., item clicked, date, and time).
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        THIRD PARTIES THAT COLLECT INFORMATION ON OUR SITES
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      Certain third parties, including but not limited to those identified
      below, collect Personal Information and/or Usage Data from users of our
      Sites using automatic information collection tools such as APIs, cookies,
      and tracking pixels. The collection, use, and disclosure of Personal
      Information and/or Usage Data by these third parties is governed by their
      respective privacy policies.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Third Party Privacy Policy
      </span>
    </strong>
    <br />
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      Facebook https://www.facebook.com/privacy/explanation
    </span>
    <br />
    <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      Google https://policies.google.com/privacy
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        HOW WE USE AND SHARE INFORMATION
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>Generally</span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We use Personal Information for internal purposes, including:
    </span>
  </p>
  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Provide the Sites and Services;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Account creation, fulfillment, servicing and customer support: such as
        to create user accounts, provide products or services, keep users
        informed about the status of products and services, respond to questions
        and address user concerns, and deliver updates, upgrades, and product
        improvement information;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Marketing and market research: such as to send communications and offers
        and place advertisements, perform analytics for market research, trend
        analysis, and financial analysis;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Other company communications: such as to provide individuals and
        organizations with information that may be of interest such as company
        newsletters and announcements;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Sites and Services use and analytics: such as to provide you with access
        to and use of our Sites and Services, and to analyze use of our Sites
        and Services including through use of third-party web analytics services
        which may utilize automated technologies to collect data (such as email
        and IP addresses);
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Product research: such as to conduct research and analysis for
        maintaining, protecting, and developing our products and services,
        increase and maintain the effectiveness of our products and services,
        and prevent misuse;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Business operations: such as to evaluate, develop, and improve business
        operations, products and services offered, and perform business
        administration and other normal business activities;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Compliance: such as to comply with applicable legal requirements,
        government requests, industry standards, contractual obligations, our
        policies, and take actions that we deem necessary to preserve and
        enforce our rights or the rights of others; and
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Information security and fraud prevention: such as to operate
        information security and anti-fraud programs.
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Testimonials
      </span>
    </li>
  </ul>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      If you provide a testimonial, we may post it publicly on the Sites or in
      other advertising material. By providing a testimonial, you give us
      permission to use it and your likeness in any manner and for any purpose,
      including in marketing and advertising communications, without any
      compensation or notice to you.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Text Message Campaigns
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      If you sign up to receive text messages from us, we will not sell Personal
      Information collected specifically in connection with such service,
      including your mobile telephone number or consent data, with any third
      party, and we will not share such Personal Information with any third
      party except our Service Providers.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Service Providers
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We may establish a relationship with other businesses to provide services
      to us which may include corporate affiliates (“Service Providers”),
      including for:
    </span>
  </p>
  <ul type="disc" style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Provision of the Sites and Services;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Facilitating marketing, non-marketing, and internal communications;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Marketing design, development, and management;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Business analytics (both marketing and non-marketing related);
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Project management and collaboration;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        IT and network administration such as data storage and management,
        website hosting, data security, mobile application development;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Payment processing;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Professional services such as legal and accounting;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Obtaining legal records;
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Collection of accounts; and
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Day-to-day business operations such as courier services, facilities
        management, electronic signatures, and document destruction.
      </span>
    </li>
    <li
      style={{
        marginLeft: "63.6pt",
        lineHeight: "21pt",
        paddingLeft: "8.4pt",
        fontFamily: "serif",
        fontSize: "10pt"
      }}
    >
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        We only provide our Service Providers with the Personal Information
        reasonably necessary for them to perform these services on our behalf.
        You may also be asked to provide Personal Information directly to a
        Service Provider. Each Service Provider is expected to use reasonable
        security measures appropriate to the nature of the information involved
        to protect your Personal Information from unauthorized access, use, or
        disclosure. Service Providers are prohibited from using Personal
        Information other than as specified or permitted by us.
      </span>
    </li>
  </ul>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We may share any of the types of Personal Information collected with one
      or more Service Providers.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Your California Privacy Rights
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We may share Personal Information with third parties such as our clients
      for their direct marketing purposes. If you are a resident of California,
      you may opt-out of having your Personal Information shared with third
      parties for their direct marketing purposes by letting us know by sending
      us an email at support@prophone.io.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        OTHER PRIVACY AND SECURITY PROVISIONS
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black'style={{ fontFamily: "Inter", fontSize: "12pt" }}>Security</span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We recognize the importance of safeguarding the confidentiality of
      Personal Information from loss, misuse, or alteration. Accordingly, we
      employ commercially reasonable administrative, technical, and physical
      safeguards to protect such Personal Information from unauthorized access,
      disclosure, and use, both in transit and at rest. Even with these
      safeguards, no data transmission over the Internet or other network can be
      guaranteed 100% secure. As a result, while we strive to protect
      information transmitted on or through our Sites or Services, you do so at
      your own risk.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Other Websites
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      Please be aware that third-party websites accessible through the Sites or
      recommended through our Services may have their own privacy and data
      collection policies and practices. We are not responsible for any actions,
      content of websites, or privacy policies of such third parties. You should
      check the applicable privacy policies of those third parties before
      providing information.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Compliance with Laws and Law Enforcement
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We cooperate with government and law enforcement officials and private
      parties to enforce and comply with the law. We may disclose Personal
      Information, Usage Data, and any other information about you to government
      or law enforcement officials or private parties if, in our sole
      discretion, we believe it is necessary or appropriate to respond to legal
      requests (including court orders, investigative demands, and subpoenas),
      to protect the safety, property, or rights of ourselves, consumers, or any
      other third party, to prevent or stop any illegal, unethical, or legally
      actionable activity, or to comply with law.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Other Transfers
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We may share Personal Information and Usage Data with businesses
      controlling, controlled by, or under common control with us. If we are
      merged, acquired, or sold, or in the event of a transfer of some or all of
      our assets, we may disclose or transfer Personal Information and Usage
      Data in connection with such transaction.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black'style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        How Long We Retain Your Data
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      We retain your Personal Information for as long as we have a relationship
      with you. We also retain your Personal Information for a period of time
      after our relationship with you has ended where there is an ongoing
      business need to retain it. This includes but is not limited to retention
      to comply with our legal, regulatory, tax, and/or accounting obligations.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span  className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Children’s Privacy
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      The Sites and Services are not intended for children under the age of 16
      and we do not knowingly collect Personal Information from children under
      the age of 16. If we become aware that we have inadvertently received
      Personal Information from a child under the age of 16, we will delete such
      information from our records.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        California “Do Not Track” Disclosure
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      “Do Not Track” is a web browser privacy preference that causes the web
      browser to broadcast a signal to websites requesting that a user’s
      activity not be tracked. Currently, our Sites and Services do not respond
      to “Do Not Track” signals.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
        Mobile Information
      </span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      No mobile information will be shared with third parties/affiliates for
      marketing/promotional purposes. All the above categories exclude text
      messaging originator opt-in data and consent; this information will not be
      shared with any third parties.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <strong>
      <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>CONTACT US</span>
    </strong>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      If you have any questions about our Sites, Services, or this Privacy
      Policy, please contact us using the information below.
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>ProPhone Inc</span>
    <br />
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      1306 Highgrove Ln
    </span>
    <br />
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      Clarksville, TN 37043
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "21pt", lineHeight: "21pt" }}>
    <span className='text-black' style={{ fontFamily: "Inter", fontSize: "12pt" }}>
      Email address: support@prophone.io
    </span>
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  
</>

      
      
      
      
      {/* <h2 class="mb-3 pt-5 " id="color-loginform">Privacy Policy</h2>
      <p class="text-black fs-5 mt-3 mb-3">ProPhone Inc and its affiliated companies (“we,” “us,” or “our”) works hard to build and maintain a relationship of trust with you. This Privacy Policy (the “Privacy Policy”) governs our collection, use, and disclosure of your Personal Information (as defined below) in connection with your use of our website(s) on which this Privacy Policy is linked (collectively, “Sites”), such as prophone.io, and any transactions, interactions, or other engagements you might have with us (collectively, “Services”).</p>
      <p class="text-black fs-5 mt-3 mb-3">This Privacy Policy explains what information we collect about you, how we use or disclose such information, how to exercise any rights you may have with respect to your Personal Information (including any rights to opt-out of certain uses of your information), and the safeguards we have in place to reduce the risk of unauthorized access and use. This Privacy Policy does not apply to our collection, use, or disclosure of Personal Information (as defined below) when acting as a data processor performing services on behalf of our clients. For information about the privacy practices of our clients, please consult their privacy policies.</p>
      <p class="text-black fs-5 mt-3 mb-3">Please carefully read this Privacy Policy, which is part of our Terms and Conditions, before using our Sites and/or Services. If you do not agree with our Terms and Conditions or this Privacy Policy, you must refrain from using our Sites/Services. By accessing or using our Sites or Services, you agree to our use of your information consistent with our Terms and Conditions and this Privacy Policy.</p>
      <h2 class="mb-3 pt-5 " id="color-loginform">About This Privacy Policy</h2>
      <h2 class="mb-3 pt-5 " id="color-loginform">Privacy Policy Changes</h2>
        <p class="text-black fs-5 mt-3 mb-3">This Privacy Policy is subject to change. We encourage you to review this Privacy Policy frequently for any revisions or amendments. If we make any material changes to this Privacy Policy, the revised policy will be posted on the Sites with the date of revision. Changes will be effective immediately upon posting. You will be deemed to have been made aware of and have accepted the changes by your continued use of our Sites or Services. If we determine that a change to this Privacy Policy will result in using your Personal Information acquired before the change being used in a materially new way, we will provide notice, such as by a posting on the Sites or an email to your last known email address, before using your Personal Information in the new way.</p>
      <h2 class="mb-3 pt-5 " id="color-loginform">Conflict Between this Policy and Laws and Regulations</h2>
      <p class="text-black fs-5 mt-3 mb-3">When federal, state, or local laws and regulations require a higher level of protection for your Personal Information, they take precedence over this Privacy Policy. The specific requirements of this Privacy Policy apply unless disallowed by applicable laws or regulations. Please be aware that the Sites and Services are subject to the laws of the United States of America and that your Personal Information will be processed in the United States and other jurisdictions. The Sites and Services are intended for use by individuals in the United States.</p>
      <h2 class="mb-3 pt-5 " id="color-loginform">INFORMATION WE COLLECT</h2>
      <h2 class="mb-3 pt-5 " id="color-loginform">Personal Information</h2>
      <p class="text-black fs-5 mt-3 mb-3">We may collect information that personally identifies, relates to, describes, or is capable of being associated with you (“Personal Information”), including:</p>
        <li>
            <ul>
            •	Unique Identifiers and Contact Information such as name, social media identifiers, mailing address, billing address, email address, phone number;

            </ul>
            <ul>
            •	Financial Information such as credit card or bank account information;

            </ul>
            <ul>
            •	Characteristics of Protected Classifications such as age, gender, race, ethnicity, national origin, military or veteran status;

            </ul>
            <ul>
            •	Commercial Information such as the type of real estate you own, whether you rent or own real estate,dwelling age, size and type, mapping information, photos and satellite imagery, details about the home or building’s structure, information related to the financing of properties that your own;

            </ul>
            <ul>
            •	Internet and Network Information such as IP address, device ID, browsing/search history, browser type, device type and settings;

            </ul>
            <ul>
            •	Audio/Visual Information such as images, call recordings;

            </ul>
            <ul>
            •	Inferences drawn from the above categories of Personal Information that relate to your preferences, characteristics, psychological trends, predispositions, behavior, attitudes, intelligence, abilities, and aptitudes (e.g., consumer response models, propensity to purchase models).

            </ul>
            <ul>
            •	The specific Personal Information we collect about you may vary based on how you use the Sites and/or Services and your relationship with us.

            </ul>
            
        </li>
        <h2 class="mb-3 pt-5 " id="color-loginform">Usage Data and Sites Activity</h2>
        <p class="text-black fs-5 mt-3 mb-3">We automatically collect information in connection with the actions you take on the Sites (“Usage Data”). For example, each time you visit our website, we may automatically collect the type of web browser you use, the type of device you use, the type of your operating system, the name of your Internet Service Provider, the pages you view on our Sites, referring and exit pages, the date and time of your visit, and the number of clicks to, from, and within the Sites, and the duration of your visits to the Sites. If this information is reasonably capable of being associated with you, we treat it as Personal Information under the category Internet and Network Information. If this information is not reasonably capable of being individually associated with you, we treat it as Usage Data</p>
        <h2 class="mb-3 pt-5 " id="color-loginform">Communication Recordings</h2>
        
        <p class="text-black fs-5 mt-3 mb-3">We may record calls and retain the content of text messages or other written/electronic communications between you and us. By communicating with us, you explicitly consent to our recording and retention of communications.</p>

        <h2 class="mb-3 pt-5 " id="color-loginform">Business Information</h2>

<p class="text-black fs-5 mt-3 mb-3">We also collect information about businesses and their representatives that engage with us such as EIN, TIN, address, phone number, email address, and credit card or bank account information (collectively, “Business Information”). This information is generally not considered Personal Information because it relates to a business or a business contact.</p>
<h2 class="mb-3 pt-5 " id="color-loginform">HOW WE COLLECT INFORMATION</h2>
<h2 class="mb-3 pt-5 " id="color-loginform">Voluntary Disclosure</h2>
<p class="text-black fs-5 mt-3 mb-3">We may ask you to provide us with Personal Information when you communicate with us (online or offline), use the Sites or Services, enter into a relationship with us, and at other times. You are not required to provide us your Personal Information; however, if you choose not to provide the requested information, you may not be able to use some or all of the features of the Sites or Services or we may not be able to fulfill your requested interaction.</p>
<h2 class="mb-3 pt-5 " id="color-loginform">Third-Party Data Sources</h2>

<p class="text-black fs-5 mt-3 mb-3">We may collect Personal Information from third-party data sources such as data brokers, marketing/advertising agencies, financial institutions, and government agencies.</p>
<h2 class="mb-3 pt-5 " id="color-loginform">Social Media</h2>
<p class="text-black fs-5 mt-3 mb-3">If you engage with us via social media, use your social media credentials to access the Site or Services, or interact with social media buttons or widgets embedded in our Sites or Services, we may collect Personal Information available on your social media profile and Usage Data. For information about privacy controls available through your social media provider, please visit their website.</p>
<h2 class="mb-3 pt-5 " id="color-loginform">Cookies and Other Automated Tools</h2>
<p class="text-black fs-5 mt-3 mb-3">We use cookies (a small text file placed on your computer) and other automated tools such as tracking pixels to improve the experience of the Sites and Services, such as saving your preferences from visit to visit to present you with a customized version of the Website. Many web browsers are initially set up to accept cookies. You can change your web browser to refuse all cookies or to indicate when a cookie is being sent. Please consult your web browser’s help section or similar resource for Instructions on how to manage cookies in your browser. However, certain features of the Sites and Services may not work properly if you delete or disable cookies.</p>

<h2 class="mb-3 pt-5 " id="color-loginform">We use the following types of cookies:</h2>
<li>
            <ul>
            •	<strong>Session Cookies</strong>: Session cookies keep track of you or your information as you move from page to page within the Sites and Services and are typically deleted once you close your browser.

            </ul>
            <ul>
            •	<strong>Persistent Cookies</strong>: Persistent cookies reside on your system and allow us to customize your experience if you leave and later return to the Sites and Services. For example, persistent cookies may allow us to remember your preferences and provide you with personalized content

            </ul>
            <ul>
            •	<strong>Advertising  Cookies</strong>: Advertising cookies are used to learn more about you and advertise products/services that might interest you.

            </ul>
            <ul>
            •	<strong>Analytics   Cookies</strong>: Analytics cookies help us understand how our Sites and Services work and who is visiting our Sites or using our Services. Google Analytics is one tool we use, and you can learn more by reviewing Google’s Privacy Policy.

            </ul>
            <ul>
            •	<strong>Functional    Cookies</strong>: Functional cookies allow our Sites and Services to work as intended such as by allowing us to recognize and honor your login and take other actions for authentication and security.

            </ul>
           <ul>
           •	We also employ web beacons, local shared objects (sometimes referred to as “flash cookies”), and other technologies that enable us to track certain aspects of a user’s visit to the Sites and use of the Services. For example, web beacons generally consist of an object embedded on a webpage or in an email that is invisible to the user but allows us to understand whether a user has viewed a particular webpage or email and to deliver certain Services. Web bacons typically help us collect Usage Data such as the time and date of a page or email view and a description of the page or email viewed
           </ul>
            
        </li>
        <p class="text-black fs-5 mt-3 mb-3">If you arrive at the Sites or Services by “clicking through” from another website, then certain information about you that you provided to that other website, such as the terms you were searching that led you to our Sites, may be transmitted to us and we may use it. You should review the privacy policy of any website from which you reached the Sites or Services to determine what information was collected by that website and for what purposes you agreed that website could use that information. We may retain information about you provided to us by other websites and will only use it in accordance with this Privacy Policy. Such information may be associated with other Usage Data or Personal Information</p>
        <h2 class="mb-3 pt-5 " id="color-loginform">Interest-Based Advertising</h2>
        <p class="text-black fs-5 mt-3 mb-3">We work with third-party advertisers, search providers, and ad networks (“Advertisers”) to learn more about you and show you our ads or other of our content that we believe would be relevant to you. Advertisers may also show you third-party ads or content when you engage with our Sites. Advertisers may collect and use information about your use of the Sites or Services as well as other websites and services. These companies may use cookies and other online tracking technologies to collect and use your information, including across time, devices, and websites. We and/or Advertisers may also append other data to the data collected by Advertisers to create an interest profile of individual users. Our Privacy Policy does not cover any use of information that an Advertiser may collect from you. Advertisements may be shown via the Sites, on third-party websites, or on social media properties. Using a tool created by the Network Advertising Initiated, you can opt out of several Advertisers’ activities. For more information, please visit the Network Advertising Initiative or the Digital Advertising Alliance (“DAA”) Consumer Choice Page. Opt-outs are device and browser specific; therefore, you will need to set your opt-out preferences for each device and browser. Please note that opt-outs only apply to interest-based advertising and not all advertising networks participate. You may still receive other types of online advertising.</p>
        <h2 class="mb-3 pt-5 " id="color-loginform">Information from Advertisements</h2>
      <p class="text-black fs-5 mt-3 mb-3">If you arrive at the Sites via an advertisement (e.g., banner ad), we may collect information regarding the advertisement with which you interacted and your interactions (e.g., item clicked, date, and time).</p>
      <h2 class="mb-3 pt-5 " id="color-loginform">THIRD PARTIES THAT COLLECT INFORMATION ON OUR SITES</h2>
      <p class="text-black fs-5 mt-3 mb-3">Certain third parties, including but not limited to those identified below, collect Personal Information and/or Usage Data from users of our Sites using automatic information collection tools such as APIs, cookies, and tracking pixels. The collection, use, and disclosure of Personal Information and/or Usage Data by these third parties is governed by their respective privacy policies.</p>
      <h2 class="mb-3 pt-5 " id="color-loginform">Third Party Privacy Policy</h2>
      <p class="text-black fs-5 mt-3 mb-3">Facebook https://www.facebook.com/privacy/explanation</p>
      <p class="text-black fs-5 mt-3 mb-3">Google https://policies.google.com/privacy</p>
      <h2 class="mb-3 pt-5 " id="color-loginform">HOW WE USE AND SHARE INFORMATION</h2>
      <h2 class="mb-3 pt-5 " id="color-loginform">Generally</h2>
      <p class="text-black fs-5 mt-3 mb-3">We use Personal Information for internal purposes, including:</p>
      <li>
        <ul>
        •	Provide the Sites and Services
        </ul>
        <ul>
        •	Account creation, fulfillment, servicing and customer support: such as to create user accounts, provide products or services, keep users informed about the status of products and services, respond to questions and address user concerns, and deliver updates, upgrades, and product improvement information
        </ul>
        <ul>
        •	Marketing and market research: such as to send communications and offers and place advertisements, perform analytics for market research, trend analysis, and financial analysis
        </ul>
        <ul>
        •	Other company communications: such as to provide individuals and organizations with information that may be of interest such as company newsletters and announcements
        </ul>
        <ul>
        •	Provide the Sites and Services
        </ul>
        <ul>
        •	Sites and Services use and analytics: such as to provide you with access to and use of our Sites and Services, and to analyze use of our Sites and Services including through use of third-party web analytics services which may utilize automated technologies to collect data (such as email and IP addresses);
        </ul>
        <ul>
        •	Product research: such as to conduct research and analysis for maintaining, protecting, and developing our products and services, increase and maintain the effectiveness of our products and services, and prevent misuse;
        </ul>
        <ul>
        •	Business operations: such as to evaluate, develop, and improve business operations, products and services offered, and perform business administration and other normal business activities;
        </ul>

        <ul>
        •	Compliance: such as to comply with applicable legal requirements, government requests, industry standards, contractual obligations, our policies, and take actions that we deem necessary to preserve and enforce our rights or the rights of others; and
        </ul>
        <ul>
        •	Information security and fraud prevention: such as to operate information security and anti-fraud programs
        </ul>
        <ul>
        •	Testimonials
        </ul>
      </li>
      <p class="text-black fs-5 mt-3 mb-3">If you provide a testimonial, we may post it publicly on the Sites or in other advertising material. By providing a testimonial, you give us permission to use it and your likeness in any manner and for any purpose, including in marketing and advertising communications, without any compensation or notice to you.</p>
      <h2 class="mb-3 pt-5 " id="color-loginform">Text Message Campaigns</h2> */}

      
      </div>
    </div>
  </div>
</section>
  );
}

export default Privacy;
