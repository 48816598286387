import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import API_BASE_URL from '../components/config';

import loading from '../icons/loading.gif';

import axios from 'axios';

import Sidebar from '../components/navbar';
import {  FaAngleRight,FaPlay,FaPause,FaReply,FaEdit } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import searchicon from '../icons/std.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';

function Smscampagintemplate() {
  const [id, setUserid] = useState('');
  const [templates, setTemplates] = useState([]);
  const [selectedMessages, setSelectedMessages] = useState([]);
  const [loadingchk, setLoading] = useState(false);
  
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [showeidtmodal, setShowEditmodl] = useState(false);
  // const handleModalOpen = () => setShowModal(true); 
  const handleModalOpen = (messages) => {
    setSelectedMessages(messages);
    setShowModal(true);
  };
  const handleModalClose = () => setShowModal(false);
  const handleEidtModalOpen = (messages) => {
    
    setShowEditmodl(true);
  };
  const handleEditModalClose = () => setShowEditmodl(false);

  
  
   const messages = [
    "Hi there",
    "Hello",
    "How are you?",
    "Welcome!"
  ];
  
  const [authenticated, setAuthenticated] = useState(null);

 
  useEffect(() => {
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
    const fetchwebhooks = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
      setAuthenticated(true)
        const response= await axios.post(`${API_BASE_URL}/api/fetch-template`, {
          id
          
        });
        setTemplates(response.data)
        setUserid(id)
        
      } else {
        navigate("/login");
      }
    };
  
    fetchwebhooks(); 
  }, []);
  const handleDelete = async (reply) => {
    
    setLoading(false)

   
    const confirmed = window.confirm('Are you sure you want to delete this Template?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-template/${reply}`);
      
      
      // setTemplates(prevWebhooks => prevWebhooks.filter(item => item._id !== reply));
      setLoading(true)
      alert("Template Deleted Succesfuly")
      window.location.reload()
    } catch (error) {
      setLoading(false)

      
    }
  };
  if (!authenticated) {
    return null;
  } else {
    return (
        <Sidebar>
           {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
          <Modal size="lg" show={showModal} contentClassName="custom-modal-content modal-lg" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
            <h4>Messages</h4>
        </div>
        <div className="modal-body">
        {selectedMessages.map((message, index) => (
              <div className="mb-2" key={index}>
                {`${index + 1}. ${message.text}`} <hr />
              </div>
            ))}
    </div>
        
        </Modal.Body>
       
      </Modal>
      <Modal size="lg" show={showeidtmodal} contentClassName="custom-modal-content modal-lg" onHide={handleEditModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
            <h4>Template</h4>
        </div>
        <div className="container">
          <div className="row">
          <div className="col-2">
            <label>Template Name</label>
            <input></input>
        </div>
        </div>
    </div>
        
        </Modal.Body>
       
      </Modal>
       
        
        <div className="row ">
          
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
          <div className="tab">
      <div className={`tab-item ${location.pathname === '/campaign' ? 'active' : ''}`}>
        <Link to="/campaign" className="link disabled">Campaign</Link>
        {location.pathname === '/campaign' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/audience' ? 'active' : ''}`}>
      <Link to="/audience"  className="link disabled">Audience</Link>
        {location.pathname === '/audience' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/sms_cam_templates' ? 'active' : ''}`}>
      <Link to="/sms_cam_templates"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_cam_templates' && <div className="strip"></div>}
      </div>
      {/* <div className={`tab-item ${location.pathname === '/create_template' ? 'active' : ''}`}>
      <Link to="/create_template"  className="link disabled">Create Template</Link>
        {location.pathname === '/create_template' && <div className="strip"></div>}
      </div> */}
    </div>
    </div>
    <div className="userEditForm mt-4">
            <h5>&nbsp;SMS Template</h5>
            <div className=" mb-2" style={{textAlign:'right'}}>
          
            <Link to="/create_template" type="button"  className="btn btn_primary save_btn"   >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Template
          </Link>
          </div>
          <div class="table-responsive text-nowrap mt-3">
    <table class="table" >
      <thead>
        <tr>
          {/* <th>Id</th> */}
                  <th>Name</th>
                  <th>Message</th>
                  <th>Backup Message</th>
                  <th></th>
                  {/* <th>List</th> */}
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
      
           
      {templates.map((template, index) => (
          <tr key={template._id}>
            <td>{template.templatename}</td>
          <td>
                {template.messages && template.messages.length > 0 ? template.messages[0].text : 'No messages'} <FaAngleRight onClick={() => handleModalOpen(template.messages)} />
              </td>
            <td>{template.backuptext}</td>
            <td>
              
            <Link to={`/edit_template/${template._id}`}><button className="btn btn-success"   >Edit</button></Link>&nbsp;&nbsp;

                <button class="btn btn-danger" onClick={() => handleDelete(template._id)}>Delete</button>
              
            </td>
          </tr>
        ))}
                  {/* <tr>
                    
                      <td>Morning</td>
                      <td>
                     
                          Hello Good Morning  <FaAngleRight onClick={handleModalOpen}></FaAngleRight>

                        </td>
                      <td>Hello This, is me Happy Morning to you !</td>
          <a href="" ><img src={deletes} className="mt-3"></img></a>
                      <td></td>
                      
                  </tr> */}
                  {/* <tr>
                     
                      <td>Night</td>
                      <td>Hello Good Night</td>
                      <td>Hello This, is me Happy Morning to you !</td>
                      <td>
          <a href="" ><img src={deletes} className="mt-3"></img></a>

                      </td>
                      
                  </tr> */}
                  
                                        </tbody>
    </table>
    <div class="pagnination">
          
      </div>
  </div>
        </div>
       

            

      
        </div>
        </div>
          
     
      
          
        </Sidebar>
        );
      }
    
}

export default Smscampagintemplate;
