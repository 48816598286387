// // // src/PaymentForm.js
// // import React, { useState } from 'react';
// // import { CardElement, useStripe, useElements } from '@stripe/react-stripe-js';
// import axios from 'axios';

// // const PaymentForm = () => {
// //   const [error, setError] = useState(null);
// //   const stripe = useStripe();
// //   const elements = useElements();

// //   const handleSubmit = async (event) => {
// //     event.preventDefault();

// //     if (!stripe || !elements) {
// //       // Stripe.js has not loaded yet. Make sure to disable form submission until Stripe.js has loaded.
// //       return;
// //     }

// //     const cardElement = elements.getElement(CardElement);

// //     const { token, error } = await stripe.createToken(cardElement);

// //     if (error) {
// //       setError(error.message);
// //     } else {
// //       // Send token to your server for processing
// //       console.log(token);
// //     }
// //   };

// //   return (
// //     <div>
// //       <h2>Stripe Payment Form</h2>
// //       <form onSubmit={handleSubmit}>
// //         <label>
// //           Card number:
// //           <input type="text" name="cardNumber" placeholder="Card number" />
// //         </label>
// //         <label>
// //           Expiration month:
// //           <input type="text" name="expMonth" placeholder="MM" maxLength="2" />
// //         </label>
// //         <label>
// //           Expiration year:
// //           <input type="text" name="expYear" placeholder="YYYY" maxLength="4" />
// //         </label>
// //         <label>
// //           CVV:
// //           <input type="text" name="cvc" placeholder="CVV" maxLength="4" />
// //         </label>
// //         <button type="submit">Pay</button>
// //       </form>
// //       {error && <p style={{ color: 'red' }}>{error}</p>}
// //     </div>
// //   );
// // };

// // export default PaymentForm;
// import React, { useState } from 'react';
// import { CardElement, useStripe, useElements,PaymentElement } from '@stripe/react-stripe-js';

// const PaymentForm = ({ clientSecret }) => {
//   const [error, setError] = useState(null);
//   const stripe = useStripe();
//   const elements = useElements();
//     const ok ='2';
    
//   const handleSubmit = async (event) => {
//     event.preventDefault();

//     if (!stripe || !elements) {
//       return;
//     }
//     try{
        
//       const response = await axios.post('/create-payment-intent', {
//         amount: 1000, // Amount in cents
//         currency: 'usd',
//       });
//       const { clientSecret } = response.data;
//       console.log(clientSecret)
//     }catch{
//          ok = 'a'
//     }
//     const result = await stripe.confirmCardPayment(clientSecret, {
//       payment_method: {
//         card: elements.getElement(CardElement),
//       },
//     });

//     if (result.error) {
//       setError(result.error.message);
//     } else {
//       console.log('Payment successful:', result.paymentIntent);
//     }
//   };

//   return (
//     <form onSubmit={handleSubmit}>
//       <PaymentElement />
//       <button type="submit">Pay</button>
//       {error && <div>{error}</div>}
//     </form>
//   );
// };

// export default PaymentForm;
// frontend/src/PaymentForm.js
import React, { useState } from 'react';
import { useNavigate  } from 'react-router-dom';
import { useStripe, useElements, CardElement,PaymentElement } from '@stripe/react-stripe-js';
import axios from 'axios';
import { Modal,Button } from 'react-bootstrap'
import { useAuth } from '../auth.js'; // Import the useAuth hook
import API_BASE_URL from '../components/config';
import loading from '../icons/loading.gif';
import { loadStripe } from '@stripe/stripe-js';
const PaymentForm =({ email, password,phone,plan })  => {
  
  const { login } = useAuth(); // Access the login function from the authentication context
  const stripePromise = loadStripe('pk_live_51NcEOuHYDnVieE8qBDQDi9KA2qOYbL12Tc5FsfBmhdFaBVv6Iw74JD41SbnN9K7QqvOGCVnBHDnm0Z5RPlBmAjXz00uGQnoN3U');
  // const stripePromise = loadStripe('pk_test_51NcEOuHYDnVieE8qBKcvklXl3MmaICIcZjb5gJsofNoFI0YLRYvIQImnR3b5z6WH46ur0xU9SV8cSYqYj7kNG6eK00QNpNU7Gl');

 
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const stripe = useStripe("pk_live_51NcEOuHYDnVieE8qBDQDi9KA2qOYbL12Tc5FsfBmhdFaBVv6Iw74JD41SbnN9K7QqvOGCVnBHDnm0Z5RPlBmAjXz00uGQnoN3U");
  // const stripe = useStripe("pk_test_51NcEOuHYDnVieE8qBKcvklXl3MmaICIcZjb5gJsofNoFI0YLRYvIQImnR3b5z6WH46ur0xU9SV8cSYqYj7kNG6eK00QNpNU7Gl");
  
  const elements = useElements();
  const [paymentError, setPaymentError] = useState(false);
  const [paymentSuccess, setPaymentSuccess] = useState(null);
  const [processing, setProcessing] = useState(false);
  const [firstName, setFirstName] = useState('');
  const [lastName, setLastName] = useState('');
  const [addressLine1, setAddressLine1] = useState('');
  const [addressLine2, setAddressLine2] = useState('');
  const [city, setCity] = useState('');
  const [province, setProvince] = useState('');
  const [postalCode, setPostalCode] = useState('');
  const [country, setCountry] = useState('');
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility

  const handleModalOpen = () => setShowModal(true); // Function to open the modal
  const handleModalClose = () => setShowModal(false); // Function to close the modal

  const handleSubmit = async (event) => {
    event.preventDefault();
    const cardElement = elements.getElement(CardElement);
    try {
      setPaymentError(false);
      const {  paymentMethod } = await stripe.createPaymentMethod({
        type: 'card',
        card: elements.getElement(CardElement),
      });
  
      setProcessing(true);
      const response = await axios.post(`${API_BASE_URL}/api/create-subscription`, {
        plan,
        amount: 30, 
        currency: 'usd',
        paymentMethodId: paymentMethod.id,
        email,
        password,
        phone,
        firstName,
        lastName,
        addressLine1,
        addressLine2,
        city,
        province,
        postalCode,
        country,
      });
      if (response.data != "0"){
        sessionStorage.setItem('login', response.data.user_id);
        
        setPaymentError(false)
        setPaymentSuccess(true)
        navigate('/')
      }else{
        setPaymentError(true)
        setPaymentSuccess(false)
      }
      // console.log(response.data);
      // const { paymentIntentId, clientSecret } = response.data;
      // alert(clientSecret)
    //   const { paymentIntent, error } = await stripe.confirmCardPayment(clientSecret, {
    //     payment_method: {
    //         card: cardElement, 
    //     }
    // });
    //   if (error) {
    //     // alert("error")
    //     console.log(error)
    //     setPaymentSuccess(false)

    //     setProcessing(true); // Set processing state back to false
    //     setPaymentError(true)
    //       // Handle error here
    //   } else if (paymentIntent && paymentIntent.status === 'succeeded') {
    //     alert("scuess")
    //     setPaymentError(false)
    //     setPaymentSuccess(true)

    //     // console.log(paymentIntent)
    //   }
    }  catch (error) {
      setPaymentError(true)

      console.error('Error:', error);
  } finally {
      setProcessing(false); // Set processing state back to false
      

  }
        
    

  };

  return (
    <div>
     <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
            <h4>CREATE LIST</h4>
        </div>
        <div className="modal-body ">
            <form>
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label mb-2">List Name:</label>
                <input type="text" className="form-control" id="recipient-name"  />
              </div>
              {/* <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Type:</label>
                <input type="text" name="type" className="form-control" value="Local" id="recipient-name"  readOnly />

              </div>
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Area Code:</label>
                <input type="text" className="form-control" id="recipient-name"  />

              </div> */}
            </form>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="button" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer>
      </Modal>
      <form onSubmit={handleSubmit}>
      <label id='color-loginform' className='ml-3' htmlFor="card-details" style={{marginLeft: '9px !important'}}>Card Details:</label>

      <div className="card-element-container">
      <div id="card-details">
        <CardElement stripe={stripePromise} required/>
      </div>
    

     
    </div>
       
    <div className='container mt-3'>
      <div className='row'>
          <div className='col-md-6'>
              <label id="color-loginform" htmlFor="firstName">First Name:</label>
              <input type="text" placeholder='First Name' value={firstName} onChange={(e) => setFirstName(e.target.value)}  id="email-pass-input" className="pay-form-fields" required />
          </div>
          <div className='col-md-6'>
              <label id="color-loginform" htmlFor="lastName">Last Name:</label>
              <input type="text"  placeholder='Last Name' value={lastName} onChange={(e) => setLastName(e.target.value)} id="email-pass-input" className="pay-form-fields" required />
          </div>
      </div>
      <div className='row'>
          <div className='col-md-12'>
              <label id="color-loginform" htmlFor="addressLine1">Address Line 1:</label>
              <br />
              <input type="text"  placeholder='Address Line 1' value={addressLine1} onChange={(e) => setAddressLine1(e.target.value)} id="email-pass-input" className="pay-form-fields" required />
          </div>
      </div>
      <div className='row'>
          <div className='col-md-12'>
              <label id="color-loginform" htmlFor="addressLine2">Address Line 2:</label>
              <br />
              <input type="text"  placeholder='Address Line 2 (optional)' value={addressLine2} onChange={(e) => setAddressLine2(e.target.value)} id="email-pass-input" className="pay-form-fields" name="addressLine2" />
          </div>
      </div>
      <div className='row'>
          <div className='col-md-12'>
              <label id="color-loginform" htmlFor="city">City:</label>
              <br />
              <input type="text"  placeholder='City' value={city} onChange={(e) => setCity(e.target.value)} id="email-pass-input" className="pay-form-fields"  required/>
          </div>
          <div className='col-md-6'>
              <label  id="color-loginform" htmlFor="province">State:</label>
              <br />
              <input type="text"  placeholder='State' value={province} onChange={(e) => setProvince(e.target.value)} id="email-pass-input" className="pay-form-fields" required />
          </div>
          <div className='col-md-6'>
              <label id="color-loginform" htmlFor="postalCode">Postal Code:</label>
              <br />
              <input type="number"  placeholder='Postal Code' value={postalCode} onChange={(e) => setPostalCode(e.target.value)}  id="email-pass-input" className="pay-form-fields" required/>
          </div>
      </div>
      
      <div className='row'>
          <div className='col-md-12'>
              <label id="color-loginform" htmlFor="country">Country:</label>
              <br />
              <select id="country"  placeholder='First Name' value={country} onChange={(e) => setCountry(e.target.value)} className='form-control' required >
                  <option value="USA">United States</option>
                  <option value="CAN">Canada</option>
                
                
              </select>
          </div>
      </div>
  </div>
  
<div class='row mt-3 p-2 baln-row'>
  <br></br>
        <div class='col-5 mt-2 fs-5'>
           <strong>Today's total :</strong>
           <br></br>
           <strong>Plan :</strong>
           
       
        </div>
        <div class='col-md-7  mt-2 fs-5' style={{textAlignLast: 'right'}}>
           <strong>&nbsp;&nbsp;&nbsp;&nbsp;$0 USD</strong>
           <br></br>
           <strong>&nbsp;&nbsp;&nbsp;&nbsp;{plan == 'business-pro' ? 'Business Pro': 'Business Starter'}</strong>
           {/* {plan ? 'Processing...' : 'Start 7 Day Free Trial'} */}
    <p className='text-center text-black' style={{ marginTop: '2px', fontSize: '14px',color:"black !important" }}>For buisnesses looking to scale without limits</p>

        </div>
        
        
        {paymentError && <p className="alert alert-danger mt-2" style={{color:'#fff'}}>Please check your card & try again</p> }
      {paymentSuccess && <p className="alert alert-success mt-3" style={{color:'#fff'}}>Subscribed Succesfully</p>}
      {!paymentSuccess && <button className='btn btn-primary ing-reg-btn payment-btn mt-3 mb-3' disabled={!stripe || processing}>
                    {processing ? 'Processing...' : 'Start 7 Day Free Trial'}
                </button>}
    <p className='text-center text-black' style={{ marginTop: '2px', fontSize: '14px',color:"black !important" }}>($0 due today then  {plan == 'business-pro' ? '$99': '$30'}/month after your free trial)</p>

    </div>

      </form>
  
    </div>
  );
};

export default PaymentForm;
