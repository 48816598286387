import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link,useNavigate,useLocation  } from 'react-router-dom';
import '../css/loginform.css';
import mailicon  from '../icons/email-icon.png';
import eye  from '../icons/eye.png';

import PhoneInput from "react-phone-input-2";
import passwordpng from '../icons/pass.png';
import API_BASE_URL from '../components/config';
import tickicon  from '../icons/green.png';
import { Elements } from '@stripe/react-stripe-js';
import { useParams } from 'react-router-dom';
import { toast,ToastContainer } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { loadStripe } from '@stripe/stripe-js';
import 'react-phone-input-2/lib/plain.css';
import Paymentpage from './paymentpage.js';
// const stripePromise = loadStripe('pk_test_51NcEOuHYDnVieE8qBKcvklXl3MmaICIcZjb5gJsofNoFI0YLRYvIQImnR3b5z6WH46ur0xU9SV8cSYqYj7kNG6eK00QNpNU7Gl');
const stripePromise = loadStripe('pk_live_51NcEOuHYDnVieE8qBDQDi9KA2qOYbL12Tc5FsfBmhdFaBVv6Iw74JD41SbnN9K7QqvOGCVnBHDnm0Z5RPlBmAjXz00uGQnoN3U');


// import { useAuth } from '../components/AuthContext';
function Codepage() {
    // const [countries, setCountries] = useState([]);
  const [phone, setPhone] = useState('');
  const [code, setCode] = useState('');
  const [numcode, setnumCode] = useState('');
  const [processing, setProcessing] = useState(false);
  const [passwordpage, setPasswordpage] = useState(false);
  const [paymentpage, setPaymentpage] = useState(false);
  const [selectedplan, setSelectedPlan] = useState('');

  const [planpage, setPlanpage] = useState(false);
  const [coderesent, setCoderesent] = useState(false);
  const [googlelogin, setGooglelogin] = useState(false);
  const [signupfromphone, setSignedupphone] = useState(false);
  const location = useLocation();
  const searchParams = new URLSearchParams(location.search);
  const email = searchParams.get('email');
  const pass = searchParams.get('pass');
  const phones = searchParams.get('phone');
  const userplan = searchParams.get('plan');
  // const { pass,phones  } = useParams();
  const [error, setError] = useState('');
  const [emailverified, setEmail] = useState('');
  const [numberverify, setNumber] = useState('');
  
  const countries = [
    { name: 'United States', dialCode: '1', code: 'US', flag: '🇺🇸' },
    { name: 'Canada', dialCode: '1', code: 'CA', flag: '🇨🇦' }
  ];
  
  useEffect(() => {

    toast.success("sign_up_complete");
   window.postMessage('sign_up_complete'); 
    
    if(pass || phones || userplan){
      
      setPhone(phones)
      setSelectedPlan(userplan)
      setPasswords({ password: pass})

      setPaymentpage(true)
      setPlanpage(false)
      // alert("founded")
    }
  
  document.body.style.backgroundColor = 'white'; // White background for login page
}, []);
  // useEffect(() => {
  //   const fetchCountries = async () => {
  //     try {
  //       const response = await axios.get('https://restcountries.com/v3.1/all');
  //       const filteredCountries = response.data.filter(country => {
  //         return country.name.common === 'United States' || country.name.common === 'Canada';
  //       });
  //     setCountries(filteredCountries);
  //     console.log(filteredCountries)
  //     } catch (error) {
  //       setError('Failed to fetch countries');
  //     }
  //   };

  //   fetchCountries();
  // }, []);
  const navigate = useNavigate();
 
  
  // http://localhost:3000/signup/code?email=abul84hjhi776%40gmail.com?pass=asdasdasd?phone=534532434
  const [disableResend, setDisableResend] = useState(true);
  const [countdown, setCountdown] = useState(30);
  const [authenticated, setauthenticated] = useState(localStorage.getItem(localStorage.getItem("authenticated")|| false));

  const [successMessage, setSuccessMessage] = useState('');
  const [passwords, setPasswords] = useState({ password: '', confirmPassword: '' });
  const [passerror, setpassError] = useState('');
  // const [isSubmitEnabled, setIsSubmitEnabled] = useState(false);

  const handlePassChange = (e) => {
    const { name, value } = e.target;
    
    setPasswords(prevPasswords => ({
      ...prevPasswords,
      [name]: value
    }));
   

  };
  const handlePassSubmit = (e) => {
    e.preventDefault();
    if (passwords.password !== passwords.confirmPassword) {
      setpassError('Password do not match');
    // } else if (passwords.password.length <= 6 && passwords.confirmPassword.length <= 6) {
    //   setpassError('Passwords Must be');

    
  }else {
    // setPaymentpage(true)
    setPlanpage(true)
    setpassError('');

      
    }
  };

  
  const handlenumSubmit = async (e) => {
    setProcessing(true)

    e.preventDefault();
    setNumber(true)
    setDisableResend(true);
    if (disableResend) {
        setCountdown(30); // Reset countdown to 30 before starting the interval
        let interval = setInterval(() => {
          setCountdown((prevCountdown) => {
            if (prevCountdown === 1) {
              clearInterval(interval);
              setDisableResend(false);
              return 30;
            }
            return prevCountdown - 1;
          });
        }, 1000);
      }
      try {
        // const verificationCode = Math.floor(100000 + Math.random() * 900000);

        // Send the email and verification code to the backend
        const response = await axios.post(`${API_BASE_URL}/api/number-verfication`, { phone });
  
        // alert(response.data)
            if (response.data === 1) {
        
              setEmail(true);
              
              setProcessing(false)
      
            } else  if (response.data === 0){
              setError("Code Didn't Matched  ")
              setProcessing(false)
      
            }else{
              setError("Please Enter Correct Code  ")
              setProcessing(false)


            }
          } catch (error) {
            setError("Please Enter Correct Code  ")
            setProcessing(false)
      
          }
  }
  const handleResendClick = async (e) => {
    setCoderesent(false);

    setDisableResend(true);
   
    setCountdown(30); 
    let interval = setInterval(() => {
        setCountdown((prevCountdown) => {
        if (prevCountdown === 1) {
            clearInterval(interval);
            setDisableResend(false);
            return 5;
        }
        return prevCountdown - 1;
        });
    }, 1000);
    try {

      // Send the email and verification code to the backend
      const response = await axios.post(`${API_BASE_URL}/api/number-verfication`, { phone, numcode });

      // alert(response.data)
          if (response.data === 1) {
      
            setCoderesent(true);
            
            setProcessing(false)
    
          } 
        } catch (error) {
          setError("Please Enter Correct Code  ")
          setProcessing(false)
    
        }
  };
  
  const verifynumbercode = async (e) => {
    setCoderesent(false)
    setProcessing(true)
    e.preventDefault();
    try {

  const response = await axios.post(`${API_BASE_URL}/api/number-code`, {phone,numcode});
  // alert(response.data)
      if (response.data === 1) {
  
        setPasswordpage(true)
        setProcessing(false)

      }else  if (response.data === 0){
        setError("Code Didn't Matched  ")
        setProcessing(false)

      }else{
        setError("Please Enter Correct Code  ")
        setProcessing(false)


      
    }

    } catch (error) {
      setError("Please Enter Correct Code  ")

      setProcessing(false)

    }
   
  };
  const handleplanchange= async (plan) => {
    setSelectedPlan(plan)
    setPaymentpage(true)
    setPlanpage(false)
    
  };
  const handleSubmit = async (e) => {
    setError("")

    setProcessing(true)
    e.preventDefault();
    try {

  const response = await axios.post(`${API_BASE_URL}/api/mail-verfication`, {code,email});
  // alert(response.data)
      if (response.data === 1) {
  
        setEmail(true);
        
        setProcessing(false)

      }else  if (response.data === 0){
        setError("Code Didn't Matched  ")
        setProcessing(false)

      }else{
        setError("Please Enter Correct Code  ")
        setProcessing(false)


      
    }

    } catch (error) {
      setError("Please Enter Correct Code  ")

      setProcessing(false)

    }
   
  };

  return (
    <section className="bg-white ">
      <ToastContainer />
  <div className="container">
    <div className="row justify-content-center">
    {!planpage && !paymentpage && ( <div className="col-12 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4">
        <div className='authentication-wrapper authentication-basic container-p-y'>
        <div className='authentication-inner py-1'>
        
        <div className="card   login-card" id="login-card">
        {!emailverified &&(<div className="card-body p-3 p-md-4 p-xl-5">
            <div className="text-center mb-3">
            <img src={mailicon} style={{width: '60%'}}></img>
            </div>
            <h4 class="mb-1 pt-2 " id="color-loginform">Check your email</h4>
            <p class="mb-4 " id="color-loginform">Enter to the 6-digit code we just sent to {email} to continue</p>
            <form onSubmit={handleSubmit}>
              <div className="row gy-2 overflow-hidden">
                
                <div className="col-12">

                  <div className="form-floating mb-3">
                    <input value={code} onChange={(e) => setCode(e.target.value)} type='text' className=" " name="code" id="email-pass-input" placeholder="6-digit code" required/>
                  </div>
                  {error && (<p className="text-danger mt-2">{error}</p>)}
                </div>
              
                <div className="col-12">
                  <div className="d-grid my-3">
                    <button className="btn btn-primary ing-reg-btn" type="submit"disabled={processing}>{processing ? 'Submitting...' : 'Submit'} </button>
                  </div>
                </div>
                <div className="col-12">
    <p className="m-0 text-secondary text-center">
        <Link to="/signup" className="link-primary text-decoration-none">Go Back</Link>
    </p>
</div>
              </div>
            </form>
          </div> 
  )}
          {emailverified && !numberverify  && (
  <div className="card-body p-3 p-md-4 p-xl-5">
    <div className="text-center mb-3">
      <div className="text-center" style={{ width: '60%', fontSize: '45px', marginLeft: '50px' }}>📱</div>
    </div>
    <h4 className="mb-1 pt-2" id="color-loginform">Verify you're real</h4>
    <p className="mb-4" id="color-loginform">Enter your mobile number to receive an SMS verification code. Carrier charges may apply</p>
    <form onSubmit={handlenumSubmit}>
      <div className="row gy-2 overflow-hidden">
        <div className="col-12">
          <label htmlFor="password" className="form-label" id="color-loginform">Mobile Phone</label>
          <div className="form-floating mb-3">
            {/* <PhoneInput
              enableAreaCodes={true}
              countries={countries}
              value={phone}
              onChange={setPhone}
              dropdownClass="phone-input-dropdown"
              inputProps={{
                id: "number-input"
              }}
            /> */}
            <PhoneInput
            required
            country={'us'}
            onlyCountries={['us']}
            defaultCountry="US"
            areaCodes={{us: ['+1']}}
        enableAreaCodes={false}
        value={phone}
        onChange={setPhone}
        autoFormat={true}
        countryCodeEditable={false}
        placeholder="Phone Number"
        dropdownClass="phone-input-dropdown"
        
        inputProps={{
          id: "number-input",
          required: true,
          autoFocus: true,
        }}
      >
        {/* {countries.map(country => (
          <option key={country.code} value={country.dialCode}>
            {country.flag} {country.name}
          </option> */}
        {/* ))} */}
      </PhoneInput>
          </div>
        </div>
        <div className="col-12">
          <div className="d-grid my-3">
            <button className="btn btn-primary ing-reg-btn" type="submit"disabled={processing}>{processing ? 'Sending Code...' : 'Get Code'}</button>
          </div>
        </div>
        <div className="col-12">
          <p className="m-0 text-secondary text-center">
            {/* <Link to="/signup" className="link-primary text-decoration-none">Go Back</Link> */}
          </p>
        </div>
      </div>
    </form>
  </div>
)}
        {numberverify && !passwordpage &&(
  <div className="card-body p-3 p-md-4 p-xl-5">
    <div className="text-center mb-3">
      <div className="text-center ml-2" style={{ width: '60%', fontSize: '45px', marginLeft: '55px' }}>🔢</div>
    </div>
    <h4 className="mb-1 pt-2" id="color-loginform">Enter the code</h4>
    <p className="mb-1" id="color-loginform">we just sent verification code to  </p>
    <p className="mb-2 text-center" id="color-loginform">+{phone} </p>
    <form onSubmit={verifynumbercode}>
      <div className="row gy-2 overflow-hidden">
        <div className="col-12">
          {/* <label htmlFor="password" className="form-label" id="color-loginform">Mobile Phone</label> */}
          <div className="form-floating mb-3">
          <input value={numcode} onChange={(e) => setnumCode(e.target.value)} type='text' className=" " name="password" id="email-pass-input" placeholder="6-digit code" required/>

           
          </div>
          {error && (<p className="text-danger mt-2">{error}</p>)}
        </div>
        <div className="col-12">
          <div className="d-grid my-3">
            <button className="btn btn-primary ing-reg-btn" type="submit">Verify</button>
          </div>
        </div>
        <div className="col-12">
          <p className="m-0 text-secondary text-center">
          {coderesent &&(<p id="color-loginform">Code resent succesfully !</p>)}
          <button type="button" onClick={handleResendClick} className="btn btn-default text-decoration-none" disabled={disableResend}>
  {disableResend ? `Resend code in ${countdown}s` : 'Resend code'}
</button>

          </p>
        </div>
      </div>
    </form>
  </div>
)}
   {passwordpage && !planpage &&!paymentpage && (
  <div className="card-body p-3 p-md-4 p-xl-5">
    <div className="text-center mb-3">
      <div className="text-center ml-2" style={{ width: '60%', fontSize: '45px', marginLeft: '55px' }}><img src={passwordpng} style={{width: '60%'}}></img></div>
    </div>
    <h4 className="mb-1 pt-2 mb-3" id="color-loginform text-center">Set your Password</h4>
    
    <form onSubmit={handlePassSubmit}>
      <div className="row gy-2 overflow-hidden">
        <div className="col-12">
          <div className="form-floating mb-3">
            <input 
              type='password' 
              name="password" 
              // className='form-control'
              placeholder="Your password" 
              value={passwords.password}
              id ="email-pass-input"
              onChange={handlePassChange}
              required
            />
            <label htmlFor="password-input">Password</label>
          </div>
        </div>
        <div className="col-12">
          <div className="form-floating mb-3">
            <input 
            id ="email-pass-input"
              type='password' 
              name="confirmPassword" 
              placeholder="Confirm password" 
              value={passwords.confirmPassword}
              onChange={handlePassChange}
              required
            />
            <label htmlFor="confirm-password-input">Confirm Password</label>
          </div>
        </div>
        {passerror && <div className=" text-danger">{passerror}</div>}
        <div className="col-12">
          <div className="d-grid my-3">
            <button 
              className="btn btn-primary ing-reg-btn" 
              type="submit" 
              
            >
              Submit
            </button>
          </div>
        </div>
      </div>
    </form>
  </div>
)}


        </div>
        </div>
        </div>
      </div>
    )}
    {planpage && !paymentpage &&  (
      <section className="bg-white ">
      <div className="container">
        <div className="row justify-content-center">
          <center><div className="col-12 mt-3 col-sm-10 col-md-8 col-lg-6 col-xl-5 col-xxl-4 text-center">
          <img src={eye} style={{width: '13%'}}></img>
          <h4 class="mb-1  " id="color-loginform">Choose your plan</h4>
                <p class="mb-3  " id="color-loginform">Choose a plan to start your <strong>7-day free trial</strong><br></br> you can change or cancel at any time.</p>
    </div></center>
    <div class="container center">
      <div class="row justify-content-center">
        
        <div class="col-md-4">
        <div class="card mb-4" id='plan-card'>
                    
            <div class="card-body text-black" style={{fontSize: '15px', color: 'black !important'}}>
                
                <h3 class="float-left ml-2">Business Starter</h3>
                
                <span id="color-loginform" style={{fontSize:'14px' ,textAlignlast: 'left',color:'black !Important'}} class="float-left ml-2">For businesses looking to prospect<br></br></span>
                
                <div id="color-loginform" style={{display:'flex'}}><h2    class="float-left ml-2 fs-3 mt-2 mb-2">
    $&nbsp;<span id="color-loginform" style={{fontsize: '40px', fontweight: '600'}}>30&nbsp;&nbsp;</span></h2>
    
    <small className='mt-2' style={{fontsize: '14px'}}>&nbsp;Per Month</small>
    </div>
    <button onClick={() => handleplanchange('business-starter')} className='btn btn-primary sel-bsn'>Select business</button>
                {/* <h2 class="float-left ml-2 fs-3 mt-3 mb-3">$&nbsp;<span style={{fontSize:'40px',fontWeight: '600'}}>30 <small style={{fontsize: '14px'}}>Per Month</small></span></h2> */}
                
                {/* <a href="https://billing.prophone.io/b/bIY7tH0JIf8ugMwbII" class="btn btn-lg  text-white mb-3 btn-grad">Start Your 7 Day Free Trial   <i style={{fontsize: '15px'}} class="fa fa-arrow-right ml-3 fs-2"></i></a> */}
                
                
                <ul class="list-unstyled  p-2 pb-0 " style={{fontsize:'12px',color:'black'}}>
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Unlimited Text (pay as you go )</span></div></li>
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Unlimited Voice (pay as you go )</span></div></li>
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Full CRM </span></div></li>
                    <li><div class="d-flex align-items-center "><img style={{width: '25px'}} src={tickicon} className=""></img><span>Webhook  Integerations</span></div></li>
                    <li><div class="d-flex align-items-center "><img style={{width: '25px'}} src={tickicon} className=""></img><span>Zapier  Integeration (Coming Soon)</span></div></li>

                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>ProDialer (Coming Soon)</span></div></li> */}
                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Follow Up Automation (Coming Soon)</span></div></li> */}
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>DNC Manager </span></div></li>
                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Full Control Sms Automation (Coming Soon)</span></div></li> */}
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Auto DNC</span></div></li>
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Quick Replies</span></div></li>
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Status Tracking</span></div></li>
                </ul>
                
                
                
            </div>
        </div>
          </div>
          <div class="col-md-4">
        <div class="card mb-4" id='plan-card'>
                    
            <div class="card-body text-black" style={{fontSize: '15px', color: 'black !important'}}>
                
                <h3 class="float-left ml-2">Business Pro</h3>
                
                <span id="color-loginform" style={{fontSize:'14px' ,textAlignlast: 'left',color:'black !Important'}} class="float-left ml-2">For businesses looking to scale<br></br> without limits</span>
                
                <div id="color-loginform" style={{display:'flex'}}><h2    class="float-left ml-2 fs-3 mt-2 mb-2">
    $&nbsp;<span id="color-loginform" style={{fontsize: '40px', fontweight: '600'}}>99&nbsp;&nbsp;</span></h2>
    
    <small className='mt-2' style={{fontsize: '14px'}}>&nbsp;Per Month</small>
    </div>
    <button onClick={() => handleplanchange('business-pro')} className='btn btn-primary sel-bsn'>Select business</button>
                {/* <h2 class="float-left ml-2 fs-3 mt-3 mb-3">$&nbsp;<span style={{fontSize:'40px',fontWeight: '600'}}>30 <small style={{fontsize: '14px'}}>Per Month</small></span></h2> */}
                
                {/* <a href="https://billing.prophone.io/b/bIY7tH0JIf8ugMwbII" class="btn btn-lg  text-white mb-3 btn-grad">Start Your 7 Day Free Trial   <i style={{fontsize: '15px'}} class="fa fa-arrow-right ml-3 fs-2"></i></a> */}
                
                
                <ul class="list-unstyled  p-2 pb-0 " style={{fontsize:'12px',color:'black'}}>
  
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Everything From Business Starter </span></div></li>
                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>ProDialer (Coming Soon)</span></div></li> */}
                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Follow Up Automation (Coming Soon)</span></div></li> */}
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Automated SMS Campaigns </span></div></li>
                    {/* <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>Full Control Sms Automation (Coming Soon)</span></div></li> */}
                    <li><div class="d-flex align-items-center mb-2"><img style={{width: '25px'}} src={tickicon} className=""></img><span>ProDialer  (Coming Soon)</span></div></li>
                    <li><div class="d-flex align-items-center "><img style={{width: '25px'}} src={tickicon} className=""></img><span>Follow Up System (Coming Soon)</span></div></li>
                    <li><div class="d-flex align-items-center "><img style={{width: '25px'}} src={tickicon} className=""></img><span>Pro Mode (Coming Soon)</span></div></li>
                </ul>
                
                
                
            </div>
        </div>
          </div>
        </div>
      </div>
    </div>
    
         
        </div>
      
    </section>
    )}
      {paymentpage && !planpage && ( 
        <Elements stripe={stripePromise}>
        
      
  <Paymentpage  email={email} password={passwords.password} phone={phone} plan={selectedplan}/>
  </Elements>
)}
    </div>
  </div>
</section>
  );
}

export default Codepage;
