import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';

import Sidebar from '../components/navbar';
import { Modal,Button } from 'react-bootstrap'
import add from '../icons/add.svg';
import upload from '../icons/upload.svg';
import webhookimage from '../icons/webhook_icon.svg';
import deletes from '../icons/delete.svg';
import telnyx from '../icons/telnyx.svg';
import API_BASE_URL from '../components/config';

import Menu from '../components/menu';
function Integerations() {
    const [showModal, setShowModal] = useState(false); 

    const handleModalOpen = () => setShowModal(true);
    const handleModalClose = () => setShowModal(false); 
    const [shownameModal, setShownameModal] = useState(false); 

    const handlenameModalOpen = () => setShownameModal(true);
    const handlenameModalClose = () => setShownameModal(false);// Function to close the modal
    const [apikey, setApi] = useState('');
    const [publickey, setPrublickey] = useState('');
    const [webhookname, setWebhookname] = useState('');
    const [webhookurl, setWebhookurl] = useState('');
    const [webhooks, setWebhooks] = useState([]);
    const [numbers, setFetchednumbers] = useState([]);
    // const [profileid, setProfileid] = useState([]);
    const [formData, setFormData] = useState({});
    const [id, setUserid] = useState('');
    const [profileid, setProfileid] = useState('');
    const [processing, setProcessing] = useState(false);
    const [error, setError] = useState('');
    const [successmessage, setSuccessmessage] = useState('');
    const [webhooksucc, setWebhooksucc] = useState(false);
    
  const navigate = useNavigate();
  const [authenticated, setAuthenticated] = useState(null);
  const handleClose = () => {
    // Close modal logic here
  };
  const [nameInputs, setNameInputs] = useState([]);

  const handleInputChange = (index, value) => {
      const newInputs = [...nameInputs];
      newInputs[index] = value;
      setNameInputs(newInputs);
  };
  const submitAPI = (event) => {
        event.preventDefault();

        const payload = nameInputs.map((name, index) => ({
          name,
          number: numbers[index]
      }));

        
        axios.post(`${API_BASE_URL}/api/settenyxapinumber`, {
          userid:id,
          profileid,
          payload,
          apikey,publickey
        })
        .then(response => {
            // Handle success if needed
            alert("Api Connected Succesfuly")
            window.location.reload()
        })
        .catch(error => {
            // Handle error if needed
            console.error('Error:', error);
        });
      }
  const isValidUrl = (url) => {
    try {
      const parsedUrl = new URL(url);
      
     
      if (parsedUrl.protocol !== "http:" && parsedUrl.protocol !== "https:") {
        return false;
      }
      
     
      if (!parsedUrl.hostname.includes(".")) {
        return false;
      }
      
      return true;
    } catch (error) {
      return false;
    }
  };
  
  
  useEffect(() => {
    document.body.style.backgroundColor = "rgb(42,45,54)";
    const fetchUserData = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)
        try {
          const {data: { firstname, lastname,email,profile,apikey,profileid,publickey }} = await axios.post(`${API_BASE_URL}/api/userinfo`, {
            id
          });
          
          setApi(apikey)
          setProfileid(profileid)
          setPrublickey(publickey)
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchUserData(); 
  }, []);
  useEffect(() => {
    const fetchwebhooks = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-webhook`, {
            id
          });
          setWebhooks(response.data);
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchwebhooks(); 
  }, []);
  const submitwebhook = async (e) => {
    setError('');
  
    e.preventDefault();
  
    if (!isValidUrl(webhookurl)) {
      alert('Please enter a valid URL');
      return;
    }
  
    setProcessing(true);
  
    const id = sessionStorage.getItem("login");
    
    try {
      const response = await axios.post(`${API_BASE_URL}/api/add-webhook`, { id, webhookname, webhookurl });
  
      if (response.data) {
        setProcessing(false);
        setShowModal(false); 
        
        const newwebhook = {
          id: response.data,
          webhookname: webhookname, 
          webhookurl: webhookurl, 
        };
        
        const updatedWebhooks = webhooks ? [...webhooks, newwebhook] : [newwebhook];
        setWebhooks(updatedWebhooks);
      } 
    } catch (error) {
      setProcessing(false);
      // Handle error
    }
  };
  const handlepublicPaste = (e) => {
    const pastedValue = e.clipboardData.getData('Text');
    setPrublickey(pastedValue);
};
  const handlePaste = (e) => {
    const pastedValue = e.clipboardData.getData('Text');
    setApi(pastedValue);
};
  const handleSubmit = async (e) => {
    setError('')
    setSuccessmessage(false)

    setProcessing(true)
    e.preventDefault();
    
     
        try {

          
      const response = await axios.post(`${API_BASE_URL}/api/telnyx-api`, { id,apikey,publickey });
          // console.log(response.data[0])
          if (response.data.numbers ) {
            setFetchednumbers(response.data.numbers)
            setProfileid(response.data.profile_id)
            handlenameModalOpen()
            // setCodeSent(true);
            // setError('Profile is Already Created !')
            setProcessing(false)
            

          } else {
            setSuccessmessage(true)
            setProcessing(false)
            setProfileid(response.data.profileid)

          }
        } catch (error) {
          setError('Connection Error')
          setProcessing(false)


        }
      

       
    
  };
  const handleDelete = async (webhookId) => {
    setWebhooksucc(false)

   
    const confirmed = window.confirm('Are you sure you want to delete this webhook?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-webhook/${webhookId}`);
      
    
      setWebhooks(prevWebhooks => prevWebhooks.filter(webhook => webhook.id !== webhookId));
      setWebhooksucc(true)
    } catch (error) {
      setWebhooksucc(false)

      console.error('Error deleting webhook:', error);
    }
  };
  
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        
      <Sidebar>
    <Modal show={shownameModal} contentClassName="custom-modal-content" onHide={handlenameModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={submitAPI}>
        <Modal.Body>
        
        <div className="text-center">
            <h4>ASSIGN NAME TO NUMBERS</h4>
        </div>
        <div className="modal-body ">
            <div className="table-responsive">
              <table className="table">
                <thead>
                  <th>
                    Name
                  </th>
                  <th>
                    Number
                  </th>
                </thead>
                <tbody>
                {numbers.map((item, index) => (
                                    <tr key={index} className="mt-3 mb-3">
                                        <td style={{padding:'0px'}}>
                                            <input
                                                type="text"
                                                className="form-control mt-3 mb-2"
                                                style={{ width: '80%' }}
                                                placeholder="Number Name"
                                                required
                                                value={nameInputs[index] || ''}
                                                onChange={(e) => handleInputChange(index, e.target.value)}
                                            />
                                        </td>
                                        <td>{item}</td>
                                    </tr>
                                ))}
                  
                </tbody>
              </table>
            </div>
            
           
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlenameModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Saving...' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal>
    <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={submitwebhook}>
        <Modal.Body>
        
        <div className="text-center">
            <h4>ADD WEBHOOK</h4>
        </div>
        <div className="modal-body ">
            
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label">Webhook Name:</label>
                <input type="text" className="form-control" id="recipient-name" placeholder="Webhook Name" required value={webhookname} onChange={(e) => setWebhookname(e.target.value)}  />
              </div>
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">URL:</label>
                <textarea className="form-control" id="message-text"required value={webhookurl} onChange={(e) => setWebhookurl(e.target.value)} ></textarea>
              </div>
           
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Saving...' : 'Save'}</button>
        </Modal.Footer>
        </form>
      </Modal>
      <section className="">
  <div className="container" style={{marginLeft: '0px'}}>
    <div className="row ">
      <div className="col-3" style={{marginRight: '-60px'}}>
      <Menu/>

      </div>
      <div className="col-9" style={{marginLeft:'-2px'}}>
        {/* <div class="serch_bar full_width">
    <div class="tab">
        <div routerlinkactive="active" placement="bottom" show-delay="500" class="tab-item active">
            <a class="link disabled" href="/app/setting/profile">User Profile</a>
            <div class="strip"></div>
        </div>
    </div>
    </div> */}
    
    <div className="userEditForm mt-4">
        <h5>&nbsp;Webhooks</h5>
        {webhooksucc && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Webhook Deleted Successfuly !</div>}
        
    <div className="mt-4 mb-2" style={{textAlign:'right'}}>
          
          <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;ADD NEW WEBHOOK
          </button>
      </div>
      {webhooks.length === 0 && <div className="col-md-12 text-center">
      <div className="mb-2 ml-2" style={{color:'white',fontSize:'25px'}}>No Webhook Found</div>
            </div>}
            {webhooks.map(webhook => (
            <div className="row mb-3" key={webhook.id}>
              <div className="col-md-2 mt-1">
              <img src={webhookimage} style={{paddingLeft: '53px'}} />
              </div>
              <div className="col-md-3">
                <label className="mb-2 ml-2">Webhook Name</label>
                <input  value={webhook.webhookname} readOnly className="form-control" />
              </div>
              <div className="col-md-4">
                <label className="ml-3 mb-2">Webhook URL</label>
                <input  value={webhook.webhookurl} readOnly className="form-control from-control--email" />
              </div>
              <div className="col-md-1 mt-4">
              <img onClick={() => handleDelete(webhook.id)} src={deletes} className="mt-3"  style={{cursor: 'pointer'}} ></img>
                {/* <button onClick={() => handleDelete(webhook.id)} className="btn btn-danger mt-3">Delete</button> */}
              </div>
            </div>
          ))}
      {/* <form className="profile_form" >
       
        <div className="row" >
        
        <div className="col-md-2 ">
        <img src={webhook} style={{paddingLeft: '53px'}} />
        </div>
          <div className="col-md-3 form-group ">
            <label className="mb-2 ml-2">Webhook Name</label>
            <input type="text" formControlName="last_name"  id="app_setting_profile-profile-last-name" className="form-control"  />
          </div>
          <div className="col-md-4 form-group">
            <label className="ml-3 mb-2 ">Webhook URL</label>
            <input type="text" formControlName="email"  id="app_setting_profile-profile-email" className="form-control from-control--email"  />
          </div>
          <div className="col-md-1 form-group mt-4">
            <img src={deletes} className="mt-3"></img>
          </div>
          
        </div>
        <br></br>
        <div className="row" >
        
        <div className="col-md-2 " >
        <img src={webhook} alt="" style={{paddingLeft: '53px'}} />
        </div>
          <div className="col-md-3 ">
            <label className="mb-2 ml-2">Webhook Name</label>
            <input type="text" formControlName="last_name"  id="app_setting_profile-profile-last-name" className="form-control"  />
          </div>
          <div className="col-md-4">
            <label className="ml-3 mb-2">Webhook URL</label>
            <input type="text" formControlName="email"  id="app_setting_profile-profile-email" className="form-control from-control--email"  />
          </div>
          <div className="col-md-1  mt-4">
            <span></span>
            <img src={deletes} className="mt-3"></img>
          </div>
          
        </div>
        <br></br>
        
      </form> */}
    </div>
    <div className="userPasswordChange mt-5" style={{height:'auto'}}>
      
        <h5 className="mb-1">Telnyx API</h5>
        {error && (<p className=" text-danger mb-1" >{error}</p>)}
        {successmessage && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Api Connected Succesfuly</div>}
        
        <p style={{color: '#cfd3ecc9'}}> Create your Free Telnyx Account by visiting <a href="https://telnyx.com/sign-up" style={{color: '#fff',
    fontWeight: '600'}} target="blank">https://telnyx.com/sign-up</a> </p>
        <div className="row">
  <div className="col-lg-7">
    <form onSubmit={handleSubmit}>
      {/* <input type="hidden" name="userid" value="1" />
      <input type="hidden"  name="_token" value="XoH6mNzySnJZXRzwk4jufQditCJaavRB5z7xQf2s" />
      <input type="hidden" name="id" value="1" /> */}
      <div className="row mb-3">
        <label className="col-sm-2 col-form-label" htmlFor="basic-default-name">API Key</label>
        <div className="col-sm-10">
          <input type="text"  value={apikey} onChange={(e) => setApi(e.target.value)} placeholder="API Key" required className="form-control" id="basic-default-name" />
        </div>
      </div>
      <div className="row mb-3">
        <label className="col-sm-2 col-form-label" htmlFor="basic-default-company">Public Key</label>
        <div className="col-sm-10">
          <input type="text"  placeholder="Public Key" required value={publickey} onChange={(e) => setPrublickey(e.target.value)}  className="form-control" name="public_key" id="basic-default-company" />
        </div>
      </div>
      {profileid && (<div className="row mb-3">
        <label className="col-sm-2 col-form-label" htmlFor="basic-default-profile">Profile ID</label>
        <div className="col-sm-10">
          <div className="input-group input-group-merge">
            <input type="text" readOnly placeholder="Profile ID"  value={profileid} onChange={(e) => setProfileid(e.target.value)}   className="form-control" id="basic-default-profile" />
          </div>
          {/* <span><b>Webhook URL: </b>https://app.prophone.io/api/SmsInbound</span> */}
        </div>
      </div>
      )}
      <div className="row mt-3">
        <div className="col-sm-10" style={{marginLeft: '11%'}}>
          <button type="submit" className="btn btn-primary save_web_btn"disabled={processing}>{processing ? 'Saving...' : 'Save Keys'}</button>
        </div>
      </div>
    </form>
  </div>
  <div className="col-lg-5" style={{marginLeft: '-11% '}}>
    <iframe width="400" height="259" src="https://www.youtube.com/embed/iSYeb4e-e18?si=jnZWJquIOKcHUS7L" title="YouTube video player" frameBorder="0" allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share" allowFullScreen=""></iframe>
  </div>
</div>

      
    </div>
    </div>
      
  </div>
  </div>
</section>
      {/* Additional content for Page 1 */}
    </Sidebar>
    );
  }
}

export default Integerations;
