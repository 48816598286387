import React, { useState } from 'react';
import { BrowserRouter as Router,Routes, Route, useNavigate,useLocation  } from 'react-router-dom';
import Dashboard from './pages/dashboard';
import LoginForm from './pages/LoginForm';
import RegisterForm from './pages/RegisterForm';
import Settings from './pages/settings';
import Numbers from './pages/numbers';
import Integerations from './pages/integerations';
import Autodnc from './pages/autodnc';
import Support from './pages/help_support';
import Privacy from './pages/privacy';
import Terms from './pages/terms';
import Datamanage from './pages/data';
import Leadstatus from './pages/lead-status';
import Exupload from './pages/excel_upload';
import Tags from './pages/tags';
import Crm from './pages/crm';
import Tickets from './pages/tickets';
import Phone from './pages/phone';
import Quickreply from './pages/reply';
import Smstempalte from './pages/smstemplate';
import Billing from './pages/billing';
import UserBilling from './pages/user_billing';
import Createtemplate from './pages/createsmstemplate';
import Smssettings from './pages/smssettings';
import Codepage from './pages/codepage';
import Plans from './pages/plan.js';
import Thankyou from './pages/thank.js';
import Paymentpage from './pages/paymentpage.js';
import Campaign from './pages/campaign.js';
import Smscampagintemplate from './pages/smscamtemplate.js';
import Audience from './pages/audience.js';
import ViewAudience from './pages/view_audience.js';
import CreateAudience from './pages/create_audience.js';
import CreateCampaign from './pages/create_campaign.js';
import Users from './pages/users.js';
import AllTickets from './pages/alltickets.js';
import ForgetForm from './pages/forgetpassword.js';

import { TransitionGroup, CSSTransition } from 'react-transition-group';


import './index.css';
import SupportCenter from './pages/support.js';
import Addcontact from './pages/add_contact.js';
import EditTemplate from './pages/editsmstemplate.js';
import ViewCampaign from './pages/view_campaign.js';
const BodyBackgroundSetter = () => {
  const location = useLocation();

  // Determine which class to apply based on the current path
  const bodyClassName = location.pathname === '/login' ? 'bg-white' : 'bg-black';

  // Apply the class to the body element
  React.useEffect(() => {
    document.body.className = bodyClassName;
    return () => {
      document.body.className = ''; // Reset body class on component unmount
    };
  }, [bodyClassName]);

  return null; // This component does not render anything to the DOM
};

function App() {
  const [isLoginPage, setIsLoginPage] = useState(false);

  // const location = useLocation();

  // // Determine which class to apply based on the current path
  // const bodyClassName = location.pathname === '/login' ? 'bg-white' : 'bg-black';

  const toggleLoginPage = (value) => {
    setIsLoginPage(value);
  };

  // if (isLoginPage) {
  //   document.body.style.backgroundColor = 'orange'; // White background for login page
  // } else {
  //   document.body.style.backgroundColor = 'red'; // Black background for other pages
  // }
  return (
    <Router>
      
        <Routes>
        <Route
        path="/users"
        element={
          <TransitionGroup>
            <CSSTransition key="users" timeout={100} classNames="fade">
              <Users />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route
        path="/create_campaign"
        element={
          <TransitionGroup>
            <CSSTransition key="create_campaign" timeout={100} classNames="fade">
              <CreateCampaign />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route
        path="/terms"
        element={
          <TransitionGroup>
            <CSSTransition key="terms" timeout={100} classNames="fade">
              <Terms />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route
        path="/privacy_policy"
        element={
          <TransitionGroup>
            <CSSTransition key="privacy_policy" timeout={100} classNames="fade">
              <Privacy />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route
        path="/create_audience"
        element={
          <TransitionGroup>
            <CSSTransition key="create_audience" timeout={100} classNames="fade">
              <CreateAudience />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route
        path="/view_campaign/:campid"
        element={
          <TransitionGroup>
            <CSSTransition key="view_campaign" timeout={100} classNames="fade">
              <ViewCampaign />
            </CSSTransition>
          </TransitionGroup>
        }/>
         <Route
        path="/edit_template/:templateid"
        element={
          <TransitionGroup>
            <CSSTransition key="edit_template" timeout={100} classNames="fade">
              <EditTemplate />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route
        path="/view_audience/:audienceid"
        element={
          <TransitionGroup>
            <CSSTransition key="view_audience" timeout={100} classNames="fade">
              <ViewAudience />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route
        path="/audience"
        element={
          <TransitionGroup>
            <CSSTransition key="Audience" timeout={100} classNames="fade">
              <Audience />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route
        path="/sms_cam_templates"
        element={
          <TransitionGroup>
            <CSSTransition key="sms_cam_templates" timeout={100} classNames="fade">
              <Smscampagintemplate />
            </CSSTransition>
          </TransitionGroup>
        }/>
          <Route
        path="/"
        element={
          <TransitionGroup>
            <CSSTransition key="dashboard" timeout={100} classNames="fade">
              <Dashboard />
            </CSSTransition>
          </TransitionGroup>
        }/>
        
          <Route  path="/forgetpassword" element={<ForgetForm/>} />
          <Route  path="/login" element={<LoginForm/>} />
          <Route
        path="/campaign"
        element={
          <TransitionGroup>
            <CSSTransition key="campaign" timeout={100} classNames="fade">
              <Campaign />
            </CSSTransition>
          </TransitionGroup>
        }/>
          <Route toggleLoginPage={toggleLoginPage}
        path="/signup"
        element={
          <TransitionGroup>
            <CSSTransition key="signup" timeout={100} classNames="fade">
              <RegisterForm />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route toggleLoginPage={toggleLoginPage}
        path="/signup/code"
        element={
          <TransitionGroup>
            <CSSTransition key="code" timeout={100} classNames="fade" >
              <Codepage />
            </CSSTransition>
          </TransitionGroup>
        }
      />
          <Route
        path="/crm"
        element={
          <TransitionGroup>
            <CSSTransition key="crm" timeout={200} classNames="fade">
              <Crm />
            </CSSTransition>
          </TransitionGroup>
        }/>
        {/* settings path */}
          <Route
                  path="/numbers"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="numbers" timeout={200} classNames="fade">
                        <Numbers />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
          <Route
                  path="/integerations"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="integerations" timeout={200} classNames="fade">
                        <Integerations />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
          <Route
                  path="/support"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="support" timeout={200} classNames="fade">
                        <Support />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
           <Route
                  path="/data-management"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="data-management" timeout={200} classNames="fade">
                        <Datamanage />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
          <Route
                  path="/lead-status"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="lead-status" timeout={200} classNames="fade">
                        <Leadstatus />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
          <Route
                  path="/tags"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="tags" timeout={200} classNames="fade">
                        <Tags />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
                   <Route
                  path="/add_contact"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="add_contact" timeout={200} classNames="fade">
                        <Addcontact />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
          <Route
                  path="/file_upload"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="file_upload" timeout={200} classNames="fade">
                        <Exupload />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
          <Route path="/plans/:email" element={<Plans />} />
          <Route path="/thankyou" element={<Thankyou />} />
          <Route path="/payment/:email" element={<Paymentpage />} />
          <Route
                  path="/billing/:id"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="billing" timeout={200} classNames="fade">
                        <UserBilling />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
          <Route
                  path="/all_tickets/:ticketId"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="all_tickets" timeout={200} classNames="fade">
                        <AllTickets />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
          <Route
                  path="/view_ticket/:ticketId"
                  element={
                    <TransitionGroup>
                      <CSSTransition key="view_ticket" timeout={200} classNames="fade">
                        <Tickets />
                      </CSSTransition>
                    </TransitionGroup>
                  }/>
          <Route
        path="/settings"
        element={
          <TransitionGroup>
            <CSSTransition key="settings" timeout={200} classNames="fade">
              <Settings />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route
        path="/support-center"
        element={
          <TransitionGroup>
            <CSSTransition key="support-center" timeout={200} classNames="fade">
              <SupportCenter />
            </CSSTransition>
          </TransitionGroup>
        }/>
<Route
        path="/chats"
        element={
          <TransitionGroup>
            <CSSTransition key="chats" timeout={200} classNames="fade">
              <Phone />
            </CSSTransition>
          </TransitionGroup>
        }/>

          <Route
        path="/chats/:numberid"
        element={
          <TransitionGroup>
            <CSSTransition key="chats" timeout={200} classNames="fade">
              <Phone />
            </CSSTransition>
          </TransitionGroup>
        }/>
        <Route
        path="/auto_dnc"
        element={
          <TransitionGroup>
            <CSSTransition key="auto_dnc" timeout={200} classNames="fade">
              <Autodnc />
            </CSSTransition>
          </TransitionGroup>
        }
      />
          <Route
        path="/sms_settings"
        element={
          <TransitionGroup>
            <CSSTransition key="sms_settings" timeout={200} classNames="fade">
              <Smssettings />
            </CSSTransition>
          </TransitionGroup>
        }
      />
      <Route
        path="/billing"
        element={
          <TransitionGroup>
            <CSSTransition key="billing" timeout={200} classNames="fade">
              <Billing />
            </CSSTransition>
          </TransitionGroup>
        }
      />
       <Route
        path="/quick_reply"
        element={
          <TransitionGroup>
            <CSSTransition key="quick_reply" timeout={200} classNames="fade">
              <Quickreply />
            </CSSTransition>
          </TransitionGroup>
        }
      />
      <Route
        path="/sms_template"
        element={
          <TransitionGroup>
            <CSSTransition key="sms_template" timeout={200} classNames="fade">
              <Smscampagintemplate />
            </CSSTransition>
          </TransitionGroup>
        }
      />
       <Route
        path="/create_template"
        element={
          <TransitionGroup>
            <CSSTransition key="create_template" timeout={200} classNames="fade">
              <Createtemplate />
            </CSSTransition>
          </TransitionGroup>
        }
      />
      
          {/* <Route path="/sms_settings" element={<Smssettings />} /> */}
          {/* <Route component={NotFound} /> */}
        </Routes>
    
    </Router>
  );
}

export default App;
