import React, { useEffect, useState } from "react";
import { Link, useNavigate,useLocation } from 'react-router-dom';
import axios from 'axios';

import Sidebar from '../components/navbar';
import { FaBackward,FaReply,FaTags,FaBan,FaCommentDots,FaPaperclip,FaPaperPlane,FaPenSquare,FaSun,FaUser, FaHistory, FaThumbsDown, FaHome, FaFire, FaThermometerHalf, FaInbox, FaPhone, FaTimes } from 'react-icons/fa';

import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import '../css/ticket.css'
import API_BASE_URL from '../components/config';


function Tickets() {
  useEffect(() => {
    
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
  const navigate = useNavigate();
  const location = useLocation();
  const pathname = location.pathname;
  const ticketId = pathname.split('/').pop();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility
  
    const handleModalOpen = () => setShowModal(true); // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
  
  const [authenticated, setAuthenticated] = useState(null);
  // tickdata
  const [id, setUserid] = useState('');
  const [subject, setSubject] = useState('');
  const [message, setMessage] = useState('');
  const [messages, setMessages] = useState([]);
  
  const [ticketdate, setTicketdate] = useState('');

  const [reply, setReply] = useState('');
  const [ticketData, setTicketData] = useState({});
  const [profilePic, setProfilePic] = useState(null);
  const [showProfilePic, setShowProfilePic] = useState(null);

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    if (file) {
      const reader = new FileReader();
      reader.onloadend = () => {
        setProfilePic(file);
        setShowProfilePic(reader.result);
      };
      reader.readAsDataURL(file);
    }
  };
  useEffect(() => {
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)

        try {
          const response= await axios.post(`${API_BASE_URL}/api/get-ticket`, {
            id : ticketId
          });
          // setSubject(subject);
          // setMessage(message);
          // setTicketdate(date);
          // setMessages(messages);
          if (response.data.length > 0) {
            setTicketData(response.data[0]);
          }
          
         
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchreplies(); 
   
  }, []);
  const submitreply = async (id,ticketId,reply,event) => {
    // setProcessing(true)
    // setRepsucc(false)
    event.preventDefault();
    
    const formData = new FormData();
    formData.append('id', id);
    formData.append('ticketId', ticketId);
    formData.append('reply', reply);
    formData.append('from', 'user');

   
    if (!reply && !profilePic ){
      alert("Please Enter a Message")
      return;
    }
    if (profilePic) {
      formData.append('image', profilePic);
    }
    try {
      const response = await axios.post(`${API_BASE_URL}/api/ticket-reply`, formData, {
        headers: {
          'Content-Type': 'multipart/form-data'
        }
      });
      // const response = await axios.post('${API_BASE_URL}/api/ticket-reply', { 
      //   id:id,
      //   ticketId:ticketId,
      //   reply:reply,
       
      // });
      const newMessage = {
        from: 'user', 
        reply: reply,
        media: response.data.media,
        createdAt: new Date().toLocaleTimeString([], { hour: '2-digit', minute: '2-digit' }) + ' ' + (new Date().toLocaleDateString() === new Date().toLocaleDateString() ? 'Today' : new Date().toLocaleDateString())
    };

    setTicketData(tickets => ({
        ...tickets,
        messages: [...tickets.messages, newMessage]
    }));

    
    setReply('');
    setProfilePic('')
    setShowProfilePic('')
    } catch (error) {
      
    }
  };
  if (!authenticated) {
    
    return null;
  } else {
    return (
        <Sidebar>
   
       
        
        <div className="row ">
          <div className="col-3" style={{marginRight: '-60px'}}>
          <Menu/>
    
          </div>
          <div className="col-9" style={{marginLeft:'-2px'}}>
            {/* <div class="serch_bar full_width">
        <div class="tab">
            <div routerlinkactive="active" placement="bottom" show-delay="500" class="tab-item active">
                <a class="link disabled" href="/app/setting/profile">User Profile</a>
                <div class="strip"></div>
            </div>
        </div>
        </div> */}
        
        <div className="">
            
      <div class="col-12 chat">
      <div class="chat-header clearfix">
        {/* <img src={user1} className="rounded-circle mr-4" style={{ width: '50px', height: '50px' }}></img> */}
        {/* <img src="https://s3-us-west-2.amazonaws.com/s.cdpn.io/195612/chat_avatar_01_green.jpg" alt="avatar" /> */}
        <Link to="/support" className="mt-2 btn btn-primary" style={{float: 'inline-end'}}>Back <FaBackward ></FaBackward></Link>
        <div class="chat-about" >
          <div class="chat-with">Ticket ID # {ticketData.id}</div>
          <div class="chat-num-messages">{ticketData.date}</div>
          
        </div>
       
      </div>
     
      <div class="chat-history">
        <div class="ticket-view">
          <div className="mb-2">Ticket ID :<span id="color-loginform">{ticketData.id}</span> </div>
          <div className="mb-2">Subject   :<span id="color-loginform">{ticketData.subject}</span> </div>
          <div className="mb-2">Message   :<span id="color-loginform">{ticketData.message}</span> </div>
        </div>
        <ul>
        {ticketData.messages && ticketData.messages.map((msg, index) => (
          <li key={index} className={msg.from === 'user' ? 'clearfix user-msg' : 'clearfix admin-msg float-left'}>
         
            <div className="message-data" >
              <span className="message-data-time">{msg.from === 'admin' && 'Admin ' }{msg.createdAt}</span>
            </div>
            {/* {msg.from === 'user' &&<br></br>} */}
            <div className={`message ${msg.from === 'user' ? 'ticket-other-message float-right' : 'ticket-my-message'}`}>
              {msg.reply}
              <br></br>
              <br></br>
                {msg.media.length > 0 && (
                  <a href={msg.media} target="_blank">
                    <img
                    style={{ height: '100px', width: '100px' }}
                    src={msg.media}
                    
                  />
                  </a>
                )}
            </div>
          </li>
        ))}
      </ul>
      </div> 

      {/* <form  onSubmit={(event) => submitreply(id,ticketId,reply,event)}>
      <div class="msg-inputs">
      <label for="file-input" className="file-input-icon" style={{display:'flex'}}>
  
  <FaPaperclip></FaPaperclip>
</label>
<input type="file" id="file-input" style={{display: 'none'}}></input>
   
      <input class="fs-5 msg-input" type="text" rows="3" placeholder="Reply" name="message" id="message" required value={reply} onChange={(e) => setReply(e.target.value)} />
      <button class="send-btn" type="submit" name="send" id="send-btn" ><FaPaperPlane></FaPaperPlane></button>
     
</div>
</form> */}

  <form  onSubmit={(event) => submitreply(id,ticketId,reply,event)}>
         <div style={{display: 'flex',
    backgroundColor: '#26272d',
    paddingLeft: '20px'}}>
         {showProfilePic && (
       <>
        <div style={{ textAlign: 'center', marginTop: '10px' }}>
          <img src={showProfilePic}  style={{ width: '100px', height: '100px' }} />
        </div>
        <br></br>
        </>
      )}
         </div>
      <div className="msg-inputs">
       
      {ticketData.closed === 0 ? (
  <>
        <label htmlFor="file-input" className="file-input-icon" style={{ display: 'flex' }}>
          <FaPaperclip />
        </label>
        <input type="file" id="file-input" style={{ display: 'none' }} onChange={handleFileChange} accept="image/png, image/jpeg, image/jpg" />
      <input class="fs-5 msg-input" type="text" rows="3" placeholder="Reply" name="message" id="message"  value={reply} onChange={(e) => setReply(e.target.value)} />
        
        <button className="send-btn" type="submit" name="send" id="send-btn"><FaPaperPlane /></button>
      
      </>
    ):(
      
      <center className="text-center"><h4 className="text-white mt-3">Ticket Has Been Closed</h4></center>
    
    )}
    </div>
    </form>
  
      
    </div> 
        </div>
        
        </div>
          
      </div>
     
          {/* Additional content for Page 1 */}
        </Sidebar>
        );
      }
    
}

export default Tickets;
