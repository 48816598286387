import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import { FaChartLine,FaInfoCircle,FaChevronDown,FaUser,FaPlug,FaPhone,FaUserCircle, FaCog, FaSignOutAlt, FaUber, FaUsers } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import  Updtpayform from  '../components/uppayform';
import API_BASE_URL from '../components/config';
import Sidebar from '../components/navbar';
const stripePromise = loadStripe('pk_test_51NcEOuHYDnVieE8qBKcvklXl3MmaICIcZjb5gJsofNoFI0YLRYvIQImnR3b5z6WH46ur0xU9SV8cSYqYj7kNG6eK00QNpNU7Gl');

function Billing() {

  const navigate = useNavigate();
  
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility

    const handleModalOpen = () => setShowModal(true); // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
    const [updateshowModal, setupdateShowModal] = useState(false);
    const handleupdateModalOpen = () => setupdateShowModal(true); 
    const handleupdateModalClose = () => setupdateShowModal(false);
    const [plan, setSelectedPlan] = useState('');
    const [id, setUserid] = useState('');
    const [invoice, setInvoiceData] = useState([]);
    
  const [authenticated, setAuthenticated] = useState(null);
  const [nextinvoice, setnextInvoice] = useState('');
  const [currentplan, setUsercurrentplan] = useState('');
  const [cardlast, setCard] = useState('');
  const [expiry, setExp] = useState('');
  const [customerid, setCustomerid] = useState('');
  const [cancelbtn, setCancelsucc] = useState(false);
  const [suspendtime, setSuspendtime] = useState(false);
  const changeUserplan = async (event) => {
    event.preventDefault();
    // setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Change the Plan?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response= await axios.post(`${API_BASE_URL}/api/update-user-plan`, {
        customerid,
        plan
      });
      alert("Plan Has Been Updated")
      window.location.reload()
      

    } catch (error) {
      // setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const CancelSubcription = async (customerid) => {
    
    setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Cancel The Subscription?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response = await axios.delete(`${API_BASE_URL}/api/cancel-subscription/${customerid}`);
      
      if (response.data == 1){
        setCancelsucc(true)
      navigate("/login");
      }
      

    } catch (error) {
      setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  // const [selectedPlan, setSelectedPlan] = useState('');

  // useEffect(() => {
  //   // Set default plan if needed
  //   if (currentplan) {
  //     setSelectedPlan(currentplan);
  //   }
  // }, [currentplan]);

  const handlePlanChange = (e) => {
    setSelectedPlan(e.target.value);
    // Add any additional logic to handle plan change here
  };
  useEffect(() => {
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)

        try {
          const response= await axios.post(`${API_BASE_URL}/api/get-billing`, {
            id
          });
          if (response.data.suspendtime){
            setSuspendtime(response.data.suspendtime)
            return
          }
          setnextInvoice(response.data.nextinvoice
          );
          setCard(response.data.card
          );
          setExp(response.data.exp
          );
          setCustomerid(response.data.customer_id
          );
          setUsercurrentplan(response.data.plan
          );
          setInvoiceData(response.data.invoices
          );
          if (response.data.plan == "business-pro"){
            setSelectedPlan('business-starter')
          }else{
            setSelectedPlan('business-pro')

          }
          
          
         
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchreplies(); 
   
  }, []);
  const planNames = {
    'business-pro': 'Business Pro',
    'business-pro-legacy': 'Business Pro (Legacy)',
    'free': 'Free',
    'business-starter': 'Business Starter'
  };
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
      
        <Sidebar>
           <Modal show={updateshowModal} contentClassName="custom-modal-content" onHide={handleupdateModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => changeUserplan(event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>UPDATE PLAN</h4>
        </div>
        <div className="modal-body ">
        <div className="d-flex" style={{justifyContent: 'space-evenly'}}>
          <p className="mt-2">CHOOSE PLAN : </p>
          
        <select style={{width:'70%'}} 
        value={plan}
        onChange={handlePlanChange} 
        className="form-select custom-dorpdown-toglle mt-1" id="mySelect" >
        {currentplan !== 'business-pro' && (
      <option value="business-pro">Business Pro $99/month</option>
    )}
    
    {/* Only show "Business Starter" if the current plan is NOT "business-starter" */}
    {currentplan !== 'business-starter' && (
      <option value="business-starter">Business Starter $30/month</option>
    )}
            </select>

            
            </div>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <div style={{ display: 'flex' }}>
              <button type="button" className="btn btn-secondary cancel_btn" onClick={handleupdateModalClose} data-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary save_web_btn">Submit</button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
   <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
            <h4>Update Card</h4>
        </div>
        <div className="modal-body ">
        <Elements stripe={stripePromise}>
      <Updtpayform customerid={customerid}/>
    </Elements>
          </div>
        </Modal.Body>
        
      </Modal>
       
          <section className="">
          
      <div className="container" style={{marginLeft: '0px'}}>
        <div className="row ">
          <div className="col-3" style={{marginRight: '-60px'}}>
          <Menu/>
    
          </div>
          <div className="col-9" style={{marginLeft:'-2px'}}>
          {suspendtime && (
            <div className="userEditForm mt-4">
            <h5>&nbsp;Billing Sections</h5>
            <span>&nbsp;Subscription : <strong> Canceled </strong></span>
            <br></br>
            <span>&nbsp;Your Account Will Be Suspended On : <strong> {suspendtime} </strong></span>
            
            </div>
          )}
        {!suspendtime   && (
        <div className="userEditForm mt-4">
            <h5>&nbsp;Billing Sections</h5>
            <span>&nbsp;Current Plan : <strong>
 
    <span>
      {planNames[currentplan] || 'Business Starter'}
    </span>
  
</strong></span>
            <br></br>
            
            <span>&nbsp;Next Invoice : <strong>{nextinvoice}</strong></span>
            <br></br>

            <span>&nbsp;Payment Method : <strong>****{cardlast}</strong> <span>&nbsp;Expiry :</span> <strong>{expiry}</strong></span>
            <div className="mt-2 mb-2" style={{textAlign:'right'}}>
            
            <button type="button" className="btn btn-success save_btn" onClick={handleupdateModalOpen}  data-toggle="modal"   >Upgrade Plan</button>&nbsp;&nbsp;
            <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal"   >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Update Payment Method
          </button>&nbsp;&nbsp;&nbsp;
          <button type="button" className="btn  btn_primary save_btn " onClick={() => CancelSubcription(customerid)}style={{backgroundColor:'red',borderColor:'red'}}  
          
          disabled={cancelbtn}>{cancelbtn ? 'Cancelling....' : 'Cancel Subscription'}</button>
      </div>
          <div class="table-responsive  mt-3">
    <table class="table" >
      <thead>
        <tr>
          <th>Name</th>
          <th>Plan</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Date Created</th>
          
           
                
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
      {invoice.map((item) => (
          <tr key={item.id}>
            <td>{item.customer_name}</td>
            <td>{currentplan === 'business-pro'
    ? 'Business Pro'
    : currentplan === 'business-pro-legacy'
    ? 'Business Pro (Legacy)'
    : currentplan === 'free'
    ? 'Free'
    : currentplan === 'business-starter'
    ? 'Business Starter'
    : 'Business Starter'}</td>
            <td>{item.amount_due}</td>
            <td >
      {item.status === 'paid' && <p className="badge bg-success text-capitalize">{item.status}</p>}
      {item.status === 'pending' && <p className="badge bg-primary text-capitalize">{item.status}</p>}
    </td>
            <td>{item.date_created}</td>
            
            
            
          </tr>
        ))}
                
                  
      </tbody>
    </table>
   
  </div>
        </div>
  )}
        
        </div>
          
      </div>
      </div>
    </section>
    
        </Sidebar>
        );
      }
    
}

export default Billing;
