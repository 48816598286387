import React, { useState,useEffect } from 'react';
import axios from 'axios';
import { Link,useNavigate  } from 'react-router-dom';
import '../css/loginform.css';
import API_BASE_URL from '../components/config';
import logo from '../icons/logo.png';
import alerticon from '../icons/alert-icon.png';
import { GoogleLogin,useGoogleLogin } from '@react-oauth/google';
import {GoogleOAuthProvider} from '@react-oauth/google';
import { jwtDecode } from 'jwt-decode';
import FingerprintJS from '@fingerprintjs/fingerprintjs';
import { Modal,Button } from 'react-bootstrap'


function Terms() {
  const [processing, setProcessing] = useState(false);
  const [reason, setReason] = useState('');
   
  const [showModal, setShowModal] = useState(false);
  
  
 
  return (
   
    <section className="bg-white ">
 
  <div className="container">
    <div className="row justify-content-center text-center">
        
      <div className="col-12 col-sm-12 col-md-12 col-lg-12 col-xl-8 col-xxl-4 text-black" style={{textAlign:'-webkit-left',color:'black'}}>
   

      <>
  <p 
    style={{
      marginTop: "0pt",
      marginBottom: "8pt",
      textAlign: "center",
      lineHeight: "108%",
      fontSize: "24pt"
    }}
  >
    <strong  className="text-black mt-4 mb-4" >TERMS AND CONDITIONS</strong>
  </p>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    The following Terms of Service (the “Agreement”) are entered into by and
    between ProPhone Inc (“ProPhone”) and the person or entity on whose behalf
    the Services (as defined below) are used or accessed (the “Subscriber”)
    governing our platform services, including our programs, features, account
    portals, and technical support (the “Services”).&nbsp; If you do not agree
    to this Agreement, you are prohibited from using or accessing our Services.
    We may modify these terms at any time by posting the revised terms to our
    website. Your continued use of our Service means that you have accepted the
    changed terms.
  </p>
  <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt"
      }}
    >
      <strong>Grant of License</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Subject to the terms and conditions of this Agreement, ProPhone grants
    Subscriber a limited, revocable, non-exclusive, non-sublicensable,
    non-transferable, non-assignable license to access the Services for the Term
    of this Agreement.&nbsp; Subscriber’s license and use of the Services is
    permitted solely for the purposes stated in this Agreement and is subject to
    the restrictions set forth herein.&nbsp; Except for the foregoing limited
    license, Subscriber does not receive any right, title, or interest in or to
    the Services and Subscriber acknowledges that ProPhone retains all right,
    title, and interest in the Services including all intellectual property
    rights therein.
  </p>
  <ol  className="text-black" start={2} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt"
      }}
    >
      <strong>Limited Purposes</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Subscriber’s access and use of the Services is limited to identifying and
    communicating with leads regarding prospective transactions. Subscriber has
    no right to access, share, or otherwise use the Services for any other
    purpose.
  </p>
  <ol start={3} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt"
      }}
    >
      <strong>Access and Security</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    The Services may be accessed by Subscriber through its employees, agents, or
    representatives that are authorized by Subscriber.&nbsp; Subscriber shall
    ensure that only authorized individuals shall have access to the
    Services.&nbsp; Subscriber shall promptly inform ProPhone of any
    unauthorized access to the Services or the loss or theft of its access
    credentials.&nbsp; Subscriber is responsible for all access and use of the
    Services using Subscriber’s access credentials regardless of whether such
    access and use was authorized by Subscriber.&nbsp; Subscriber shall be
    responsible for, and liable to ProPhone, any security breaches by
    Subscriber, its employees, agents, or representatives.
  </p>
  <ol start={4} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt"
      }}
    >
      <strong>Restrictions</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Subscriber shall not: (i) modify or create any derivative works of the
    Services; (ii) modify the Services or reverse assemble, disassemble,
    decompile, engineer, or otherwise attempt to derive source code from the
    Services; (iii) redistribute, encumber, sell, rent, lease, sub-license,
    pledge, assign or otherwise transfer rights to the Services; (iv) remove or
    alter any trademark, logo, copyright or other proprietary notices, legends,
    symbols or labels in the Services; (v) access or use the Services for any
    unlawful or illegal purpose; or (vi) provide access to or use of the
    Services on any computer network or allow concurrent use thereof by more
    than one individual without the prior written permission of ProPhone.
  </p>
  <ol start={5} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt"
      }}
    >
      <strong>Compliance with Law</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Subscriber agrees to use the Services in accordance with all applicable
    federal, state, and local laws and regulations and industry standards.&nbsp;
    Without limiting the generality of the foregoing, Subscriber will use the
    Services in compliance with the Telephone Consumer Protection Act of 1991
    (“TCPA”) and all regulations implementing the TCPA, other teleservices laws
    and regulations, and privacy and data security laws and regulations.&nbsp;
    Subscriber bears sole responsibility for compliance with applicable laws and
    regulations and sole liability for any and all communications sent using the
    Services.
  </p>
  <ol start={6} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt"
      }}
    >
      <strong>Subscriber Data</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Subscriber provides to ProPhone a non-exclusive, royalty-free, worldwide,
    perpetual, irrevocable, transferrable, sublicensable license to use, copy,
    perform, reproduce, display, and distribute, including to create derivative
    works or incorporate into other works, all Subscriber-owned data provided by
    Subscriber or which Subscriber makes available to ProPhone in connection
    with Subscriber’s use of the Services (“Subscriber Data”) for the limited
    purpose of fulfilling ProPhone obligations under this Agreement, including
    without limitation conducting research, development, usage monitoring, and
    other day-to-day business activities.
  </p>
  <ol  className="text-black" start={7} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt"
      }}
    >
      <strong>Usage Data</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Subscriber acknowledges that ProPhone may obtain certain usage, technical,
    and statistical data regarding Subscriber’s use of the Services and that
    such usage, technical, and statistical data is the sole property of ProPhone
    and is not Subscriber Data. ProPhone may use and disclose usage, technical,
    and statistical data without restriction.
  </p>
  <ol  className="text-black" start={8} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt"
      }}
    >
      <strong>Payment</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Subscriber’s access to and use of the Services is contingent upon payment of
    all subscription and other fees as set forth by ProPhone.&nbsp; Subscriber’s
    failure to timely pay all amounts due shall be grounds for ProPhone, at its
    election, to limit or eliminate Subscriber’s access to the Services,
    including any information or data contained therein.&nbsp; All fees are due
    when charged by ProPhone and are non-refundable except as explicitly
    provided herein.&nbsp; ProPhone may increase subscription charges or other
    prices at any time, and will provide at least 30 days’ notice of any price
    increase.&nbsp; Subscriber is responsible for all taxes and governmental
    fees associated with Subscriber’s use of the Services.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "50.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt",
        fontWeight: "bold"
      }}
    >
      Auto Payment Terms and Cancellation
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Unless otherwise agreed to by ProPhone, Subscriber’s use of the Services
    will be subject to a monthly subscription that will automatically renew
    every month until Subscriber cancels its services. Monthly subscription fees
    are paid in advance and are non-refundable, even if Subscriber cancels
    mid-month. All monthly service fee payments are made in advance for the
    following month of Services. Subscriber must cancel at least five business
    days prior to the monthly renewal date to prevent charges for the following
    month of Services. Subscriber may cancel its subscription by accessing your
    account settings or email:{" "}
    <a href="mailto:support@prophone.io" style={{ textDecoration: "none" }}>
      <u>
        <span style={{ color: "#467886" }}>support@prophone.io</span>
      </u>
    </a>
  </p>
  <ol  className="text-black" start={9} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt"
      }}
    >
      <strong>Termination</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Without limiting its rights elsewhere in this Agreement, ProPhone may
    terminate this Agreement and Subscriber’s use of the Services at any time
    for convenience, with or without notice. In the event ProPhone terminates
    this Agreement without cause, ProPhone will provide Subscriber a pro rated
    refund of paid for but unused Services. In the event ProPhone terminates
    this Agreement for cause, including, without limitation, due to (i)
    Subscriber’s failure to timely pay all fees when due, (ii) Subscriber’s
    assignment for the benefit of its creditors, filing of a bankruptcy
    petition, filing of a bankruptcy petition against it, or other insolvency
    event, or (iii) Subscriber’s breach or likely breach of this Agreement, as
    determined by ProPhone in its sole discretion, ProPhone will not provide any
    refund.
  </p>
  <ol  className="text-black" start={10} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Indemnification</strong>
    </li>
  </ol>
  <p    className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Subscriber will defend (at ProPhone option), indemnify, and hold ProPhone
    and its affiliates, subsidiaries, successors, assignees, owners, directors,
    officers, employees, contractors, representatives, and agents (collectively,
    “ProPhone Indemnitees”) harmless from and against any and all claims,
    governmental investigations, demands, actions, and proceedings, real or
    threatened, and all losses, judgments, awards, settlements, damages, fines,
    injuries, penalties, and costs (including, without limitation, reasonable
    attorneys’ fees and expenses) arising out of or related to (i) any breach or
    alleged breach of this Agreement, including the representations and
    warranties contained herein, by Subscriber, (ii) Subscriber’s negligence or
    misconduct, or (iii) Subscriber’s use of the Services or information
    obtained therefrom (including without limitation Subscriber transmitting or
    receiving communications through the Service).&nbsp; If ProPhone elects for
    Subscriber to provide defense, Subscriber will (i) obtain legal counsel
    reasonably acceptable to ProPhone; (ii) permit ProPhone to participate in
    the defense using separate counsel at ProPhones’ cost; and (iii) not settle
    any action without the prior written consent of ProPhone (which may not be
    unreasonably withheld). The foregoing indemnification obligations represent
    the sole indemnification protections intended and the Parties waive all
    right to any other indemnification protections provided by common law,
    statute, or otherwise.
  </p>
  <ol start={11} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Warranty Disclaimer --------------------------</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    The parties acknowledge that ALL ASPECTS OF THE SERVICES, INCLUDING WITHOUT
    LIMITATION SOFTWARE, SERVER AND NETWORK COMPONENTS ARE PROVIDED ON AN “AS
    IS” AND “AS AVAILABLE” BASIS, WITHOUT ANY WARRANTIES OF ANY KIND TO THE
    FULLEST EXTENT PERMITTED BY LAW, AND PROPHONE EXPRESSLY DISCLAIMS ANY AND
    ALL WARRANTIES, WHETHER EXPRESS OR IMPLIED, INCLUDING, BUT NOT LIMITED TO,
    ANY IMPLIED WARRANTIES OF MERCHANTABILITY, TITLE, FITNESS FOR A PARTICULAR
    PURPOSE, AND NON-INFRINGEMENT.&nbsp; SUBSCRIBER ACKNOWLEDGES THAT PROPHONE
    DOES NOT WARRANT THAT ACCESS TO THE SERVICES WILL BE UNINTERRUPTED, TIMELY,
    SECURE, ERROR-FREE OR FREE FROM VIRUSES OR OTHER MALICIOUS SOFTWARE, AND NO
    INFORMATION OR ADVICE OBTAINED BY SUBSCRIBER FROM PROPHONE SHALL CREATE ANY
    WARRANTY NOT EXPRESSLY STATED IN THIS AGREEMENT.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol  className="text-black" start={12} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Limitation of Liability</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    IN NO EVENT SHALL PROPHONE BE LIABLE TO SUBSCRIBER FOR ANY DIRECT, INDIRECT,
    CONSEQUENTIAL, SPECIAL, INCIDENTAL, ACTUAL, PUNITIVE OR OTHER DAMAGES, OR
    FOR ANY LOST PROFITS OF ANY KIND OR NATURE WHATSOEVER, ARISING FROM OR IN
    CONNECTION WITH THE SERVICES.&nbsp; IF PROPHONE IS EVER DETERMINED TO HAVE
    ANY LIABILITY TO SUBSCRIBER ARISING DIRECTLY OR INDIRECTLY FROM OR IN
    CONNECTION WITH THE SERVICES OR THIS AGREEMENT, SUCH LIABILITY SHALL NOT
    EXCEED THE TOTAL AMOUNT OF FEES PAYABLE TO PROPHONE UNDER THIS AGREEMENT
    DURING THE CALENDAR QUARTER ENDING IMMEDIATELY PRIOR TO THE DATE THE CAUSE
    OF ACTION AROSE.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol  className="text-black" start={13} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Third Party Content</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    From time to time, ProPhone may offer third-party applications, APIs, and
    related services through or in connection with the Services.&nbsp;
    Subscriber acknowledges and understands that the use of such third-party
    applications, APIs, and related services may be subject to separate terms
    and conditions contained on the websites of, or as otherwise provided or
    made available by those third-party providers.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol start={14} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Third Party Data Sharing</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Form time to time, ProPhone may offer Subscriber the ability to link
    Subscriber’s Services account with a third-party service as a convenience to
    Subscriber. Subscriber agrees that such third-party service may share
    Subscriber’s data and personal information with ProPhone and ProPhone may
    use Subscriber’s access credentials for such third-party service to validate
    Subscriber’s right to link the third-party service account to the Services
    and to facilitate such data transfer.&nbsp; Subscriber acknowledges and
    agrees that ProPhone is not responsible for the third-party service, data or
    information provided by the third-party service to ProPhone, or Subscriber’s
    use of the third-party service alone or in connection with the Services.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol start={15} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Relationship of the Parties</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    The parties hereto are independent contractors.&nbsp; Neither party is an
    employee, agent, partner or joint venture of the other.&nbsp; Neither party
    shall have the right to bind the other to any agreement with a third party
    or to incur any obligation or liability on behalf of the other party.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol start={16} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Dispute Resolution</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Any dispute, controversy or claim arising out of, relating to, or in
    connection with this Agreement or any breach, termination or validity
    thereof (a “Dispute”) shall be solely and exclusively resolved by
    arbitration. The demand for arbitration shall be made within a reasonable
    time after the Dispute has arisen, but in no event shall it be made more
    than one year from when the aggrieved party knew or should have known of the
    controversy, claim, or facts forming the basis of the Dispute. The
    arbitration shall be initiated and conducted according to American
    Arbitration Association rules and procedures for commercial arbitration,
    including provisions for the resolution of consumer disputes, if applicable
    (the “Arbitration Rules”). The arbitration shall be conducted in Montgomery
    County, Tennessee before a single neutral arbitrator appointed in accordance
    with the Arbitration Rules. Either party may bring a Dispute in small claims
    court in Montgomery County, Tennessee to the extent permitted by the
    Arbitration Rules. If the amount in controversy is less than $10,000, the
    parties agree that the Dispute will be decided on the basis of written
    submissions without a hearing. The decision of the arbitrator will be final
    without option to appeal. To the fullest extent permitted by law, the
    arbitrator shall not have the power to award punitive, special,
    consequential, or indirect damages against any party. Arbitration costs and
    fees shall be divided in accordance with the Arbitration Rules. Each party
    shall be responsible for paying its own attorneys’ fees, costs, and
    expenses, regardless of which party prevails, but a party may recover any or
    all expenses from another party if the arbitrator, applying applicable law,
    so determines. No disputes may be arbitrated on a class or representative
    basis and the arbitrator may not consolidate or join the claims of other
    persons or parties who may be similarly situated. BY AGREEING TO THIS
    AGREEMENT, EACH PARTY IRREVOCABLY WAIVES ANY RIGHT IT MAY HAVE TO JOIN
    CLAIMS OR DISPUTES WITH THOSE OF OTHERS IN THE FORM OF A CLASS ACTION, CLASS
    ARBITRATION, OR SIMILAR PROCEDURAL DEVICE; AND WAIVES ANY RIGHT IT MAY HAVE
    TO PRESENT ITS CLAIM OR DISPUTE IN A COURT OF LAW OR BEFORE A JURY. Judgment
    on the award rendered by the arbitrator(s), if any, may be entered for
    enforcement purposes in any court having jurisdiction thereof.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol start={17} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Governing Law</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    This Agreement is governed according to the laws of the State of Tennessee,
    without regard to its conflicts of law principles. Subject to the dispute
    resolution process described above, all claims, disputes, and suits must be
    brought exclusively in the state or federal courts located in Montgomery
    County, Tennessee, and the parties agree to the jurisdiction thereof.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol start={18} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Export Laws and International Privacy</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Subscriber agrees to fully comply with all U.S. and other applicable export
    laws and regulations. Subscriber is not permitted to use the Services in
    connection with the processing of personal data of an EU, EEA, UK, or Swiss
    data subject or of any person located outside the United States of America.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol start={19} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Feedback and Trademarks</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    If Subscriber provide any ideas, suggestions, or recommendations regarding
    the Services (“Feedback”), ProPhone will be free to use, disclose,
    reproduce, license or otherwise distribute, and exploit such Feedback as it
    sees fit, entirely without obligation or restriction of any kind and without
    compensation or notice to Subscriber.&nbsp; By providing Feedback,
    Subscriber grants ProPhone a worldwide, perpetual, irrevocable,
    sublicensable, fully-paid and royalty-free license to use and exploit in any
    manner such Feedback. If Subscriber is using the Services in a business or
    for business purposes, it grants ProPhone a worldwide, perpetual,
    irrevocable, sublicensable, fully-paid and royalty-free license to use
    Subscriber’s trade name (and the corresponding trademark or logo) on
    ProPhones’ website and marketing materials to identify Subscriber as a
    current or former customer.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol start={20} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Severability and Survivability</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    If a court of competent jurisdiction holds any provision of this Agreement
    to be contrary to law or public policy or otherwise unenforceable, the
    remaining provisions shall remain in full force and effect; and the invalid
    provision shall remain in force as reformed by the court. Portions of this
    Agreement which by their nature would survive termination thereof (e.g.,
    disclaimer of warranties, limitation of liability, indemnification) shall be
    deemed to survive.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol start={21} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Waiver</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    No term or provision of this Agreement shall be deemed waived and no breach
    consented to or excused, unless such waiver, consent or excuse is in writing
    and signed by the party claiming to have waived, consented or excused.&nbsp;
    Should either party consent, waive or excuse a breach by the other party,
    such shall not constitute a consent to, waiver of, or excuse of any other
    different or subsequent breach whether or not of the same kind as the
    original breach.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol start={22} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>Miscellaneous</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Each party represents and warrants to the other party that such party has
    the legal power to enter into this Agreement, that the signatory hereto has
    the authority to bind the applicable party, and this Agreement will
    constitute a legal, valid, and binding obligation of each party in
    accordance with its terms. Except for the payment of fees by Subscriber, if
    either party is rendered unable, wholly or in part, to carry out its
    obligations here under due to a force majeure event (i.e., act of God,
    strike, industrial disturbance, fire, store, flood, epidemic/pandemic,
    utility failure, governmental restraint, war, or other similar event), such
    party’s obligations under this Agreement will be suspended during the force
    majeure event. Subscriber agrees that ambiguities in this Agreement will not
    be construed against ProPhone by attribution of drafting. ProPhone may
    assign any of its rights or obligations to others at any time without notice
    to Subscriber. Subscriber may not assign any of its rights or obligations to
    others without ProPhones’ prior written consent.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol  className="text-black" start={23} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li style={{ marginLeft: "36pt", marginBottom: "8pt" }}>
      <strong>ProPhone Messaging Terms &amp; Conditions</strong>
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Last Updated: March19, 2024
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <p   className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    These ProPhone Messaging Terms &amp; Conditions apply to your participation
    in the promotional text message program (the “Program”) provided by ProPhone
    Inc (“ProPhone”, “we”, “us”, “our”). They supplement our general Terms and
    Conditions which are incorporated be reference herein. Your participation in
    the Program is also subject to our Privacy Policy. We may update these Terms
    &amp; Conditions at any time by posting an updated version on this website.
    Any changes will be effective immediately upon posting.
  </p>
  <ol type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt",
        fontWeight: "bold"
      }}
    >
      Consent
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    By agreeing to participate in the Program, you agree to receive recurring
    automated promotional and personalized marketing text (e.g., SMS and MMS)
    messages from ProPhone, including text messages that may be sent using an
    automatic telephone dialing system, to the mobile telephone number you
    provided when signing up or any other number that you designate. Consent to
    receive automated marketing text messages is not a condition of any
    purchase. Msg &amp; Data rates may apply.
  </p>
  <p   className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Message frequency will vary. ProPhone reserves the right to alter the
    frequency of messages sent at any time, so as to increase or decrease the
    total number of sent messages. ProPhone also reserves the right to change
    the short code or phone number from which messages are sent and we will
    notify you when we do so.
  </p>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Not all mobile devices or handsets may be supported and our messages may not
    be deliverable in all areas. ProPhone, its service providers and the mobile
    carriers supported by the program are not liable for delayed or undelivered
    messages.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol  className="text-black" start={2} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt",
        fontWeight: "bold"
      }}
    >
      Cancellation
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Text the keyword STOP, END, CANCEL, UNSUBSCRIBE or QUIT to cancel. After
    texting STOP, END, CANCEL, UNSUBSCRIBE or QUIT you will receive one
    additional message confirming that your request has been processed. You
    acknowledge that our text message platform may not recognize and respond to
    unsubscribe requests that do not include the STOP, END, CANCEL, UNSUBSCRIBE
    or QUIT keyword commands and agree that ProPhone and its service providers
    will have no liability for failing to honor such requests.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol  className="text-black" start={3} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt",
        fontWeight: "bold"
      }}
    >
      Help
    </li>
  </ol>
  <p   className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    Text the keyword HELP to return customer care contact information.
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol   className="text-black" start={4} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt",
        fontWeight: "bold"
      }}
    >
      Customer Care
    </li>
  </ol>
  <p  className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    If you are experiencing any problems, please email support@prophone.io
  </p>
  <p style={{ marginTop: "0pt", marginBottom: "8pt" }}>&nbsp;</p>
  <ol start={5} type={1} style={{ margin: "0pt", paddingLeft: "0pt" }}>
    <li
      style={{
        marginLeft: "32.17pt",
        marginBottom: "8pt",
        paddingLeft: "3.83pt",
        fontWeight: "bold"
      }}
    >
      Contact
    </li>
  </ol>
  <p className="text-black" style={{ marginTop: "0pt", marginBottom: "8pt" }}>
    This message program is a service of ProPhone Inc located at 1306 Highgrove
    Ln Clarksville, TN 37043
  </p>
 
</>

      
         
      
      </div>
    </div>
  </div>
</section>
  );
}

export default Terms;
