import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import { useParams } from 'react-router-dom'; // If you're using React Router v6, use `useNavigate` instead
import DataTable from 'datatables.net';
import Sidebar from '../components/navbar';
import {  FaPlay,FaPause,FaReply } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import searchicon from '../icons/std.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';

import loading from '../icons/loading.gif';

import axios from 'axios';
function ViewAudience() {
  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
   
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false);
  const [id, setUserid] = useState('');
  const handleModalOpen = () => setShowModal(true); 
  const handleModalClose = () => setShowModal(false);
  const [audience, setAudiences] = useState([]);
  const [loadingchk, setLoading] = useState(false);
  const [audiencename, setAudiencename] = useState('');
  
  
  
  const [authenticated, setAuthenticated] = useState(null);
  const { audienceid } = useParams();


  useEffect(() => {
    setLoading(true)
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true);
        setUserid(id);
  
        try {
          const response = await axios.post(`${API_BASE_URL}/api/fetch-audience`, {
            audienceid:audienceid
          });
          setAudiencename(response.data.name)
          setAudiences(response.data.audiences);
    setLoading(false)

        } catch (error) {
          setLoading(false)

        }
      } else {
        navigate("/login");
      }
    };
  
    // Call fetchreplies only when the component mounts
    fetchreplies();
  
 
  }, []);
  useEffect(() => {
    if (audience.length > 0) {
      const newTableInstance = new DataTable('.crm_table', {
        stateSave: true,
        searching: true,
        bDestroy: true,
        initComplete: function () {
          const that = this;
          that.api().columns().every(function () {
            const column = this;
            // const footerCell = column.footer();
            // const select = footerCell.querySelector('select');

            
          });
        },
        columnDefs: [
        
        
          { orderable: false, targets: '_all' } // This line disables ordering for all columns
          
        ]

      });
      
      
      // setTableInstance(newTableInstance);
    }}, [audience]);
  const formatPhoneNumber = (phone) => {
    if (phone.startsWith("+1")) {
      return phone.substring(2).replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  } else {
      return phone.replace(/(\d{3})(\d{3})(\d{4})/, '($1) $2-$3');
  }
  };
  if (!authenticated) {
    return null;
  } else {
    return (
        <Sidebar>
            {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
          {/* <Modal show={showfilterModal} contentClassName="custom-modal-content" onHide={handlefilterModalClose} backdropClassName="custom-backdrop" >
   <form id="columnForm" >
        <Modal.Header closeButton>
        </Modal.Header>
        
        <Modal.Body>
       
      
        <div class="container " style={{fontSize: '15px'}}>
          <div class="row">
            <div  class="col-md-6 float-left" style={{textAlign:'left'}}>
              <span>Lists</span>
              <br></br>
              <br></br>
              <div ><span className="ball-icon">OK</span> <span >Included</span></div>
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div ><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" style={{ }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
            <div  class="col-md-6 float-right" style={{textAlign:'right'}}>
            <div style={{textAlign:'left'}}> <span >Tags</span></div>
          
              
              <br></br>
              <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Included</span></div>
              
              <br></br>
              <select id="mySelect" className="lst_tag mb-3" style={{  }}>
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            <br></br>
            <div style={{textAlign:'left'}}><span className="ball-icon">OK</span> <span >Excluded</span></div>
            <br></br>
              <select id="mySelect" className="lst_tag" >
                <option value=""> New</option>
                <option value="option2"> Select All</option>
                
            </select>
            </div>
          </div>
        </div>



       

             
         
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handlefilterModalClose} data-dismiss="modal" >Close</button>
            <button type="submit"  className="btn btn-primary save_web_btn" >Apply</button>
        </Modal.Footer>
        </form>
      </Modal> */}
 
       
          
        <div className="row ">
          
          <div className="col-12 crm_bd userEditForm " style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
          <div className="tab">
      <div className={`tab-item ${location.pathname === '/campaign' ? 'active' : ''}`}>
        <Link to="/campaign" className="link disabled">Campaign</Link>
        {location.pathname === '/campaign' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/audience' ? 'active' : ''}`}>
      <Link to="/audience"  className="link disabled">Audience</Link>
        {location.pathname === '/audience' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/sms_cam_templates' ? 'active' : ''}`}>
      <Link to="/sms_cam_templates"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_cam_templates' && <div className="strip"></div>}
      </div>
      {/* <div className={`tab-item ${location.pathname === '/create_template' ? 'active' : ''}`}>
      <Link to="/create_template"  className="link disabled">Create Template</Link>
        {location.pathname === '/create_template' && <div className="strip"></div>}
      </div> */}
    </div>
    </div>
       

            

    <h4 id="dash-num-color">Audience Name : {audiencename}</h4>

<div class="table-responsive text-nowrap mt-2">
    <table class="table crm_table" >
      <thead>
        <tr>
                  <th id="aud-tabl-ths" >First Name</th>
                  <th  id="aud-tabl-ths">Last Name</th>
                  <th id="aud-tabl-ths">Address</th>
                  <th id="aud-tabl-ths">City</th>
                  <th id="aud-tabl-ths">State</th>
                  
                  <th id="aud-tabl-ths">ZIP</th>

                  <th id="aud-tabl-ths">Numbers</th>
                  
        </tr>
      </thead>
      <tbody class="table-border-bottom-0" >
      {audience.map((item) => (
          <tr key={item.id}>
            <td >{item.first_name}</td>
            <td >{item.last_name}</td>
            <td >{item.property_address}</td>
            <td >{item.city}</td>
            <td >{item.state}</td>
            <td >{item.zip}</td>
            <td >
              {item.phones && item.phones.length > 0 ? (
                item.phones.map((phone, index) => (
                  <div key={index}>Phone {index + 1}: {formatPhoneNumber(phone)}</div>
                ))
              ) : (
                <div>No phone numbers available</div>
              )}
            </td>
            </tr>
            ))}
                   
                     
                  
                                        </tbody>
    </table>
    <div class="pagnination">
          
      </div>
  </div>


       
        </div>
        </div>
          
     
      
          
        </Sidebar>
        );
      }
    
}

export default ViewAudience;
