import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';

import Sidebar from '../components/navbar';
import { FaChartLine,FaInfoCircle,FaChevronDown,FaUser,FaPlug,FaPhone,FaUserCircle, FaCog, FaSignOutAlt, FaUber, FaUsers } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import { useParams } from 'react-router-dom';
import { useLocation } from 'react-router-dom';
import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import axios from 'axios';
import  Updtpayform from  '../components/uppayform';
import API_BASE_URL from '../components/config';

const stripePromise = loadStripe('pk_test_51NcEOuHYDnVieE8qBKcvklXl3MmaICIcZjb5gJsofNoFI0YLRYvIQImnR3b5z6WH46ur0xU9SV8cSYqYj7kNG6eK00QNpNU7Gl');

function UserBilling() {

  const navigate = useNavigate();
  
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility

    const handleModalOpen = () => setShowModal(true); // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
  
    const [uid, setUserid] = useState('');
    const [invoice, setInvoiceData] = useState([]);
  
  const [authenticated, setAuthenticated] = useState(null);
  
  const [reason, setReason] = useState('');
  const [customerid, setCustomerid] = useState('');
  const [cancelbtn, setCancelsucc] = useState(false);
  const { id  } = useParams();

  const suspendAccount = async (event) => {
    event.preventDefault();
    setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Suspend This Account?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response = await axios.delete(`${API_BASE_URL}/api/suspend-account/${id}/${reason}`);
      
      

    } catch (error) {
      setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  const CancelSubcription = async (customerid) => {
    
    setCancelsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to Cancel The Subscription?');
    if (!confirmed) {
      return; 
    }
  
    try {
      const response = await axios.delete(`${API_BASE_URL}/api/cancel-subscription/${customerid}`);
      
      if (response.data == 1){
        setCancelsucc(true)
      navigate("/login");
      }
      

    } catch (error) {
      setCancelsucc(false)

      // console.error('Error deleting webhook:', error);
    }
  };
  useEffect(() => {
    const fetchreplies = async () => {
      const userid = sessionStorage.getItem("login");
      
      if (userid == 1) {
        setAuthenticated(true)
        setUserid(id)

        try {
          const response= await axios.post(`${API_BASE_URL}/api/billing-data`, {
            id
          });
         
          setInvoiceData(response.data.invoices
          );
          
          
          
         
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchreplies(); 
   
  }, []);
  
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
      
        <Sidebar>
     <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => suspendAccount(event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>Suspension Reason</h4>
        </div>
        <div className="modal-body ">
        <textarea  value={reason} onChange={(e) => setReason(e.target.value)} className="p-3"  required style={{width: '100%',
    height: '100px'}}></textarea>
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <div style={{ display: 'flex' }}>
              <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal">Close</button>
              <button type="submit" className="btn btn-primary save_web_btn">Submit</button>
            </div>
          </Modal.Footer>
        </form>
      </Modal>
       
       
        <div className="row ">
          
          <div className="col-12" style={{marginLeft:'-2px'}}>
        
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;Billing Sections</h5>
            {/* <span>&nbsp;Current Plan : <strong>Buisness Pro</strong></span>
            <br></br>
            
            <span>&nbsp;Next Invoice : <strong>{nextinvoice}</strong></span>
            <br></br>

            <span>&nbsp;Payment Method : <strong>****{cardlast}</strong> <span>&nbsp;Expiry :</span> <strong>{expiry}</strong></span>
           */}
            <div className=" mb-2" style={{textAlign:'right'}}>
             
          {/* <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Update Payment Method
          </button>&nbsp;&nbsp;&nbsp;
          <button type="button" className="btn  btn_primary save_btn " onClick={() => CancelSubcription(customerid)}style={{backgroundColor:'red',borderColor:'red'}}  
          
          disabled={cancelbtn}>{cancelbtn ? 'Cancelling....' : 'Cancel Subscription'}</button> */}
      {/* <button type="button" className="btn  btn_primary save_btn " onClick={handleModalOpen}style={{backgroundColor:'red',borderColor:'red'}}  
          
          disabled={cancelbtn}>{cancelbtn ? 'Suspending....' : 'Suspend Account'}</button> */}
      </div>
          <div class="table-responsive  mt-3">
    <table class="table" >
      <thead>
        <tr>
          <th>Name</th>
          <th>Plan</th>
          <th>Amount</th>
          <th>Status</th>
          <th>Date Created</th>
          
           
                
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
      {invoice.map((item) => (
          <tr key={item.id}>
            <td>{item.customer_name}</td>
            <td>Buisness Pro</td>
            <td>{item.amount_due}</td>
            <td >
      {item.status === 'paid' && <p className="badge bg-success text-capitalize">{item.status}</p>}
      {item.status === 'pending' && <p className="badge bg-primary text-capitalize">{item.status}</p>}
    </td>
            <td>{item.date_created}</td>
            
            
            
          </tr>
        ))}
                
                  
      </tbody>
    </table>
   
  </div>
        </div>
        
        </div>
          
      </div>
  
    
        </Sidebar>
        );
      }
    
}

export default UserBilling;
