import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import axios from 'axios';
import API_BASE_URL from '../components/config';

import Sidebar from '../components/navbar';
import { FaChartLine,FaInfoCircle,FaChevronDown,FaUser,FaPlug,FaPhone,FaUserCircle, FaCog, FaSignOutAlt, FaUber, FaUsers } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
function Numbers() {
  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
  const navigate = useNavigate();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility

    const handleModalOpen = () => setShowModal(true); // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
  
  const [authenticated, setAuthenticated] = useState(null);
  const [n, setn] = useState('');
  const [areacode, setAreacode] = useState('');
  const [numbername, setNumbername] = useState('');
  const [processing, setProcessing] = useState(false);
  const [balanceerror, setBalancerror] = useState(false);
  const [ownnumbers, setNumbers] = useState([]);
  const [buynumberloading, setBuynumberloadingin] = useState(false);
  const [loadingMap, setLoadingMap] = useState({});
  const [numbers, setPhoneNumbers] = useState([]);
  const [repsucc, setRepsucc] = useState(false);
  
  const [id, setUserid] = useState('');
  const [apikey, setApikey] = useState('');
  useEffect(() => {
    const fetchreplies = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        setAuthenticated(true)
        setUserid(id)

        try {
          const response= await axios.post(`${API_BASE_URL}/api/fetch-numbers`, {
            id
          });
          setNumbers(response.data);
          // alert(response.data.apikey)
          setApikey(response.data.apikey)
        
        } catch (error) {
          
        }
      } else {
        navigate("/login");
      }
    };
  
    fetchreplies(); 
  }, []);
  const getnumbers = async (id,areacode,event) => {
    setProcessing(true)
    // setRepsucc(false)
    event.preventDefault();

    try {
      // Send POST request to add a list
      // await axios.post('${API_BASE_URL}/api/get-number', { id, areacode });
      const response = await axios.post(`${API_BASE_URL}/api/get-number`, { id, areacode });
        setPhoneNumbers(response.data);
        if (response.data == 1){
          alert('Please Attach Telnyx Api to Purchase Numbers')
          return;
        }
      setProcessing(false)
      setShowModal(false);
    } catch (error) {
      setProcessing(false)
      alert('Numbers Not Found')
      console.error('Error fetching numbers:');
    }
  };
  const handleBuyNumber = async (id,number,numbername,event) => {
    event.preventDefault()
    
    setBalancerror(false)

   
    const confirmed = window.confirm('Are you sure you want to buy this number?');
    if (!confirmed) {
      return; 
    }
    setLoadingMap(prevLoadingMap => ({
      ...prevLoadingMap,
      [number]: true
    }));
    try {
      const response = await axios.post(`${API_BASE_URL}/api/buy-number/${id}/${number}/${numbername}/`);
      if (response.data == 0){
        // setBalancerror(true)
        alert('Please Add Funds to your Telnyx account to purchase a number')
        setLoadingMap(prevLoadingMap => ({
          ...prevLoadingMap,
          [number]: false
        }));

      }else  if (response.data == 1){
          // setBalancerror(true)
          alert('Number Purchased Successfully')
          setLoadingMap(prevLoadingMap => ({
            ...prevLoadingMap,
            [number]: false
          }));
  
        
      }else  if (response.data == 2){
        // setBalancerror(true)
        alert('Please Attach Telnyx Api to Purchase this Number')
        setLoadingMap(prevLoadingMap => ({
          ...prevLoadingMap,
          [number]: false
        }));

      
    }
    
      // setRepsucc(false)
    } catch (error) {
      // setRepsucc(false)
    setLoadingMap(prevLoadingMap => ({
      ...prevLoadingMap,
      [number]: false
    }));

      console.error('Error deleting webhook:', error);
    }
  };
  const handleDelete = async (id,number) => {
    
    setRepsucc(false)

   
    const confirmed = window.confirm('Are you sure you want to delete this Number?');
    if (!confirmed) {
      return; 
    }
  
    try {
      await axios.delete(`${API_BASE_URL}/api/delete-number/${id}/${number}`);
      
    
      setNumbers(prevnumber => prevnumber.filter(item => item.number !== number));
      setRepsucc(true)
    } catch (error) {
      setRepsucc(false)

      console.error('Error deleting webhook:', error);
    }
  };
  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
    <Modal show={showModal} contentClassName="custom-modal-content" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <form onSubmit={(event) => getnumbers(id, areacode,event)}>
        <Modal.Body>
        <div className="text-center">
            <h4>Search Number</h4>
        </div>
        <div className="modal-body ">
            
              <div className="form-group">
                <label htmlFor="recipient-name" className="col-form-label">Number Name:</label>
                <input type="text" className="form-control" id="recipient-name" placeholder="Number Name" required value={numbername} onChange={(e) => setNumbername(e.target.value)}  />
              </div>
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Type:</label>
                <input type="text" name="type" className="form-control" value="Local" id="recipient-name"  readOnly />

              </div>
              <div className="form-group">
                <label htmlFor="message-text" className="col-form-label">Area Code:</label>
                <input type="text" className="form-control" id="recipient-name" placeholder="Area code" required value={areacode} onChange={(e) => setAreacode(e.target.value)}  />

              </div>
            
          </div>
        </Modal.Body>
        <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="submit" className="btn btn-primary save_web_btn" disabled={processing}>{processing ? 'Searching....' : 'Search'}</button>
        </Modal.Footer>
        </form>
      </Modal>
       
      
        <div className="row ">
          <div className="col-3" style={{marginRight: '-60px'}}>
          <Menu/>
    
          </div>
          <div className="col-9" style={{marginLeft:'-2px'}}>
            {/* <div class="serch_bar full_width">
        <div class="tab">
            <div routerlinkactive="active" placement="bottom" show-delay="500" class="tab-item active">
                <a class="link disabled" href="/app/setting/profile">User Profile</a>
                <div class="strip"></div>
            </div>
        </div>
        </div> */}
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;Numbers</h5>
        {repsucc && <div className="col-12 mb-3" style={{color: '#65ff16'}}>Number Deleted Successfuly !</div>}

            {ownnumbers.length === 0 && <div className="col-md-12 text-center">
      <div className="mb-2 ml-2" style={{color:'white',fontSize:'25px'}}>No Numbers Found</div>
            </div>}
          <div class="table-responsive text-nowrap">
    <table class="table">
      <thead>
      {ownnumbers.length > 0&&  <tr>
         
                  <th>Name</th>
                  <th>Number</th>
                  <th>Action</th>
        </tr>}
      </thead>
      <tbody class="table-border-bottom-0">
      {ownnumbers.map((item) => (
          <tr key={item.id}>
            <td>{item.name}</td>
            <td>{item.number}</td>
            
            
            <td>
              {/* <img src={deletes} className="mt-3" alt="Delete"  /> */}
              <img src={deletes} className="mt-3" alt="Delete" onClick={() => handleDelete(id,item.number)} />
            </td>
          </tr>
        ))}
                                        </tbody>
    </table>
    <div class="pagnination">
          
      </div>
  </div>
        </div>
        <div className="userPasswordChange mt-5" style={{height:'auto'}}>
          <form noValidate >
            <h5>Buy Phone Number</h5>
            {balanceerror && <div class="col-12 text-danger"> Sorry Your Balance is to low </div>}
            <div className=" mb-2" style={{textAlign:'right'}}>
          
          <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Buy Number
          </button>
      </div>
            
            {/* <p style={{color: '#cfd3ecc9'}}> Create your Free Telnyx Account by visiting <a href="https://telnyx.com/sign-up" style={{color: '#fff',
        fontWeight: '600'}} target="blank">https://telnyx.com/sign-up</a> </p> */}
            <div className="row">
            
            <div class="table-responsive text-nowrap">
    <table class="table">
      <thead>
        {numbers.length > 0 && <tr>
                  <th style={{padding:'0px'}}>Numbers</th>
                  {/* <th style={{padding:'0px'}}>Monthly Cost</th> */}
                  <th style={{padding:'0px'}} class="text-center">Features</th>

                  <th>Action</th>
        </tr>
  }
      </thead>
      <tbody class="table-border-bottom-0">
               
      {numbers.map(numbers => (
          <tr key={numbers.phone_number}>
            <td style={{padding:'0px'}}>{numbers.phone_number}</td>
            {/* <td style={{padding:'0px'}} class="text-center">$ {numbers.monthly_cost}</td> */}
            <td style={{padding:'0px'}}>{numbers.features}</td>
           
            <td>
        {/* <button onClick={(event) => handleBuyNumber(id, numbers.phone_number, numbername,event)}
 className="btn btn-primary"disabled={buynumberloading}>{buynumberloading ? 'Purchasing....' : 'Purchase'}</button> */}
      <button
                onClick={(event) => handleBuyNumber(id, numbers.phone_number, numbername, event)}
                className="btn btn-primary"
                disabled={loadingMap[numbers.phone_number]}
              >
                {loadingMap[numbers.phone_number] ? 'Purchasing...' : 'Purchase'}
              </button>
      </td>
          </tr>
        ))}
                       
                                        </tbody>
    </table>
 
  </div>
      
      
    </div>
    
           
          </form>
        </div>
        </div>
          
      </div>
     
          {/* Additional content for Page 1 */}
        </Sidebar>
        );
      }
    
}

export default Numbers;
{/* <div class="table-responsive text-nowrap">
    <table class="table">
      <thead>
        <tr>
          <th>Id</th>
                  <th>Number</th>
                  <th>Action</th>
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
                                                <tr>
                      <td>2</td>
                      <td>+19314009320</td>
                      <td>
                          <a href="https://app.prophone.io/delete_number/2"  class="btn btn-danger">Delete</a>
                      </td>
                  </tr>
                                                    <tr>
                      <td>28</td>
                      <td>+16154889870</td>
                      <td>
                          <a href="https://app.prophone.io/delete_number/28"  class="btn btn-danger">Delete</a>
                      </td>
                  </tr>
                                                    <tr>
                      <td>29</td>
                      <td>+19312360183</td>
                      <td>
                          <a href="https://app.prophone.io/delete_number/29"  class="btn btn-danger">Delete</a>
                      </td>
                  </tr>
                                        </tbody>
    </table>
    <div class="pagnination">
          
      </div>
  </div> */}