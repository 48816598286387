import React from 'react';
import { Bar } from 'react-chartjs-2';
import { Chart as ChartJS } from 'chart.js/auto'
import { Chart }            from 'react-chartjs-2'
import { Pie } from 'react-chartjs-2';

const BarGraph = ({ data }) => {
  const labels = data.map(entry => entry[0]);
  const values = data.map(entry => entry[1]);
  
  // Chart data
  const chartData = {
    labels: labels,
    datasets: [
      {
        label: 'Total Contacts',
        color:'white',
        data: values,
        backgroundColor: '#f9b840', 
        borderColor: '#f9b840', 
        borderWidth: 1,
      },
    ]
    
  };

 
  const chartOptions = {
    indexAxis: 'x',
    scales: {
        x: {
            ticks: {
                color: "white",
                stepSize: 1,
                beginAtZero: true
            }
        }

    },
    plugins: {
        legend: {
          display: false,
            labels: {
                color: "white",
                fontSize: 18
            }
        },

    }, tooltip: {
        enabled: false,
        color: 'white' // Text color for tooltip
      }
  };

  return (
    <div>
      <Bar height={200} data={chartData} options={chartOptions} />

    </div>
  );
};

export default BarGraph;
