import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import Container from 'react-bootstrap/Container';
import Nav from 'react-bootstrap/Nav';
import Navbar from 'react-bootstrap/Navbar';
import NavDropdown from 'react-bootstrap/NavDropdown';
import Sidebar from '../components/navbar';
import { FaAngleRight,FaInfoCircle,FaChevronDown,FaUser,FaPlug,FaPhone,FaUserCircle, FaCog, FaSignOutAlt, FaUber, FaUsers } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';


function Smstempalte() {
  useEffect(() => {
    // change background color with a random color
    // const color = Math.floor(Math.random()*16777215).toString(16);
    // document.body.style.background = color;
    document.body.style.backgroundColor = "rgb(42,45,54)";
  }, []);
    const [text, setText] = useState('');
  const maxLength = 160;

  const handleChange = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setText(inputValue);
    }
};
  const wordCount = text.trim().split(/\s+/).filter(Boolean).length;

  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility

    const handleModalOpen = () => setShowModal(true); // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
  
  const [authenticated, setAuthenticated] = useState(null);
  const messages = [
    "Hi there",
    "Hello",
    "How are you?",
    "Welcome!"
  ];
  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("login");
    if (loggedInUser) {
      setAuthenticated(loggedInUser);
    } else {
      // Redirect to login if not authenticated
      navigate("/login");
    }
  }, []);

  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>
   <Modal show={showModal} contentClassName="custom-modal-content modal-lg" onHide={handleModalClose} backdropClassName="custom-backdrop" >
        <Modal.Header closeButton>
        </Modal.Header>
        <Modal.Body>
        <div className="text-center">
            <h4>Messages</h4>
        </div>
        <div className="modal-body">
      {messages.map((message, index) => (
        <div className="mb-2" key={index}>{`${index + 1},  ${message}`} <hr></hr></div>
       
      ))}
    </div>
        
        </Modal.Body>
        {/* <Modal.Footer className="justify-content-center mb-2">
            <button type="button" className="btn btn-secondary cancel_btn" onClick={handleModalClose} data-dismiss="modal" >Close</button>
            <button type="button" className="btn btn-primary save_web_btn" >Save</button>
        </Modal.Footer> */}
      </Modal>
       
          <section className="">
      <div className="container" style={{marginLeft: '0px'}}>
        <div className="row ">
          <div className="col-3" style={{marginRight: '-60px'}}>
          <Menu/>
    
          </div>
          <div className="col-9" style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
        <div className="tab">
      <div className={`tab-item ${location.pathname === '/sms_settings' ? 'active' : ''}`}>
        <Link to="/sms_settings" className="link disabled">DNC Manager</Link>
        {location.pathname === '/sms_settings' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/quick_reply' ? 'active' : ''}`}>
      <Link to="/quick_reply"  className="link disabled">Quick Reply</Link>
        {location.pathname === '/quick_reply' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/sms_template' ? 'active' : ''}`}>
      <Link to="/sms_template"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_template' && <div className="strip"></div>}
      </div>
      
    </div>
    </div>
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;SMS Template</h5>
            <div className=" mb-2" style={{textAlign:'right'}}>
          
            <Link to="/create_template" type="button"  className="btn btn_primary save_btn"   >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Template
          </Link>
          </div>
          <div class="table-responsive text-nowrap mt-3">
    <table class="table" >
      <thead>
        <tr>
          {/* <th>Id</th> */}
                  <th>Name</th>
                  <th>Message</th>
                  <th>Backup Message</th>
                  <th></th>
                  {/* <th>List</th> */}
        </tr>
      </thead>
      <tbody class="table-border-bottom-0">
                                                <tr>
                      {/* <td>2</td> */}
                      <td>Morning</td>
                      <td>
                     
                          Hello Good Morning  <FaAngleRight onClick={handleModalOpen}></FaAngleRight>

                        </td>
                      <td>Hello This, is me Happy Morning to you !</td>
          <a href="" ><img src={deletes} className="mt-3"></img></a>
                      <td></td>
                      
                  </tr>
                  <tr>
                      {/* <td>2</td> */}
                      <td>Night</td>
                      <td>Hello Good Night</td>
                      <td>Hello This, is me Happy Morning to you !</td>
                      <td>
          <a href="" ><img src={deletes} className="mt-3"></img></a>

                      </td>
                      
                  </tr>
                  
                                        </tbody>
    </table>
    <div class="pagnination">
          
      </div>
  </div>
        </div>
        
        </div>
          
      </div>
      </div>
    </section>
          {/* Additional content for Page 1 */}
        </Sidebar>
        );
      }
    
}

export default Smstempalte;
