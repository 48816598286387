import React from 'react';
import { Pie } from 'react-chartjs-2';

const Piegraph = ({ data }) => {
  // Extract labels and values from the data
  const labels = data.map(entry => entry[0]);
  const values = data.map(entry => entry[1]);
  // alert(labels)
  // Define a color map for your specific labels
  const colorMap = {
    'DNC': 'red', // Custom color for DNC
    'Not Interested': '#bbbbbb', // Custom color for Not Interested
    'New': '#6cff6cd1', // Custom color for New
    'Hot': '#f6775b', // Custom color for Hot
    'Follow UP': '#a600e0', // Custom color for Follow UP
    'Warm' : '#ffa43a'
    // Add more label colors as needed
  };

  // Assign colors based on the labels
  const backgroundColors = labels.map(label => colorMap[label.split(' : ')[0]] || 'rgba(153, 102, 255, 0.6)');
  const borderColors = labels.map(label => colorMap[label.split(' : ')[0]] ? colorMap[label.split(' : ')[0]].replace('0.6', '0.4') : 'rgba(153, 102, 255, 0.4)');

  // Chart data
  const chartData = {
    labels: labels,
    datasets: [
      {
        data: values,
        backgroundColor: backgroundColors,
        borderColor: borderColors,
        borderWidth: 1,
      },
    ],
  };

  // Chart options
  const chartOptions = {
    plugins: {
      legend: {
        display: true,
        position: 'bottom',
        labels: {
          color: "white",
          fontSize: 18,
          align: "start",
        }
      }
    }
  };

  return (
    <>
      <Pie width={400} height={400} data={chartData} options={chartOptions} />
    </>
  );
};

export default Piegraph;
