import React, { useEffect, useState } from "react";
import { Link, useNavigate } from 'react-router-dom';
import loading from '../icons/loading.gif';
import axios from 'axios';

import Sidebar from '../components/navbar';
import { FaTrash,FaInfoCircle,FaChevronDown,FaUser,FaPlug,FaPhone,FaUserCircle, FaCog, FaSignOutAlt, FaUber, FaUsers } from 'react-icons/fa';
import deletes from '../icons/delete.svg';
import add from '../icons/add.svg';
import { Modal,Button } from 'react-bootstrap'
import Menu from '../components/menu';
import { useLocation } from 'react-router-dom';
import API_BASE_URL from '../components/config';


function Createtemplate() {
  const [id, setUserid] = useState('');
 
  useEffect(() => {
    document.body.style.backgroundColor = "rgb(42, 45, 54)";
    const fetchwebhooks = async () => {
      const id = sessionStorage.getItem("login");
      
      if (id) {
        
        setUserid(id)
        
      } else {
        navigate("/login");
      }
    };
  
    fetchwebhooks(); 
  }, []);
  const [loadingchk, setLoading] = useState(false);

  const maxLength = 160;
  const initialMessages = Array.from({ length: 1 }, () => '');
  const [messages, setMessages] = useState(initialMessages);
  const [templatename, setTemplateName] = useState('');
  const [backuptext, setBackuptext] = useState('');
  const maxRows = 20;
  const handleChange = (index, e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[index] = inputValue;
        return updatedMessages;
      });
    }
  };

  const wordCounts = messages.map((message) =>
    message.trim().split(/\s+/).filter(Boolean).length
  );
  const deleteMessage = (index) => {
    setMessages((prevMessages) => {
      const updatedMessages = prevMessages.filter((_, i) => i !== index);
      return updatedMessages;
    });
  };
  
  const handleButtonClick = (variable, index) => {
    const updatedMessage = messages[index] + `{{${variable}}}`;
    if (updatedMessage.length <= maxLength) {
      setMessages((prevMessages) => {
        const updatedMessages = [...prevMessages];
        updatedMessages[index] = updatedMessage;
        return updatedMessages;
      });
    }
  };

  const addMessageRow = () => {
  //  const complen = messages.length + 1
    if (messages.length < maxRows) {
      // alert(messages.length)
      setMessages((prevMessages) => [...prevMessages, '']);
    }else{
      alert("Messages Limit Is Executed !")
    }
  };
  
  const handleChangebackup = (e) => {
    const inputValue = e.target.value;
    if (inputValue.length <= maxLength) {
      setBackuptext(inputValue);
    }
};
  // const wordCount = text.trim().split(/\s+/).filter(Boolean).length;

  const [formSubmitted, setFormSubmitted] = useState(false);

  const handleSubmit = async (e) => {
    if (messages.length < 1){
      alert("Enter Atleast 1 Message")
      return;
    }
  setLoading(true)
    e.preventDefault();
    
   
    const response= await axios.post(`${API_BASE_URL}/api/create-template`, {
      userid:id,
      templatename: templatename, // Assuming you have a state variable named templateName
      backuptext: backuptext,
      messages: messages.map((message, index) => ({ 
        text: message, 
        length: message.length 
      }))
    });
      setLoading(false)
      window.location.href ="/sms_cam_templates"
    
  };
  
  const navigate = useNavigate();
  const location = useLocation();
  const [showModal, setShowModal] = useState(false); // State to control the modal's visibility

    const handleModalOpen = () => setShowModal(true); // Function to open the modal
    const handleModalClose = () => setShowModal(false); // Function to close the modal
  
  const [authenticated, setAuthenticated] = useState(null);

  useEffect(() => {
    const loggedInUser = sessionStorage.getItem("login");
    if (loggedInUser) {
      setAuthenticated(loggedInUser);
    } else {
      // Redirect to login if not authenticated
      navigate("/login");
    }
  }, []);

  if (!authenticated) {
    // Return null or loading indicator while checking authentication
    return null;
  } else {
    return (
        <Sidebar>

        {loadingchk && (
          <>
        <div class="loading-container">
    <img src={loading} alt="Loading..." class="loading-gif"></img>
  </div>
  </>
  )}
        
        <div className="row ">
         
          <div className="col-12" style={{marginLeft:'-2px'}}>
          <div class="serch_bar full_width">
          <div className="tab">
      <div className={`tab-item ${location.pathname === '/campaign' ? 'active' : ''}`}>
        <Link to="/campaign" className="link disabled">Campaign</Link>
        {location.pathname === '/campaign' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/audience' ? 'active' : ''}`}>
      <Link to="/audience"  className="link disabled">Audience</Link>
        {location.pathname === '/audience' && <div className="strip"></div>}
      </div>
      <div className={`tab-item ${location.pathname === '/sms_cam_templates' ? 'active' : ''}`}>
      <Link to="/sms_cam_templates"  className="link disabled">SMS Template</Link>
        {location.pathname === '/sms_cam_templates' && <div className="strip"></div>}
      </div>
     
    </div>
    </div>
        
        <div className="userEditForm mt-4">
            <h5>&nbsp;Create Template</h5>
            
           
            <div  className="mt-3 mb-3" style={{display:'flex',
    border: '1px solid #808080bf',
    padding: '12px 15px 1px 19px',
    borderRadius: '3px',
}}>
            {/* <p>Minimum 5 is Required  </p>&nbsp; */}
            <p>Total Messages : <span className="def-countl">{messages.length}</span></p>
            </div>
            {formSubmitted && messages.length < 5 && (
        <div className="row mt-2">
          <div className="col-12">
            <p className="alert alert-danger">Minimum 5 Messages is Required</p>
          </div>
        </div>
      )}
            <div className=" mb-2" style={{textAlign:'right'}}>
          
          {/* <button type="button" className="btn btn_primary save_btn" onClick={handleModalOpen} data-toggle="modal" data-target="#newwebhookmodal"  >
          <img src={add} alt="" className="mr-3" />&nbsp;&nbsp;Create Template
          </button> */}
          </div>
          <div>
          <form className="profile_form" onSubmit={handleSubmit} >
        
        <div className="row">
          
          <div className="col-md-4 form-group with_210">
            <label className="mb-2 ml-2">Template Name *</label>
            <input placeholder="Template Name" type="text" id="temp-name" name="templatename" value={templatename} onChange={(e) => setTemplateName(e.target.value)}   className="form-control"  required />
          </div>
          <br></br>
          <br></br>
          <div>
      {messages.map((message, index) => (
        <div className="row mt-4 mb-4" key={index} style={{border:'1px solid #afafaf21',
        margin: '-2px',
        borderRadius: '14px'}}>
          <div className="col-8">
            <div className="mt-3 mb-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label>Message:</label>
              <div style={{ textAlign: 'right' }}>
                <label className={message.length >= maxLength ? 'custom-countl' : 'def-countl'}>
                  {message.length} / {maxLength}&nbsp;&nbsp;
                  <span>Characters</span>
                </label>
              </div>
            </div>
            <div className="form-group">
              <textarea  
              id="message-area"
                type="text"
                value={message}
                onChange={(e) => handleChange(index, e)}
                className="form-control mb-4"
                name={`message${index}`}
                style={{height: '103px',
                  backgroundColor: '#585858ba '}}
                  required
              />
              {/* <div>Word Count: {wordCounts[index]}</div> */}
            </div>
          </div>
          <div className="col-4 mt-4">
          <div className="form-group mb-3" style={{float:'right'}}>
          {index !== 0 && (
  <button className="btn btn-danger float-right" style={{ width: '50px' }} onClick={() => deleteMessage(index)}>
    <FaTrash />
  </button>
)}

            </div>
            <div className="form-group mt-3">
              <div className="disabl_field">
                <div className="form-group d-flex  mb-1" style={{placeContent: 'space-evenly'}}>
                  <input
                    type="button"
                    className="custom-cls-bg"
                    value="First Name"
                    id="first_name"
                    onClick={() => handleButtonClick('first_name', index)}
                  />
                  <input
                    type="button"
                    className="custom-cls-bg"
                    value="Last Name"
                    id="last_name"
                    onClick={() => handleButtonClick('last_name', index)}
                  />
                </div>
                <div className="labelCheckList" style={{placeContent: 'space-evenly'}}>
                  <div className="form-group d-flex " style={{placeContent: 'space-evenly'}}>
                    <input
                      type="button"
                      className="custom-cls-bg"
                      value="State"
                      id="state"
                      onClick={() => handleButtonClick('state', index)}
                    />
                    <input
                      type="button"
                      className="custom-cls-bg"
                      value="City"
                      id="city"
                      onClick={() => handleButtonClick('city', index)}
                    />
                  </div>
                </div>
                <div className="labelCheckList" >
                  <div className="form-group d-flex " style={{placeContent: 'space-evenly'}}>
                    <input
                      type="button"
                      className="custom-cls-bg"
                      value="Address"
                      id="address"
                      onClick={() => handleButtonClick('property_address', index)}
                    />
                    <input
                      type="button"
                      className="custom-cls-bg"
                      value="Zip"
                      id="zip"
                      onClick={() => handleButtonClick('zip', index)}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      ))}
      <div className="row mt-4 mb-4"  style={{border:'1px solid #afafaf21',
        margin: '-2px',
        borderRadius: '14px'}}>
          <div className="col-8">
            <div className="mt-3 mb-2" style={{ display: 'flex', justifyContent: 'space-between' }}>
              <label>Backup Text:</label>
              <div style={{ textAlign: 'right' }}>
              <label className={backuptext.length >= maxLength ? 'custom-countl' : 'def-countl' } >
                    {backuptext.length} / {maxLength}&nbsp;&nbsp; 
                    <span>Characters</span></label>
             
              </div>
            </div>
            <div className="form-group">
              <textarea 
              
              id="message-area"
                type="text"
                value={backuptext}
                
                onChange={handleChangebackup}
                className="form-control mb-4"
                name="backupmessage"
                style={{height: '103px',
                  backgroundColor: '#585858ba '}}
                  required
              />
            </div>
          </div>
         
        </div>
      <div className="row mt-2">
        <div className="col-12">
          <button   type="button" className="btn btn-primary" onClick={addMessageRow}>
           <img src={add}></img>&nbsp;&nbsp;Add Message
          </button>
        </div>
      </div>
    </div>
        </div>
        <br></br>
        
        <div className="modal_btn prifile_btn text-center mt-4 mb-2">
          
          <button  type="submit" className="btn btn_primary save_btn" >
            <img  alt="" /> Save Template
          </button>
        </div>
      </form>
          </div>
        </div>
        
        </div>
          
      </div>
     
          {/* Additional content for Page 1 */}
        </Sidebar>
        );
      }
    
}

export default Createtemplate;
